import TextField from '@material-ui/core/TextField';
import { Divider, FormControlLabel, FormHelperText, MenuItem, Radio, RadioGroup, Select, SelectChangeEvent, TextareaAutosize, Typography } from '@mui/material';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { IAccountInfo } from 'react-aad-msal';
import { getInitialProps, useTranslation } from 'react-i18next';
import { IFormValidationObject, IVaccinsSectionProps } from '../../VKI/Interfaces/IForm';
import { IDuitseOndertekening, IVKI, IVaccins } from '../../VKI/Interfaces/IVKI';
import { IVKIFormItem } from '../../VKI/Interfaces/VKIForm';
import { VKIUtil } from '../../utils/vki';
import CustomDatePicker from '../CustomDatePicker';
import { FileUpload } from '../common/FileUpload';
import { Valideren } from '../common/Validatie';

const VaccinsDataSection: React.FC<IVaccinsSectionProps> = ({ vkiData, accountInfo, onChange, isReadonly, groupClaim, ziekteOptions, registartieNummerOptions, salmonellaStatusOptions, methodOptions, validateObject, setValidationObject }) => {
    const { t } = useTranslation();
    const [BijlageError, setBijlageError] = useState<boolean>(false);
    const [BijlageErrorString, setBijlageErrorString] = useState<string>('');
    const [OpmerkingError, setOpmerkingError] = useState<boolean>(false);
    const [OpmerkingErrorString, setOpmerkingErrorString] = useState<string>('');
    const [DuitseOntertekeningError, setDuitseOntertekeningError] = useState<boolean>(false);
    const [DuitseOntertekeningErrorString, setDuitseOntertekeningErrorString] = useState<string>('');
    const [DuitseOntertekeningErrorAufgezogenIn, setDuitseOntertekeningErrorAufgezogenIn] = useState<boolean>(false);
    const [DuitseOntertekeningErrorAufgezogenInString, setDuitseOntertekeningErrorAufgezogenInString] = useState<string>('');
    const intialProps = getInitialProps();
    const placeholderstring: string = t("VoegOpmerkingToe") === undefined ? "" : t("VoegOpmerkingToe");

    const [selectedValue, setSelectedValue] = useState('');
  
    const [IsEindDatumError, setIsEindDatumError] = useState<boolean>(false);
    const [IsEindDatumError1, setIsEindDatumError1] = useState<boolean>(false);
    const [IsEindDatumError2, setIsEindDatumError2] = useState<boolean>(false);
    const [IsEindDatumError3, setIsEindDatumError3] = useState<boolean>(false);
    const [IsEindDatumError4, setIsEindDatumError4] = useState<boolean>(false);
    const [IsEindDatumErrorString, setIsEindDatumErrorString] = useState<string>('');
    const [IsEindDatumErrorString1, setIsEindDatumErrorString1] = useState<string>('');
    const [IsEindDatumErrorString2, setIsEindDatumErrorString2] = useState<string>('');
    const [IsEindDatumErrorString3, setIsEindDatumErrorString3] = useState<string>('');
    const [IsEindDatumErrorString4, setIsEindDatumErrorString4] = useState<string>('');

    // initial check
    useEffect(() => {
      if(vkiData) {
        isVaccinsDataValid(vkiData);

        // check if one of the duitseondertekening booleans is true so the selected value can be set.
        if(vkiData.DuitseOndertekening) {
          if(vkiData.DuitseOndertekening.GeborenIn)setSelectedValue("GeborenIn");
          if(vkiData.DuitseOndertekening.Aufgezogen)setSelectedValue("Aufgezogen");
          if(vkiData.DuitseOndertekening.GeborenAufgezogenIn)setSelectedValue("GeborenAufgezogenIn");
        }
      }
    }, [vkiData]);

    const handleRadioChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setSelectedValue(event.target.value);
      const {name, value} = event.target;
      onChange((prevVkiData: IVKI) => {
        const updatedVki: IVKI = prevVkiData;
        if(updatedVki.DuitseOndertekening) {
          const selectedRadio = updatedVki.DuitseOndertekening[(value as keyof IDuitseOndertekening)];

          if(selectedRadio !== true) {

            // first set everything on false because its switching....
            updatedVki.DuitseOndertekening.GeborenIn = false;
            updatedVki.DuitseOndertekening.GeborenAufgezogenIn = false;
            updatedVki.DuitseOndertekening.Aufgezogen = false;


            // then update the new value to true because its set..
            updatedVki.DuitseOndertekening = {
              ...updatedVki.DuitseOndertekening,
              [value]: true
            };
          } 

          // check if 
          if(!updatedVki.DuitseOndertekening.Aufgezogen) {
            updatedVki.DuitseOndertekening.AufgezogenIn = '';
          }
        }
         
         isVaccinsDataValid(updatedVki);

         return updatedVki;
       });
       
    };

    
  const isVaccinValid = (data: IVKI): void => {
    let isValid: boolean = false;
  
    if (data && data.Vaccins && data.Gezondheid.length > 0) {
      isValid = true;
  
      data.Vaccins.forEach((vaccins: IVaccins, index: number) => {
        if (vaccins.Ziekte || (vaccins.Ziekte && vaccins.Ziekte.trim() !== '')) {
         

          if(vaccins.Datum && vaccins.Datum !== undefined && vaccins.WettelijkeWachttermijn && vaccins.WettelijkeWachttermijn > 0) {
            const eidDatumWettelijkWachttermijnString = Valideren.valideerVaccinEindDatumWettelijkWachttermijn(data, index + 1, true);
            setEindDatumErrorMessage(eidDatumWettelijkWachttermijnString.toString(), index);
            if (eidDatumWettelijkWachttermijnString !== '') {
              isValid = false;
            }
          }

          
        }
      });
  
      // Update the validation object
      setValidationObject((prevValidationObject: IFormValidationObject) => ({
        ...prevValidationObject,
        VaccinsDataSection: isValid,
      }));
    }
  };

  const setEindDatumErrorMessage = (validationString: string, index: number): void => {
    switch (index) {
      case 0:
        if(validationString !== '') {
          setIsEindDatumError(true);
          setIsEindDatumErrorString(validationString.toString());
        } else {
          setIsEindDatumError(false);
          setIsEindDatumErrorString('');
        }
        break;
      case 1:
        if(validationString !== '') {
          setIsEindDatumError1(true);
          setIsEindDatumErrorString1(validationString.toString());
        } else {
          setIsEindDatumError1(false);
          setIsEindDatumErrorString1('');
        }
        break;
      case 2:
        if(validationString !== '') {
          setIsEindDatumError2(true);
          setIsEindDatumErrorString2(validationString.toString());
        } else {
          setIsEindDatumError2(false);
          setIsEindDatumErrorString2('');
        }
        break;
      case 3:
        if(validationString !== '') {
          setIsEindDatumError3(true);
          setIsEindDatumErrorString3(validationString.toString());
        } else {
          setIsEindDatumError3(false);
          setIsEindDatumErrorString3('');
        }
        break;
      case 4:
        if(validationString !== '') {
          setIsEindDatumError4(true);
          setIsEindDatumErrorString4(validationString.toString());
        } else {
          setIsEindDatumError4(false);
          setIsEindDatumErrorString4('');
        }
        break;
      default:
        break;
    }
  };

    const handleInputChange = (date: Date | null, name:string, index: number) => {
      onChange((prevVkiData: IVKI) => ({
        ...prevVkiData,
        Vaccins: (prevVkiData.Vaccins || []).map((VaccinsItem: IVaccins, itemIndex: number) => {
          if (itemIndex === index) {

            return {
              ...VaccinsItem,
              [name]: date !== null  ? new Date(date) : undefined,
            };
          }
          prevVkiData.Vaccins[index].Datum  =  date !== null  ? new Date(date) : undefined
          isVaccinValid(prevVkiData);
          return VaccinsItem;
        }),
      }));
    };

    

  const handleFileUpload = (updatedVKI: IVKI) => {
    onChange((prevVkiData: IVKI) => ({
      ...prevVkiData,
      Bijlagen: updatedVKI.Bijlagen,
    }));
  };

    
    const handleAufgezogenInInputChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const {value} = event.target;

      onChange((prevVkiData: IVKI) => {
        const updatedVki: IVKI = prevVkiData;
        if(updatedVki.DuitseOndertekening) {
          updatedVki.DuitseOndertekening.AufgezogenIn = value;
        }
 
         isVaccinsDataValid(updatedVki);
 
         return updatedVki;
       });
    }
    

    const handleSalmonellaSelectChanges = (event: SelectChangeEvent<string>) => {
      const { name, value } = event.target;

      onChange((prevVkiData: IVKI) => {
      const updatedVki: IVKI = prevVkiData;

      const selectedStatusOption = salmonellaStatusOptions?.find((statusOption: IVKIFormItem) => statusOption.ExterneId === value && statusOption.Taal?.toLowerCase() === intialProps.initialLanguage.toLowerCase());

      
        if(selectedStatusOption)
        {
          if(name === "SalmonellaStatusOverschoen") {
            updatedVki.Salmonella.SalmonellaStatusOverschoen = selectedStatusOption.Titel; 
            updatedVki.Salmonella.SalmonellaStatusOverschoenExterneId = selectedStatusOption.ExterneId; 
          } else {
            updatedVki.Salmonella.SalmonellaStatusInlegvel = selectedStatusOption.Titel; 
            updatedVki.Salmonella.SalmonellaStatusInlegvelExterneId = selectedStatusOption.ExterneId; 
          }
        } else { 
          if(value === 'empty') {
            if(name === "SalmonellaStatusOverschoen") {
              updatedVki.Salmonella.SalmonellaStatusOverschoen = ''; 
              updatedVki.Salmonella.SalmonellaStatusOverschoenExterneId = ''; 
            } else {
              updatedVki.Salmonella.SalmonellaStatusInlegvel = ''; 
              updatedVki.Salmonella.SalmonellaStatusInlegvelExterneId = ''; 
            } 
          }
        }

        isVaccinsDataValid(updatedVki);

        return updatedVki;
      });
    };
    const handleSalmonellaTextAreaChanges = (event: ChangeEvent<HTMLTextAreaElement>) => {
        const { name, value } = event.target;

        onChange((prevVkiData: IVKI) => {
          const updatedVki: IVKI = prevVkiData;

          updatedVki.Salmonella.Opmerkingen = value;

          isVaccinsDataValid(updatedVki);

          return updatedVki;
        });
    };
    
    function getItemByTitle(array:any, title:string) {
      for (let i = 0; i < array.length; i++) {
        if (array[i].Titel === title) {
          return array[i];
        }
      }
      return null;
    }

    const createNewVaccinsObject = (name?: keyof IVaccins, value?: any): IVaccins => {
      const newObject: Partial<IVaccins> = {
        Ziekte: '',
        MerkTypeRegistratienummer: '',
        WettelijkeWachttermijn: 0,
        Methode: '',
        Datum: undefined,
      };
    
      if (name && value) {
        newObject[name] = value;
      }
    
      return newObject as IVaccins;
    };
    
    const handleSelectChanges = (event: SelectChangeEvent<string>, index: number) => {
      const { value, name } = event.target;
    
      onChange((prevVkiData: IVKI) => {
        const updatedVki: IVKI = prevVkiData;

        let updatedVaccins = prevVkiData.Vaccins || [];
    
        if (updatedVaccins.length <= 0 || updatedVaccins === null || updatedVaccins === undefined) {
          // Create a new vaccins object if it is null or empty
          updatedVaccins = [createNewVaccinsObject(name as keyof IVaccins, value)];
        } else if (index >= 0 && index < updatedVaccins.length) {
          updatedVaccins[index] = {
            ...updatedVaccins[index],
            [name]: value,
          };
        }

        if(name === 'Ziekte') {
          const selectedItemData = ziekteOptions?.find((ziekte: IVKIFormItem) => ziekte.ExterneId === value && ziekte.Taal?.toLowerCase() === intialProps.initialLanguage.toLowerCase());
          if(selectedItemData !== undefined) {
            updatedVaccins[index] = {
              ...updatedVaccins[index],
              ZiekteExterneId: selectedItemData.ExterneId,
              Ziekte: selectedItemData.Titel,
            };
          } 
        }
        if(name === 'Methode') {
          const selectedItemData = methodOptions?.find((methode: IVKIFormItem) => methode.ExterneId === value  && methode.Taal?.toLowerCase() === intialProps.initialLanguage.toLowerCase());
          if(selectedItemData !== undefined) {
            updatedVaccins[index] = {
              ...updatedVaccins[index],
              MethodeExterneId: selectedItemData.ExterneId,
              Methode: selectedItemData.Titel,
            };
          } 
        }
        if(name === 'MerkTypeRegistratienummer') {
          const selectedItemData = registartieNummerOptions?.find((registratieNummer: IVKIFormItem) => registratieNummer.ExterneId === value  && registratieNummer.Taal?.toLowerCase() === intialProps.initialLanguage.toLowerCase());
          if(selectedItemData !== undefined) {
            updatedVaccins[index] = {
              ...updatedVaccins[index],
              MerkTypeRegistratienummerExterneId: selectedItemData.ExterneId,
              MerkTypeRegistratienummer: selectedItemData.Titel,
              WettelijkeWachttermijn: selectedItemData.Wachttermijn
            };
          } 
        }
    
    
        if (name === 'RegnrGebruikteGeneesmiddel') {
          const selectedItemData = getItemByTitle(ziekteOptions, value);
          if (selectedItemData !== null) {
            updatedVaccins[index] = {
              ...updatedVaccins[index],
              WettelijkeWachttermijn: selectedItemData.Wachttermijn,
            };
          }
        }

        if(value === 'empty') {
          if(name === 'Ziekte') {
            updatedVki.Vaccins.length = index + 1;
            updatedVki.Vaccins[index] = {
              ...updatedVki.Vaccins[index],
              [name]: '',  
              ZiekteExterneId: '',
              Datum: undefined,
            }  
          } else if(name === 'MerkTypeRegistratienummer') {
            updatedVki.Vaccins[index] = {
              ...updatedVki.Vaccins[index],
              [name]: '',
              MerkTypeRegistratienummerExterneId: '',
            }
          } else { 
            updatedVki.Vaccins[index] = {
              ...updatedVki.Vaccins[index],
              [name]: '',
              MethodeExterneId: '',
            }  
          }
        }
        
        updatedVki.Vaccins = updatedVaccins;

        if (index < 4 && name === 'Ziekte' && updatedVaccins.length < 5) {
          VKIUtil.formatVaccins(updatedVki);
        }


        isVaccinsDataValid(updatedVki);

        return updatedVki;
      });
    };

    const isVaccinsDataValid = (VkiObject: IVKI): void => {
      let isValid: boolean = true; // Initialize to true, as we need at least one valid item
      if (VkiObject && VkiObject.Vaccins && VkiObject.Vaccins.length > 0) {
        VkiObject.Vaccins.forEach((vaccin: IVaccins, index: number) => {
          if (vaccin.Ziekte && vaccin.Ziekte.trim() !== '') {
            // if ziekte is set check if method is set
            if(!vaccin.Methode || vaccin.Methode.trim() === '') {
              isValid = false;
            }
          } else {
            isValid = true;
          }
        });
      }

      // salmonella check
      if (VkiObject && VkiObject.Salmonella) {
        if(!VkiObject.Salmonella.SalmonellaStatusInlegvelExterneId || VkiObject.Salmonella.SalmonellaStatusInlegvelExterneId.trim() === '') {
          isValid = false;
        }
        if(!VkiObject.Salmonella.SalmonellaStatusOverschoenExterneId || VkiObject.Salmonella.SalmonellaStatusOverschoenExterneId.trim() === '') {
          isValid = false;
        }
      }

      // bijlagen check
      // const validatieBijlage:string = Valideren.valideerBijlagenSalmonella(data).toString();
      // if(validatieBijlage !== '') {
      //   setBijlageError(true);
      //   setBijlageErrorString(validatieBijlage);
      //   isValid = false;
      // } else {
      //   setBijlageError(false);
      //   setBijlageErrorString(validatieBijlage);
      // }

      // valideer opmerkingen moet gezet zijn als er geen vaccins ingevuld zijn...
      // const valideerOpmerkingenVaccinNietAanwezigResult:string = Valideren.valideerOpmerkingenVaccinNietAanwezig(data).toString();
      // if(valideerOpmerkingenVaccinNietAanwezigResult !== '') {
      //   setOpmerkingError(true);
      //   setOpmerkingErrorString(valideerOpmerkingenVaccinNietAanwezigResult);
      //   isValid = false;
      // } else {
      //   setOpmerkingError(false);
      //   setOpmerkingErrorString(valideerOpmerkingenVaccinNietAanwezigResult);
      // }

      // duitse ondertekening check
      if(intialProps.initialLanguage === "de") {
        const valideerDuitseOndertekening:string = Valideren.valideerDuitseOndertekeningRequired(VkiObject).toString();
        const valideerDuitseOndertekeningAufgezogenIn:string = Valideren.valideerDuitseOndertekeningAufgezogenInCheck(VkiObject).toString();
        // check if duitse ondertekening is set at all
        if(valideerDuitseOndertekening !== '') {
          setDuitseOntertekeningError(true);
          setDuitseOntertekeningErrorString(valideerDuitseOndertekening);
          isValid = false;
        } else {
          setDuitseOntertekeningError(false);
          setDuitseOntertekeningErrorString("");
        }

        // check if aufgezogenIn is checken and the string is filled in...
        if(valideerDuitseOndertekeningAufgezogenIn !== '') {
          setDuitseOntertekeningErrorAufgezogenIn(true);
          setDuitseOntertekeningErrorAufgezogenInString(valideerDuitseOndertekeningAufgezogenIn);
          isValid = false;
        } else {
          setDuitseOntertekeningErrorAufgezogenIn(false);
          setDuitseOntertekeningErrorAufgezogenInString("");
        }
      }

      // update the validation object..
      setValidationObject((prevValidationObject: IFormValidationObject) => ({
        ...prevValidationObject,
        VaccinsDataSection: isValid,
      }));
    };
    
    const handleKeyPress = (event:any) => {
      if (event.key === 'Enter') {
        try
        {
          event.preventDefault(); // Prevent the default behavior of the Enter key
          // You can add any custom logic here if needed
          const form = event.target.form;
          const index = Array.from(form.elements).indexOf(event.target);
          form.elements[index + 1].focus();
        }
        catch(ex){}
      }
    };
  
  return (
    <div className="default-section-container">
    <h4>{t('VaccinsCaps')}</h4>

      <div className="default-row-container-columns">
{/* -----------------------------Ziekte---------------------------------------------------------------- */}

        <div className="default-row-content-columns-data">
            <div className="default-row-content-label">{t('Ziekte')}</div>
            <Select onKeyPress={handleKeyPress} 
                tabIndex={4001}
                inputProps={{ tabIndex: 4001}}
                name={`Ziekte`}
                disabled={isReadonly}
                value={ vkiData && vkiData.Vaccins && vkiData.Vaccins[0] && vkiData.Vaccins[0].Ziekte ? vkiData.Vaccins[0].ZiekteExterneId : vkiData?.ZiekteNa?.ExterneId}
                onChange={(event) => handleSelectChanges(event, 0)}
                className="default-row-content-selectbox"
                size="small"
            >
              <MenuItem style={{textIndent: '-9999px'}} value="empty">
                {"Empty"}
              </MenuItem> 
              {ziekteOptions?.map((ziekte: IVKIFormItem, index: number) => (
                  ziekte.Taal?.toLowerCase() === intialProps.initialLanguage.toLowerCase() ? (
                    <MenuItem key={index} value={ziekte.ExterneId}>{ziekte.Titel}</MenuItem>
                  ) : (
                    null
                  )
                ))}
            </Select>
            { vkiData && vkiData.Vaccins && vkiData.Vaccins[1] ? 
                <Select onKeyPress={handleKeyPress} 
                  tabIndex={4011}
                  inputProps={{ tabIndex: 4011}}
                    name={`Ziekte`}
                    disabled={isReadonly}
                    value={vkiData.Vaccins[1].Ziekte ? vkiData.Vaccins[1].ZiekteExterneId : ''}
                    onChange={(event) => handleSelectChanges(event, 1)}
                    className="default-row-content-selectbox"
                    size="small"
                >
                <MenuItem style={{textIndent: '-9999px'}} value="empty">
                  {"Empty"}
                </MenuItem>
                {ziekteOptions?.map((ziekte: IVKIFormItem, index: number) => (
                  ziekte.Taal?.toLowerCase() === intialProps.initialLanguage.toLowerCase() ? (
                    <MenuItem key={index} value={ziekte.ExterneId}>{ziekte.Titel}</MenuItem>
                  ) : (
                    null
                  )
                ))}
               </Select>
            : null }
            { vkiData && vkiData.Vaccins && vkiData.Vaccins[2] ? 
                <Select onKeyPress={handleKeyPress} 
                  tabIndex={4021}
                  inputProps={{ tabIndex: 4021}}
                    name={`Ziekte`}
                    disabled={isReadonly}
                    value={vkiData.Vaccins[2].Ziekte ? vkiData.Vaccins[2].ZiekteExterneId : ''}
                    onChange={(event) => handleSelectChanges(event, 2)}
                    className="default-row-content-selectbox"
                    size="small"
                >
                <MenuItem style={{textIndent: '-9999px'}} value="empty">
                  {"Empty"}
                </MenuItem>
                {ziekteOptions?.map((ziekte: IVKIFormItem, index: number) => (
                  ziekte.Taal?.toLowerCase() === intialProps.initialLanguage.toLowerCase() ? (
                    <MenuItem key={index} value={ziekte.ExterneId}>{ziekte.Titel}</MenuItem>
                  ) : (
                    null
                  )
                ))}
               </Select>
            : null }
            { vkiData && vkiData.Vaccins && vkiData.Vaccins[3] ? 
              <Select onKeyPress={handleKeyPress} 
                    tabIndex={4031}
                    inputProps={{ tabIndex: 4031}}
                    name={`Ziekte`}
                    disabled={isReadonly}
                    value={vkiData.Vaccins[3].Ziekte ? vkiData.Vaccins[3].ZiekteExterneId : ''}
                    onChange={(event) => handleSelectChanges(event, 3)}
                    className="default-row-content-selectbox"
                    size="small"
                >
                <MenuItem style={{textIndent: '-9999px'}} value="empty">
                  {"Empty"}
                </MenuItem>
                {ziekteOptions?.map((ziekte: IVKIFormItem, index: number) => (
                  ziekte.Taal?.toLowerCase() === intialProps.initialLanguage.toLowerCase() ? (
                    <MenuItem key={index} value={ziekte.ExterneId}>{ziekte.Titel}</MenuItem>
                  ) : (
                    null
                  )
                ))}
               </Select>
            : null }
            { vkiData && vkiData.Vaccins && vkiData.Vaccins[4] ? 
                <Select onKeyPress={handleKeyPress} 
                    tabIndex={4041}
                    inputProps={{ tabIndex: 4041}}
                    name={`Ziekte`}
                    disabled={isReadonly}
                    value={vkiData.Vaccins[4].Ziekte ? vkiData.Vaccins[4].ZiekteExterneId : ''}
                    onChange={(event) => handleSelectChanges(event, 4)}
                    className="default-row-content-selectbox"
                    size="small"
                >
                <MenuItem style={{textIndent: '-9999px'}} value="empty">
                  {"Empty"}
                </MenuItem>
                {ziekteOptions?.map((ziekte: IVKIFormItem, index: number) => (
                  ziekte.Taal?.toLowerCase() === intialProps.initialLanguage.toLowerCase() ? (
                    <MenuItem key={index} value={ziekte.ExterneId}>{ziekte.Titel}</MenuItem>
                  ) : (
                    null
                  )
                ))}
               </Select>
            : null }
        </div>
{/* -----------------------------Merktyperegistratienummer---------------------------------------------------------------- */}
        <div className="default-row-content-columns-data">
            <div className="default-row-content-label">{t('Merktype')}</div>
            <Select onKeyPress={handleKeyPress} 
                tabIndex={4002}
                inputProps={{ tabIndex: 4002}}
                name={`MerkTypeRegistratienummer`}
                disabled={isReadonly}
                value={ vkiData && vkiData.Vaccins && vkiData.Vaccins[0] && vkiData.Vaccins[0].MerkTypeRegistratienummer ? vkiData.Vaccins[0].MerkTypeRegistratienummerExterneId : ''}
                onChange={(event) => handleSelectChanges(event, 0)}
                className={`${
                  vkiData && vkiData.Vaccins && vkiData.Vaccins[0] && vkiData.Vaccins[0].Ziekte && (vkiData.Vaccins[0].MerkTypeRegistratienummer === undefined || vkiData.Vaccins[0].MerkTypeRegistratienummer === null || vkiData.Vaccins[0].MerkTypeRegistratienummer === '')
                    ? 'default-row-content-selectbox-error-vaccins'
                    : 'default-row-content-selectbox'
                }`}
                style={{border: vkiData && vkiData.Vaccins && vkiData.Vaccins[0] && vkiData.Vaccins[0].Ziekte && (vkiData.Vaccins[0].MerkTypeRegistratienummer === undefined || vkiData.Vaccins[0].MerkTypeRegistratienummer === null || vkiData.Vaccins[0].MerkTypeRegistratienummer === '')
                  ? '1px solid red'
                  : '',
                }}
                size="small"
            >
            {registartieNummerOptions?.map((registratieNummer: IVKIFormItem, index: number) => (
                <MenuItem key={index} value={registratieNummer.ExterneId}>{registratieNummer.Titel}</MenuItem>
            ))}
            </Select>
            {(vkiData && vkiData.Vaccins && vkiData.Vaccins[0] && vkiData.Vaccins[0].Ziekte && (vkiData.Vaccins[0].MerkTypeRegistratienummer === undefined || vkiData.Vaccins[0].MerkTypeRegistratienummer === null || vkiData.Vaccins[0].MerkTypeRegistratienummer === '' )) ? (<FormHelperText className='custom-error-message-methode' error>{t('verplichtVeld')}</FormHelperText>) : null}        

            { vkiData && vkiData.Vaccins && vkiData.Vaccins[1] ? 
            <div  className='methode-select-container'>
                <Select onKeyPress={handleKeyPress} 
                    tabIndex={4012}
                    inputProps={{ tabIndex: 4012}}
                    name={`MerkTypeRegistratienummer`}
                    disabled={isReadonly}
                    value={vkiData.Vaccins[1].MerkTypeRegistratienummer ? vkiData.Vaccins[1].MerkTypeRegistratienummerExterneId : ''}
                    onChange={(event) => handleSelectChanges(event, 1)}
                    className={`${
                      vkiData && vkiData.Vaccins && vkiData.Vaccins[1] && vkiData.Vaccins[1].Ziekte && (vkiData.Vaccins[1].MerkTypeRegistratienummer === undefined || vkiData.Vaccins[1].MerkTypeRegistratienummer === null || vkiData.Vaccins[1].MerkTypeRegistratienummer === '')
                        ? 'default-row-content-selectbox-error-vaccins'
                        : 'default-row-content-selectbox'
                    }`}
                    style={{border: vkiData && vkiData.Vaccins && vkiData.Vaccins[1] && vkiData.Vaccins[1].Ziekte && (vkiData.Vaccins[1].MerkTypeRegistratienummer === undefined || vkiData.Vaccins[1].MerkTypeRegistratienummer === null || vkiData.Vaccins[1].MerkTypeRegistratienummer === '')
                      ? '1px solid red'
                      : '',
                    }}
                    size="small"
                >
                  {registartieNummerOptions?.map((registratieNummer: IVKIFormItem, index: number) => (
                    registratieNummer.Taal?.toLowerCase() === intialProps.initialLanguage.toLowerCase() ? (
                      <MenuItem key={index} value={registratieNummer.ExterneId}>{registratieNummer.Titel}</MenuItem>
                    ) : (
                      null
                    )
                  ))}
               </Select>
                {(vkiData && vkiData.Vaccins && vkiData.Vaccins[1] && vkiData.Vaccins[1].Ziekte && (vkiData.Vaccins[1].MerkTypeRegistratienummer === undefined || vkiData.Vaccins[1].MerkTypeRegistratienummer === null || vkiData.Vaccins[1].MerkTypeRegistratienummer === '' )) ? (<FormHelperText className='custom-error-message-methode' error>{t('verplichtVeld')}</FormHelperText>) : null}        
              </div>

            : null }
            { vkiData && vkiData.Vaccins && vkiData.Vaccins[2] ? 
              <div  className='methode-select-container'>
                <Select onKeyPress={handleKeyPress} 
                    tabIndex={4022}
                    inputProps={{ tabIndex: 4022}}
                    name={`MerkTypeRegistratienummer`}
                    disabled={isReadonly}
                    value={vkiData.Vaccins[2].MerkTypeRegistratienummer ? vkiData.Vaccins[2].MerkTypeRegistratienummerExterneId : ''}
                    onChange={(event) => handleSelectChanges(event, 2)}
                    className={`${
                      vkiData && vkiData.Vaccins && vkiData.Vaccins[2] && vkiData.Vaccins[2].Ziekte && (vkiData.Vaccins[2].MerkTypeRegistratienummer === undefined || vkiData.Vaccins[2].MerkTypeRegistratienummer === null || vkiData.Vaccins[2].MerkTypeRegistratienummer === '')
                        ? 'default-row-content-selectbox-error-vaccins'
                        : 'default-row-content-selectbox'
                    }`}
                    style={{border: vkiData && vkiData.Vaccins && vkiData.Vaccins[2] && vkiData.Vaccins[2].Ziekte && (vkiData.Vaccins[2].MerkTypeRegistratienummer === undefined || vkiData.Vaccins[2].MerkTypeRegistratienummer === null || vkiData.Vaccins[2].MerkTypeRegistratienummer === '')
                      ? '1px solid red'
                      : '',
                    }}
                    size="small"
                >
                  {registartieNummerOptions?.map((registratieNummer: IVKIFormItem, index: number) => (
                    registratieNummer.Taal?.toLowerCase() === intialProps.initialLanguage.toLowerCase() ? (
                      <MenuItem key={index} value={registratieNummer.ExterneId}>{registratieNummer.Titel}</MenuItem>
                    ) : (
                      null
                    )
                  ))}
               </Select>
               {(vkiData && vkiData.Vaccins && vkiData.Vaccins[2] && vkiData.Vaccins[2].Ziekte && (vkiData.Vaccins[2].MerkTypeRegistratienummer === undefined || vkiData.Vaccins[2].MerkTypeRegistratienummer === null || vkiData.Vaccins[2].MerkTypeRegistratienummer === '' )) ? (<FormHelperText className='custom-error-message-methode' error>{t('verplichtVeld')}</FormHelperText>) : null}        
              </div>
            : null }
            { vkiData && vkiData.Vaccins && vkiData.Vaccins[3] ? 
            <div  className='methode-select-container'>
                <Select onKeyPress={handleKeyPress} 
                    tabIndex={4032}
                    inputProps={{ tabIndex: 4032}}
                    name={`MerkTypeRegistratienummer`}
                    disabled={isReadonly}
                    value={vkiData.Vaccins[3].MerkTypeRegistratienummer ? vkiData.Vaccins[3].MerkTypeRegistratienummerExterneId : ''}
                    onChange={(event) => handleSelectChanges(event, 3)}
                    className={`${
                      vkiData && vkiData.Vaccins && vkiData.Vaccins[3] && vkiData.Vaccins[3].Ziekte && vkiData.Vaccins[3]?.ZiekteExterneId !== vkiData?.ZiekteNa?.ExterneId && (vkiData.Vaccins[3].MerkTypeRegistratienummer === undefined || vkiData.Vaccins[3].MerkTypeRegistratienummer === null || vkiData.Vaccins[3].MerkTypeRegistratienummer === '')
                        ? 'default-row-content-selectbox-error-vaccins'
                        : 'default-row-content-selectbox'
                    }`}
                    style={{border: vkiData && vkiData.Vaccins && vkiData.Vaccins[3] && vkiData.Vaccins[3].Ziekte && vkiData.Vaccins[3]?.ZiekteExterneId !== vkiData?.ZiekteNa?.ExterneId && (vkiData.Vaccins[3].MerkTypeRegistratienummer === undefined || vkiData.Vaccins[3].MerkTypeRegistratienummer === null || vkiData.Vaccins[3].MerkTypeRegistratienummer === '')
                      ? '1px solid red'
                      : '',
                    }}
                    size="small"
                >
                  {registartieNummerOptions?.map((registratieNummer: IVKIFormItem, index: number) => (
                    registratieNummer.Taal?.toLowerCase() === intialProps.initialLanguage.toLowerCase() ? (
                      <MenuItem key={index} value={registratieNummer.ExterneId}>{registratieNummer.Titel}</MenuItem>
                    ) : (
                      null
                    )
                  ))}
               </Select>
               {(vkiData && vkiData.Vaccins && vkiData.Vaccins[3] && vkiData.Vaccins[3].Ziekte && vkiData.Vaccins[3]?.ZiekteExterneId !== vkiData?.ZiekteNa?.ExterneId && (vkiData.Vaccins[3].MerkTypeRegistratienummer === undefined || vkiData.Vaccins[3].MerkTypeRegistratienummer === null || vkiData.Vaccins[3].MerkTypeRegistratienummer === '' )) ? (<FormHelperText className='custom-error-message-methode' error>{t('verplichtVeld')}</FormHelperText>) : null}        
               </div>
            : null }
            { vkiData && vkiData.Vaccins && vkiData.Vaccins[4] ? 
            <div  className='methode-select-container'>
                <Select onKeyPress={handleKeyPress} 
                tabIndex={4042}
                inputProps={{ tabIndex: 4042}}
                name={`MerkTypeRegistratienummer`}
                disabled={isReadonly}
                value={vkiData.Vaccins[4].MerkTypeRegistratienummer ? vkiData.Vaccins[4].MerkTypeRegistratienummerExterneId : ''}
                onChange={(event) => handleSelectChanges(event, 4)}
                className={`${
                  vkiData && vkiData.Vaccins && vkiData.Vaccins[4] && vkiData.Vaccins[4].Ziekte && vkiData.Vaccins[4]?.ZiekteExterneId !== vkiData?.ZiekteNa?.ExterneId && (vkiData.Vaccins[4].MerkTypeRegistratienummer === undefined || vkiData.Vaccins[4].MerkTypeRegistratienummer === null || vkiData.Vaccins[4].MerkTypeRegistratienummer === '')
                    ? 'default-row-content-selectbox-error-vaccins'
                    : 'default-row-content-selectbox'
                }`}
                style={{border: vkiData && vkiData.Vaccins && vkiData.Vaccins[4] && vkiData.Vaccins[4].Ziekte && vkiData.Vaccins[4]?.ZiekteExterneId !== vkiData?.ZiekteNa?.ExterneId && (vkiData.Vaccins[4].MerkTypeRegistratienummer === undefined || vkiData.Vaccins[4].MerkTypeRegistratienummer === null || vkiData.Vaccins[4].MerkTypeRegistratienummer === '')
                  ? '1px solid red'
                  : '',
                }}
                size="small"
                >
                  {registartieNummerOptions?.map((registratieNummer: IVKIFormItem, index: number) => (
                    registratieNummer.Taal?.toLowerCase() === intialProps.initialLanguage.toLowerCase() ? (
                      <MenuItem key={index} value={registratieNummer.ExterneId}>{registratieNummer.Titel}</MenuItem>
                    ) : (
                      null
                    )
                  ))}
               </Select>
               {(vkiData && vkiData.Vaccins && vkiData.Vaccins[4] && vkiData.Vaccins[4].Ziekte && vkiData.Vaccins[4]?.ZiekteExterneId !== vkiData?.ZiekteNa?.ExterneId && (vkiData.Vaccins[4].MerkTypeRegistratienummer === undefined || vkiData.Vaccins[4].MerkTypeRegistratienummer === null || vkiData.Vaccins[4].MerkTypeRegistratienummer === '' )) ? (<FormHelperText className='custom-error-message-methode' error>{t('verplichtVeld')}</FormHelperText>) : null}        
               </div>
            : null }
            
        </div>
{/* ------------------------------WettelijkWachttermijn--------------------------------------------------------------- */}

        <div className="default-row-content-columns-data">
            <div className="default-row-content-label">{t('Wettelijkewachttermijn')}</div>
           
            <Typography
              variant="body1"
              className="default-row-content-typography"
              component="div"
              style={{ fontWeight: 'bold' }}
            >
              {vkiData && vkiData.Vaccins &&  vkiData.Vaccins[0] && vkiData.Vaccins[0].WettelijkeWachttermijn ? vkiData.Vaccins[0].WettelijkeWachttermijn + ' ' + t("Dagen") : '0 '+ t("Dagen")}
            </Typography>
            { vkiData && vkiData.Vaccins && vkiData.Vaccins[1] ? 
                 <Typography
                 variant="body1"
                 className="default-row-content-typography"
                 component="div"
                 style={{ fontWeight: 'bold' }}
               >
                 {vkiData && vkiData.Vaccins[1] && vkiData.Vaccins[1].WettelijkeWachttermijn ? vkiData.Vaccins[1].WettelijkeWachttermijn + ' ' + t("Dagen") : '0 '+ t("Dagen")}
               </Typography>
            : null }
            { vkiData && vkiData.Vaccins && vkiData.Vaccins && vkiData.Vaccins[2] ? 
                <Typography
                variant="body1"
                className="default-row-content-typography"
                component="div"
                style={{ fontWeight: 'bold' }}
              >
                {vkiData && vkiData.Vaccins[2] && vkiData.Vaccins[2].WettelijkeWachttermijn ? vkiData.Vaccins[2].WettelijkeWachttermijn + ' ' + t("Dagen") : '0 '+ t("Dagen")}
              </Typography>
            : null }
            { vkiData && vkiData.Vaccins && vkiData.Vaccins[3] ? 
                 <Typography
                 variant="body1"
                 className="default-row-content-typography"
                 component="div"
                 style={{ fontWeight: 'bold' }}
               >
                 {vkiData && vkiData.Vaccins[3] && vkiData.Vaccins[3].WettelijkeWachttermijn ? vkiData.Vaccins[3].WettelijkeWachttermijn + ' ' + t("Dagen") : '0 '+ t("Dagen")}
               </Typography>
            : null }
            { vkiData && vkiData.Vaccins && vkiData.Vaccins[4] ? 
                 <Typography
                 variant="body1"
                 className="default-row-content-typography"
                 component="div"
                 style={{ fontWeight: 'bold' }}
               >
                 {vkiData && vkiData.Vaccins[4] && vkiData.Vaccins[4].WettelijkeWachttermijn ? vkiData.Vaccins[4].WettelijkeWachttermijn + ' ' + t("Dagen") : '0 '+ t("Dagen")}
               </Typography>
            : null }
        </div>
{/* ------------------------------METHODE--------------------------------------------------------------- */}

        <div className="default-row-content-columns-data">
            <div className="default-row-content-label">{t('Methode')}</div>
            <div  className='methode-select-container'>
              <Select onKeyPress={handleKeyPress} 
                    tabIndex={4003}
                    inputProps={{ tabIndex: 4003}}
                  name={`Methode`}
                  disabled={isReadonly}
                  value={ vkiData && vkiData.Vaccins && vkiData.Vaccins[0] && vkiData.Vaccins[0].Methode ? vkiData.Vaccins[0].MethodeExterneId : ''}
                  onChange={(event) => handleSelectChanges(event, 0)}
                  className={`${
                    vkiData && vkiData.Vaccins && vkiData.Vaccins[0] && vkiData.Vaccins[0].Ziekte && (vkiData.Vaccins[0].Methode === undefined || vkiData.Vaccins[0].Methode === null || vkiData.Vaccins[0].Methode === '')
                      ? 'default-row-content-selectbox-error-vaccins'
                      : 'default-row-content-selectbox'
                  }`}
                  size="small"
                  style={{border: vkiData && vkiData.Vaccins && vkiData.Vaccins[0] && vkiData.Vaccins[0].Ziekte && (vkiData.Vaccins[0].Methode === undefined || vkiData.Vaccins[0].Methode === null || vkiData.Vaccins[0].Methode === '')
                      ? '1px solid red'
                      : '',
                    }}
                >
                  <MenuItem style={{textIndent: '-9999px'}} value="empty">
                    {"Empty"}
                  </MenuItem> 
                  {methodOptions?.map((methode: IVKIFormItem, index: number) => (
                    methode.Taal?.toLowerCase() === intialProps.initialLanguage.toLowerCase() ? (
                      <MenuItem key={index} value={methode.ExterneId}>{methode.Titel}</MenuItem>
                    ) : (
                      null
                    )
                  ))}
              </Select>
              {(vkiData && vkiData.Vaccins && vkiData.Vaccins[0] && vkiData.Vaccins[0].Ziekte && (vkiData.Vaccins[0].Methode === undefined || vkiData.Vaccins[0].Methode === null || vkiData.Vaccins[0].Methode === '' )) ? (<FormHelperText className='custom-error-message-methode' error>{t('verplichtVeld')}</FormHelperText>) : null}        
            </div>    
            { vkiData && vkiData.Vaccins && vkiData.Vaccins[1] ? 
                <React.Fragment>
                  <div  className='methode-select-container'>
                    <Select onKeyPress={handleKeyPress} 
                        tabIndex={4013}
                        inputProps={{ tabIndex: 4013}}
                        name={`Methode`}
                        disabled={isReadonly}
                        value={vkiData.Vaccins[1].Methode ? vkiData.Vaccins[1].MethodeExterneId : ''}
                        onChange={(event) => handleSelectChanges(event, 1)}
                        className={`${
                          vkiData && vkiData.Vaccins && vkiData.Vaccins[1] && vkiData.Vaccins[1].Ziekte && (vkiData.Vaccins[1].Methode === undefined || vkiData.Vaccins[1].Methode === null || vkiData.Vaccins[1].Methode === '')
                            ? 'default-row-content-selectbox-error-vaccins'
                            : 'default-row-content-selectbox'
                        }`}
                        size="small"
                        style={{border: vkiData && vkiData.Vaccins && vkiData.Vaccins[1] && vkiData.Vaccins[1].Ziekte && (vkiData.Vaccins[1].Methode === undefined || vkiData.Vaccins[1].Methode === null || vkiData.Vaccins[1].Methode === '')
                      ? '1px solid red'
                      : '',
                    }}
                    >
                        <MenuItem style={{textIndent: '-9999px'}} value="empty">
                          {"Empty"}
                        </MenuItem> 
                        {methodOptions?.map((methode: IVKIFormItem, index: number) => (
                          methode.Taal?.toLowerCase() === intialProps.initialLanguage.toLowerCase() ? (
                            <MenuItem key={index} value={methode.ExterneId}>{methode.Titel}</MenuItem>
                          ) : (
                            null
                          )
                        ))}
                    </Select>
                    {(vkiData && vkiData.Vaccins && vkiData.Vaccins[1] && vkiData.Vaccins[1].Ziekte && (vkiData.Vaccins[1].Methode === undefined || vkiData.Vaccins[1].Methode === null || vkiData.Vaccins[1].Methode === '' )) ? (<FormHelperText className='custom-error-message-methode' error>{t('verplichtVeld')}</FormHelperText>) : null}            
                  </div>
                </React.Fragment>
            : null }
            { vkiData && vkiData.Vaccins && vkiData.Vaccins[2] ? 
              <React.Fragment>
                <div  className='methode-select-container'>
                  <Select onKeyPress={handleKeyPress} 
                        tabIndex={4023}
                        inputProps={{ tabIndex: 4023}}
                      name={`Methode`}
                      disabled={isReadonly}
                      value={vkiData.Vaccins[2].Methode ? vkiData.Vaccins[2].MethodeExterneId : ''}
                      onChange={(event) => handleSelectChanges(event, 2)}
                      className={`${
                        vkiData && vkiData.Vaccins && vkiData.Vaccins[2] && vkiData.Vaccins[2].Ziekte && (vkiData.Vaccins[2].Methode === undefined || vkiData.Vaccins[2].Methode === null || vkiData.Vaccins[2].Methode === '' )
                          ? 'default-row-content-selectbox-error-vaccins'
                          : 'default-row-content-selectbox'
                      }`}
                      size="small"
                      style={{border: vkiData && vkiData.Vaccins && vkiData.Vaccins[2] && vkiData.Vaccins[2].Ziekte && (vkiData.Vaccins[2].Methode === undefined || vkiData.Vaccins[2].Methode === null || vkiData.Vaccins[2].Methode === '')
                      ? '1px solid red'
                      : '',
                    }}
                  >
                      <MenuItem style={{textIndent: '-9999px'}} value="empty">
                        {"Empty"}
                      </MenuItem> 
                      {methodOptions?.map((methode: IVKIFormItem, index: number) => (
                        methode.Taal?.toLowerCase() === intialProps.initialLanguage.toLowerCase() ? (
                          <MenuItem key={index} value={methode.ExterneId}>{methode.Titel}</MenuItem>
                        ) : (
                          null
                        )
                      ))}
                  </Select>
                  {(vkiData && vkiData.Vaccins && vkiData.Vaccins[2] && vkiData.Vaccins[2].Ziekte && (vkiData.Vaccins[2].Methode === undefined || vkiData.Vaccins[2].Methode === null || vkiData.Vaccins[2].Methode === '' )) ? (<FormHelperText className='custom-error-message-methode' error>{t('verplichtVeld')}</FormHelperText>) : null}            
                </div>
              </React.Fragment>
            : null }
           {vkiData?.Vaccins?.[3] && (
              <React.Fragment>
                <div  className='methode-select-container'>
                  <Select onKeyPress={handleKeyPress} 
                        tabIndex={4033}
                        inputProps={{ tabIndex: 4033}}
                    name={`Methode`}
                    disabled={isReadonly}
                    value={vkiData.Vaccins[3].MethodeExterneId || ''}
                    onChange={(event) => handleSelectChanges(event, 3)}
                    className={`${
                      vkiData.Vaccins[3]?.Ziekte && vkiData.Vaccins[3]?.ZiekteExterneId !== vkiData?.ZiekteNa?.ExterneId && (vkiData.Vaccins[3].Methode === undefined || vkiData.Vaccins[3].Methode === null || vkiData.Vaccins[3].Methode === '')
                        ? 'default-row-content-selectbox-error-vaccins'
                        : 'default-row-content-selectbox'
                    }`}
                    style={{
                      border: vkiData.Vaccins[3]?.Ziekte && vkiData.Vaccins[3]?.ZiekteExterneId !== vkiData?.ZiekteNa?.ExterneId && (vkiData.Vaccins[3].Methode === undefined || vkiData.Vaccins[3].Methode === null || vkiData.Vaccins[3].Methode === '')
                        ? '1px solid red'
                        : '',
                    }}
                    size="small"
                  >
                    <MenuItem style={{ textIndent: '-9999px' }} value="empty">
                      {"Empty"}
                    </MenuItem>
                    {methodOptions?.map((methode: IVKIFormItem, index: number) => (
                      methode.Taal?.toLowerCase() === intialProps.initialLanguage.toLowerCase() ? (
                        <MenuItem key={index} value={methode.ExterneId}>{methode.Titel}</MenuItem>
                      ) : (
                        null
                      )
                    ))}
                  </Select>
                  {vkiData.Vaccins[3]?.Ziekte && vkiData.Vaccins[3]?.ZiekteExterneId !== vkiData?.ZiekteNa?.ExterneId && (vkiData.Vaccins[3].Methode === undefined || vkiData.Vaccins[3].Methode === null || vkiData.Vaccins[3].Methode === '') && (
                    <FormHelperText className='custom-error-message-methode' error>
                      {t('verplichtVeld')}
                    </FormHelperText>
                  )}
                </div>
              </React.Fragment>
            )}

            {vkiData?.Vaccins?.[4] && (
              <React.Fragment>
                <div  className='methode-select-container'>
                  <Select onKeyPress={handleKeyPress} 
                        tabIndex={4043}
                        inputProps={{ tabIndex: 4043}}
                    name={`Methode`}
                    disabled={isReadonly}
                    value={vkiData.Vaccins[4].MethodeExterneId || ''}
                    onChange={(event) => handleSelectChanges(event, 4)}
                    className={`${
                      vkiData.Vaccins[4]?.Ziekte && vkiData.Vaccins[4]?.ZiekteExterneId !== vkiData?.ZiekteNa?.ExterneId && (vkiData.Vaccins[4].Methode === undefined || vkiData.Vaccins[4].Methode === null || vkiData.Vaccins[4].Methode === '')
                        ? 'default-row-content-selectbox-error-vaccins'
                        : 'default-row-content-selectbox'
                    }`}
                    style={{
                      border: vkiData.Vaccins[4]?.Ziekte && vkiData.Vaccins[4]?.ZiekteExterneId !== vkiData?.ZiekteNa?.ExterneId && (vkiData.Vaccins[4].Methode === undefined || vkiData.Vaccins[4].Methode === null || vkiData.Vaccins[4].Methode === '')
                        ? '1px solid red'
                        : '',
                    }}
                    size="small"
                  >
                    <MenuItem style={{ textIndent: '-9999px' }} value="empty">
                      {"Empty"}
                    </MenuItem>
                    {methodOptions?.map((methode: IVKIFormItem, index: number) => (
                      methode.Taal?.toLowerCase() === intialProps.initialLanguage.toLowerCase() ? (
                        <MenuItem key={index} value={methode.ExterneId}>{methode.Titel}</MenuItem>
                      ) : (
                        null
                      )
                    ))}
                  </Select>
                  {vkiData.Vaccins[4]?.Ziekte && vkiData.Vaccins[4]?.ZiekteExterneId !== vkiData?.ZiekteNa?.ExterneId && (vkiData.Vaccins[4].Methode === undefined || vkiData.Vaccins[4].Methode === null || vkiData.Vaccins[4].Methode === '') && (
                    <FormHelperText className='custom-error-message-methode' error>
                      {t('verplichtVeld')}
                    </FormHelperText>
                  )}
                </div>
              </React.Fragment>
            )}
        </div>
{/* ------------------------------------Datum--------------------------------------------------------- */}

        <div className="default-row-content-columns-data">
            <div className="default-row-content-label">{t('Datum')}</div>
            {/* <TextField onKeyPress={handleKeyPress} 
                        tabIndex={4004}
                        inputProps={{ inputcomponent: inputComponent, tabIndex: 4004}}
                name={`Datum`}
                type="date"
                InputProps={{
                    readOnly: isReadonly,
                }}
                value={vkiData && vkiData.Vaccins && vkiData.Vaccins[0] && vkiData?.Vaccins[0].Datum ? vkiData.Vaccins[0].Datum.toString().split('T')[0] : null}
                className="default-row-content-date-input"
                size="small"
                onChange={(event) => handleInputChange(event, 0)}
            /> */}
            <CustomDatePicker datePickerClassName='datepickerWidth140px' readOnly={isReadonly} name="Datum" className="vaccinsDateFields" label={''} value={vkiData && vkiData.Vaccins && vkiData.Vaccins[0] && vkiData?.Vaccins[0].Datum ? vkiData.Vaccins[0].Datum : null} onChange={handleInputChange} size="small" 
              isError={vkiData && vkiData.Vaccins && vkiData.Vaccins[0] && vkiData.Vaccins[0].Ziekte && (vkiData.Vaccins[0].Datum === undefined || vkiData.Vaccins[0].Datum === null || IsEindDatumError) ? true : false} 
              errorText={ IsEindDatumErrorString ?  IsEindDatumErrorString :  t('verplichtVeld') as string } 
              hideBorders={true} index={0} />
            
            { vkiData && vkiData.Vaccins && vkiData.Vaccins[1] ? 
                <CustomDatePicker datePickerClassName='datepickerWidth140px' readOnly={isReadonly} name="Datum" className="vaccinsDateFields" label={''} value={vkiData && vkiData.Vaccins && vkiData.Vaccins[1] && vkiData?.Vaccins[1].Datum ? vkiData.Vaccins[1].Datum : null} onChange={handleInputChange} size="small" 
                isError={vkiData && vkiData.Vaccins && vkiData.Vaccins[1] && vkiData.Vaccins[1].Ziekte && (vkiData.Vaccins[1].Datum === undefined || vkiData.Vaccins[1].Datum === null || IsEindDatumError1) ? true : false} 
                errorText={ IsEindDatumErrorString1 ?  IsEindDatumErrorString1 :  t('verplichtVeld') as string } 
                hideBorders={true} index={1} />

                // />
            : null }
            { vkiData && vkiData.Vaccins && vkiData.Vaccins[2] ? 
                <CustomDatePicker datePickerClassName='datepickerWidth140px' readOnly={isReadonly} name="Datum" className="vaccinsDateFields" label={''} value={vkiData && vkiData.Vaccins && vkiData.Vaccins[1] && vkiData?.Vaccins[2].Datum ? vkiData.Vaccins[2].Datum : null} onChange={handleInputChange} size="small" 
                isError={vkiData && vkiData.Vaccins && vkiData.Vaccins[2] && vkiData.Vaccins[2].Ziekte && (vkiData.Vaccins[2].Datum === undefined || vkiData.Vaccins[2].Datum === null || IsEindDatumError2) ? true : false} 
                errorText={ IsEindDatumErrorString2 ?  IsEindDatumErrorString2 :  t('verplichtVeld') as string }  
                hideBorders={true} index={2} />

                // />
            : null }

            { vkiData && vkiData.Vaccins && vkiData.Vaccins[3] ? 
                <CustomDatePicker datePickerClassName='datepickerWidth140px' readOnly={isReadonly} name="Datum" className="vaccinsDateFields" label={''} value={vkiData && vkiData.Vaccins && vkiData.Vaccins[3] && vkiData?.Vaccins[3].Datum ? vkiData.Vaccins[3].Datum : null} onChange={handleInputChange} size="small" 
                isError={vkiData && vkiData.Vaccins && vkiData.Vaccins[3] && vkiData.Vaccins[3].Ziekte && vkiData.Vaccins[3]?.ZiekteExterneId !== vkiData?.ZiekteNa?.ExterneId && (vkiData.Vaccins[3].Datum === undefined || vkiData.Vaccins[3].Datum === null || IsEindDatumError2) ? true : false} 
                errorText={ IsEindDatumErrorString2 ?  IsEindDatumErrorString2 :  t('verplichtVeld') as string }  
                
                hideBorders={true} index={3} />

                // <TextField onKeyPress={handleKeyPress} 
                // tabIndex={4034}
                // inputProps={{inputcomponent: inputComponent, tabIndex: 4034}}
                //     name={`Datum`}
                //     type="date"
                //     InputProps={{
                //     readOnly: isReadonly,
                //     }}
                //     value={vkiData?.Vaccins[3] && vkiData.Vaccins[3] && vkiData?.Vaccins[3].Datum ? vkiData.Vaccins[3].Datum.toString().split('T')[3] : new Date().toISOString().split('T')[3]}
                //     className="default-row-content-date-input"
                //     size="small"
                //     onChange={(event) => handleInputChange(event, 3)}
                // />
            : null }
            { vkiData && vkiData.Vaccins && vkiData.Vaccins[4] ? 
              <CustomDatePicker datePickerClassName='datepickerWidth140px' readOnly={isReadonly} name="Datum" className="vaccinsDateFields" label={''} value={vkiData && vkiData.Vaccins && vkiData.Vaccins[4] && vkiData?.Vaccins[4].Datum ? vkiData.Vaccins[4].Datum : null} onChange={handleInputChange} size="small" isError={vkiData && vkiData.Vaccins && vkiData.Vaccins[4] && vkiData.Vaccins[4].Ziekte && vkiData.Vaccins[4]?.ZiekteExterneId !== vkiData?.ZiekteNa?.ExterneId && (vkiData.Vaccins[4].Datum === undefined || vkiData.Vaccins[4].Datum === null) ? true : false} errorText={t('verplichtVeld') as string} hideBorders={true} index={4} />
              //   <TextField onKeyPress={handleKeyPress} 
              //       tabIndex={4044}
              //       inputProps={{inputcomponent: inputComponent, tabIndex: 4044}}
              //       name={`Datum`}
              //       type="date"
              //       InputProps={{
              //       readOnly: isReadonly,
              //       }}
              //       value={vkiData?.Vaccins[4] && vkiData.Vaccins[4] && vkiData?.Vaccins[4].Datum ? vkiData.Vaccins[4].Datum.toString().split('T')[4] : new Date().toISOString().split('T')[4]}
              //       className="default-row-content-date-input"
              //       size="small"
              //       onChange={(event) => handleInputChange(event, 4)}
              // />
            : null }
        </div>    
      </div>
    
    <Divider variant="middle" />

{/* -----------------------------------RowSection salmonella status---------------------------------------------------------- */}

      <div className="default-row-container-columns-salmonella">
        <div className="default-row-content-columns-data-salmonella" >
            <div className="default-row-content-label-salmonella">{t('salmonell')}</div>           
            <Select onKeyPress={handleKeyPress} 
                    tabIndex={5000}
                    inputProps={{ tabIndex: 5000}}
              name={`SalmonellaStatusInlegvel`}
              disabled={isReadonly}
              value={vkiData && vkiData.Salmonella && vkiData.Salmonella.SalmonellaStatusInlegvel ? vkiData.Salmonella.SalmonellaStatusInlegvelExterneId : ''}
              onChange={(event) => handleSalmonellaSelectChanges(event)}
              className="default-row-content-selectbox-salmonella"
              size="small"
              style={{ border: (!vkiData || !vkiData.Salmonella || vkiData.Salmonella.SalmonellaStatusInlegvel === undefined || vkiData.Salmonella.SalmonellaStatusInlegvel === null || vkiData.Salmonella.SalmonellaStatusInlegvel === '') ? '1px solid red' : '' }}
            >
              <MenuItem style={{textIndent: '-9999px'}} value="empty">
                {"Empty"}
              </MenuItem>
               {salmonellaStatusOptions?.map((salmonellaStatus: IVKIFormItem, index: number) => (
                  salmonellaStatus.Taal?.toLowerCase() === intialProps.initialLanguage.toLowerCase() ? (
                    <MenuItem key={index} value={salmonellaStatus.ExterneId}>{salmonellaStatus.Titel}</MenuItem>
                  ) : (
                    null
                  )
                ))}
            </Select>
            {(!vkiData || !vkiData.Salmonella || vkiData.Salmonella.SalmonellaStatusInlegvel === undefined || vkiData.Salmonella.SalmonellaStatusInlegvel === null || vkiData.Salmonella.SalmonellaStatusInlegvel === '') ? (<FormHelperText error>{t('verplichtVeld')}</FormHelperText>) : null}
        </div>

        <div className="default-row-content-columns-data-salmonella">
            <div className="default-row-content-label-salmonella">{t('salmonellas')}</div>
            <Select onKeyPress={handleKeyPress} 
                    tabIndex={5015}
                    inputProps={{ tabIndex: 5015}}
              name={`SalmonellaStatusOverschoen`}
              disabled={isReadonly}
              value={vkiData && vkiData.Salmonella && vkiData.Salmonella.SalmonellaStatusOverschoen ? vkiData.Salmonella.SalmonellaStatusOverschoenExterneId : ''}
              onChange={(event) => handleSalmonellaSelectChanges(event)}
              className="default-row-content-selectbox-salmonella"
              size="small"
              style={{ border: (!vkiData || !vkiData.Salmonella || vkiData.Salmonella.SalmonellaStatusOverschoen === undefined || vkiData.Salmonella.SalmonellaStatusOverschoen === null || vkiData.Salmonella.SalmonellaStatusOverschoen === '') ? '1px solid red' : '' }}
            >
              <MenuItem style={{textIndent: '-9999px'}} value="empty">
                {"Empty"}
              </MenuItem>
                {salmonellaStatusOptions?.map((salmonellaStatus: IVKIFormItem, index: number) => (
                  salmonellaStatus.Taal?.toLowerCase() === intialProps.initialLanguage.toLowerCase() ? (
                    <MenuItem key={index} value={salmonellaStatus.ExterneId}>{salmonellaStatus.Titel}</MenuItem>
                  ) : (
                    null
                  )
                ))}
            </Select>
            {(!vkiData || !vkiData.Salmonella || vkiData.Salmonella.SalmonellaStatusOverschoen === undefined || vkiData.Salmonella.SalmonellaStatusOverschoen === null || vkiData.Salmonella.SalmonellaStatusOverschoen === '') ? (<FormHelperText error>{t('verplichtVeld')}</FormHelperText>) : null}            
            {/* space between these elemetns */}
            { accountInfo && vkiData && groupClaim &&
            <FileUpload accountInfo={accountInfo as IAccountInfo} vki={vkiData as IVKI} rol={groupClaim as string} type="Salmonella" onFileUpload={handleFileUpload} readonly={isReadonly}/>}

            { BijlageError ? (<FormHelperText error>{BijlageErrorString}</FormHelperText>) : null}            
            </div>
        </div>
    
    <Divider variant="middle" />
{/* -----------------------------------Opmerkingen---------------------------------------------------------- */}

        <div className="default-row-container-columns-opmerkingen">
            <div className="default-row-content-columns-data-opmerkingen" >
                <div className="default-row-content-label-opmerkingen">{t('Opmerkingen')}</div>
                <TextareaAutosize
                    tabIndex={5025}
                    name={`Opmerkingen`}
                    placeholder={placeholderstring}
                    disabled={isReadonly}
                    value={vkiData && vkiData.Salmonella && vkiData.Salmonella.Opmerkingen ? vkiData.Salmonella.Opmerkingen : ''}
                    onChange={(event) => handleSalmonellaTextAreaChanges(event)}
                    minRows={4}
                    style={{ 
                      width: '100%',
                      resize: 'vertical',
                      fontSize:'inherit',
                      border: (OpmerkingError) ? '0.0625rem solid red' : ''
                    }}
                />
                { OpmerkingError ? (<FormHelperText error>{OpmerkingErrorString}</FormHelperText>) : null}            
            </div>
        </div>
    <Divider variant="middle" />
{/* -----------------------------------Duitse ontertekening---------------------------------------------------------- */}

{intialProps.initialLanguage === "de" &&
        <div className="default-row-container-columns-duitseOndertekening">
          <div className="default-row-content-columns-data-duitseOndertekening" >
            <div className="default-row-content-label-duitseOndertekening">{t('titelExtraInformatieDe')}</div>
      
            <RadioGroup
              aria-label="checkbox-group"
              name="checkbox-group"
              value={selectedValue}
              onChange={handleRadioChange}
              style={{border: (DuitseOntertekeningError) ? '1px solid red' : ''}}
            >
              <FormControlLabel
                value="GeborenAufgezogenIn"
                control={<Radio />}
                label={t('GeborenAufgezogenIn')}
              />
              <FormControlLabel
                value="GeborenIn"
                control={<Radio />}
                label={t('GeborenIn')}
              />
              <FormControlLabel
                value="Aufgezogen"
                control={<Radio />}
                label={t('Aufgezogen')}
              />

              <TextField onKeyPress={handleKeyPress} 
                name="AufgezogenIn"
                type="text"
                InputProps={{
                  readOnly: isReadonly,
                }}
                value={vkiData && vkiData.DuitseOndertekening && vkiData.DuitseOndertekening.AufgezogenIn}
                className="default-row-content-aufgezogenIn-input"
                size="small"
                onChange={(event) => handleAufgezogenInInputChange(event)}
                error={DuitseOntertekeningErrorAufgezogenIn}
                helperText={DuitseOntertekeningErrorAufgezogenInString}
              />
            </RadioGroup>
              
            { DuitseOntertekeningError ? (<FormHelperText error>{DuitseOntertekeningErrorString}</FormHelperText>) : null}            

            </div>

        </div>
      }
    </div>
  );
};

export default VaccinsDataSection;