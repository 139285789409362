import TextField from '@material-ui/core/TextField';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IFormSectionProps } from '../../VKI/Interfaces/IForm';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const GegevensVoerLeverancierDataSection: React.FC<IFormSectionProps> = ({ vkiData, accountInfo, onChange, isReadonly, groupClaim, validateObject, setValidationObject }) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(true);
  const [initialLoad, setInitialLoad] = useState(true);


  useEffect(() => {
    // Check for missing inputs in vkiData.Voerleverancier
    const checkMissingInputs = () => {
      if (vkiData && vkiData.Voerleverancier && initialLoad) {
        const { Naam, Adres, Woonplaats } = vkiData.Voerleverancier;
        let isDataMissing = false;

        if (!Naam) isDataMissing = true;
        if (!Adres) isDataMissing = true;
        if (!Woonplaats) isDataMissing = true;
        

        // set colapsing state false when there is not data missing.
        if(isDataMissing === false) {
         //setExpanded(isDataMissing);
        }
      }
    };
    
    checkMissingInputs();
    setInitialLoad(false);
  }, [vkiData]);
 

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;
      onChange((prevVkiData: any) => ({
        ...prevVkiData,
        Voerleverancier: {
          ...prevVkiData.Voerleverancier,
          [name]: value,
        }
      }));
    };

  const toggleExpand = () => {
    setExpanded(!expanded);
  };
  
  const handleKeyPress = (event:any) => {
    if (event.key === 'Enter') {
      try
      {
        event.preventDefault(); // Prevent the default behavior of the Enter key
        // You can add any custom logic here if needed
        const form = event.target.form;
        const index = Array.from(form.elements).indexOf(event.target);
        form.elements[index + 1].focus();
      }
      catch(ex){}
    }
  };


  return (
    <div
    className={`default-section-container ${expanded ? 'expanded' : 'collapsed'}`}
  >
    <div className="header" style={{ display: 'flex', justifyContent: 'space-between' }}>
      <h4 style={{ marginRight: 'auto' }}>{t('GegevensvoerleverancierCaps')}</h4>
      <IconButton
          onClick={toggleExpand}
          className={`expand-button ${expanded ? 'expanded' : ''}`}
          aria-label={expanded ? t('collapse') as string | undefined: t('expand')  as string | undefined}
          style={{ marginLeft: 'auto' }}
        >
          <ExpandMoreIcon />
        </IconButton>
    </div>
    {expanded && (
      <div className="content">
        <div className="default-row">
          <TextField onKeyPress={handleKeyPress} 
            name="Naam"
            label={t('naam')}
            InputProps={{
              readOnly: isReadonly,
            }}
            error={!vkiData?.Voerleverancier || !vkiData?.Voerleverancier.Naam}
            helperText={!vkiData?.Voerleverancier || !vkiData?.Voerleverancier.Naam || !('Naam' in vkiData.Dierenarts) ? t('verplichtVeld') : ''}
            value={vkiData && vkiData.Voerleverancier ? vkiData?.Voerleverancier.Naam : ''}
            onChange={handleInputChange}
            className='default-field-Naam'
            InputLabelProps={{
              className: 'input-label', 
              classes: {
                shrink: 'input-label-shrink', 
              },
            }}
          />
        </div>
        <div className="default-row">
          <TextField onKeyPress={handleKeyPress} 
            name="Adres"
            label={t('adres')}
            InputProps={{
              readOnly: isReadonly,
            }}
            value={vkiData && vkiData.Voerleverancier ? vkiData?.Voerleverancier.Adres : ''}
            error={!vkiData?.Voerleverancier || !vkiData?.Voerleverancier.Adres}
            helperText={!vkiData?.Voerleverancier || !vkiData?.Voerleverancier.Adres || !('Adres' in vkiData.Dierenarts) ? t('verplichtVeld') : ''}
            onChange={handleInputChange}
            className='default-field-Adres'
            InputLabelProps={{
              className: 'input-label', 
              classes: {
                shrink: 'input-label-shrink', 
              },
            }}
          />
        </div>
        <div className="default-row">
          <TextField onKeyPress={handleKeyPress} 
            name="Woonplaats"
            label={t('woonplaats')}
            InputProps={{
              readOnly: isReadonly,
            }}
            error={!vkiData?.Voerleverancier || !vkiData?.Voerleverancier.Woonplaats}
            helperText={!vkiData?.Voerleverancier || !vkiData?.Voerleverancier.Woonplaats || !('Woonplaats' in vkiData.Dierenarts) ? t('verplichtVeld') : ''}
            value={vkiData && vkiData.Voerleverancier ? vkiData?.Voerleverancier.Woonplaats : ''}
            onChange={handleInputChange}
            className='default-field-Woonplaats'
            InputLabelProps={{
              className: 'input-label', 
              classes: {
                shrink: 'input-label-shrink', 
              },
            }}
          />
        </div>
      </div>
    )}
    </div>
  );
};

export default GegevensVoerLeverancierDataSection;