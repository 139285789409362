import { Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, LinearProgress, makeStyles } from '@material-ui/core';
import { CloudUpload as CloudUploadIcon, Delete } from '@material-ui/icons';
import camelcaseKeys from 'camelcase-keys';
import React, { ChangeEvent, FC, useState } from 'react';
import { IAccountInfo } from 'react-aad-msal';
import { FileIcon } from 'react-file-icon';
import { useTranslation } from 'react-i18next';
import { IVKI, IVKIBijlage, VKIBijlagePreview } from '../../VKI/Interfaces/IVKI';
import VKIService from '../Services/VKIService';
import { PreviewDialog } from './PreviewDialog';
import { environment } from './env';

export type UploadProps = {
	accountInfo: IAccountInfo;
	vki: IVKI;
  type:string;
  rol:string;
  onFileUpload: (updatedVKI: IVKI) => void; 
  readonly?: boolean;
};
export const FileUpload: FC<UploadProps> = (props: UploadProps) => {
    const { t } = useTranslation();
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [deletedFile, setDeletedFile] = useState<string | null>(null);
    const [uploadStatus, setUploadStatus] = useState<boolean>(false); // Initialize as false
    const [dialogOpen, setDialogOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const key = props.type + '_' + props.rol; // set de key value to know what file goes to what button
    const [previews, setPreviews] = useState<VKIBijlagePreview[]>([]);
    const [geselecteerdeBijlage, setBijlage] = useState<IVKIBijlage | null>(null);
    const [rusure, setRusure] = useState(false);
    const [bijlageToDelete, setBijlageToDelete] =  useState<IVKIBijlage | null>(null);
    
    const handleDialogClose = () => {
      setDialogOpen(false);
    };

    const handleNextPage = () => {
      setCurrentPage((prevPage) =>
        Math.min(prevPage + 1, Math.floor(props.vki.Bijlagen.length / 10))
      );
    };
    
    const handlePreviousPage = () => {
      setCurrentPage((prevPage) => Math.max(prevPage - 1, 0));
    };
    const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.files && event.target.files.length > 0) {
        setSelectedFile(event.target.files[0]);
        await handleUpload(event.target.files[0]);
        setSelectedFile(null);
      }
    };

    const useStyles = makeStyles((theme) => ({
      input: {
        display: 'none',
      },
      button: {
        marginTop: theme.spacing(1),
      },
    }));
    const classes = useStyles();
  
    const handleUpload = async (file:any) => {
      if (file) {
        try {
          const formData = new FormData();
          formData.append('file', file);
    
          const response = await fetch(environment.backendBaseUrl + 'UploadFile?type=' + props.type + '&id=' + props.vki.Id + '&environment=' + environment.environment  + '&code=' + environment.REACT_APP_FUNCTION_KEY, {
            method: 'POST',
            body: formData,
            headers: {Authorization: 'Bearer ' + props.accountInfo.jwtIdToken}
          });

          const responseVki = await VKIService.getVki(props.vki.Id, props.accountInfo.jwtIdToken, props.accountInfo.account.idToken.sub);
          const camelCasedData = camelcaseKeys(await responseVki.json(), { deep: true, pascalCase: true });
          if (response.ok) {
            // setVki(camelCasedData as IVKI)
            // update component in vki form
            
            props.onFileUpload(camelCasedData as IVKI);
          } else {
            console.error('File upload failed.');
          }
        } catch (error) {
          console.error('Error occurred during file upload:', error);
        }
      }
    };
  
    async function handleDelete(name: string, type: string): Promise<void> {
      // setDeletedFile(name);
      if (props.rol !== "NVWA" && props.rol !== "NVWA Lezen" && !props.readonly)
      {
        const updatedVki = { ...props.vki }; // Create a new copy of the vki object
        const index = props.vki.Bijlagen.findIndex((item: any) => item.Naam === name && item.Soort === type);
        if (index > -1) {
          props.vki.Bijlagen.splice(index, 1);
        }
        await VKIService.saveVki(props.vki, props.accountInfo.jwtIdToken, props.accountInfo.account.idToken.sub);
      
        props.onFileUpload(updatedVki);
      }
      // setDeletedFile(null);
    }

  function downloadFile(filePath:any){
    var link=document.createElement('a');
    link.href = filePath;
    link.download = filePath.substr(filePath.lastIndexOf('/') + 1);
    link.click();
  }

  if (props.vki.Bijlagen == null)
  {
    return <></>;
  }
  else
    return (
      <>
      <div>
            <Dialog open={rusure} onClose={() =>{setRusure(false)}}>
              <DialogTitle>{t('rusureTitle')}</DialogTitle>
              <DialogContent>
              <p>{t('rusure')}</p>
              </DialogContent>
              <DialogActions>
              <Button onClick={() => { 
                  if (bijlageToDelete){
                    handleDelete(bijlageToDelete.Naam, bijlageToDelete.Soort);setRusure(false);
                  }
                  }} variant="contained" color="primary"  autoFocus>
                    
                  Ok
                  </Button>
                  <Button onClick={()=>{setRusure(false);}} variant="contained" color="primary"  autoFocus>
                  Annuleren
                  </Button>
              </DialogActions>
            </Dialog>
      </div>
      <div key={key}>
        <strong>{t('bijlagen')}:</strong>&nbsp;     
        {props.vki.Bijlagen.map((bijlage, index) => (
          <>
          {(props.type === "*" || bijlage.Soort === props.type) && (
          <Chip  disabled={deletedFile != null} size='small' key={props.vki.Id + "_" + bijlage.Naam} label={bijlage.Naam} 
          onClick={() =>{
            setPreviews(bijlage.Previews);
            setBijlage(bijlage);
            setCurrentPage(1);
            setDialogOpen(true);
            
            //downloadFile( environment.backendBaseUrl + bijlage.Locatie)
          }} 
          variant="outlined"   deleteIcon={
            <IconButton hidden={(props.rol === "NVWA" || props.rol === "NVWA Lezen" || props.readonly)} >
              <Delete />
            </IconButton>
          }onDelete={() => { setRusure(true); setBijlageToDelete(bijlage);}} 
          
          icon={<div  className="icon"><FileIcon radius={15} type='document' extension={bijlage.Naam.split('.')[bijlage.Naam.split('.').length -1]}  /></div>} />)}&nbsp;
          </>
        ))}
          <input
          accept="*/*"
          id={'file-upload-' + key}
          type="file"
          className={classes.input}
          onChange={handleFileChange}
        />
        <label htmlFor={'file-upload-' + key}>
          {props.rol !== "NVWA" && props.rol !== "NVWA Lezen" && !props.readonly && ( 
            <Button
              variant="contained"
              color="primary"
              component="span"
              disabled={selectedFile != null}
              startIcon={<CloudUploadIcon />}
            >
              Upload
            </Button>
          )}
          <div hidden={selectedFile == null} ><Box sx={{ width: '100%' }}> <LinearProgress  /> </Box></div>
        </label>

        {dialogOpen && (
          <PreviewDialog
          accountInfo={props.accountInfo}
            bijlage={geselecteerdeBijlage}
            previews={previews}
            currentPage={currentPage}
            onClose={() => setDialogOpen(false)}
            onPreviousPage={() => setCurrentPage(currentPage - 1)}
            onNextPage={() => setCurrentPage(currentPage + 1)}
          />
        )}
      </div>
      </>
    );
  };

  