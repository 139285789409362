export const environment = {
	apiBaseUrl: process.env.REACT_APP_API_BASE_URL || '',
	AD_B2C_Tenant: process.env.REACT_APP_AD_B2C_TENANT || '',
	AD_B2C_SignInPolicy: 'B2C_1_AanmeldenRegistreren',
	AD_B2C_ApplicationId: process.env.REACT_APP_AD_B2C_APPLICATION_ID || '',
	AD_B2C_ReactRedirectUri: process.env.REACT_APP_AD_B2C_REACT_REDIRECT_URI || '',
	REACT_APP_FUNCTION_KEY: process.env.REACT_APP_FUNCTION_KEY || '',
	test: process.env.REACT_APP_USE_TEST_COLLECTION || '',
	backendBaseUrl:  process.env.REACT_APP_BACKEND_API_BASE_URI || '',
	environment: process.env.REACT_APP_BACKEND_ENVIRONMENT || ''
	
};
