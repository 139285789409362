import { Box, LinearProgress } from '@mui/material';
import React, { FC } from 'react';

const Authenticating: FC<{}> = (): JSX.Element => {
	if (localStorage.getItem("VKILanguage") !== null) {
		localStorage.removeItem("VKILanguage");
	}
	return <p><div><Box sx={{ width: '100%' }}> <LinearProgress  /> </Box></div></p>;
};

export default Authenticating;
