import React, { useEffect, useState } from 'react';
import {
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  LinearProgress,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { IVKI } from '../../VKI/Interfaces/IVKI';
import camelcaseKeys from 'camelcase-keys';
import Button from '@mui/material/Button';
import { environment } from './env';
import VKIService from '../Services/VKIService';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router';
import { t } from 'i18next';

export const MeerdereSlachthuizen = (props: any) => {
  const [selectedItem, setSelectedItem] = useState('1');
  const [isBusy, setIsBusy] = useState(false);
  const options: Intl.DateTimeFormatOptions = { day: '2-digit', month: '2-digit', year: 'numeric' };
  const navigate = useNavigate();
  if (props.rol !== "Boer")
  {
    return <></>;
  }
  return (

    <>
      <strong>{t('meerdereSlachthuizen')}</strong><br/>
      <Select
        value={selectedItem}
        autoWidth={true}
        onChange={(event) => setSelectedItem(event.target.value)}
        displayEmpty
        size="small"	
        style={{ marginBottom: '1rem' }}
      >
          <MenuItem key={1} value={1}>
            1
          </MenuItem>
          <MenuItem key={2} value={2}>
            2
          </MenuItem>
          <MenuItem key={3} value={3}>
            3
          </MenuItem>
      </Select>&nbsp;
      <Button disabled={isBusy} variant="contained" color="primary" onClick={async () => {
        setIsBusy(true);
        await VKIService.kopieerExtern(props.vki.Id, selectedItem, props.accountInfo.account.idToken.sub, props.accountInfo.jwtIdToken);
        navigate("/");
      }}>Kopieer</Button>
      <Box hidden={!isBusy} sx={{ width: '100%' }}> <LinearProgress  /> </Box>
      </>
  );
};

export default MeerdereSlachthuizen;
