import { FormControl } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { nlNL } from '@mui/x-date-pickers/locales';
import dayjs from 'dayjs';
import React from 'react';
interface CustomDatePickerProps {
  label: string;
  value: Date | null | undefined;
  onChange: (date: Date | null, name: string, index: number) => void;
  errorText?: string;
  isError?: boolean;
  className: string;
  name: string;
  size?: 'small' | 'medium' | 'auto';
  hideBorders?: boolean;
  index: number;
  readOnly?: boolean;
  datePickerClassName?: string;
}

export const CustomDatePicker: React.FC<CustomDatePickerProps> = ({
  name,
  className,
  label,
  value,
  onChange,
  errorText,
  isError,
  size = "auto",
  hideBorders = false,
  index = 0,
  readOnly = false,
  datePickerClassName = '',
}) => {
  const handleChange = (newDate: Date | null) => {
    onChange(newDate, name, index);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} localeText={nlNL.components.MuiLocalizationProvider.defaultProps.localeText}>
      <FormControl fullWidth className={className}>
        <DatePicker
          label={label}
          className={datePickerClassName}
          value={value !== null ? dayjs(value) as any: null}
          onChange={handleChange}
          readOnly={readOnly} 
          slotProps={{
            textField: {
              helperText: isError ? errorText : '',
              error: isError || false,
              sx: {
                width: size === "small" ? "120px" : size === "medium" ? "180px" : "auto",
                height: size === "small" ? "0.5rem !important" : "auto",
                padding: "0 !important",
              },
            },
          }}
          format='DD-MM-YYYY'
        />
      </FormControl>
    </LocalizationProvider>
  );
};

export default CustomDatePicker;
