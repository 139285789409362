import React from 'react';
import { Snackbar } from '@mui/material';
import { CheckCircleOutline, ErrorOutline, HourglassEmptyOutlined } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

interface SnackbarComponentProps {
  open: boolean;
  onClose: () => void;
  status: 'success' | 'failed' | 'pending' | '';
}

const StyledSnackbar = styled(Snackbar)(({ status }: { status: string }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: '8px',
  border: '2px solid',
  borderRadius: '4px',
  boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.2)',
  height: '50px',
  width: '320px',
  top: '5%',
  right: '5%', // Adjust this value to set the right anchor position
  zIndex: 9999,
  borderColor:
    status === 'success'
      ? '#4caf50' // Use your desired color here
      : status === 'failed'
      ? '#f44336' // Use your desired color here
      : status === 'pending'
      ? '#ff9800' // Use your desired color here
      : '#000', // Use your desired default color here
  backgroundColor:
    status === 'success'
      ? '#e8f5e9' // Use your desired color here
      : status === 'failed'
      ? '#ffebee' // Use your desired color here
      : status === 'pending'
      ? '#fff3e0' // Use your desired color here
      : '#fff', // Use your desired default color here
  color:
    status === 'success'
      ? '#4caf50' // Use your desired color here
      : status === 'failed'
      ? '#f44336' // Use your desired color here
      : status === 'pending'
      ? '#ff9800' // Use your desired color here
      : '#000', // Use your desired default color here
}));

const SnackbarComponent: React.FC<SnackbarComponentProps> = ({ open, onClose, status }) => {
  const { t } = useTranslation();

  const getMessageAndIcon = () => {
    switch (status) {
      case 'success':
        return {
          message: t('FormSubmitSuccess'),
          icon: <CheckCircleOutline fontSize="large" />,
        };
      case 'failed':
        return {
          message: t('FormSubmitFailed'),
          icon: <ErrorOutline fontSize="large" />,
        };
      case 'pending':
        return {
          message: t('FormSubmitInprogress'),
          icon: <HourglassEmptyOutlined fontSize="large" />,
        };
      default:
        return {
          message: '',
          icon: null,
        };
    }
  };

  const { message, icon } = getMessageAndIcon();

  return (
    <StyledSnackbar open={open} autoHideDuration={6000} onClose={onClose} status={status}>
      <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
        {icon}
        <span>{message}</span>
      </div>
    </StyledSnackbar>
  );
};

export default SnackbarComponent;
