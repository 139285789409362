import { Box, LinearProgress, Menu, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import camelcaseKeys from 'camelcase-keys';
import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { IVKI } from '../VKI/Interfaces/IVKI';
import { environment } from './common/env';
import download from 'downloadjs';
import { DataGrid, GridCellParams, GridColDef, GridTreeNode } from '@mui/x-data-grid';
import { localizedTextsMap } from './common/LocalizedTextMap';
import { useTranslation } from 'react-i18next';
import MenuIcon from '@mui/icons-material/Menu';

export const NVWA: React.FC = (props:any) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
	const [data, setData] = useState<IVKI[] | null>(null);
  const handleClick2 = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl2(event.currentTarget);
  };
  
	useEffect(() => {

  
	  fetchData( localStorage.getItem("pagina") === "goedgekeurd");
	}, []);
	
  const fetchData = async (showGoed:boolean) => {
    console.log(showGoed);
		try {
		  const response = await fetch(environment.backendBaseUrl + 'VKIOverzicht?showall=' + showGoed + '&type=nvwa&email=' + props.accountInfo.account.idToken.emails[0] + '&environment=' + environment.environment + '&code=' + environment.REACT_APP_FUNCTION_KEY,
      { headers: { Authorization: 'Bearer ' + props.accountInfo.jwtIdToken } }
      ); // Replace with your REST endpoint URL
		  if (response.ok) {
			const jsonData = await response.json();
			
			const camelCasedData = camelcaseKeys(jsonData, { deep: true, pascalCase: true });
			setData(camelCasedData);
		  } else {
			// Handle error if needed
			
			console.error('Error:', response.status);
		  }
		} catch (error) {
		  // Handle error if needed
		  console.error('Error:', error);
		}
	  };
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const filteredData = data?.filter(item =>
    item.Slachthuis?.Naam?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    item.Pluimveehouder?.Naam?.toString().includes(searchTerm) ||
    item.Slachthuis?.Plaats?.toString().includes(searchTerm) ||
    item.LaatsteSlachtDatum?.toString().includes(searchTerm) ||
    item.Stalnummer?.toString().includes(searchTerm) ||
    item.Opzetdatum?.toString().includes(searchTerm) ||
    item.Leveringsnummer?.toString().includes(searchTerm) ||
    item.Risico?.toString()?.replace("true", "ja")?.replace("false", "nee")?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
	  item.StatusNvwa.toString().includes(searchTerm)
  );

  const groupedData:any = {};

// Group the data by NvwaStatus and Slachthuis.Plaats
if (filteredData) {
  filteredData.forEach(item => {
    const status = item.StatusNvwa.toString();
    const plaats = item.Slachthuis.Plaats;

    if (!groupedData[status]) {
      groupedData[status] = [];
    }


    groupedData[status].push(item);
  });
}


	const options:Intl.DateTimeFormatOptions = { day: '2-digit', month: '2-digit', year: 'numeric' };
  const columns:GridColDef<IVKI>[] = [
    { field: 'Slachthuis.Naam', headerName: t('naamvanslachthuis')?.toString(), width: 300, editable: false, 
      headerClassName: 'headerOverviewVKI',
      valueGetter: (params) => {
        return params.row.Slachthuis?.Naam;
      }
    },
    { field: 'Pluimveehouder.Naam', headerName: 'Pluimveehouder', width: 300, editable: false,
      headerClassName: 'headerOverviewVKI',
      valueGetter: (params) => {
        return params.row.Pluimveehouder?.Naam;
      }
    },
    { field: 'Stalnummer', headerName: t('stalnummer')?.toString() , width: 150, headerClassName: 'headerOverviewVKI', editable: false }, 
    {
      field: 'Opzetdatum',
      headerClassName: 'headerOverviewVKI',
      headerName: t('opzetdatum')?.toString(),
      width: 150, editable: false,
      renderCell: (params: GridCellParams) => (
        params.value ? new Date(params.value as string).toLocaleDateString('nl-NL') : ''
      ),
    },
    {
      field: 'LaatsteSlachtDatum',
      headerClassName: 'headerOverviewVKI',
      headerName: t('Slachtdatum')?.toString(),
      width: 150, editable: false,
      renderCell: (params: GridCellParams<IVKI, any, any, GridTreeNode>) => (
        params.value ? new Date(params.value as string).toLocaleDateString('nl-NL') : ''
      ),
    },
    { field: 'Slachthuis.Plaats', headerName: t('plaatsvanslachthuis')?.toString(), width: 300, editable: false,
      headerClassName: 'headerOverviewVKI',
      valueGetter: (params) => {
        return params.row.Slachthuis?.Plaats;
      }
    },
    { field: 'Leveringsnummer',headerClassName: 'headerOverviewVKI', headerName: t('levering')?.toString(), width: 120, editable: false },
    { field: 'Kipconcept',headerClassName: 'headerOverviewVKI', headerName: "Kipconcept", width: 100, editable: false },


    { field: 'Risico', headerName: t('risico')?.toString(), width: 100, renderCell: (params: GridCellParams<IVKI, any, any, GridTreeNode>) => (params.value ? 'Ja' : 'Nee'), editable: false,headerClassName: 'headerOverviewVKI' },
    {
      field: 'actions',
      headerClassName: 'headerOverviewVKI',
      headerName: '...',
      width: 200,
      editable: false, 
      renderCell: (params: GridCellParams<IVKI, any, any, GridTreeNode>) => (
        <div>
            <Button variant="contained" color="primary" onClick={() => navigate("/VKI/" + params.row.Id)}>Bekijken</Button>&nbsp;
            <Button className="vkibutton"  variant="contained" color="primary" 
              onClick={() => {
                const token = props.accountInfo.jwtIdToken;
                const downloadUrl = `${environment.backendBaseUrl}DownloadPDF?userid=${props.accountInfo.account.idToken.sub}&id=${params.row.Id}&code=${environment.REACT_APP_FUNCTION_KEY}${'&environment=' + environment.environment}`;
    
                const options = {
                  headers: {
                    Authorization: `Bearer ${token}`
                  }
                };
    
                fetch(downloadUrl, options)
                .then( res => res.blob() )
                .then( blob => {
                  let myDate:Date = new Date();
                  try{
                    if (params.row.Opzetdatum)
                    {
                      myDate = new Date(params.row.Opzetdatum);
                    }
                  }
                  catch(error){}
                  download(blob, params.row.Pluimveehouder?.Naam + "_" + params.row?.Stalnummer + "_" + myDate.getDate() +  myDate.getMonth() + myDate.getFullYear() + ".pdf");
                  });
                }}
              >PDF</Button>
        </div>
       )
      }
    ]

    const handleClose2 = () => {
      setAnchorEl2(null);
    };  
    const [anchorEl2, setAnchorEl2] = React.useState<null | HTMLElement>(null);
    const open2 = Boolean(anchorEl2);
    const [showAll, setShowAll] = useState<boolean>(false);
    
  if (data && data.length != 0){
    return (
      <div className='VKIOverzicht'>

      <Button
        hidden={props.role == "AGRI Lezen" }
        id="basic-button"
        aria-controls={open2 ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open2 ? 'true' : undefined}
        variant="contained" color="primary"
        onClick={handleClick2}
        startIcon={<MenuIcon />}
        className='menuButton'
      >
        Menu
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl2}
        open={open2}
        onClose={handleClose2}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem  onClick={() => {setAnchorEl2(null);  setShowAll(false);localStorage.setItem("pagina", "nieuwvki"); fetchData(false); }}>Nieuwe VKI's</MenuItem> 
        <MenuItem  onClick={() => {setAnchorEl2(null);  setShowAll(true); localStorage.setItem("pagina", "goedgekeurd");fetchData(true);}}>Goedgekeurde VKI's</MenuItem> 
      </Menu>
        <TextField
          label="Zoeken"
          value={searchTerm}
          onChange={handleSearch}
          variant="outlined"
          style={{ marginBottom: '1rem' }}
        />
        <Box hidden={data != null} sx={{ width: '100%' }}> <LinearProgress  /> </Box>
        {Object.keys(groupedData).sort((a, b) => b.localeCompare(a)).map(status => (
          <div key={status}>
            <h2>{status}</h2>
                      <DataGrid
                      getRowId={(row) => row.Id}
                      rows={groupedData[status] || []}
                      columns={columns}
                      unstable_ignoreValueFormatterDuringExport={true}
                      rowHeight={30}
                      pageSizeOptions={[{ value: 25, label: "25" },  { value: 10, label: "10" }]}
                      localeText={localizedTextsMap}
                      
                      componentsProps={{
                        pagination: {
                          labelRowsPerPage: "Rijen per pagina"
                        }
                      }}
                  />

          </div>
        ))}

    </div>
    );
  }else if (data && data.length == 0){
    return(
      <div className='VKIOverzicht'>
      <Button
        hidden={props.role == "AGRI Lezen" }
        id="basic-button"
        aria-controls={open2 ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open2 ? 'true' : undefined}
        variant="contained" color="primary"
        onClick={handleClick2}
        startIcon={<MenuIcon />}
        className='menuButton'
      >
        Menu
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl2}
        open={open2}
        onClose={handleClose2}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem  onClick={() => {setAnchorEl2(null);  setShowAll(false); fetchData(false);}}>Nieuwe VKI's</MenuItem> 
        <MenuItem  onClick={() => {setAnchorEl2(null);  setShowAll(true); fetchData(true);}}>Goedgekeurde VKI's</MenuItem> 
      </Menu>
        <p>VKI Portaal is leeg.</p>
      </div>
    );
  }
  else
  {
    return(
    <Box sx={{ width: '100%' }}> <LinearProgress  /> </Box>
    );
  }
}

export default NVWA;