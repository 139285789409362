import TextareaAutosize from '@mui/base/TextareaAutosize';
import SaveIcon from '@mui/icons-material/Save';
import { Box, LinearProgress, MenuItem, Select, SelectChangeEvent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, styled } from '@mui/material';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import camelcaseKeys from 'camelcase-keys';
import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { ISettings } from '../VKI/Interfaces/ISettings';
import { IVKIFormItem } from '../VKI/Interfaces/VKIForm';
import { environment } from './common/env';
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
import { blue, grey } from '@mui/material/colors';
export const WachtwoordReset: React.FC<any> = (props:any) => {
	const [nvwaDisplayName, setnvwaDisplayName] = useState<string>("");
  const [nvwaEmail, setnvwaEmail] = useState<string>("");
  const [isSaving, setIsSaving] = useState(false);
	const [selectedItem, setSelectedItem] = useState('NVWA');
  
  const [rol, setRol] = useState(false);
  const Textarea = styled(BaseTextareaAutosize)(
    ({ theme }) => `
    width: 320px;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 8px 12px;
    border-radius: 8px;
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    box-shadow: 0px 2px 2px ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};

    &:hover {
      border-color: ${blue[400]};
    }

    &:focus {
      border-color: ${blue[400]};
      box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[600] : blue[200]};
    }

    // firefox
    &:focus-visible {
      outline: 0;
    }
  `,
  );
  const handleSave = async () => {
    setIsSaving(true);
  
    try {
      // Make the REST API call to save the settings
      // Replace `YOUR_API_ENDPOINT` with the actual endpoint
      await fetch(environment.backendBaseUrl + 'ResetPassword?userid=' + props.accountInfo.account.idToken.sub +'&environment=' + environment.environment + '&email=' + props.accountInfo.account.idToken.emails[0]  + '&resetEmail=' + nvwaEmail + '&code=' + environment.REACT_APP_FUNCTION_KEY, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + props.accountInfo.jwtIdToken
          // Add any necessary authorization headers
        },
      
      }).then(() => {
        alert("Email gereset!");
      }
      ).catch
      ((e:any) => {
        alert("Er ging wat fout: " + e);
      });
  
      // Handle successful save
    } catch (error) {
      alert("Er ging wat fout");
      console.error('Error saving settings:', error);
    }
  
    setIsSaving(false);
  };

  const handleNVWAEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setnvwaEmail(event.target.value);
  };
  const navigate = useNavigate();

  return (
    <div className='VKIOverzicht'>
		<h1>Reset het wachtwoord van een gebruiker</h1>
    <TextField
        label="Email"
        value={nvwaEmail}
        onChange={handleNVWAEmail}
        variant="outlined"
        style={{ marginBottom: '1rem' }}
      /><br/>
 
      <Button
        variant="contained"
        color="primary"
        startIcon={<SaveIcon />}
        onClick={handleSave}
        disabled={isSaving}
        style={{ marginTop: '1rem' }}
      >
        {isSaving ? (
          <CircularProgress size={24} color="inherit" />
        ) : (
          'Reset'
        )}
    </Button>
    </div>
  );
};

export default WachtwoordReset;