import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import camelcaseKeys from 'camelcase-keys';
import { IVKI } from '../../VKI/Interfaces/IVKI';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { IAccountInfo } from 'react-aad-msal';
import { IVKIForm } from '../../VKI/Interfaces/VKIForm';
import VKIService from '../Services/VKIService';
import { Client } from '@microsoft/microsoft-graph-client';
import { PublicClientApplication } from '@azure/msal-browser';
import { environment } from './env';
export type GoedkeuringProps = {
	accountInfo: IAccountInfo;
	vki: IVKI;
  rol:string;
};
export const Voortgang: FC<GoedkeuringProps> = (props: GoedkeuringProps) => {
  var vki: IVKI = props.vki;
  if (vki && vki.StatusNvwa && vki.StatusNvwa == "Goedgekeurd")
  {
    return <><img className="goedkeuringsStatus" src="/nvwa_goedgekeurd.png"/></>;
  }else if (vki && vki.StatusNvwa && vki.StatusNvwa === "Afgekeurd")
  {
    return <><img className="goedkeuringsStatus" src="/nvwa_afgekeurd.png"/></>;
  } else if (vki && vki.StatusAgri && vki.StatusAgri === "Goedgekeurd")
  {
    return <><img className="goedkeuringsStatus" src="/agri_goedkeuring.png"/></>;
  }else if (vki && vki.Status && vki.Status === "Ingediend")
  {
    return <><img className="goedkeuringsStatus" src="/boer_ingediend.png"/></>;
  }  else if (vki && vki.Status && vki.Status === "Archief")
  {
    return <><img className="goedkeuringsStatus" src="/nvwa_goedgekeurd.png"/></>;
  }
  else
  {
    return <></>;
  }
};