import { Box, LinearProgress } from '@material-ui/core';
import { AuthError } from 'msal';
import React, { FC } from 'react';

export declare type LoginFunction = () => void;

export interface UnauthenticatedProps {
	error: AuthError | null;
	login: LoginFunction;
}

const Unauthenticated: FC<UnauthenticatedProps> = ({ error, login }) => {
	return (
		<div>
			{error && (
				<p>
					<div><Box sx={{ width: '100%' }}> <LinearProgress  /> </Box></div>
				</p>
			)}
			<p>
				<button onClick={login}>Login</button>
			</p>
		</div>
	);
};

export default Unauthenticated;
