import { Box, DialogContentText, LinearProgress, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import React, { FC, useState } from 'react';
import { IAccountInfo } from 'react-aad-msal';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { IVKI } from '../../VKI/Interfaces/IVKI';
import VKIService from '../Services/VKIService';
import { Valideren } from './Validatie';
export type GoedkeuringProps = {
	accountInfo: IAccountInfo;
	vki: IVKI;
  rol:string;
};
export const Goedkeuring: FC<GoedkeuringProps> = (props: GoedkeuringProps) => {
  var vki: IVKI = props.vki;
  const { t } = useTranslation();
  
  console.log(props.rol);
  // TODO: Bijlagen checken op salmonella, inlegvel, mortaliteit, dierenartsverklaring.
  const [open, setKanNietIndienen] = useState(false);
  const [agriOpen, setAgriOpen] = useState(false);
  const [keuringinProgress, setKeuring] = useState(false);
  const [validatieBericht, valideer] = useState("");
  const [reden, setKeuringReden] = useState("");
  const [message, setMessage] = useState('');
  const [openAfkeuringReden, setOpenAfkeuringReden] = useState(false);

    // Event handler to update the state when the text changes
    const handleTextFieldChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {

      setKeuringReden(event.target.value);
    };
  const handleAfkeuringRedenClickOpen = () => {
    setOpenAfkeuringReden(true);
  };

  const handleAfkeuringRedenClose = () => {
    setOpenAfkeuringReden(false);
  };

  const kanNietIndienenClose = () => {
    setKanNietIndienen(false);
  };
  
  const handleAfkeurenNVWAClick = () => {
    vki.StatusAgri = "Goedgekeurd";
    vki.StatusNvwa = "Afgekeurd";
    vki.RedenAfkeuren = reden;
    setMessage("VKI Formulier is afgekeurd");
    setKeuring(true);
    VKIService.afkeuren(vki, vki.RedenAfkeuren, props.accountInfo.account.idToken.sub, props.accountInfo.jwtIdToken).then(() =>{
      setKeuring(false);
        setAgriOpen(true);
    });
  };

  const handleGoedkeurenAgriClick = () => {
    setKeuring(true);
    vki.StatusAgri = "Goedgekeurd";
    vki.StatusNvwa = "Nieuw";
    setMessage("VKI Formulier is goedgekeurd");
    VKIService.goedkeuren(vki, props.accountInfo.account.idToken.sub, props.accountInfo.jwtIdToken).then(() =>{
        
        setAgriOpen(true);
        setKeuring(false);
    });
  };
  
  const handleIndienKlaarClick = () => {
    var msg = Valideren.valideerFormulier({vki: vki, bearerToken: props.accountInfo.jwtAccessToken, userId: props.accountInfo.account.idToken.sub});
    if (msg === "")
    {
      vki.Status = "Ingediend";
      vki.StatusAgri = "Nieuw";
      setKeuring(true);
      setMessage("VKI Formulier is ingediend");    
        VKIService.goedkeuren(vki, props.accountInfo.account.idToken.sub, props.accountInfo.jwtIdToken).then(() =>{
            
          setAgriOpen(true);
          setKeuring(false);
      });
    }
    else
    {
      valideer(msg);
      setKanNietIndienen(true);
    }
  };
  
  const handleGoedkeurenNVWAClick = () => {
    setKeuring(true);
    vki.StatusNvwa = "Goedgekeurd";
    vki.IsVrijgegeven = true;
    setMessage("VKI Formulier is goedgekeurd");
    VKIService.goedkeuren(vki, props.accountInfo.account.idToken.sub, props.accountInfo.jwtIdToken).then(() =>{
        
        setAgriOpen(true);
        setKeuring(false);
    });
  };
  const navigate = useNavigate();
  const goHome = () => {
    navigate('/');
  };
  if (props.rol === "Boer" && vki) {

    if (!vki.IsGevalideerd)
    {
        return (
        <>
            <div hidden={!keuringinProgress}><Box sx={{ width: '100%' }}> <LinearProgress  /> </Box></div>
            <Button disabled={keuringinProgress} variant="contained" hidden={props.vki.Status !== "In bewerking" && props.vki.Status !== "Nieuw" } color="primary" onClick={handleIndienKlaarClick}>
            {t('VKIFormulierIndienen')}
            </Button>
            <Dialog open={open} onClose={kanNietIndienenClose}>
            <DialogTitle>{t('VKIFormulierIndienen')}</DialogTitle>
            <DialogContent hidden={validatieBericht !== ""} >
            <p>{t('validatieSucces')}</p>
            </DialogContent>
            <DialogContent hidden={validatieBericht === ""}>
                <p>{t('validatieGeenSucces')}</p>
                <p dangerouslySetInnerHTML={{ __html:validatieBericht}}></p>
            </DialogContent>
            <DialogActions>
                <Button onClick={kanNietIndienenClose} variant="contained" color="primary"  autoFocus>
                Ok
                </Button>
            </DialogActions>
            </Dialog>
        </>);
    }
    else
    {
        return (
            <>
              <div hidden={!keuringinProgress}><Box sx={{ width: '100%' }}> <LinearProgress  /> </Box></div>
                <Button disabled={keuringinProgress} variant="contained" color="primary" hidden={props.vki.Status !== "In bewerking"} onClick={handleIndienKlaarClick}>
                {t('VKIFormulierIndienen')}
                </Button>
                <Dialog open={agriOpen} onClose={kanNietIndienenClose}>
                <DialogTitle>{t('VKIFormulierIndienen')}</DialogTitle>
                <DialogContent>
                    <p > {t('VKIFormulierIngediend')}</p>
                </DialogContent>
                <DialogActions>
                    <Button onClick={goHome} variant="contained" color="primary"  autoFocus>
                    {t('ok')}
                    </Button>
                </DialogActions>
                </Dialog>
            </>);
    }

    
} else if ((props.rol === "AGRI" || props.rol === "AGRI Keuren") && vki) {
    return (
        <>
        <div hidden={!keuringinProgress}><Box sx={{ width: '100%' }}> <LinearProgress  /> </Box></div>
          <Button  disabled={keuringinProgress || vki.StatusAgri !== "Nieuw"} variant="contained" color="primary" onClick={handleGoedkeurenAgriClick}>
            Goedkeuren
          </Button>&nbsp;
          <Button  hidden={props.rol === "AGRI Keuren"} variant="contained" color="primary" onClick={()=>{window.location.href ='/Versiebeheer/' + vki.Id}}>
            Versies beheren
          </Button>
          <Dialog open={agriOpen} onClose={goHome}>
            <DialogTitle>{t('vkiform')}</DialogTitle>
            <DialogContent>
              <p>{message}</p>
            </DialogContent>
            <DialogActions>
              <Button onClick={goHome} variant="contained" color="primary"  autoFocus>
                {t('ok')}
              </Button>
            </DialogActions>
          </Dialog>
        </>
      );  
  } else if (props.rol === "NVWA" && vki) {
    return (
        <>
        <div hidden={!keuringinProgress}><Box sx={{ width: '100%' }}> <LinearProgress  /> </Box></div>
          <Button  disabled={keuringinProgress || vki.StatusNvwa !== "Nieuw"} variant="contained" color="primary" onClick={handleGoedkeurenNVWAClick}>
            Goedkeuren
          </Button>&nbsp;
          <Button  disabled={keuringinProgress  || vki.StatusNvwa !== "Nieuw" && vki.StatusNvwa !== "Goedgekeurd"} variant="contained" color="primary" onClick={handleAfkeuringRedenClickOpen}>
            Afkeuren
          </Button>

          <Dialog open={openAfkeuringReden} onClose={handleAfkeuringRedenClose}>
            <DialogTitle>Afkeuren</DialogTitle>
            <DialogContent>
              <DialogContentText>
                <p>Geef een reden van Afkeuren op</p>
                <p>{keuringinProgress ? "Bezig met keuren 1 moment geduld a.u.b" : ""}</p>
              </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Afkeuren"
                type="text"
                value={reden}
                fullWidth
                variant="standard"
                onChange={handleTextFieldChange}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleAfkeurenNVWAClick}  disabled={keuringinProgress}>Afkeuren</Button>
            </DialogActions>
          </Dialog>

          <Dialog open={agriOpen} onClose={goHome}>
            <DialogTitle>VKI Formulier</DialogTitle>
            <DialogContent>
              <p>{message}</p>
            </DialogContent>
            <DialogActions>
              <Button onClick={goHome} variant="contained" color="primary"  autoFocus>
                Ok
              </Button>
            </DialogActions>
          </Dialog>
        </>
      );  
  } 
  else {
    return <></>;
  }
};