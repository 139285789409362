import TextField from '@material-ui/core/TextField';
import { FormHelperText, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { TimeField } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs, { Dayjs } from 'dayjs';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { getInitialProps, useTranslation } from 'react-i18next';
import { IFormValidationObject, ILeveringDataSectionProps } from '../../VKI/Interfaces/IForm';
import { ILevering, IVKI } from '../../VKI/Interfaces/IVKI';
import { IVKIFormItem } from '../../VKI/Interfaces/VKIForm';
import { DateUtil } from '../../utils/date';
import { NumberUtils } from '../../utils/numbers';
import CustomDatePicker from '../CustomDatePicker';
import { Valideren } from '../common/Validatie';
import { Tooltip } from '@mui/material';

const LeveringDataSection: React.FC<ILeveringDataSectionProps> = ({ vkiData, accountInfo, onChange, strooiselOptions, stooiselKwaliteitOptions, isReadonly, groupClaim, validateObject, setValidationObject }) => {
  const { t } = useTranslation();

  // set state of default levering object,
  const [errors, setErrors] = useState({
    isGewichtError: false,
    isGewichtError1: false,
    isGewichtError2: false,
    isGewichtError3: false,
    isSlachtDatumError: false,
    isSlachtDatumError1: false,
    isSlachtDatumError2: false,
    isSlachtDatumError3: false,
    isSlachtDatumErrorString: '',
    isSlachtDatumErrorString1: '',
    isSlachtDatumErrorString2: '',
    isSlachtDatumErrorString3: ''
  });

  const verplichtErrorMessage = t('verplichtVeld');
  const alwaysReadonly = true;
  const intialProps = getInitialProps();
  useEffect(() => {
    if (vkiData && vkiData.Levering !== null) {
      if (
        vkiData &&
        vkiData.Levering &&
        vkiData.Levering[0] &&
        vkiData.Levering[0].TypeStrooisel &&
        (vkiData.Levering[0].TypeStrooisel !== undefined || vkiData.Levering[0].TypeStrooisel !== null)
      ) {
        // No action needed if there's a valid levering object.
      } else {
        if (!vkiData.Levering || vkiData.Levering.length === 0) {
          vkiData.Levering = [
            {
              Laden: '',
              DatumLaden: undefined,
              AanvangLaden: undefined,
              Slachtdatum: undefined,
              Aantal: 0,
              AantalCombinaties: 0,
              Gewicht: 0,
              TypeStrooisel: undefined,
              KwaliteitStrooisel: undefined,
              KwaliteitStrooiselExterneId: "",
              TypeStrooiselExterneId: ""
            },
          ];
        }
  
        // Set default values for TypeStrooisel if it's undefined or null.
        vkiData.Levering[0].TypeStrooisel = vkiData.Levering[0].TypeStrooisel || '';
      }
    }

  }, [vkiData]);

  useEffect(() => {
    if(vkiData !== undefined)isLeveringFormValid(vkiData);
  }, [vkiData]);

  const createNewLeveringObject = (name: keyof ILevering, value: any): ILevering => {
    // eigenschappen van ilevering worden optioneel omdat later de changes die gedaan is aangepast wordt.
    const newObject: Partial<ILevering> = {
      Laden: undefined,
      DatumLaden: undefined,
      AanvangLaden: undefined,
      Slachtdatum: undefined,
      Aantal: 0,
      AantalCombinaties: undefined,
      Gewicht: 0,
      TypeStrooisel: undefined,
      KwaliteitStrooisel: undefined,
    };
  
    // get the new changes and add them to the new object already
    if(vkiData?.Levering[0]?.TypeStrooisel)newObject.TypeStrooisel = vkiData?.Levering[0]?.TypeStrooisel; 
    if(vkiData?.Levering.length !== undefined) newObject.Laden = vkiData?.Levering.length.toString();

    // give the new values that has changed with...
    newObject[name] = value;

    return newObject as ILevering;
  };

  const handleDatePickerChange = (date: Date | null, name:string, index: number) => {
    onChange((prevVkiData: IVKI) => {
      // if the object doesnt exists yet, create a new one.
      let updatedVkiData :IVKI = prevVkiData;
      //if(!updatedVkiData.Levering[index]) updatedVkiData.Levering[index] = createNewLeveringObject(name as keyof ILevering, date); 

      const updatedLevering = updatedVkiData.Levering ? [...updatedVkiData.Levering] : [];
      
      if (!updatedVkiData.Levering || (date === null || date === undefined) && name === 'DatumLaden') {
        //updatedLevering[index] = createNewLeveringObject(name as keyof ILevering, undefined);
      } else {
        updatedLevering[index] = { ...updatedLevering[index], [name]: date !== null  ? new Date(date) : undefined};
      }

      updatedVkiData.Levering = updatedLevering;

      updatedVkiData = Valideren.zetLaatsteSlachtDatum(updatedVkiData);
      updatedVkiData = Valideren.zetLaatsteLaadDatum(updatedVkiData);

      isLeveringFormValid(updatedVkiData);

      return {...updatedVkiData};
    });
  };

  const handleDateChange = (index: number, event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    
    onChange((prevVkiData: IVKI) => {
      // if the object doesnt exists yet, create a new one.
      let updatedVkiData :IVKI = prevVkiData;
      //if(!updatedVkiData.Levering[index]) updatedVkiData.Levering[index] = createNewLeveringObject(name as keyof ILevering, value); 

      const updatedLevering = updatedVkiData.Levering ? [...updatedVkiData.Levering] : [];
      
      if (!updatedVkiData.Levering || (value === "" || value === null || value === undefined) && name === 'DatumLaden') {
        //updatedLevering[index] = createNewLeveringObject(name as keyof ILevering, undefined);
      } else {
        updatedLevering[index] = { ...updatedLevering[index], [name]: DateUtil.isValidDate(value) ? new Date(value) : undefined };
      }

      updatedVkiData.Levering = updatedLevering;

      updatedVkiData = Valideren.zetLaatsteSlachtDatum(updatedVkiData);
      updatedVkiData = Valideren.zetLaatsteLaadDatum(updatedVkiData);

      isLeveringFormValid(updatedVkiData);

      return {...updatedVkiData};
    });
  };
  
  const handleRowNumberInputChange = (index: number, event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
  
    onChange((prevVkiData: IVKI) => {
      const updatedLevering: ILevering[] = prevVkiData.Levering ? [...prevVkiData.Levering] : [];
      let newValue: number | undefined;
  
      // remove leading zero if value has it.
      if(value !== '') {
        newValue = NumberUtils.RemoveLeadingZero(Number(value));
      } else {
        newValue = undefined;
      }
  
      if (!prevVkiData.Levering[index]) {
        //updatedLevering[index] = createNewLeveringObject(name as keyof ILevering, newValue);
      } else {
        const updatedObject = { ...updatedLevering[index], [name]: newValue };
        updatedLevering[index] = updatedObject;
      }
  
      prevVkiData.Levering = updatedLevering;
      isLeveringFormValid(prevVkiData);
  
      return { ...prevVkiData };
    });
  };

  const handleRowNumberGewichtInputChange = (index: number, event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;

    const includesDotOrComma = /[.,]/.test(value);

    console.log('includesDotOrComma', includesDotOrComma, 'dit is de value: ', value);

    if(index === 0) {
      setErrors(prevErrors => ({
          ...prevErrors,
          isGewichtError: includesDotOrComma,
      }));
    } else if (index === 1) {
        setErrors(prevErrors => ({
            ...prevErrors,
            isGewichtError1: includesDotOrComma,
        }));
    } else if (index === 2) {
        setErrors(prevErrors => ({
            ...prevErrors,
            isGewichtError2: includesDotOrComma,
        }));
    } else if (index === 3) {
        setErrors(prevErrors => ({
            ...prevErrors,
            isGewichtError3: includesDotOrComma,
        }));
    }

    

    onChange((prevVkiData: IVKI) => {
      const updatedLevering: ILevering[] = prevVkiData.Levering ? [...prevVkiData.Levering] : [];
      let newValue: number | undefined;
  
      // remove leading zero if value has it.
      if(value !== '') {
        newValue = NumberUtils.RemoveLeadingZero(Number(value));
      } else {
        newValue = undefined;
      }
  
      if (!prevVkiData.Levering[index]) {
        //updatedLevering[index] = createNewLeveringObject(name as keyof ILevering, newValue);
      } else {
        const updatedObject = { ...updatedLevering[index], [name]: newValue };
        updatedLevering[index] = updatedObject;
      }
  
      prevVkiData.Levering = updatedLevering;
      isLeveringFormValid(prevVkiData);
  
      return { ...prevVkiData };
    });
  };
  

  const handleTimeChange = (index: number, value: any | null) => {
    if(value === null || value === undefined) return;
    if(value.isValid() === false) return;

    onChange((prevVkiData: IVKI) => {
      const updatedLevering: ILevering[] = prevVkiData.Levering ? [...prevVkiData.Levering] : [];
      let hour:string = value["$H"].toString();
      console.log(hour);
      if (hour?.length == 1)
      {
        hour = "0" + hour;
      }
      let minute:string = value["$m"].toString();
      if (minute?.length == 1)
      {
        minute = "0" + minute;
      }
      console.log(minute);

      if (!prevVkiData.Levering ||  !prevVkiData.Levering[index]) {
        //updatedLevering[index] = createNewLeveringObject("AanvangLaden" as keyof ILevering, hour + ":" + minute);
      } else {
        // update object
        const updatedObject = { ...updatedLevering[index], AanvangLaden: hour + ":" + minute };
        updatedLevering[index] = updatedObject;
      }

      prevVkiData.Levering = updatedLevering;
      isLeveringFormValid(prevVkiData);
  
      return prevVkiData;
    });
  };

  const getTimeDayJs = (time:string): any =>
  {
    if (!time || (time && (time.indexOf(':') === -1)))
    {
      return undefined;
      //time = "06:00";
    }
    
    let date:Date = new Date();
    date.setHours(parseInt(time.split(":")[0]));
    date.setMinutes(parseInt(time.split(":")[1]));
    return dayjs(date);
  }
  const handleTypeStrooiselChange = (event: SelectChangeEvent<any>) => {
    const { value } = event.target;
    console.log("type strooisel" + value);
    onChange((prevVkiData: IVKI) => {
      const updatedData = prevVkiData.Levering?.map((levering: ILevering) => ({
        ...levering,
        TypeStrooisel: strooiselOptions?.find((strooiselOption) => strooiselOption.ExterneId === value && strooiselOption.Taal?.toLowerCase() === intialProps.initialLanguage.toLowerCase())?.Titel,
        TypeStrooiselExterneId: value,
      }));
      console.log(updatedData);
      return {
        ...prevVkiData,
        Levering: updatedData
      };
    });
  };
  
  const handleKwaliteitStrooiselChange = (index: number, event: SelectChangeEvent<any>) => {
    const { name, value } = event.target;
    // set the validation object of VKI Form 
    onChange((prevVkiData: IVKI) => {
      const updatedLevering = prevVkiData.Levering ? [...prevVkiData.Levering] : [];
      if (!prevVkiData.Levering) {
        updatedLevering[index] = createNewLeveringObject(name as keyof ILevering, value);
      } else {
          if((value === 'empty')) {
            updatedLevering[index] = {
            ...updatedLevering[index],
            KwaliteitStrooisel: undefined,
            KwaliteitStrooiselExterneId: '',
          };
        }
        updatedLevering[index] = { ...updatedLevering[index],
           [name]: stooiselKwaliteitOptions?.find((strooiselKwaliteitOptions) => strooiselKwaliteitOptions.ExterneId === value && strooiselKwaliteitOptions.Taal?.toLowerCase() === intialProps.initialLanguage.toLowerCase())?.Titel,
           KwaliteitStrooiselExterneId: value,
          };
      }

      prevVkiData.Levering = updatedLevering;

      isLeveringFormValid(prevVkiData);

      return prevVkiData;
    });
  };

  // function that determines if the form is valid or not.
  const isLeveringFormValid = (data: IVKI): void => {
    let isValid:boolean = true;
    if(data)  {
      let valideerSlachtDatum:string;
      let valideerSlachtDatumDatumInvullen:string = "";
      // controleer of slachtDatum ok is vergeleken met datum vaninvullen
       data.Levering.forEach((levering: ILevering, index:number) => {
         if((levering.Slachtdatum === undefined && index === 1) || (levering.Slachtdatum === undefined && (levering.DatumLaden === undefined || levering.DatumLaden === null))) isValid = false;
         valideerSlachtDatum = Valideren.valideerSlachtDatum(data, index + 1, true).toString();
        
        // depricated.. Gaan er vanuit dat dit goed ingevuld gaat worden.
        //valideerSlachtDatumDatumInvullen = Valideren.valideerSlachtDatumDatumInvullen(data, index + 1, true).toString();
        //valideerSlachtDatumDatumInvullen = Valideren.valideerSlachtDatumDatumInvullen(data, index + 1, true).toString();

        if(valideerSlachtDatum === '' && valideerSlachtDatumDatumInvullen === '/') {
           if(index === 0) {
            setErrors(prevErrors => ({
                ...prevErrors,
                isSlachtDatumError: false,
                setIsSlachtDatumErrorString: "",
            }));
           } else if( index === 1 ) {
            setErrors(prevErrors => ({
                ...prevErrors,
                isSlachtDatumError1: false,
                setIsSlachtDatumErrorString1: "",
            }));
           } else if ( index === 2) {
            setErrors(prevErrors => ({
                ...prevErrors,
                isSlachtDatumError2: false,
                setIsSlachtDatumErrorString2: "",
            }));
           } else if ( index === 3) {
            setErrors(prevErrors => ({
                ...prevErrors,
                isSlachtDatumError3: false,
                setIsSlachtDatumErrorString3: "",
            }));
           }} else
         if(valideerSlachtDatum !== '') {
           if(index === 0) {
            setErrors(prevErrors => ({
                ...prevErrors,
                isSlachtDatumError: true,
                setIsSlachtDatumErrorString: valideerSlachtDatum,
            })); 
           } else if( index === 1 ) {
            setErrors(prevErrors => ({
                ...prevErrors,
                isSlachtDatumError1: true,
                setIsSlachtDatumErrorString1: valideerSlachtDatum,
            }));
           } else if ( index === 2) {
            setErrors(prevErrors => ({
                ...prevErrors,
                isSlachtDatumError2: true,
                setIsSlachtDatumErrorString2: valideerSlachtDatum,
            }));
           } else if ( index === 3) {
            setErrors(prevErrors => ({
                ...prevErrors,
                isSlachtDatumError3: true,
                setIsSlachtDatumErrorString3: valideerSlachtDatum,
            }));
           }
           isValid = false;
         } 
         else if(valideerSlachtDatumDatumInvullen !== '') {
           if(index === 0) {
            setErrors(prevErrors => ({
                ...prevErrors,
                isSlachtDatumError: true,
                setIsSlachtDatumErrorString: valideerSlachtDatumDatumInvullen,
            }));
           } else if( index === 1 ) {
            setErrors(prevErrors => ({
                ...prevErrors,
                isSlachtDatumError1: true,
                setIsSlachtDatumErrorString1: valideerSlachtDatumDatumInvullen,
            }));
           } else if ( index === 2) {
            setErrors(prevErrors => ({
                ...prevErrors,
                isSlachtDatumError2: true,
                setIsSlachtDatumErrorString2: valideerSlachtDatumDatumInvullen,
            }));
           } else if ( index === 3) {
            setErrors(prevErrors => ({
                ...prevErrors,
                isSlachtDatumError3: true,
                setIsSlachtDatumErrorString3: valideerSlachtDatumDatumInvullen,
            }));
           }
           isValid = false;
        }

        // required fields for levering
         if(levering.Aantal === undefined) isValid = false;

         if(levering.AantalCombinaties === undefined) isValid = false;

         if(levering.Gewicht === undefined) isValid = false;

         if(levering.TypeStrooisel === undefined || levering.TypeStrooisel === null) isValid = false;

         if(levering.KwaliteitStrooisel === undefined || levering.KwaliteitStrooisel === null || levering.KwaliteitStrooisel === '') isValid = false;

       }); 
    }
    // update the object..
    setValidationObject((prevValidationObject: IFormValidationObject) => ({
      ...prevValidationObject,
      LeveringDataSection: isValid,
    }));
  };
  const handleKeyPress = (event:any) => {
    if (event.key === 'Enter') {
      try
      {
        event.preventDefault(); // Prevent the default behavior of the Enter key
        // You can add any custom logic here if needed
        const form = event.target.form;
        const index = Array.from(form.elements).indexOf(event.target);
        form.elements[index + 1].focus();
      }
      catch(ex){}
    }
  };

  return (
    <div className="default-section-container" >
      <h4>{t('LEVERING')}</h4>
      <div className="default-row">
        <div className="default-label" style={{fontWeight:"bold"}}>{t('delivery')}</div>
        <div className="default-label" style={{fontWeight:"bold"}}>{t('datumladen')}</div>
        <div className="default-label" style={{fontWeight:"bold"}}>{t('aanvangladen')}</div>
        <div className="default-label" style={{fontWeight:"bold"}}>{t('Slachtdatum')}</div>
        <div className="default-label" style={{fontWeight:"bold"}}>{t('Aantal')}</div>
        <div className="default-label" style={{fontWeight:"bold"}}>{t('AantalCombinaties')}</div>
        <div className="default-label" style={{fontWeight:"bold"}}>{t('Gewicht')}</div>
        <div className="default-label" style={{fontWeight:"bold"}}>{t('TypeStrooisel')}</div>
        <div  className={(!vkiData?.Levering || !vkiData?.Levering[0] || !vkiData?.Levering[0].TypeStrooisel || vkiData?.Levering[0].TypeStrooisel === '' || vkiData?.Levering[0].TypeStrooisel === undefined)? 'strooisekwaliteit-select-container-error' : 'strooisekwaliteit-select-container'}>
          <Select
            name="typestrooisel"
            value={vkiData && vkiData.Levering[0] && vkiData.Levering[0].TypeStrooisel ? vkiData.Levering[0].TypeStrooiselExterneId : ''}
            disabled={isReadonly}
            onKeyPress={handleKeyPress}
            className='select-container-levering'
            style={{border:(!vkiData?.Levering || !vkiData?.Levering[0] || !vkiData?.Levering[0].TypeStrooisel || vkiData?.Levering[0].TypeStrooisel === '' || vkiData?.Levering[0].TypeStrooisel === undefined)?"1px solid red": ''}}
            renderValue={(selected) => (
              <span className="selected-option-levering">
                {selected === 'empty'
                  ? 'Empty Selection'
                  : strooiselOptions?.find(
                      (strooiselOption) => strooiselOption.ExterneId === selected && strooiselOption.Taal?.toLowerCase() === intialProps.initialLanguage.toLowerCase()
                    )?.Titel || ''}
              </span>
            )}
            onChange={handleTypeStrooiselChange as (event: SelectChangeEvent<any>, child: React.ReactNode) => void}
            size="small"
          >
            <MenuItem value="empty" style={{ color: 'transparent' }}>
              {"Empty Selection"}
            </MenuItem>
            {/* set strooisel options to the languague selected. externId is the same so should select the otehr language options. */}
            {strooiselOptions?.map((strooiselOption: IVKIFormItem, index: number) => (
              strooiselOption.Taal?.toLowerCase() === intialProps.initialLanguage.toLowerCase() ? (
                <MenuItem key={index} value={strooiselOption.ExterneId}>{strooiselOption.Titel}</MenuItem>
              ) : (
                null
              )
            ))}
          </Select>
          {!vkiData?.Levering || !vkiData?.Levering[0] || !vkiData?.Levering[0].TypeStrooisel || vkiData?.Levering[0].TypeStrooisel === '' || vkiData?.Levering[0].TypeStrooisel === undefined ? <FormHelperText className='custom-error-message-kwaliteitstrooisel' error>{verplichtErrorMessage}</FormHelperText> : null}
        </div> 
    </div>
      <div key={0} className="default-row">
        <div className="default-label-levering">1e {t('levering')}</div>
        <CustomDatePicker readOnly={isReadonly|| (vkiData && vkiData.Leveringsnummer > 1)} name="DatumLaden" className="leveringDateFields" label={''} value={vkiData && vkiData.Levering &&  vkiData.Levering[0] && vkiData?.Levering[0].DatumLaden ? vkiData.Levering[0].DatumLaden : null} onChange={handleDatePickerChange} size="small" isError={!vkiData?.Levering || !vkiData?.Levering[0] || !vkiData?.Levering[0].DatumLaden || vkiData?.Levering[0].DatumLaden === undefined} errorText={verplichtErrorMessage} hideBorders={true} index={0}/>
        {/* <TextField           
          inputProps={{
            inputcomponent: inputComponent
          }}
          onKeyPress={handleKeyPress}
          name="DatumLaden"
          type="date"
          size="small"
          InputProps={{
            readOnly: false,
          }}
          value={vkiData && vkiData.Levering &&  vkiData.Levering[0] && vkiData?.Levering[0].DatumLaden ? DateUtil.FormatDateToString(vkiData.Levering[0].DatumLaden) : undefined}
          onChange={(event) => handleDateChange(0, event)}
          error={!vkiData?.Levering || !vkiData?.Levering[0] || !vkiData?.Levering[0].DatumLaden || vkiData?.Levering[0].DatumLaden === undefined}
          helperText={!vkiData?.Levering || !vkiData?.Levering[0] || !vkiData?.Levering[0].DatumLaden || vkiData?.Levering[0].DatumLaden === undefined ? verplichtErrorMessage : ''}
          FormHelperTextProps={{
            className: 'custom-error-message',
          }}
        /> */}
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <TimeField
            onKeyPress={handleKeyPress}
            name='AanvangLaden'
            readOnly={isReadonly|| (vkiData && vkiData.Leveringsnummer > 1) ||  (vkiData?.Levering && vkiData.Levering.length < 1)}
            className='time-field-aanvangladen'
            value={vkiData && vkiData.Levering &&  vkiData.Levering[0] && vkiData?.Levering[0].AanvangLaden !== undefined ? getTimeDayJs(vkiData?.Levering[0].AanvangLaden) : undefined}
            onChange={(event) => handleTimeChange(0, event)}
            format="HH:mm"
          />
          {/* <TextField
            error={vkiData && vkiData.Levering[0]?.DatumLaden !== undefined && vkiData.Levering[0]?.DatumLaden !== null &&( vkiData.Levering[0]?.AanvangLaden === undefined || vkiData.Levering[0]?.AanvangLaden === null)}
            helperText={vkiData && vkiData.Levering[0]?.DatumLaden !== undefined && vkiData.Levering[0]?.DatumLaden !== null &&( vkiData.Levering[0]?.AanvangLaden === undefined || vkiData.Levering[0]?.AanvangLaden === null) ? t('verplichtveld') as string : ''}
          /> */}
        </LocalizationProvider>
        <CustomDatePicker readOnly={isReadonly|| (vkiData && vkiData.Leveringsnummer > 1)}  name="Slachtdatum" isError={vkiData && vkiData.Levering[0]?.DatumLaden !== undefined && vkiData.Levering[0]?.DatumLaden !== null &&( vkiData.Levering[0]?.Slachtdatum === undefined || vkiData.Levering[0]?.Slachtdatum === null)} errorText={t('verplichtveld') as string} className="leveringDateFields" label={''} value={vkiData && vkiData.Levering &&  vkiData.Levering[0] && vkiData?.Levering[0].Slachtdatum ? vkiData.Levering[0].Slachtdatum : null} onChange={handleDatePickerChange} size="small" hideBorders={true} index={0}/>
        {/* <TextField
          inputProps={{
            inputcomponent: inputComponent
          }}
          onKeyPress={handleKeyPress}
          name="Slachtdatum"
          InputProps={{
            readOnly: isReadonly,
          }}
          type="date"
          size="small"
          value={vkiData && vkiData.Levering &&  vkiData.Levering[0] && vkiData?.Levering[0].Slachtdatum ? DateUtil.FormatDateToString(vkiData.Levering[0].Slachtdatum) : undefined}
          onChange={(event) =>  handleDateChange(0, event)}
          // error={isSlachtDatumError}
          // helperText={isSlachtDatumError ?  isSlachtDatumErrorString : ''}
          // FormHelperTextProps={{
          //   className: 'custom-error-message',
          // }}
        /> */}
       <TextField
          name="Aantal"
          InputProps={{
            readOnly: isReadonly|| (vkiData && vkiData.Leveringsnummer > 1) || (vkiData?.Levering && vkiData.Levering.length < 1),
          }}
          className='custom-number-textfield-levering'
          type="number"
          size="small"
          onKeyPress={handleKeyPress}
          value={vkiData && vkiData.Levering && vkiData.Levering[0] && vkiData?.Levering[0].Aantal !== undefined ? vkiData?.Levering[0].Aantal.toString() : ''}
          onChange={(event) => handleRowNumberInputChange(0, event)}
          error={vkiData?.Levering[0].DatumLaden && vkiData?.Levering[0]?.DatumLaden !== undefined && (!vkiData?.Levering || !vkiData?.Levering[0] || !vkiData?.Levering[0].Aantal || vkiData?.Levering[0].Aantal === undefined || vkiData?.Levering[0].Aantal === 0)}
          helperText={vkiData?.Levering[0].DatumLaden && vkiData?.Levering[0]?.DatumLaden !== undefined && (!vkiData?.Levering || !vkiData?.Levering[0] || !vkiData?.Levering[0].Aantal || vkiData?.Levering[0].Aantal === undefined || vkiData?.Levering[0].Aantal === 0) ? verplichtErrorMessage : ''}
          FormHelperTextProps={{
            className: 'custom-error-message',
          }}
        />
        <TextField
          onKeyPress={handleKeyPress}
          name="AantalCombinaties"
          InputProps={{
            readOnly: isReadonly|| (vkiData && vkiData.Leveringsnummer > 1) || (vkiData?.Levering && vkiData.Levering.length < 1),
          }}
          className='custom-number-textfield-levering'
          type="number"
          size="small"
          disabled={isReadonly || (vkiData?.Levering && vkiData.Levering.length < 1)}
          value={vkiData && vkiData.Levering &&  vkiData.Levering[0] && vkiData?.Levering[0].AantalCombinaties ? vkiData?.Levering[0].AantalCombinaties.toString() : ''}
          onChange={(event) => handleRowNumberInputChange(0, event)}
          error={vkiData?.Levering[0].DatumLaden && vkiData?.Levering[0]?.DatumLaden !== undefined && ( !vkiData?.Levering || !vkiData?.Levering[0] || !vkiData?.Levering[0].AantalCombinaties || vkiData?.Levering[0].AantalCombinaties === undefined || vkiData?.Levering[0].AantalCombinaties === 0)}
          helperText={vkiData?.Levering[0].DatumLaden && vkiData?.Levering[0]?.DatumLaden !== undefined && ( !vkiData?.Levering || !vkiData?.Levering[0] || !vkiData?.Levering[0].AantalCombinaties || vkiData?.Levering[0].AantalCombinaties === undefined || vkiData?.Levering[0].AantalCombinaties === 0) ? verplichtErrorMessage : ''}
          FormHelperTextProps={{
            className: 'custom-error-message',
          }}
        />
        <TextField
          onKeyPress={handleKeyPress}
          name="Gewicht"
          InputProps={{
              readOnly: isReadonly|| (vkiData && vkiData.Leveringsnummer > 1) || (vkiData?.Levering && vkiData.Levering.length < 1),
          }}
          className='custom-number-textfield-levering'
          type="number"
          size="small"
          value={vkiData && vkiData.Levering && vkiData.Levering[0] && vkiData?.Levering[0].Gewicht !== undefined ? vkiData?.Levering[0].Gewicht.toString() : ''}
          onChange={(event) => handleRowNumberGewichtInputChange(0, event)}
          error={vkiData?.Levering[0].DatumLaden && vkiData?.Levering[0]?.DatumLaden !== undefined && (!vkiData?.Levering || !vkiData?.Levering[0] || !vkiData?.Levering[0].Gewicht || vkiData?.Levering[0].Gewicht === undefined || vkiData?.Levering[0].Gewicht === 0) || errors.isGewichtError} 
          helperText={errors.isGewichtError ? t("gewichtToolTip") : vkiData?.Levering[0].DatumLaden && vkiData?.Levering[0]?.DatumLaden !== undefined && (!vkiData?.Levering || !vkiData?.Levering[0] || !vkiData?.Levering[0].Gewicht || vkiData?.Levering[0].Gewicht === undefined || vkiData?.Levering[0].Gewicht === 0) ? verplichtErrorMessage : ''}
          FormHelperTextProps={{
              className: 'custom-error-message',
          }}
        />
        <span className="default-label-strookwaliteit">{t('strooiselkwaliteit')}</span>
        <div  className='strooisekwaliteit-select-container'>

          <Select
            onKeyPress={handleKeyPress}
            name="KwaliteitStrooisel"
            value={
              vkiData &&
              vkiData.Levering &&
              vkiData.Levering[0] &&
              vkiData?.Levering[0].KwaliteitStrooisel
                ? vkiData?.Levering[0].KwaliteitStrooiselExterneId
                : ''
            }
            disabled={isReadonly ||  (vkiData?.Levering && vkiData.Levering.length < 1)  || (vkiData && vkiData.Leveringsnummer > 1)}
            style={{
              border:
                vkiData &&
                vkiData.Levering &&
                vkiData.Levering[0] &&
                vkiData.Levering[0].DatumLaden &&
                vkiData?.Levering[0]?.DatumLaden !== undefined &&
                (!vkiData?.Levering ||
                  !vkiData?.Levering[0] ||
                  !vkiData?.Levering[0].KwaliteitStrooisel ||
                  vkiData?.Levering[0].KwaliteitStrooisel === '' ||
                  vkiData?.Levering[0].KwaliteitStrooisel === undefined)
                  ? '1px solid red'
                  : '',
            }}
            onChange={(event) => handleKwaliteitStrooiselChange(0, event)}
            size="small"
            className="select-container-levering" // Apply the select container style
            displayEmpty
            renderValue={(selected) => (
              <span className="selected-option-levering">
                {selected === 'empty'
                  ? 'Empty Selection'
                  : stooiselKwaliteitOptions?.find(
                      (option) => option.ExterneId === selected && intialProps.initialLanguage.toLowerCase() === option.Taal?.toLowerCase()
                    )?.Titel || ''}
              </span>
            )}
          >
            <MenuItem value="empty" style={{ color: 'transparent' }}>
              {"Empty Selection"}
            </MenuItem>
            {stooiselKwaliteitOptions?.map((strooiselKwaliteit: IVKIFormItem, optionIndex: number) => (
              strooiselKwaliteit.Taal?.toLowerCase() === intialProps.initialLanguage.toLowerCase() ? (
                <MenuItem key={optionIndex} value={strooiselKwaliteit.ExterneId}>
                  {strooiselKwaliteit.Titel}
                </MenuItem>
              ) : (
                null
              )
            ))}
          </Select>
          {vkiData?.Levering[0].DatumLaden && vkiData?.Levering[0]?.DatumLaden !== undefined && (!vkiData?.Levering || !vkiData?.Levering[0] || !vkiData?.Levering[0].KwaliteitStrooisel || vkiData?.Levering[0].KwaliteitStrooisel === '' || vkiData?.Levering[0].KwaliteitStrooisel === undefined) ? <FormHelperText className='custom-error-message-kwaliteitstrooisel' error>{verplichtErrorMessage}</FormHelperText> : null}
          </div>
      </div>
      <div key={1} className="default-row">
        <div className="default-label-levering">2e {t('levering')}</div>
        <CustomDatePicker readOnly={isReadonly || (vkiData && vkiData.Leveringsnummer > 2) || (vkiData?.Levering && vkiData.Levering.length < 2)}  name="DatumLaden" className="leveringDateFields" label={''} value={vkiData && vkiData.Levering &&  vkiData.Levering[1] && vkiData?.Levering[1].DatumLaden ? vkiData.Levering[1].DatumLaden : null} onChange={handleDatePickerChange} size="small" isError={!vkiData?.Levering || !vkiData?.Levering[0] || !vkiData?.Levering[0].DatumLaden || vkiData?.Levering[0].DatumLaden === undefined} errorText={verplichtErrorMessage} hideBorders={true} index={1}/>
        {/* 
        <TextField
          inputProps={{
            inputcomponent: inputComponent
          }}
          onKeyPress={handleKeyPress}
          name="DatumLaden"
          type="date"
          size="small"
          InputProps={{
            readOnly: isReadonly,
          }}
          value={vkiData && vkiData.Levering &&  vkiData.Levering[1] && vkiData?.Levering[1].DatumLaden ? DateUtil.FormatDateToString(vkiData.Levering[1].DatumLaden) : undefined}
          onChange={(event) => handleDateChange(1, event)}
          // FormHelperTextProps={{
          //   className: 'custom-error-message',
          // }}
        /> */}
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <TimeField
            onKeyPress={handleKeyPress}
            name='AanvangLaden'
            readOnly={isReadonly || (vkiData && vkiData.Leveringsnummer > 2) || (vkiData?.Levering && vkiData.Levering.length < 2)}
            className='time-field-aanvangladen'
            value={vkiData && vkiData.Levering &&  vkiData.Levering[1] && vkiData?.Levering[1].AanvangLaden !== undefined ? getTimeDayJs(vkiData?.Levering[1].AanvangLaden) : undefined}
            onChange={(event) => handleTimeChange(1, event)}
            format="HH:mm"
          />
        </LocalizationProvider>
        <CustomDatePicker readOnly={isReadonly || (vkiData && vkiData.Leveringsnummer > 2)}  name="Slachtdatum" isError={vkiData && vkiData.Levering[1]?.DatumLaden !== undefined && vkiData.Levering[1]?.DatumLaden !== null &&( vkiData.Levering[1]?.Slachtdatum === undefined || vkiData.Levering[1]?.Slachtdatum === null)} errorText={t('verplichtveld') as string} className="leveringDateFields" label={''} value={vkiData && vkiData.Levering &&  vkiData.Levering[1] && vkiData?.Levering[1].Slachtdatum ?  vkiData.Levering[1].Slachtdatum : null} onChange={handleDatePickerChange} size="small" hideBorders={true} index={1}/>
        {/* <TextField
          inputProps={{
            inputcomponent: inputComponent
          }}
          onKeyPress={handleKeyPress}
          name="Slachtdatum"
          InputProps={{
            readOnly: isReadonly,
          }}
          type="date"
          size="small"
          value={vkiData && vkiData.Levering &&  vkiData.Levering[1] && vkiData?.Levering[1].Slachtdatum ?  DateUtil.FormatDateToString(vkiData.Levering[1].Slachtdatum) : undefined}
          onChange={(event) =>  handleDateChange(1, event)}
          // error={isSlachtDatumError1}
          // helperText={isSlachtDatumError1 ? isSlachtDatumErrorString1 : ''}
          // FormHelperTextProps={{
          //   className: 'custom-error-message',
          // }}
        /> */}
        <TextField
          onKeyPress={handleKeyPress}
          name="Aantal"
          InputProps={{
            readOnly: isReadonly || (vkiData && vkiData.Leveringsnummer > 2) || (vkiData?.Levering && vkiData.Levering.length < 2),
          }}
          className='custom-number-textfield-levering'
          type="number"
          size="small"
          value={vkiData && vkiData.Levering && vkiData.Levering[1] && vkiData?.Levering[1].Aantal !== undefined ? vkiData?.Levering[1].Aantal.toString() : ''}
          onChange={(event) => handleRowNumberInputChange(1, event)}
          error={vkiData?.Levering[1]?.DatumLaden && vkiData?.Levering[1]?.DatumLaden !== undefined && ( !vkiData?.Levering || !vkiData?.Levering[1] || !vkiData?.Levering[1].Aantal || vkiData?.Levering[1].Aantal === undefined || vkiData?.Levering[1].Aantal === 0)}
          helperText={vkiData?.Levering[1]?.DatumLaden && vkiData?.Levering[1]?.DatumLaden !== undefined && ( !vkiData?.Levering || !vkiData?.Levering[1] || !vkiData?.Levering[1].Aantal || vkiData?.Levering[1].Aantal === undefined || vkiData?.Levering[1].Aantal === 0) ? verplichtErrorMessage : ''}
          FormHelperTextProps={{
            className: 'custom-error-message',
          }}
        />
        <TextField
          onKeyPress={handleKeyPress}
          name="AantalCombinaties"
          InputProps={{
            readOnly: isReadonly || (vkiData && vkiData.Leveringsnummer > 2) || (vkiData?.Levering && vkiData.Levering.length < 2),
          }}
          className='custom-number-textfield-levering'
          type="number"
          size="small"
          disabled={isReadonly || (vkiData?.Levering && vkiData.Levering.length < 2)}
          value={vkiData && vkiData.Levering &&  vkiData.Levering[1] && vkiData?.Levering[1].AantalCombinaties ? vkiData?.Levering[1].AantalCombinaties.toString() : ''}
          onChange={(event) => handleRowNumberInputChange(1, event)}
          error={vkiData?.Levering[1]?.DatumLaden && vkiData?.Levering[1]?.DatumLaden !== undefined && ( !vkiData?.Levering || !vkiData?.Levering[1] || !vkiData?.Levering[1].AantalCombinaties || vkiData?.Levering[1].AantalCombinaties === undefined || vkiData?.Levering[1].AantalCombinaties === 0)}
          helperText={vkiData?.Levering[1]?.DatumLaden && vkiData?.Levering[1]?.DatumLaden !== undefined && (!vkiData?.Levering || !vkiData?.Levering[1] || !vkiData?.Levering[1].AantalCombinaties || vkiData?.Levering[1].AantalCombinaties === undefined || vkiData?.Levering[1].AantalCombinaties === 0) ? verplichtErrorMessage : ''}
          FormHelperTextProps={{
            className: 'custom-error-message',
          }}
        />
        <TextField
          onKeyPress={handleKeyPress}
          name="Gewicht"
          InputProps={{
              readOnly: isReadonly || (vkiData && vkiData.Leveringsnummer > 2) || (vkiData?.Levering && vkiData.Levering.length < 2),
          }}
          className='custom-number-textfield-levering'
          type="number"
          size="small"
          value={vkiData && vkiData.Levering && vkiData.Levering[1] && vkiData?.Levering[1].Gewicht !== undefined ? vkiData?.Levering[1].Gewicht.toString() : ''}
          onChange={(event) => handleRowNumberGewichtInputChange(1, event)}
          error={vkiData?.Levering[1]?.DatumLaden && vkiData?.Levering[1]?.DatumLaden !== undefined && ( !vkiData?.Levering || !vkiData?.Levering[1] || !vkiData?.Levering[1].Gewicht || vkiData?.Levering[1].Gewicht === undefined || vkiData?.Levering[1].Gewicht === 0) || errors.isGewichtError1}
          helperText={errors.isGewichtError1 ? t("gewichtToolTip") :vkiData?.Levering[1]?.DatumLaden && vkiData?.Levering[1]?.DatumLaden !== undefined && (!vkiData?.Levering || !vkiData?.Levering[1] || !vkiData?.Levering[1].Gewicht || vkiData?.Levering[1].Gewicht === undefined || vkiData?.Levering[1].Gewicht === 0) ? verplichtErrorMessage : ''}
          FormHelperTextProps={{
              className: 'custom-error-message',
          }}
        />
        <span className="default-label-strookwaliteit">{t('strooiselkwaliteit')}</span>
        <div  className='strooisekwaliteit-select-container'>
          <Select
           onKeyPress={handleKeyPress}
            name="KwaliteitStrooisel"
            value={
              vkiData &&
              vkiData.Levering &&
              vkiData.Levering[1] &&
              vkiData?.Levering[1].KwaliteitStrooisel
                ? vkiData?.Levering[1].KwaliteitStrooiselExterneId
                : ''
            }
            disabled={isReadonly || (vkiData && vkiData.Leveringsnummer > 2) || (vkiData?.Levering && vkiData.Levering.length < 2)}
            style={{
              border:
                vkiData &&
                vkiData.Levering &&
                vkiData.Levering[1] &&
                vkiData.Levering[1].DatumLaden &&
                vkiData?.Levering[1]?.DatumLaden !== undefined &&
                (!vkiData?.Levering ||
                  !vkiData?.Levering[1] ||
                  !vkiData?.Levering[1].KwaliteitStrooisel ||
                  vkiData?.Levering[1].KwaliteitStrooisel === '' ||
                  vkiData?.Levering[1].KwaliteitStrooisel === undefined)
                  ? '1px solid red'
                  : '',
            }}
            onChange={(event) => handleKwaliteitStrooiselChange(1, event)}
            size="small"
            className="select-container-levering"
            displayEmpty
            renderValue={(selected) => (
              <span className="selected-option-levering">
                {selected === 'empty'
                  ? 'Empty Selection'
                  : stooiselKwaliteitOptions?.find(
                      (option) => option.ExterneId === selected && intialProps.initialLanguage.toLowerCase() === option.Taal?.toLowerCase()
                    )?.Titel || ''}
              </span>
            )}
          >
            <MenuItem value="empty" style={{ color: 'transparent' }}>
              {"Empty Selection"}
            </MenuItem>
            {stooiselKwaliteitOptions?.map((strooiselKwaliteit: IVKIFormItem, optionIndex: number) => (
              strooiselKwaliteit.Taal?.toLowerCase() === intialProps.initialLanguage.toLowerCase() ? (
                <MenuItem key={optionIndex} value={strooiselKwaliteit.ExterneId}>
                  {strooiselKwaliteit.Titel}
                </MenuItem>
              ) : (
                null
              )
            ))}
          </Select>

          {vkiData?.Levering[1]?.DatumLaden && vkiData?.Levering[1]?.DatumLaden !== undefined && (!vkiData?.Levering || !vkiData?.Levering[1] || !vkiData?.Levering[1].KwaliteitStrooisel || vkiData?.Levering[1].KwaliteitStrooisel === '' || vkiData?.Levering[1].KwaliteitStrooisel === undefined) ? <FormHelperText className='custom-error-message-kwaliteitstrooisel' error>{verplichtErrorMessage}</FormHelperText> : null}
        </div>
      </div>
      <div key={2} className="default-row">
        <div className="default-label-levering">3e {t('levering')}</div>
        <CustomDatePicker readOnly={isReadonly || (vkiData?.Levering && vkiData.Levering.length < 3)}  name="DatumLaden" className="leveringDateFields" label={''} value={vkiData && vkiData.Levering && vkiData.Levering[2] && vkiData?.Levering[2].DatumLaden ? vkiData.Levering[2].DatumLaden : null} onChange={handleDatePickerChange} size="small" isError={!vkiData?.Levering || !vkiData?.Levering[0] || !vkiData?.Levering[0].DatumLaden || vkiData?.Levering[0].DatumLaden === undefined} errorText={verplichtErrorMessage} hideBorders={true} index={2} />
        {/* 
        <TextField
          inputProps={{
            inputcomponent: inputComponent
          }}
          onKeyPress={handleKeyPress}
          name="DatumLaden"
          type="date"
          
          size="small"
          InputProps={{
            readOnly: isReadonly,
          }}
          value={vkiData && vkiData.Levering && vkiData.Levering[2] && vkiData?.Levering[2].DatumLaden ? DateUtil.FormatDateToString(vkiData.Levering[2].DatumLaden) : undefined}
          onChange={(event) =>  handleDateChange(2, event)}
          // FormHelperTextProps={{
          //   className: 'custom-error-message',
          // }}
        /> */}
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <TimeField
            onKeyPress={handleKeyPress}
            name='AanvangLaden'
            readOnly={isReadonly || (vkiData?.Levering && vkiData.Levering.length < 3)}
            className='time-field-aanvangladen'
            value={vkiData && vkiData.Levering &&  vkiData.Levering[2] && vkiData?.Levering[2].AanvangLaden !== undefined ? getTimeDayJs(vkiData?.Levering[2].AanvangLaden): undefined}
            onChange={(event) => handleTimeChange(2, event)}
            format="HH:mm"
          />
        </LocalizationProvider>
        <CustomDatePicker readOnly={isReadonly || (vkiData && vkiData.Leveringsnummer > 3)}  name="Slachtdatum" isError={vkiData && vkiData.Levering[2]?.DatumLaden !== undefined && vkiData.Levering[2]?.DatumLaden !== null &&( vkiData.Levering[2]?.Slachtdatum === undefined || vkiData.Levering[0]?.Slachtdatum === null)} errorText={t('verplichtveld') as string} className="leveringDateFields" label={''} value={vkiData && vkiData.Levering && vkiData.Levering[2] && vkiData?.Levering[2].Slachtdatum ? vkiData.Levering[2].Slachtdatum : null} onChange={handleDatePickerChange} size="small" hideBorders={true} index={2}/>
        {/* <TextField
          inputProps={{
            inputcomponent: inputComponent
          }}
          onKeyPress={handleKeyPress}
          name="Slachtdatum"
          InputProps={{
            readOnly: isReadonly,
          }}
          type="date"
          size="small"
          value={vkiData && vkiData.Levering && vkiData.Levering[2] && vkiData?.Levering[2].Slachtdatum ? DateUtil.FormatDateToString(vkiData.Levering[2].Slachtdatum) : undefined}
          onChange={(event) =>  handleDateChange(2, event)}
          // error={isSlachtDatumError2}
          // helperText={isSlachtDatumError2 ?  isSlachtDatumErrorString2 : ''}
          // FormHelperTextProps={{
          //   className: 'custom-error-message',
          // }}
        /> */}
        <TextField
          onKeyPress={handleKeyPress}
          name="Aantal"
          InputProps={{
            readOnly: isReadonly || (vkiData?.Levering && vkiData.Levering.length < 3),
          }}
          className='custom-number-textfield-levering'
          type="number"
          size="small"
          value={vkiData && vkiData.Levering && vkiData.Levering[2] && vkiData?.Levering[2].Aantal !== undefined ? vkiData?.Levering[2].Aantal.toString() : ''}
          onChange={(event) => handleRowNumberInputChange(2, event)}
          error={vkiData?.Levering[2]?.DatumLaden && vkiData?.Levering[2]?.DatumLaden !== undefined && (!vkiData?.Levering || !vkiData?.Levering[2] || !vkiData?.Levering[2].Aantal || vkiData?.Levering[2].Aantal === undefined || vkiData?.Levering[2].Aantal === 0)}
          helperText={vkiData?.Levering[2]?.DatumLaden && vkiData?.Levering[2]?.DatumLaden !== undefined && (!vkiData?.Levering || !vkiData?.Levering[2] || !vkiData?.Levering[2].Aantal || vkiData?.Levering[2].Aantal === undefined || vkiData?.Levering[2].Aantal === 0) ? verplichtErrorMessage : ''}
          FormHelperTextProps={{
            className: 'custom-error-message',
          }}
        />
        <TextField
          onKeyPress={handleKeyPress}
          name="AantalCombinaties"
          InputProps={{
            readOnly: isReadonly || (vkiData?.Levering && vkiData.Levering.length < 3),
          }}
          className='custom-number-textfield-levering'
          type="number"
          size="small"
          disabled={isReadonly || (vkiData?.Levering && vkiData.Levering.length < 3)}
          value={vkiData && vkiData.Levering &&  vkiData.Levering[2] && vkiData?.Levering[2].AantalCombinaties ? vkiData?.Levering[2].AantalCombinaties.toString() : ''}
          onChange={(event) => handleRowNumberInputChange(2, event)}
          error={vkiData?.Levering[2]?.DatumLaden && vkiData?.Levering[2]?.DatumLaden !== undefined && (!vkiData?.Levering || !vkiData?.Levering[2] || !vkiData?.Levering[2].AantalCombinaties || vkiData?.Levering[2].AantalCombinaties === undefined || vkiData?.Levering[2].AantalCombinaties === 0)}
          helperText={vkiData?.Levering[2]?.DatumLaden && vkiData?.Levering[2]?.DatumLaden !== undefined && (!vkiData?.Levering || !vkiData?.Levering[2] || !vkiData?.Levering[2].AantalCombinaties || vkiData?.Levering[2].AantalCombinaties === undefined || vkiData?.Levering[2].AantalCombinaties === 0) ? verplichtErrorMessage : ''}
          FormHelperTextProps={{
            className: 'custom-error-message',
          }}
        />
          <TextField
            onKeyPress={handleKeyPress}
            name="Gewicht"
            InputProps={{
                readOnly: isReadonly || (vkiData?.Levering && vkiData.Levering.length < 3),
            }}
            className='custom-number-textfield-levering'
            type="number"
            size="small"
            value={vkiData && vkiData.Levering && vkiData.Levering[2] && vkiData?.Levering[2].Gewicht !== undefined ? vkiData?.Levering[2].Gewicht.toString() : ''}
            onChange={(event) => handleRowNumberGewichtInputChange(2, event)}
            error={vkiData?.Levering[2]?.DatumLaden && vkiData?.Levering[2]?.DatumLaden !== undefined &&  (!vkiData?.Levering || !vkiData?.Levering[2] || !vkiData?.Levering[2].Gewicht || vkiData?.Levering[2].Gewicht === undefined || vkiData?.Levering[2].Gewicht === 0) || errors.isGewichtError2}
            helperText={errors.isGewichtError2 ? t("gewichtToolTip") :vkiData?.Levering[2]?.DatumLaden && vkiData?.Levering[2]?.DatumLaden !== undefined &&  (!vkiData?.Levering || !vkiData?.Levering[2] || !vkiData?.Levering[2].Gewicht || vkiData?.Levering[2].Gewicht === undefined || vkiData?.Levering[2].Gewicht === 0) ? verplichtErrorMessage : ''}
            FormHelperTextProps={{
                className: 'custom-error-message',
            }}
          />
        <span className="default-label-strookwaliteit">{t('strooiselkwaliteit')}</span>
        <div  className='strooisekwaliteit-select-container'>

          <Select
          onKeyPress={handleKeyPress}
            name="KwaliteitStrooisel"
            value={
              vkiData &&
              vkiData.Levering &&
              vkiData.Levering[2] &&
              vkiData?.Levering[2].KwaliteitStrooisel
                ? vkiData?.Levering[2].KwaliteitStrooiselExterneId
                : ''
            }
            disabled={isReadonly || (vkiData?.Levering && vkiData.Levering.length < 3)}
            style={{
              border:
                vkiData &&
                vkiData.Levering &&
                vkiData.Levering[2] &&
                vkiData.Levering[2].DatumLaden &&
                vkiData?.Levering[2]?.DatumLaden !== undefined &&
                (!vkiData?.Levering ||
                  !vkiData?.Levering[2] ||
                  !vkiData?.Levering[2].KwaliteitStrooisel ||
                  vkiData?.Levering[2].KwaliteitStrooisel === '' ||
                  vkiData?.Levering[2].KwaliteitStrooisel === undefined)
                  ? '1px solid red'
                  : '',
            }}
            onChange={(event) => handleKwaliteitStrooiselChange(2, event)}
            size="small"
            className="select-container-levering"
            displayEmpty
            renderValue={(selected) => (
              <span className="selected-option-levering">
                {selected === 'empty'
                  ? 'Empty Selection'
                  : stooiselKwaliteitOptions?.find(
                      (option) => option.ExterneId === selected && intialProps.initialLanguage.toLowerCase() === option.Taal?.toLowerCase()
                    )?.Titel || ''}
              </span>
            )}
          >
            <MenuItem value="empty" style={{ color: 'transparent' }}>
              {"Empty Selection"}
            </MenuItem>
            {stooiselKwaliteitOptions?.map((strooiselKwaliteit: IVKIFormItem, optionIndex: number) => (
              strooiselKwaliteit.Taal?.toLowerCase() === intialProps.initialLanguage.toLowerCase() ? (
                <MenuItem key={optionIndex} value={strooiselKwaliteit.ExterneId}>
                  {strooiselKwaliteit.Titel}
                </MenuItem>
              ) : (
                null
              )
            ))}
          </Select>

          {vkiData?.Levering[2]?.DatumLaden && vkiData?.Levering[2]?.DatumLaden !== undefined && (!vkiData?.Levering || !vkiData?.Levering[2] || !vkiData?.Levering[2].KwaliteitStrooisel || vkiData?.Levering[2].KwaliteitStrooisel === '' || vkiData?.Levering[2].KwaliteitStrooisel === undefined) ? <FormHelperText className='custom-error-message-kwaliteitstrooisel' error>{verplichtErrorMessage}</FormHelperText> : null}
          </div>
      </div>
      <div key={2} className="default-row">
        <div className="default-label-levering">4e {t('levering')}</div>
        <CustomDatePicker readOnly={isReadonly || (vkiData?.Levering && vkiData.Levering.length < 4)}  name="DatumLaden" className="leveringDateFields" label={''} value={vkiData && vkiData.Levering && vkiData.Levering[3] && vkiData?.Levering[3].DatumLaden ? vkiData.Levering[3].DatumLaden : null} onChange={handleDatePickerChange} size="small" isError={!vkiData?.Levering || !vkiData?.Levering[0] || !vkiData?.Levering[0].DatumLaden || vkiData?.Levering[0].DatumLaden === undefined} errorText={verplichtErrorMessage} hideBorders={true} index={3} />
{/* 
        <TextField
          inputProps={{
            inputcomponent: inputComponent
          }}
          onKeyPress={handleKeyPress}
          name="DatumLaden"
          type="date"
          
          size="small"
          InputProps={{
            readOnly: isReadonly,
          }}
          value={vkiData && vkiData.Levering && vkiData.Levering[2] && vkiData?.Levering[2].DatumLaden ? DateUtil.FormatDateToString(vkiData.Levering[2].DatumLaden) : undefined}
          onChange={(event) =>  handleDateChange(2, event)}
          // FormHelperTextProps={{
          //   className: 'custom-error-message',
          // }}
        /> */}
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <TimeField
            onKeyPress={handleKeyPress}
            name='AanvangLaden'
            readOnly={isReadonly || (vkiData?.Levering && vkiData.Levering.length < 4)}
            className='time-field-aanvangladen'
            value={vkiData && vkiData.Levering &&  vkiData.Levering[3] && vkiData?.Levering[3].AanvangLaden !== undefined ? getTimeDayJs(vkiData?.Levering[3].AanvangLaden): undefined}
            onChange={(event) => handleTimeChange(3, event)}
            format="HH:mm"
          />
        </LocalizationProvider>
        <CustomDatePicker readOnly={isReadonly || (vkiData && vkiData.Leveringsnummer > 4)}  name="Slachtdatum" isError={vkiData && vkiData.Levering[3]?.DatumLaden !== undefined && vkiData.Levering[3]?.DatumLaden !== null &&( vkiData.Levering[3]?.Slachtdatum === undefined || vkiData.Levering[0]?.Slachtdatum === null)} errorText={t('verplichtveld') as string} className="leveringDateFields" label={''} value={vkiData && vkiData.Levering && vkiData.Levering[3] && vkiData?.Levering[3].Slachtdatum ? vkiData.Levering[3].Slachtdatum : null} onChange={handleDatePickerChange} size="small" hideBorders={true} index={3}/>
        {/* <TextField
          inputProps={{
            inputcomponent: inputComponent
          }}
          onKeyPress={handleKeyPress}
          name="Slachtdatum"
          InputProps={{
            readOnly: isReadonly,
          }}
          type="date"
          size="small"
          value={vkiData && vkiData.Levering && vkiData.Levering[3] && vkiData?.Levering[3].Slachtdatum ? DateUtil.FormatDateToString(vkiData.Levering[3].Slachtdatum) : undefined}
          onChange={(event) =>  handleDateChange(3, event)}
          // error={isSlachtDatumError3}
          // helperText={isSlachtDatumError3 ?  isSlachtDatumErrorString3 : ''}
          // FormHelperTextProps={{
          //   className: 'custom-error-message',
          // }}
        /> */}
        <TextField
          onKeyPress={handleKeyPress}
          name="Aantal"
          InputProps={{
            readOnly: isReadonly || (vkiData?.Levering && vkiData.Levering.length < 4),
          }}
          className='custom-number-textfield-levering'
          type="number"
          size="small"
          value={vkiData && vkiData.Levering && vkiData.Levering[3] && vkiData?.Levering[3].Aantal !== undefined ? vkiData?.Levering[3].Aantal.toString() : ''}
          onChange={(event) => handleRowNumberInputChange(3, event)}
          error={vkiData?.Levering[3]?.DatumLaden && vkiData?.Levering[3]?.DatumLaden !== undefined && (!vkiData?.Levering || !vkiData?.Levering[3] || !vkiData?.Levering[3].Aantal || vkiData?.Levering[3].Aantal === undefined || vkiData?.Levering[3].Aantal === 0)}
          helperText={vkiData?.Levering[3]?.DatumLaden && vkiData?.Levering[3]?.DatumLaden !== undefined && (!vkiData?.Levering || !vkiData?.Levering[3] || !vkiData?.Levering[3].Aantal || vkiData?.Levering[3].Aantal === undefined || vkiData?.Levering[3].Aantal === 0) ? verplichtErrorMessage : ''}
          FormHelperTextProps={{
            className: 'custom-error-message',
          }}
        />
        <TextField
          onKeyPress={handleKeyPress}
          name="AantalCombinaties"
          InputProps={{
            readOnly: isReadonly || (vkiData?.Levering && vkiData.Levering.length < 4),
          }}
          className='custom-number-textfield-levering'
          type="number"
          size="small"
          disabled={isReadonly || (vkiData?.Levering && vkiData.Levering.length < 3)}
          value={vkiData && vkiData.Levering &&  vkiData.Levering[3] && vkiData?.Levering[3].AantalCombinaties ? vkiData?.Levering[3].AantalCombinaties.toString() : ''}
          onChange={(event) => handleRowNumberInputChange(3, event)}
          error={vkiData?.Levering[3]?.DatumLaden && vkiData?.Levering[3]?.DatumLaden !== undefined && (!vkiData?.Levering || !vkiData?.Levering[3] || !vkiData?.Levering[3].AantalCombinaties || vkiData?.Levering[3].AantalCombinaties === undefined || vkiData?.Levering[3].AantalCombinaties === 0)}
          helperText={vkiData?.Levering[3]?.DatumLaden && vkiData?.Levering[3]?.DatumLaden !== undefined && (!vkiData?.Levering || !vkiData?.Levering[3] || !vkiData?.Levering[3].AantalCombinaties || vkiData?.Levering[3].AantalCombinaties === undefined || vkiData?.Levering[3].AantalCombinaties === 0) ? verplichtErrorMessage : ''}
          FormHelperTextProps={{
            className: 'custom-error-message',
          }}
        />
          <TextField
            onKeyPress={handleKeyPress}
            name="Gewicht"
            InputProps={{
                readOnly: isReadonly || (vkiData?.Levering && vkiData.Levering.length < 4),
            }}
            className='custom-number-textfield-levering'
            type="number"
            size="small"
            value={vkiData && vkiData.Levering && vkiData.Levering[3] && vkiData?.Levering[3].Gewicht !== undefined ? vkiData?.Levering[3].Gewicht.toString() : ''}
            onChange={(event) => handleRowNumberGewichtInputChange(3, event)}
            error={vkiData?.Levering[3]?.DatumLaden && vkiData?.Levering[3]?.DatumLaden !== undefined &&  (!vkiData?.Levering || !vkiData?.Levering[3] || !vkiData?.Levering[3].Gewicht || vkiData?.Levering[3].Gewicht === undefined || vkiData?.Levering[3].Gewicht === 0) || errors.isGewichtError3}
            helperText={errors.isGewichtError3 ? t("gewichtToolTip") :vkiData?.Levering[3]?.DatumLaden && vkiData?.Levering[3]?.DatumLaden !== undefined &&  (!vkiData?.Levering || !vkiData?.Levering[3] || !vkiData?.Levering[3].Gewicht || vkiData?.Levering[3].Gewicht === undefined || vkiData?.Levering[3].Gewicht === 0) ? verplichtErrorMessage : ''}
            FormHelperTextProps={{
                className: 'custom-error-message',
            }}
          />
        <span className="default-label-strookwaliteit">{t('strooiselkwaliteit')}</span>
        <div  className='strooisekwaliteit-select-container'>

          <Select
          onKeyPress={handleKeyPress}
            name="KwaliteitStrooisel"
            value={
              vkiData &&
              vkiData.Levering &&
              vkiData.Levering[3] &&
              vkiData?.Levering[3].KwaliteitStrooisel
                ? vkiData?.Levering[3].KwaliteitStrooiselExterneId
                : ''
            }
            disabled={isReadonly || (vkiData?.Levering && vkiData.Levering.length < 4)}
            style={{
              border:
                vkiData &&
                vkiData.Levering &&
                vkiData.Levering[3] &&
                vkiData.Levering[3].DatumLaden &&
                vkiData?.Levering[3]?.DatumLaden !== undefined &&
                (!vkiData?.Levering ||
                  !vkiData?.Levering[3] ||
                  !vkiData?.Levering[3].KwaliteitStrooisel ||
                  vkiData?.Levering[3].KwaliteitStrooisel === '' ||
                  vkiData?.Levering[3].KwaliteitStrooisel === undefined)
                  ? '1px solid red'
                  : '',
            }}
            onChange={(event) => handleKwaliteitStrooiselChange(3, event)}
            size="small"
            className="select-container-levering"
            displayEmpty
            renderValue={(selected) => (
              <span className="selected-option-levering">
                {selected === 'empty'
                  ? 'Empty Selection'
                  : stooiselKwaliteitOptions?.find(
                      (option) => option.ExterneId === selected && intialProps.initialLanguage.toLowerCase() === option.Taal?.toLowerCase()
                    )?.Titel || ''}
              </span>
            )}
          >
            <MenuItem value="empty" style={{ color: 'transparent' }}>
              {"Empty Selection"}
            </MenuItem>
            {stooiselKwaliteitOptions?.map((strooiselKwaliteit: IVKIFormItem, optionIndex: number) => (
              strooiselKwaliteit.Taal?.toLowerCase() === intialProps.initialLanguage.toLowerCase() ? (
                <MenuItem key={optionIndex} value={strooiselKwaliteit.ExterneId}>
                  {strooiselKwaliteit.Titel}
                </MenuItem>
              ) : (
                null
              )
            ))}
          </Select>

          {vkiData?.Levering[3]?.DatumLaden && vkiData?.Levering[3]?.DatumLaden !== undefined && (!vkiData?.Levering || !vkiData?.Levering[3] || !vkiData?.Levering[3].KwaliteitStrooisel || vkiData?.Levering[3].KwaliteitStrooisel === '' || vkiData?.Levering[3].KwaliteitStrooisel === undefined) ? <FormHelperText className='custom-error-message-kwaliteitstrooisel' error>{verplichtErrorMessage}</FormHelperText> : null}
          </div>
      </div>
    </div>
  );
};

export default LeveringDataSection;
