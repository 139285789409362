import dayjs from "dayjs";
import { IGezondheid, ILevering, IPluimveehouder, ISalmonella, IVKI, IVaccins, IVoer } from "../VKI/Interfaces/IVKI";
import { DateUtil } from "./date";

export class VKIUtil {

  static async formatVki(Vki: IVKI): Promise<IVKI> {
    let updatedVki:IVKI = Vki;
    // set the aanvantijd voor nu even;

    //updatedVki.Levering[0].AanvangLaden = DateUtil.formatToDigitalClock("12:00:00");
    //updatedVki.Levering[0].AantalCombinaties = 2;

    await this.formatNullData(updatedVki);
    await this.formatAllDates(updatedVki);
    await this.convertWettelijkWachttermijnToInteger(updatedVki);
    
    // check if new object is needed to be added.
    await this.formatVoer(updatedVki)
    await this.formatGezondheid(updatedVki)
    await this.formatVaccins(updatedVki)


    // make sure all time inputs are valid daysjs formatted.
    //await this.formatTodateJsObject(updatedVki);

    return Promise.resolve(updatedVki);
  };

  static async formatAllDates(Vki: IVKI): Promise<void> {
    Vki.Gemaakt = await DateUtil.FormatExpectedDateStringToDate(Vki.Gemaakt);
    Vki.Gewijzigd = await DateUtil.FormatExpectedDateStringToDate(Vki.Gewijzigd);
    Vki.LaatsteLaadDatum = await DateUtil.FormatDateStringToDate(Vki.LaatsteLaadDatum);
    Vki.LaatsteSlachtDatum = await DateUtil.FormatDateStringToDate(Vki.LaatsteSlachtDatum);
    Vki.DatumVanInvullen = await DateUtil.FormatExpectedDateStringToDate(new Date());
    Vki.Opzetdatum = await DateUtil.FormatDateStringToDate(Vki.Opzetdatum);

    // updatedVki levering
    await Promise.all(
      Vki.Levering?.map(async (levering: ILevering, index: number) => {
        Vki.Levering[index].DatumLaden = await DateUtil.FormatDateStringToDate(levering.DatumLaden);
        Vki.Levering[index].Slachtdatum = await DateUtil.FormatDateStringToDate(levering.Slachtdatum);
      })
    );

    // updatedVki voer dates formatting...
    await Promise.all(
      Vki.Voer?.map(async (voer: IVoer, index: number) => {
        Vki.Voer[index].Begindatum = await DateUtil.FormatDateStringToDate(voer.Begindatum);
        Vki.Voer[index].Einddatum = await DateUtil.FormatDateStringToDate(voer.Einddatum);
      })
    );

    // updatedVki gezondheid dates formatting...
    await Promise.all(
      Vki.Gezondheid?.map(async (gezondheid: IGezondheid, index: number) => {
        Vki.Gezondheid[index].Begindatum = await DateUtil.FormatDateStringToDate(gezondheid.Begindatum);
        Vki.Gezondheid[index].Einddatum = await DateUtil.FormatDateStringToDate(gezondheid.Einddatum);
      })
    );
  }

  // set null date to empty arrays for the vki form...
  static async formatNullData(Vki: IVKI) {
    Vki.Voer = Vki.Voer === null || Vki.Voer === undefined || Vki.Voer.length === 0 ? [{} as IVoer] : Vki.Voer;
    Vki.Levering = Vki.Levering === null || Vki.Levering === undefined || Vki.Levering.length === 0 ? [{} as ILevering] : Vki.Levering;
    Vki.Gezondheid = Vki.Gezondheid === null || Vki.Gezondheid === undefined || Vki.Gezondheid.length === 0? [{} as IGezondheid] : Vki.Gezondheid;
    Vki.Vaccins = Vki.Vaccins === null || Vki.Vaccins === undefined || Vki.Vaccins.length === 0? [{} as IVaccins] : Vki.Vaccins;
    Vki.Bijlagen = Vki.Bijlagen === null || Vki.Bijlagen === undefined ? [] : Vki.Bijlagen;
    Vki.Pluimveehouder = Vki.Pluimveehouder === null || Vki.Pluimveehouder === undefined ? { ExterneId: '', RegistratieNummer: '' } as IPluimveehouder : Vki.Pluimveehouder;
    Vki.Salmonella = Vki.Salmonella === null || Vki.Salmonella === undefined ? {} as ISalmonella : Vki.Salmonella;

    if (Vki.Pluimveehouder.ExterneId === null || Vki.Pluimveehouder.ExterneId === undefined) {
        Vki.Pluimveehouder.ExterneId = '';
    }
    if (Vki.Pluimveehouder.RegistratieNummer === null || Vki.Pluimveehouder.RegistratieNummer === undefined) {
        Vki.Pluimveehouder.RegistratieNummer = '';
    }
    if (Vki.Koppelgegevens.Rassen === null || Vki.Koppelgegevens.Rassen === undefined) {
        Vki.Koppelgegevens.Rassen = [];
    }
    if (Vki.Koppelgegevens.VBnummers === null || Vki.Koppelgegevens.VBnummers === undefined) {
        Vki.Koppelgegevens.VBnummers = [];
    }
  }

  static async formatVoer(Vki: IVKI) {
    let Voer: IVoer[] = Vki.Voer;
    let needToCreateVoerObject: boolean = false;
    const lengthOfVoer: number = Voer.length;
 
    // check if last object in array has data, if its empty create a new one.
    if(Voer[lengthOfVoer -1 ]&& Voer[lengthOfVoer -1].SoortVoerExterneId !== "4") {
      if(Voer[lengthOfVoer -1].SoortVoer && Voer[lengthOfVoer -1].SoortVoer !== "" && Voer[lengthOfVoer -1].SoortVoer !== undefined && Voer[lengthOfVoer -1].SoortVoer !== null) needToCreateVoerObject = true;
      if(Voer[lengthOfVoer -1].SoortVoerExterneId && Voer[lengthOfVoer -1].SoortVoerExterneId !== "" &&  Voer[lengthOfVoer -1].SoortVoerExterneId !== undefined && Voer[lengthOfVoer -1].SoortVoerExterneId !== null) needToCreateVoerObject = true;
      if(Voer[lengthOfVoer -1].Coccidiostaticum && Voer[lengthOfVoer -1].Coccidiostaticum !== "" &&  Voer[lengthOfVoer -1].Coccidiostaticum !== undefined && Voer[lengthOfVoer -1].Coccidiostaticum !== null) needToCreateVoerObject = true;
      if(Voer[lengthOfVoer -1].CoccidiostaticumExterneId && Voer[lengthOfVoer -1].CoccidiostaticumExterneId !== "" &&  Voer[lengthOfVoer -1].CoccidiostaticumExterneId !== undefined && Voer[lengthOfVoer -1].CoccidiostaticumExterneId !== null) needToCreateVoerObject = true;
      if(Voer[lengthOfVoer -1].WettelijkeWachttermijn && Voer[lengthOfVoer -1].WettelijkeWachttermijn !== 0 &&  Voer[lengthOfVoer -1].WettelijkeWachttermijn !== undefined && Voer[lengthOfVoer -1].WettelijkeWachttermijn !== null) needToCreateVoerObject = true;
      if(Voer[lengthOfVoer -1].Begindatum && Voer[lengthOfVoer -1].Begindatum !== undefined && Voer[lengthOfVoer -1].Begindatum !== null) needToCreateVoerObject = true;
      if(Voer[lengthOfVoer -1].Einddatum && Voer[lengthOfVoer -1].Einddatum !== undefined && Voer[lengthOfVoer -1].Einddatum !== null) needToCreateVoerObject = true;
    }

    if(needToCreateVoerObject && lengthOfVoer < 5) {
      this.createAndAddToObjectArray(Vki.Voer as IVoer[]);
    }
  }

  static async formatGezondheid(Vki: IVKI) {
    let Gezondheid: IGezondheid[] = Vki.Gezondheid;
    let needToCreateGezonheidObject: boolean = false;
    const lengthOfGezondheid: number = Gezondheid.length;

      // check if last object in array has data, if its empty create a new one.
      if(Gezondheid[lengthOfGezondheid -1].Ziektebeeld && Gezondheid[lengthOfGezondheid -1].Ziektebeeld !== "" && Gezondheid[lengthOfGezondheid -1].Ziektebeeld !== undefined && Gezondheid[lengthOfGezondheid -1].Ziektebeeld !== null) needToCreateGezonheidObject = true;
      if(Gezondheid[lengthOfGezondheid -1].ZiektebeeldExterneId && Gezondheid[lengthOfGezondheid -1].ZiektebeeldExterneId !== "" && Gezondheid[lengthOfGezondheid -1].ZiektebeeldExterneId !== undefined && Gezondheid[lengthOfGezondheid -1].ZiektebeeldExterneId !== null) needToCreateGezonheidObject = true;
      if(Gezondheid[lengthOfGezondheid -1].RegnrGebruikteGeneesmiddel && Gezondheid[lengthOfGezondheid -1].RegnrGebruikteGeneesmiddel !== "" && Gezondheid[lengthOfGezondheid -1].RegnrGebruikteGeneesmiddel !== undefined && Gezondheid[lengthOfGezondheid -1].RegnrGebruikteGeneesmiddel !== null) needToCreateGezonheidObject = true;
      if(Gezondheid[lengthOfGezondheid -1].RegnrGebruikteGeneesmiddelExterneId && Gezondheid[lengthOfGezondheid -1].RegnrGebruikteGeneesmiddelExterneId !== "" && Gezondheid[lengthOfGezondheid -1].RegnrGebruikteGeneesmiddelExterneId !== undefined && Gezondheid[lengthOfGezondheid -1].RegnrGebruikteGeneesmiddelExterneId !== null) needToCreateGezonheidObject = true;
      if(Gezondheid[lengthOfGezondheid -1].WettelijkeWachttermijn && Gezondheid[lengthOfGezondheid -1].WettelijkeWachttermijn !== 0 &&  Gezondheid[lengthOfGezondheid -1].WettelijkeWachttermijn !== undefined && Gezondheid[lengthOfGezondheid -1].WettelijkeWachttermijn !== null) needToCreateGezonheidObject = true;
      if(Gezondheid[lengthOfGezondheid -1].Begindatum && Gezondheid[lengthOfGezondheid -1].Begindatum !== undefined && Gezondheid[lengthOfGezondheid -1].Begindatum !== null) needToCreateGezonheidObject = true;
      if(Gezondheid[lengthOfGezondheid -1].Einddatum && Gezondheid[lengthOfGezondheid -1].Einddatum !== undefined && Gezondheid[lengthOfGezondheid -1].Einddatum !== null) needToCreateGezonheidObject = true;
      if(Gezondheid[lengthOfGezondheid -1].TotaalGebruikteHoeveelheid && Gezondheid[lengthOfGezondheid -1].TotaalGebruikteHoeveelheid !== 0 &&  Gezondheid[lengthOfGezondheid -1].TotaalGebruikteHoeveelheid !== undefined && Gezondheid[lengthOfGezondheid -1].TotaalGebruikteHoeveelheid !== null) needToCreateGezonheidObject = true;
      if(Gezondheid[lengthOfGezondheid -1].Maat && Gezondheid[lengthOfGezondheid -1].Maat !== "" && Gezondheid[lengthOfGezondheid -1].Maat !== undefined && Gezondheid[lengthOfGezondheid -1].Maat !== null) needToCreateGezonheidObject = true;
      if(Gezondheid[lengthOfGezondheid -1].MaatExterneId && Gezondheid[lengthOfGezondheid -1].MaatExterneId !== "" && Gezondheid[lengthOfGezondheid -1].MaatExterneId !== undefined && Gezondheid[lengthOfGezondheid -1].MaatExterneId !== null) needToCreateGezonheidObject = true;

      if(needToCreateGezonheidObject && lengthOfGezondheid < 5) {
        this.createAndAddToObjectArray(Vki.Gezondheid as IGezondheid[]);
      }
  }

  static async formatVaccins(Vki: IVKI) {
    let Vaccins: IVaccins[] = Vki.Vaccins;
    let needToCreateVaccinObject: boolean = false;
    const lengthOfVaccins: number = Vaccins.length;

      // check if last object in array has data, if its empty create a new one.
      if(Vaccins[lengthOfVaccins -1].Ziekte && Vaccins[lengthOfVaccins -1].Ziekte !== "" && Vaccins[lengthOfVaccins -1].Ziekte !== undefined && Vaccins[lengthOfVaccins -1].Ziekte !== null) needToCreateVaccinObject = true;
      if(Vaccins[lengthOfVaccins -1].ZiekteExterneId && Vaccins[lengthOfVaccins -1].ZiekteExterneId !== "" && Vaccins[lengthOfVaccins -1].ZiekteExterneId !== undefined && Vaccins[lengthOfVaccins -1].ZiekteExterneId !== null) needToCreateVaccinObject = true;
      if(Vaccins[lengthOfVaccins -1].MerkTypeRegistratienummer && Vaccins[lengthOfVaccins -1].MerkTypeRegistratienummer !== "" && Vaccins[lengthOfVaccins -1].MerkTypeRegistratienummer !== undefined && Vaccins[lengthOfVaccins -1].MerkTypeRegistratienummer !== null) needToCreateVaccinObject = true;
      if(Vaccins[lengthOfVaccins -1].MerkTypeRegistratienummerExterneId && Vaccins[lengthOfVaccins -1].MerkTypeRegistratienummerExterneId !== "" && Vaccins[lengthOfVaccins -1].MerkTypeRegistratienummerExterneId !== undefined && Vaccins[lengthOfVaccins -1].MerkTypeRegistratienummerExterneId !== null) needToCreateVaccinObject = true;
      if(Vaccins[lengthOfVaccins -1].WettelijkeWachttermijn && Vaccins[lengthOfVaccins -1].WettelijkeWachttermijn !== 0 &&  Vaccins[lengthOfVaccins -1].WettelijkeWachttermijn !== undefined && Vaccins[lengthOfVaccins -1].WettelijkeWachttermijn !== null) needToCreateVaccinObject = true;
      if(Vaccins[lengthOfVaccins -1].Datum && Vaccins[lengthOfVaccins -1].Datum !== undefined && Vaccins[lengthOfVaccins -1].Datum !== null) needToCreateVaccinObject = true;
      if(Vaccins[lengthOfVaccins -1].Methode && Vaccins[lengthOfVaccins -1].Methode !== "" && Vaccins[lengthOfVaccins -1].Methode !== undefined && Vaccins[lengthOfVaccins -1].Methode !== null) needToCreateVaccinObject = true;
      if(Vaccins[lengthOfVaccins -1].MethodeExterneId && Vaccins[lengthOfVaccins -1].MethodeExterneId !== "" && Vaccins[lengthOfVaccins -1].MethodeExterneId !== undefined && Vaccins[lengthOfVaccins -1].MethodeExterneId !== null) needToCreateVaccinObject = true;

      
      if(needToCreateVaccinObject && lengthOfVaccins < 5) {
        this.createAndAddToObjectArray(Vki.Vaccins as IVaccins[]);
      }
  }

  private static createAndAddToObjectArray<T extends object>(array: T[]): void {
    const emptyObject = {} as T;
    array.push(emptyObject);
  }

  static async convertWettelijkWachttermijnToInteger(Vki: IVKI) {

    if(Vki.Voer) {
      Vki.Voer.forEach((voer : any, index: number) => { 
        if(voer.WettelijkeWachttermijn !== undefined || voer.WettelijkeWachttermijn !== null && typeof voer.WettelijkeWachttermijn === 'string') {
          Vki.Voer[index].WettelijkeWachttermijn = parseInt(voer.WettelijkeWachttermijn);
        }
      })
    }
    if(Vki.Gezondheid) {
      Vki.Gezondheid.forEach((gezondheid : any, index: number) => { 
        if(gezondheid.WettelijkeWachttermijn !== undefined || gezondheid.WettelijkeWachttermijn !== null && typeof gezondheid.WettelijkeWachttermijn === 'string') {
          Vki.Gezondheid[index].WettelijkeWachttermijn = parseInt(gezondheid.WettelijkeWachttermijn);
        }
      })
    }
    if(Vki.Vaccins) {
      Vki.Vaccins.forEach((vaccin : any, index: number) => { 
        if(vaccin.WettelijkeWachttermijn !== undefined || vaccin.WettelijkeWachttermijn !== null && typeof vaccin.WettelijkeWachttermijn === 'string') {
          Vki.Vaccins[index].WettelijkeWachttermijn = parseInt(vaccin.WettelijkeWachttermijn);
        }
      })
    }
  }

  static async formatTodateJsObject(Vki: IVKI) {
    if(Vki.Levering) {
      Vki.Levering.forEach((levering : any, index: number) => { 
        if((levering.AanvangLaden !== undefined || levering.AanvangLaden !== null )&& typeof levering.AanvangLaden === 'string') {
          // make sure the input is a digital time...
          let dateWithDesiredTime;
          let formattedString = DateUtil.formatToDigitalClock(levering.AanvangLaden);
          dayjs(formattedString);
          const today = new Date();

          if (!/^\d{2}:\d{2}$/.test(levering.AanvangLaden)) {
            const timeParts = formattedString.split(":");
            dateWithDesiredTime = `${today.getFullYear()}-${today.getMonth()}-${today.getDate()}T${timeParts[0]}:${timeParts[1]}`;
          
            // Assuming Vki.Levering is an array and index is the desired index
            //Vki.Levering[index].AanvangLaden = dateWithDesiredTime;
          } else {
            const timeParts = levering.AanvangLaden.split(":");
            dateWithDesiredTime = `${today.getFullYear()}-${today.getMonth()}-${today.getDate()}T${timeParts[0]}:${timeParts[1]}`;
          }
          //Vki.Levering[index].AanvangLaden = dateWithDesiredTime;
        }
      });
    }
  }

  static async saveVkiFormatting(Vki: IVKI) {
    if(Vki.Levering) {
      Vki.Levering.forEach((levering : any, index: number) => { 
        if((levering.AanvangLaden !== undefined || levering.AanvangLaden !== null) && typeof levering.AanvangLaden === 'string') {
          //Vki.Levering[index].AanvangLaden = DateUtil.formatToDaysjs(levering.AanvangLaden, "HH:mm");
        }
      });
    } 
    if(Vki.Gezondheid) {
      Vki.Gezondheid.forEach((item, index ) => {
        if(item.Ziektebeeld === undefined || item.Ziektebeeld === null ||  item.Ziektebeeld === '')
        {
          Vki.Gezondheid.splice(index,1);
        }
      });
    }
    if(Vki.Koppelgegevens) {
      console.log(Vki.Koppelgegevens.VBnummers);
      Vki.Koppelgegevens.VBnummers = Vki.Koppelgegevens.VBnummers.filter((vbNummer) => vbNummer !== undefined && vbNummer !== null && vbNummer.Naam !== '');
      console.log('gefilterd..',Vki.Koppelgegevens.VBnummers);
    }
  }
}