import { Box, LinearProgress, createMuiTheme } from '@mui/material';
import i18n from "i18next";
import React, { FC, Suspense } from 'react';
import { IAccountInfo } from 'react-aad-msal';
import { I18nextProvider, initReactI18next } from "react-i18next";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { LogoutFunction } from '../authProviders/authProvider';
import de from "../language/de.json";
import nl from "../language/nl.json";
import Archief from './Archief';
import Beheer from './Beheer';
import { HomePage } from './HomePage';
import NewVKI from './NewVKI';
import VKISettings from './Settings';
import { VKI } from './VKI';
import Globals from './common/Globals';
import { Header } from './common/Header';
import { NotFoundPage } from './common/NotFoundPage';
import UserStoreContext, { UserStoreDefault } from './common/UserStoreContext';
import { environment } from './common/env';
import { Logout } from '@mui/icons-material';
import LogoutVKI from './LogoutVKI';
import { IntlProvider } from 'react-intl';
import NVWAToevoegen from './NVWAToevoegen';
import Versiebeheer from './Versiebeheer';
import WachtwoordReset from './WachtwoordReseten';

export type AppProps = {
	accountInfo: IAccountInfo;
	logout: LogoutFunction;
};

const App: FC<AppProps> = ({ accountInfo, logout }) => {
	const [readonly, setReadonly] = React.useState(UserStoreDefault.readonly);

	async function fetchLanguage() : Promise<string | null> {
		try {
		  const response = await fetch(environment.backendBaseUrl + 'GetUserLanguage?&userId=' + Globals.AccountInfo.account.idToken.sub  + '&code=' + environment.REACT_APP_FUNCTION_KEY, { headers: { Authorization: 'Bearer ' + Globals.AccountInfo.jwtIdToken } });
		  const data = await response.json();
		  return data as string | null; // Assuming the response contains the language code
		} catch (error) {
		  console.error("Failed to fetch language:", error);
		  return null;
		}
	  }
	  
	  // Initialize i18n with the fetched language
	  async function initializeI18n() {
		localStorage.setItem("VKILanguage", "");
		let storageLang:string | null = "nl";
		if (localStorage.getItem("VKILanguage") === null || localStorage.getItem("VKILanguage") == "")  {
			const language:string | null = await fetchLanguage();
			if (language){
				localStorage.setItem("VKILanguage", language);
				storageLang = language;
			}
		}	
		else
		{
			if (localStorage.getItem("VKILanguage") != null)
				storageLang = localStorage.getItem("VKILanguage");
		}
		
		i18n
		  .use(initReactI18next)
		  .init({
			resources: {
			  nl: { translation: nl },
			  de: { translation: de },
			},
			lng: storageLang || "nl", // Use fetched language or fallback to "nl"
			fallbackLng: "nl", // Corrected typo in fallbackLng
			interpolation: {
			  escapeValue: false,
			},
		  });
	  }
	  
	  initializeI18n();
	  const theme = createMuiTheme({
		palette: {
		  primary: {
			main: '#2B2B67'
		  }
		}
	  });
	return (
		
		<>
		<IntlProvider locale="nl">
			<I18nextProvider i18n={i18n}>
					<div className='app'>
						<UserStoreContext.Provider value={{ readonly, setReadonly }}>
							<ToastContainer autoClose={3000} hideProgressBar />

							<BrowserRouter>
								<Header accountInfo={accountInfo} logout={logout} />
							<Suspense fallback={<Box sx={{ width: '100%' }}> <LinearProgress  /> </Box>}>
								<Routes>
									<Route path="/" element={<HomePage  accountInfo={accountInfo} />} />
									<Route path="/VKI/:id"  element={<VKI  accountInfo={accountInfo} />} />
									<Route path="/Versiebeheer/:id"  element={<Versiebeheer {...accountInfo}  />} />
									<Route path="/NewVKI/" element={<NewVKI accountInfo={accountInfo} />} />
									<Route path="/Archief/" element={<Archief accountInfo={accountInfo} />} />
									<Route path="/Beheer/" element={<Beheer accountInfo={accountInfo} />} />
									<Route path="/Beheer/:id" element={<Beheer accountInfo={accountInfo} />} />
									<Route path="/Instellingen" element={<VKISettings accountInfo={accountInfo} />} />
									<Route path="/NVWAToevoegen" element={<NVWAToevoegen accountInfo={accountInfo} />} />
									<Route path="/WachtwoordReset" element={<WachtwoordReset accountInfo={accountInfo} />} />
									<Route path="/Logout" element={<LogoutVKI accountInfo={accountInfo} logout={logout}/>} />
									<Route path="*" element={<HomePage  accountInfo={accountInfo} />} />
								</Routes>
							</Suspense>
						</BrowserRouter>
					</UserStoreContext.Provider>
				</div>
			</I18nextProvider>
		</IntlProvider>
		</>
	);
};

export default App;
