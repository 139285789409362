import TextareaAutosize from '@mui/base/TextareaAutosize';
import SaveIcon from '@mui/icons-material/Save';
import { Box, LinearProgress, SelectChangeEvent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, styled } from '@mui/material';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import camelcaseKeys from 'camelcase-keys';
import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { ISettings } from '../VKI/Interfaces/ISettings';
import { IVKIFormItem } from '../VKI/Interfaces/VKIForm';
import { environment } from './common/env';
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
import { blue, grey } from '@mui/material/colors';
export const VKISettings: React.FC<any> = (props:any) => {

  const Textarea = styled(BaseTextareaAutosize)(
    ({ theme }) => `
    width: 320px;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 8px 12px;
    border-radius: 8px;
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    box-shadow: 0px 2px 2px ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};

    &:hover {
      border-color: ${blue[400]};
    }

    &:focus {
      border-color: ${blue[400]};
      box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[600] : blue[200]};
    }

    // firefox
    &:focus-visible {
      outline: 0;
    }
  `,
  );

  
  const navigate = useNavigate();
	//const [data, setData] = useState<IVKIForm | undefined>(undefined);
  const [data, setData] = useState<ISettings[] | undefined>(undefined);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isSaving, setIsSaving] = useState(false);
  const [formData, setFormData] = useState<IVKIFormItem>({
    Titel: '',
    Volgorde: 0,
    Taal: '',
    Wachttermijn: 0,
    ToepasbaarOpZiekte: '',
    ExterneId: '',
    ToepasbaarOp: [],
    Email: '',
    Praktijk: '',
    Vestigingsplaats: ''
  });

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }));
  };
  const handleSave = async () => {
    setIsSaving(true);
  
    try {
      // Make the REST API call to save the settings
      // Replace `YOUR_API_ENDPOINT` with the actual endpoint
      await fetch(environment.backendBaseUrl + 'SaveVkiSettings?userid=' + props.accountInfo.account.idToken.sub +'&environment=' + environment.environment + '&email=' + props.accountInfo.account.idToken.emails[0]  + '&code=' + environment.REACT_APP_FUNCTION_KEY, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + props.accountInfo.jwtIdToken
          // Add any necessary authorization headers
        },
        body: JSON.stringify(data), // Replace `data` with the actual settings data to be saved
      });
  
      // Handle successful save
    } catch (error) {
      // Handle save error
      console.error('Error saving settings:', error);
    }
  
    setIsSaving(false);
  };
  const open = Boolean(anchorEl);
  const fetchData = async () => {
    try {
      const response = await fetch(environment.backendBaseUrl + 'VKISettings?userid=' + props.accountInfo.account.idToken.sub + '&environment=' + environment.environment+ '&email=' + props.accountInfo.account.idToken.emails[0]  + '&code=' + environment.REACT_APP_FUNCTION_KEY,{
        headers: {Authorization: 'Bearer ' + props.accountInfo.jwtIdToken}
      });
      if (response.ok) {
        const jsonData = await response.json();
        
        const camelCasedData = camelcaseKeys(jsonData, { deep: true, pascalCase: true });
        const data = camelCasedData as ISettings[]
        if (data)
        {

          setData(data);
        }
      } else {

      // Handle error if needed      
      console.error('Error:', response.status);
      }
    } catch (error) {
      // Handle error if needed
      console.error('Error:', error);
    }
  };
	useEffect(() => {  
	  fetchData();
	}, []);
	
  const [searchTerm, setSearchTerm] = useState('');
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };
  if (!data)
  {
    return(<Box sx={{ width: '100%' }}> <LinearProgress  /> </Box>);
  }
  const filteredData = data?.filter(item =>
    item.Content?.toLowerCase()?.includes(searchTerm.toLowerCase()) 
  );
	const options:Intl.DateTimeFormatOptions = { day: '2-digit', month: '2-digit', year: 'numeric' };

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }));
  };
  const handleTextareaChange = (event: React.ChangeEvent<HTMLTextAreaElement>, item: ISettings) => {
    const { value } = event.target;
    setData((prevData) => {
      const newData = prevData?.map((dataItem) => {
        if (dataItem.Id === item.Id) {
          return { ...dataItem, Content: value };
        }
        return dataItem;
      });
      return newData;
    });
  };
  return (
    <div className='VKIOverzicht'>
		<h1>VKI Instellingen</h1>
    <TextField
        label="Zoeken"
        value={searchTerm}
        onChange={handleSearch}
        variant="outlined"
        style={{ marginBottom: '1rem' }}
      /><br/>
          <Button
      variant="contained"
      color="primary"
      startIcon={<SaveIcon />}
      onClick={handleSave}
      disabled={isSaving}
      style={{ marginTop: '1rem' }}
    >
      {isSaving ? (
        <CircularProgress size={24} color="inherit" />
      ) : (
        'Opslaan'
      )}
    </Button>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell><strong>Soort</strong></TableCell>
              <TableCell><strong>Inhoud</strong></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          
		  {filteredData.map(item => (
              <TableRow key={item.Id}>
                <TableCell>{item.Id}</TableCell>
                <TableCell>
                  <Textarea
                    value={item.Content}
                    className='vkiSetting'
                    onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => handleTextareaChange(event, item)}
                  />
              </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default VKISettings;