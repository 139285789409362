import TextField from '@material-ui/core/TextField';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IDierenartsDataSectionProps, IFormSectionProps, IFormValidationObject } from '../../VKI/Interfaces/IForm';
import { IDierenarts, IVKI } from '../../VKI/Interfaces/IVKI';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { isValidEmail } from '../../utils/email';
import { InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { IVKIFormItem } from '../../VKI/Interfaces/VKIForm';

const DierenartsDataSection: React.FC<IDierenartsDataSectionProps> = ({ vkiData, accountInfo, onChange, isReadonly, dierenartsen, groupClaim,  validateObject, setValidationObject }) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(true);
  const [initialLoad, setInitialLoad] = useState(true);
  const [emailError, setEmailError] = useState('');

  useEffect(() => {
    // Check for missing inputs in vkiData.Dierenarts
    const checkMissingInputs = () => {
      if (vkiData && vkiData.Dierenarts && initialLoad) {
        const { Naam, Vestigingsplaats, Email } = vkiData.Dierenarts;
        let isDataMissing = false;

        if (!Naam) isDataMissing = true;
        if (!Vestigingsplaats) isDataMissing = true;
        if (!Email) isDataMissing = true;


        // set colapsing state false when there is not data missing.
        if(isDataMissing === false) {
          //setExpanded(isDataMissing);
        } else if(isDataMissing === true) {
          // set the variable that the form cannot be submitted.....
        }
      }

      if(vkiData !== undefined) isDierenArtsValid(vkiData);
    };
    
    checkMissingInputs();
    setInitialLoad(false);
  }, [vkiData]);

  const handleDierenartsChange = (event: SelectChangeEvent<any>) => {
    const { value } = event.target;
  
    onChange((prevVkiData: IVKI) => {
      // Create a new object to avoid mutating the existing state
      const updatedVkiData = { ...prevVkiData };
      const selectedDierenarts = dierenartsen.find(item => item.ExterneId === value);
  
      if (selectedDierenarts) {
        updatedVkiData.Dierenarts = {
          ...updatedVkiData.Dierenarts,
          ExterneId: selectedDierenarts.ExterneId,
          Email: selectedDierenarts.Email,
          Naam: selectedDierenarts.Titel,
          Praktijk: selectedDierenarts.Praktijk,
          Vestigingsplaats : selectedDierenarts.Vestigingsplaats
        };
      }
  
      return updatedVkiData;
    });
  };
  
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;  
      if (name === 'Email') {
        const errorMessage = t('nietGeldigEmailAdres');
        if (!isValidEmail(value)) {
          setEmailError(errorMessage);
        } else {
          setEmailError('');
        }
      }

      onChange((prevVkiData: IVKI) => {
        const updatedData: IVKI = prevVkiData;        

        updatedData.Dierenarts[name as keyof IDierenarts] = value;

        isDierenArtsValid(updatedData);

        return updatedData;
      });
    };

    const toggleExpand = () => {
      setExpanded(!expanded);
    };

    const isDierenArtsValid = (data: IVKI): void => {
      let isValid:boolean = true;
      if(data)  {
        if(data.Dierenarts) {
          const {
            Naam,
            Vestigingsplaats,
            Email,
          } = data.Dierenarts;
          if(!Naam) isValid = false;
          if(!Vestigingsplaats) isValid = false;
          if(!Email) isValid = false;
          if (!isValidEmail(Email))  isValid = false;
        }
      }
      // update the validation object..
      setValidationObject((prevValidationObject: IFormValidationObject) => ({
        ...prevValidationObject,
        DierenartsDataSection: isValid,
      }));
    };
    const filteredDierenartsen = dierenartsen?.filter(
      (dierenarts) => dierenarts.Praktijk === vkiData?.Dierenarts?.Praktijk
    );

    const handleKeyPress = (event:any) => {
      if (event.key === 'Enter') {
        event.preventDefault(); // Prevent the default behavior of the Enter key
        // You can add any custom logic here if needed
        const form = event.target.form;
        const index = Array.from(form.elements).indexOf(event.target);
        form.elements[index + 1].focus();
      }
    };
  return (
    <div
      className={`default-section-container ${expanded ? 'expanded' : 'collapsed'}`}
    >
      <div className="header" style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h4 style={{ marginRight: 'auto' }}>{t('gegevensdierenartsCaps')}</h4>
          <IconButton
            onClick={toggleExpand}
            className={`expand-button ${expanded ? 'expanded' : ''}`}
            aria-label={expanded ? t('collapse') as string | undefined: t('expand')  as string | undefined}
            style={{ marginLeft: 'auto' }}
          >
            <ExpandMoreIcon />
          </IconButton>
        </div>
        {expanded && (
        <div className="content">
          <div className="default-row">
          <TextField onKeyPress={handleKeyPress} 
              name="Naam"
              label={t('naam')}
              InputProps={{
                readOnly: isReadonly,
              }}
              value={vkiData && vkiData.Dierenarts ? vkiData.Dierenarts.Naam : ''}
              onChange={handleInputChange}
              className='default-field-NaamDierenarts'
              InputLabelProps={{
                className: 'input-label', 
                classes: {
                  shrink: 'input-label-shrink', 
                },
              }}
              error={!vkiData?.Dierenarts || !vkiData?.Dierenarts.Naam}
              helperText={!vkiData?.Dierenarts || !vkiData?.Dierenarts.Naam || !('Naam' in vkiData.Dierenarts) ? t('verplichtVeld') : ''}
            />
            
          <InputLabel hidden={filteredDierenartsen.length == 0} id="dierenarts-select-label">&nbsp;&nbsp;{t('select')}&nbsp;&nbsp;</InputLabel>
            <Select hidden={filteredDierenartsen.length == 0}
              labelId="dierenarts-select-label"
              name="Dierenartsen"
              value={vkiData?.Dierenarts?.ExterneId?.toString() || ''}
            onKeyPress={handleKeyPress}

              disabled={isReadonly}
 
              onChange={(event) => handleDierenartsChange(event)}
              size="medium"
              className="select-container-dierenarts"
              renderValue={(selected) => {
                console.log('Selected value:', selected);
                return (
                  <span className="selected-option-dierenarts">
                    {selected === 'empty'
                      ? 'Empty Selection'
                      : dierenartsen?.find(
                          (option) => option.ExterneId === selected
                        )?.Titel || ''}
                  </span>
                );
              }}
            >
              <MenuItem value="empty" style={{ color: 'transparent' }}>
                {"Empty Selection"}
              </MenuItem>
              {filteredDierenartsen?.map((dierenarts: IVKIFormItem, optionIndex: number) => (
                  <MenuItem key={optionIndex} value={dierenarts.ExterneId}>
                    {dierenarts.Titel}
                  </MenuItem>
              ))}
            </Select>


          </div>
          <div className="default-row">
            <TextField onKeyPress={handleKeyPress} 
              name="Praktijk"
              label={t('praktijk')}
              InputProps={{
                readOnly: isReadonly,
              }}
              value={vkiData && vkiData.Dierenarts? vkiData.Dierenarts.Praktijk : ''}
              error={!vkiData?.Dierenarts || !vkiData?.Dierenarts.Praktijk}
              helperText={!vkiData?.Dierenarts || !vkiData?.Dierenarts.Praktijk || !('Praktijk' in vkiData.Dierenarts) ? t('verplichtVeld') : ''}
  
              onChange={handleInputChange}
              className='default-field-Praktijk'
              InputLabelProps={{
                className: 'input-label', 
                classes: {
                  shrink: 'input-label-shrink', 
                },
              }}
            />
          </div>
          <div className="default-row">
            <TextField onKeyPress={handleKeyPress} 
              name="Vestigingsplaats"
              label={t('vestigingsplaats')}
              InputProps={{
                readOnly: isReadonly,
              }}
              value={vkiData && vkiData.Dierenarts? vkiData.Dierenarts.Vestigingsplaats : ''}
              onChange={handleInputChange}
              className='default-field-Vestigingsplaats'
              InputLabelProps={{
                className: 'input-label', 
                classes: {
                  shrink: 'input-label-shrink', 
                },
              }}
              error={!vkiData?.Dierenarts || !vkiData?.Dierenarts.Vestigingsplaats}
              helperText={!vkiData?.Dierenarts || !vkiData?.Dierenarts.Vestigingsplaats || !('Vestigingsplaats' in vkiData.Dierenarts) ? t('verplichtVeld') : ''}
            />
          </div>
          <div className="default-row">
            <TextField onKeyPress={handleKeyPress} 
              name="Email"
              label={t('email')}
              InputProps={{
                readOnly: isReadonly,
              }}
              value={vkiData && vkiData.Dierenarts? vkiData.Dierenarts.Email : ''}
              onChange={handleInputChange}
              className='default-field-Email'
              InputLabelProps={{
                className: 'input-label', 
                classes: {
                  shrink: 'input-label-shrink', 
                },
              }}
              error={!vkiData?.Dierenarts || !vkiData?.Dierenarts.Email || !('Email' in vkiData.Dierenarts) || emailError !== ''}
              helperText={
                !vkiData?.Dierenarts || !vkiData?.Dierenarts.Email || !('Email' in vkiData.Dierenarts)
                  ? 'Dit veld is verplicht'
                  : emailError || ''
              }
            />
          </div>
        </div>
        )}
    </div>
  );
};

export default DierenartsDataSection;