import TextField from '@material-ui/core/TextField';
import React, { useState, useEffect, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { IFormSectionProps, IFormValidationObject } from '../../VKI/Interfaces/IForm';
import { IVKI } from '../../VKI/Interfaces/IVKI';
import { Checkbox } from '@mui/material';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { isValidEmail } from '../../utils/email';

const SlachthuisDataSection: React.FC<IFormSectionProps> = ({ vkiData, accountInfo, onChange, isReadonly, groupClaim, validateObject, setValidationObject }) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(true);
  const [initialLoad, setInitialLoad] = useState(true);
  const [emailError, setEmailError] = useState('');

  useEffect(() => {
    // Check for missing inputs in vkiData.Slachthuis
    const checkMissingInputs = () => {
      if (vkiData && vkiData.Slachthuis && initialLoad) {
        const { Naam, Plaats, Email } = vkiData.Slachthuis;
        let isDataMissing = false;

        if (!Naam) isDataMissing = true;
        if (!Plaats) isDataMissing = true;
        if (!Email) isDataMissing = true;

        // set collapsing state false when there is no data missing.
        if (isDataMissing === false) {
          //setExpanded(isDataMissing);
        }
      }
    };

    checkMissingInputs();
    setInitialLoad(false);
    if(vkiData !== undefined) isSlachthuisValid(vkiData);
  }, [vkiData]);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked, type } = event.target;

    // set the value of checkbox when the checkbox is checked.
    const newValue = type === 'checkbox' ? checked : value;

    if (name === 'Email') {
      const errorMessage = t('nietGeldigEmailAdres');
      if (!isValidEmail(value)) {
        setEmailError(errorMessage);
      } else {
        setEmailError('');
      }
      if(value === '') { setEmailError('');}
    }

    onChange((prevSlachthuisData: IVKI) => ({
      ...prevSlachthuisData,
      Slachthuis: {
        ...prevSlachthuisData.Slachthuis,
        [name]: newValue,
      },
    }));
  };

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  const isSlachthuisValid = (data: IVKI): void => {
    let isValid:boolean = true;
    if(data)  {
      if(data.Slachthuis) {
        if(!data.Slachthuis.Plaats) isValid = false;
        // only if email adres is set make sure that its a correct email adres.
        if (data.Slachthuis.Email !== '' && typeof data.Slachthuis.Email === 'string') {
          if (!isValidEmail(data.Slachthuis.Email))  isValid = false;
        } 
      }
    }
    // update the validation object..
    setValidationObject((prevValidationObject: IFormValidationObject) => ({
      ...prevValidationObject,
      SlachthuisDataSection: isValid,
    }));
  };

  const handleKeyPress = (event:any) => {
    if (event.key === 'Enter') {
      try
      {
        event.preventDefault(); // Prevent the default behavior of the Enter key
        // You can add any custom logic here if needed
        const form = event.target.form;
        const index = Array.from(form.elements).indexOf(event.target);
        form.elements[index + 1].focus();
      }
      catch(ex){}
    }
  };


  return (
    <div className={`default-section-container ${expanded ? 'expanded' : 'collapsed'}`}>
      <div className="header" style={{ display: 'flex', justifyContent: 'space-between' }}>
        <h4 style={{ marginRight: 'auto' }}>{t('gegevensSlachthuisCaps')}</h4>
        <IconButton
          onClick={toggleExpand}
          className={`expand-button ${expanded ? 'expanded' : ''}`}
          aria-label={expanded ? t('collapse') as string | undefined: t('expand')  as string | undefined}
          style={{ marginLeft: 'auto' }}
        >
          <ExpandMoreIcon />
        </IconButton>
      </div>
      {expanded && (
        <div className="content">
          <div className="default-row">
            <TextField onKeyPress={handleKeyPress} 
              name="Naam"
              label={t('naam')}
              InputProps={{
                readOnly: isReadonly,
              }}
              error={!vkiData?.Slachthuis || !vkiData?.Slachthuis.Naam}
              helperText={!vkiData?.Slachthuis || !vkiData?.Slachthuis.Naam || !('Naam' in vkiData.Slachthuis) ? t('verplichtVeld') : ''}
              value={vkiData && vkiData.Slachthuis ? vkiData.Slachthuis.Naam : ''}
              onChange={handleInputChange}
              className="default-field-Naam"
              InputLabelProps={{
                className: 'input-label',
                classes: {
                  shrink: 'input-label-shrink',
                },
              }}
            />
          </div>
          <div className="default-row">
            <TextField onKeyPress={handleKeyPress} 
              name="Plaats"
              label={t('plaats')}
              InputProps={{
                readOnly: isReadonly,
              }}
              value={vkiData && vkiData.Slachthuis ? vkiData.Slachthuis.Plaats : ''}
              onChange={handleInputChange}
              className="default-field-Plaats"
              InputLabelProps={{
                className: 'input-label',
                classes: {
                  shrink: 'input-label-shrink',
                },
              }}
              error={!vkiData?.Slachthuis || !vkiData?.Slachthuis.Plaats}
              helperText={!vkiData?.Slachthuis || !vkiData?.Slachthuis.Plaats || !('Plaats' in vkiData.Slachthuis) ? t('verplichtVeld') : ''}
            />
          </div>
          <div className="default-row">
            <TextField onKeyPress={handleKeyPress} 
              name="Email"
              label={t('email')}
              InputProps={{
                readOnly: isReadonly,
              }}
              error={!vkiData?.Slachthuis || !vkiData?.Slachthuis.Email || emailError !== ''}
              helperText={!vkiData?.Slachthuis || !vkiData?.Slachthuis.Email || !('Email' in vkiData.Slachthuis) ? t('verplichtVeld')+emailError : ''+ emailError}
              value={vkiData && vkiData.Slachthuis ? vkiData.Slachthuis.Email : ''}
              onChange={handleInputChange}
              className="default-field-Email"
              InputLabelProps={{
                className: 'input-label',
                classes: {
                  shrink: 'input-label-shrink',
                },
              }}
            />
          </div>
          <div className="default-row-externslachthuis">
            <div className="default-label-externslachthuis">
              {t('externslachthuis')}
              <span className="default-label-splitter"> : </span>
            </div>
            <Checkbox
              name="ExternSlachthuis"
              checked={vkiData && vkiData.Slachthuis ? vkiData.Slachthuis.ExternSlachthuis : false}
              value={vkiData && vkiData.Slachthuis ? vkiData.Slachthuis.ExternSlachthuis : false}
              disabled={isReadonly}
              onChange={handleInputChange}
              className="default-field-externslachthuis-checkbox"
              size="small"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default SlachthuisDataSection;
