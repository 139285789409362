import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, LinearProgress } from '@mui/material';
import camelcaseKeys from 'camelcase-keys';
import React, { SetStateAction, useEffect, useState } from 'react';
import { getInitialProps, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { IFormValidationObject } from '../VKI/Interfaces/IForm';
import { IVKI } from '../VKI/Interfaces/IVKI';
import { IVKIForm } from '../VKI/Interfaces/VKIForm';
import { VKIUtil } from '../utils/vki';
import VKIService from './Services/VKIService';
import DierenartsDataSection from './VKIFormSections/DierenartsDataSection';
import GegevensVoerLeverancierDataSection from './VKIFormSections/GegevensVoerleverancierDataSection';
import GeneralFormDataSection from './VKIFormSections/GeneralFormDataSection';
import GezondheidDataSection from './VKIFormSections/GezondheidDataSection';
import KoppelgegevensDataSection from './VKIFormSections/KoppelgegevensDataSection';
import LeveringDataSection from './VKIFormSections/LeveringDataSection';
import MortaliteitDataSection from './VKIFormSections/MortaliteitDataSection';
import PluimveehouderDataSection from './VKIFormSections/PluimveehouderDataSection';
import SlachthuisDataSection from './VKIFormSections/SlachthuisDataSection';
import VaccinsDataSection from './VKIFormSections/VaccinsDataSection';
import VoerLeverancierDataSection from './VKIFormSections/VoerleverancierDataSection';
import { Goedkeuring } from './common/Goedkeuring';
import KopieerFunctie from './common/KopieerFunctie';
import MeerdereSlachthuizen from './common/MeerdereSlachthuizen';
import SnackbarComponent from './common/Snackbar';
import { Valideren } from './common/Validatie';
import { Voortgang } from './common/Voortgang';
import { environment } from './common/env';
import { group } from 'console';
import { FileUpload } from './common/FileUpload';

export const VKI: React.FC<any> = (props: any) => {
  const { t } = useTranslation();
  const intialProps = getInitialProps();
  const [VKIFormData, setVKIFormData] = useState<IVKIForm>();
  const [vki, setVki] = useState<IVKI>();
  const [vkiForm, setVkiForm] = useState<IVKIForm>();
  const [loading, setLoading] = useState<boolean>(true); // Add loading state
  const [canSubmit, setCanSubmit] = useState<boolean>(false); 
  const [preview, setPreview] = useState<boolean>(false); 
  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [status, setStatus] = useState<'success' | 'failed' | 'pending' | ''>('');
  const [base64Image, setBase64Image] = useState<string>('');
  const [validateObject, setValidateObject] = useState<IFormValidationObject>({
    GeneralFormDataSection: false,
    PluimveehouderDataSection: false,
    DierenartsDataSection: false,
    SlachthuisDataSection: false,
    KoppelgegevensDataSection: false,
    MortaliteitDataSection: false,
    LeveringDataSection: false,
    GezondheidDataSection: false,
    VoerleverancierDataSection: false,
    VaccinsDataSection: false,
  });


  // @@@@ moet aangepast worden naar de gegeven manier van oscar. de confirm exit komt niet overeen met de comment van de ticket.
  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (unsavedChanges) {
        if (vki !== undefined){
          //VKIService.saveVki(vki, props.accountInfo.jwtIdToken);
          console.log("unsaved");
          event.preventDefault();
          event.returnValue = ''; // This is necessary for some browsers to show the confirmation message
        }
      }
    };
    
    // Register the event listener when the component mounts
    window.addEventListener('beforeunload', handleBeforeUnload);
    console.log("unsavedChanges"+ unsavedChanges);
    // Unregister the event listener when the component unmounts
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [unsavedChanges]);
  
  
  // goed implementeren van scroll zodat je boven aan begint met invullen van het formulier.
  //window.scrollTo(0, 0);
  
  const { id } = useParams();

  const [readonly, setReadonly] = useState<boolean>(false); 

  const handleVkiDataChange = (data : SetStateAction<IVKI | undefined>) => {
    setVki (data);
    setUnsavedChanges(true);
    console.log('changed vki data ', VKIFormData);
  };

  const handleValidateObjectChanges = (validateObject : SetStateAction<IFormValidationObject>) => {
    setValidateObject (validateObject);
    console.log("validate objects changed")
    setUnsavedChanges(true);
  };

  const fetchData = async () => {
    try {
      const response = await fetch(
        environment.backendBaseUrl +
          'VKIForm?id=' + id + '&email=' + props.accountInfo.account.idToken.emails[0] + 
          `&lang=${intialProps.initialLanguage}&userid=`+
          props.accountInfo.account.idToken.sub +
          '&environment=' + environment.environment + '&code=' + environment.REACT_APP_FUNCTION_KEY
          ,
          { headers: {Authorization: 'Bearer ' + props.accountInfo.jwtIdToken}});
      if (response.ok) {
        const jsonData = await response.json();
        const camelCasedData = camelcaseKeys(jsonData, {
          deep: true,
          pascalCase: true,
        });

        setVKIFormData(camelCasedData);
        

        if (camelCasedData?.Vki?.Bijlagen === null) {
          camelCasedData.Vki.Bijlagen = [];
        }

        // Format all dates to date objects. React sees DateTime objects as stirngs
        let formattedVki = await VKIUtil.formatVki(camelCasedData.Vki);
        // @@@@Vragen@@@@ of dit op deze manier goed is , wordt nu gekeken in de validatie functie gekeken of de voer begin datum al gezet is of niet, als jhet niet zo is wordt die gezet op de opzet datum van het vki fomrulier
        if(formattedVki && formattedVki.Opzetdatum !== undefined && !readonly) {
          // zet de eerste Begin datum van voer op 1
          formattedVki = Valideren.zetBeginDatumVoer1(formattedVki, 1);
        }
        formattedVki.LandAfkosmtFormulier = '';

        setVki(formattedVki)
        setVkiForm(camelCasedData);


      } else {
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  
  useEffect(() => {
    fetchData();
    
  }, []);

  // set readonlyMode
  useEffect(() => {
    console.log(VKIFormData?.GroupClaim)
    if (vki?.StatusAgri === 'Goedgekeurd' || vki?.StatusNvwa === 'Goedgekeurd' || VKIFormData?.GroupClaim === 'AGRI Lezen' || VKIFormData?.GroupClaim === 'NVWA Lezen' || VKIFormData?.GroupClaim === 'NVWA') {
      setReadonly(true);
      
    }
    if (VKIFormData?.GroupClaim == "NVWA" || VKIFormData?.GroupClaim == "NVWA Lezen" || VKIFormData?.GroupClaim == "AGRI Lezen")
    {
      setPreview(true);
      setZoomlever(1.3);
    }
    
    // if(vki?.Status === 'Nieuw') {
    //   vki.Status = 'In bewerking';
    // }
    // window.scroll(0, 0);
  }, [vki]);

  // depricated... oude manier van het checken of alles wel goed ingevuld was.
  // checks validate object if all components are validated
  // const checkIfComponentsAreValidated = (obj: IFormValidationObject) => {
  //   for (const key in obj) {
  //     if (!obj[key as keyof IFormValidationObject]) {
  //       return false;
  //     }
  //   }
  //   return true;
  // };

  const submit = async (event: { preventDefault: () => void; }) => {
    event.preventDefault();

    
      if (vki !== undefined) {
        setLoading(true);
        await VKIUtil.saveVkiFormatting(vki);
        setStatus('pending');
  
        try {
          const response = await VKIService.saveVki(vki, props.accountInfo.jwtIdToken, props.accountInfo.account.idToken.sub);
  
          if (response && response.status === 200) {
            setStatus('success');
            await fetchData();
            setUnsavedChanges(false);
          } else {
            setLoading(false);
            setStatus('failed');
        }
        } catch (error) {
          setLoading(false);
          setStatus('failed');
        } finally {
          setLoading(false);
          setSnackbarOpen(true); // Open the snackbar after form submission is complete
        }
      }
    };

    const [zoomlevel, setZoomlever] = useState<number>(0.8); 
    const [oldzoomlevel, setOldZoomlevel] = useState<number>(0.8); 

    const handleButtonClick = () => {
      if (zoomlevel == 0.8)
      {
          setZoomlever(1);
      }
      else if (zoomlevel == 1)
      {
        setZoomlever(0.7);
      }
      else
      {
        setZoomlever(0.8);
      }
    };
    const handlePreview = () => {
      
      if (!preview){
        setOldZoomlevel(zoomlevel)
        setZoomlever(1.3);
      }
      else
      {
        setZoomlever(oldzoomlevel);
      }
      setPreview(!preview);
    };
  
  return (
    <>

      <div className="container" style={{ transform: `scale(${zoomlevel})`, transformOrigin: 'center top' }}>
      <div className={loading ? "VKIFormLoadingHeader" : "VKIFormHeader"}>
      <Button hidden={loading} onClick={handleButtonClick} style={{ padding: 0, minWidth: 0, float: "right"}}>
          <img className='zoom' src={"/zoom.png"}/>
      </Button>
        
        <div className={loading ? "VKIComponentCenterLoading" : "VKIComponentCenter"}>
          {VKIFormData && <Voortgang accountInfo={props.accountInfo} vki={VKIFormData.Vki} rol={VKIFormData.GroupClaim} />}
        </div>
        
        <div className={loading ? "VKIComponentCenterLoading" : "VKIComponentCenter"}>
          
          {VKIFormData && <span><KopieerFunctie accountInfo={props.accountInfo} vki={VKIFormData.Vki} rol={VKIFormData.GroupClaim} /></span>}
          {VKIFormData && <span><MeerdereSlachthuizen accountInfo={props.accountInfo} vki={VKIFormData.Vki} rol={VKIFormData.GroupClaim} /></span>}
        </div>
      </div>
        <SnackbarComponent open={snackbarOpen} onClose={() => setSnackbarOpen(false)} status={status} />
        <div className={loading ? "VKIFormLoading" : "VKIForm"}>

          <div className="VKIFormContainer">
          <Button variant="contained" color="primary" hidden={false} onClick={handlePreview} style={{"float" : "right", "margin" : "3px"}}>{ preview ? t('voorbeeldSluiten') : t('voorbeeld')}</Button>&nbsp;
          <Button variant="contained" color="primary" hidden={false} onClick={() => {window.location.href = '/'}} style={{"float" : "right", "margin" : "3px"}}>{"VKI " + t('vkisluiten')}</Button>
          {VKIFormData && vki && <div className={loading ? "VKIComponentCenterLoading" : "VKIComponentCenter"}><Goedkeuring accountInfo={props.accountInfo} vki={vki} rol={VKIFormData.GroupClaim} /> </div>}
          <div className="titleTest">{t('Titel').toUpperCase()}</div>
            <div className="VKIPreview" hidden={!preview}>
              <img
                    src={"data:image/png;base64, " + VKIFormData?.Preview} // Use the base64Image state as the source
                    alt={vki?.Id}
                    className="preview-image"
                  />
                          { VKIFormData &&
                              <FileUpload accountInfo={props.accountInfo} vki={VKIFormData.Vki} rol={VKIFormData.GroupClaim} type="*" onFileUpload={() =>{}} readonly={true}/>}

            </div>
            
            {loading ? (
              <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                <LinearProgress style={{width: '99%', }} />
              </Box>
            ) : (
              VKIFormData ?
                <form onSubmit={submit} hidden={preview}>
                  <div style={{flexDirection: 'column', gap: 20 }}>
                    <GeneralFormDataSection vkiData={vki} vkiForm={vkiForm} accountInfo={props.accountInfo} onChange={handleVkiDataChange} isReadonly={readonly} groupClaim={VKIFormData.GroupClaim} validateObject={validateObject}  setValidationObject={handleValidateObjectChanges}/>
                      <Divider variant="middle" />
                    <h6 style={{ paddingLeft: "0.9375rem", paddingRight: "0.9375rem" }}>{t('Degegevens')}</h6>
                      <Divider variant="middle" />
                    <PluimveehouderDataSection vkiData={vki} vkiForm={vkiForm} accountInfo={props.accountInfo} onChange={handleVkiDataChange}  isReadonly={readonly} groupClaim={VKIFormData.GroupClaim} validateObject={validateObject}  setValidationObject={handleValidateObjectChanges} />
                    <DierenartsDataSection vkiData={vki} vkiForm={vkiForm}  dierenartsen={VKIFormData?.Dierenartsen}  accountInfo={props.accountInfo} onChange={handleVkiDataChange}  isReadonly={readonly} groupClaim={VKIFormData.GroupClaim} validateObject={validateObject}  setValidationObject={handleValidateObjectChanges} />
                    <GegevensVoerLeverancierDataSection vkiForm={vkiForm} vkiData={vki} accountInfo={props.accountInfo} onChange={handleVkiDataChange}  isReadonly={readonly} groupClaim={VKIFormData.GroupClaim}validateObject={validateObject}  setValidationObject={handleValidateObjectChanges} />
                    <SlachthuisDataSection vkiData={vki} vkiForm={vkiForm} accountInfo={props.accountInfo} onChange={handleVkiDataChange}  isReadonly={readonly} groupClaim={VKIFormData.GroupClaim} validateObject={validateObject}  setValidationObject={handleValidateObjectChanges} />
                    <KoppelgegevensDataSection vkiData={vki} vkiForm={vkiForm} accountInfo={props.accountInfo} onChange={handleVkiDataChange}  isReadonly={readonly} groupClaim={VKIFormData.GroupClaim} rasSelectBoxOptions={VKIFormData?.Ras} validateObject={validateObject}  setValidationObject={handleValidateObjectChanges} />
                      <Divider variant="middle" />
                    <MortaliteitDataSection vkiData={vki} vkiForm={vkiForm} accountInfo={props.accountInfo} onChange={handleVkiDataChange} isReadonly={readonly} groupClaim={VKIFormData.GroupClaim} validateObject={validateObject}  setValidationObject={handleValidateObjectChanges} />
                      <Divider variant="middle" />
                    <LeveringDataSection vkiData={vki} vkiForm={vkiForm} accountInfo={props.accountinfo} onChange={handleVkiDataChange} strooiselOptions={VKIFormData?.Strooisel} stooiselKwaliteitOptions={VKIFormData?.StrooiselKwaliteit} isReadonly={readonly} groupClaim={VKIFormData.GroupClaim} validateObject={validateObject}  setValidationObject={handleValidateObjectChanges} />
                      <Divider variant="middle" />
                    <VoerLeverancierDataSection vkiData={vki} vkiForm={vkiForm} accountInfo={props.accountInfo} onChange={handleVkiDataChange} isReadonly={readonly} groupClaim={VKIFormData.GroupClaim} coccidiostaticumOptions={VKIFormData?.Coccidiostaticum} validateObject={validateObject}  setValidationObject={handleValidateObjectChanges} soortVoerOptions={VKIFormData.SoortVoer}/>
                      <Divider variant="middle" />
                    <GezondheidDataSection vkiData={vki} vkiForm={vkiForm} accountInfo={props.accountInfo} onChange={handleVkiDataChange} isReadonly={readonly} groupClaim={VKIFormData.GroupClaim} ziekteBeeldOptions={VKIFormData.Ziektebeeld} geneesMiddelOptions={VKIFormData.Geneesmiddel} validateObject={validateObject}  setValidationObject={handleValidateObjectChanges} />
                      <Divider variant="middle" />
                    <VaccinsDataSection vkiData={vki} vkiForm={vkiForm} accountInfo={props.accountInfo} onChange={handleVkiDataChange} isReadonly={readonly} groupClaim={VKIFormData.GroupClaim} ziekteOptions={VKIFormData.Ziekte} registartieNummerOptions={VKIFormData.Vaccin} salmonellaStatusOptions={VKIFormData.SalmonellaStatus} methodOptions={VKIFormData.Methoden} validateObject={validateObject}  setValidationObject={handleValidateObjectChanges} />
                    <div style={{display: 'flex', justifyContent: "right"}}>
                      <Button
                        tabIndex={6015}
                        hidden={(vki?.Status == "Ingediend" || vki?.Status == "Archief") && VKIFormData?.GroupClaim !== 'AGRI Keuren' && VKIFormData?.GroupClaim !== 'AGRI'}
                        variant="contained"
                        color="primary"
                        type="submit"
                        style={{ margin: "0.9375rem"}}
                      >
                        {t('TussentijdsOpslaan')}
                      </Button>
                    </div>
                  </div>
                </form>
                : null
            )}
          </div>
        </div>
        <div>
          <br/>
          <div hidden={VKIFormData?.GroupClaim != "AGRI"}>
            {t('GemaaktDoor') + " " + (vki?.GemaaktDoor ? vki?.GemaaktDoor : 'NA') + " "}{t('GemaaktOp') + " " + (vki?.Gemaakt ? new Date(vki?.Gemaakt as unknown as string).toLocaleDateString('nl-NL') + " " + new Date(vki?.Gemaakt as unknown as string).toLocaleTimeString('nl-NL') : 'NA' )}<br/>
            {t('GewijzigdDoor') + " " + (vki?.GewijzigdDoor ? vki?.GewijzigdDoor : 'NA') + " "}{t('GewijzigdOp') + " " + (vki?.Gewijzigd ?  new Date(vki?.Gewijzigd as unknown as string).toLocaleDateString('nl-NL') + " " + new Date(vki?.Gewijzigd as unknown as string).toLocaleTimeString('nl-NL') : 'NA') }
        </div>
        </div>
      </div>
    </>
  );
};

export default VKI;


