import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IFormSectionProps, IFormValidationObject } from '../../VKI/Interfaces/IForm';
import { IPluimveehouder, IVKI } from '../../VKI/Interfaces/IVKI';
import { isValidEmail } from '../../utils/email';

const PluimveehouderDataSection: React.FC<IFormSectionProps> = ({vkiData, accountInfo, onChange, isReadonly, groupClaim, validateObject, setValidationObject}) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(true);
  const [initialLoad, setInitialLoad] = useState(true);
  const [emailError, setEmailError] = useState('');

  useEffect(() => {
    // Check for missing inputs in vkiData.Pluimveehouder so the section can be expanded if needed.
    const checkMissingInputs = () => {
      if (vkiData && vkiData.Pluimveehouder && initialLoad) {
        const { Naam, Adres, Woonplaats, Kipnummer, Email } = vkiData.Pluimveehouder;
        let isDataMissing = false;

        if (!Naam)  isDataMissing = true;
        if (!Adres)  isDataMissing = true;
        if (!Woonplaats)  isDataMissing = true;
        if (!Kipnummer)  isDataMissing = true;
        if (!Email)  isDataMissing = true;

        if(isDataMissing === false) {
          //setExpanded(isDataMissing);
        }
      }
    };

    checkMissingInputs();
    setInitialLoad(false);
    // if(vkiData !== undefined) isPluimveehouderValid(vkiData);
  }, [vkiData]);


  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    if (name === 'Email') {
      const errorMessage = t('nietGeldigEmailAdres');
      if (!isValidEmail(value)) {
        setEmailError(errorMessage);
      } else {
        setEmailError('');
      }
    }

    onChange((prevVkiData: IVKI) => {
      const updatedData: IVKI = prevVkiData;

      updatedData.Pluimveehouder[name as keyof IPluimveehouder] = value;

      isPluimveehouderValid(updatedData);
      
      return updatedData;
    });
  };

  const toggleExpand = () => {
    setExpanded(!expanded);
  };
  

    // function that determines that this component part of the form is valid.
    const isPluimveehouderValid = (data: IVKI): void => {
      let isValid:boolean = true;
      if(data)  {
        if(data.Pluimveehouder) {
          const { Kipnummer, Email } = data.Pluimveehouder;
          if (!Kipnummer)  isValid = false;
          if (!Email)  isValid = false;
          if (!isValidEmail(Email))  isValid = false;
        }
      }
      // update the object..
      setValidationObject((prevValidationObject: IFormValidationObject) => ({
        ...prevValidationObject,
        PluimveehouderDataSection: isValid,
      }));
    };
    const handleKeyPress = (event:any) => {
      if (event.key === 'Enter') {
        try
        {
          event.preventDefault(); // Prevent the default behavior of the Enter key
          // You can add any custom logic here if needed
          const form = event.target.form;
          const index = Array.from(form.elements).indexOf(event.target);
          form.elements[index + 1].focus();
        }
        catch(ex){}
      }
    };
  
  return (
    <>
      <div
        className={`default-section-container ${expanded ? 'expanded' : 'collapsed'}`}
      >
        <div className="header" style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h4 style={{ marginRight: 'auto' }}>{t('GegevensPluimveehouderCaps')}</h4>
         <IconButton
            onClick={toggleExpand}
            className={`expand-button ${expanded ? 'expanded' : ''}`}
            aria-label={expanded ? t('collapse') as string | undefined: t('expand')  as string | undefined}
            style={{ marginLeft: 'auto' }}
          >
            <ExpandMoreIcon />
          </IconButton>
        </div>
        {expanded && (
          <div className="content">
            <div className="default-row">
            <TextField onKeyPress={handleKeyPress} 
              name="Naam"
              label={t('naam')}
              InputProps={{
                readOnly: isReadonly
              }}
              error={!vkiData?.Pluimveehouder || !vkiData?.Pluimveehouder.Naam}
              helperText={!vkiData?.Pluimveehouder || !vkiData?.Pluimveehouder.Naam || !('Naam' in vkiData.Pluimveehouder) ? t('verplichtVeld') : ''}
              value={vkiData && vkiData.Pluimveehouder ? vkiData.Pluimveehouder.Naam : ''}
              onChange={handleInputChange}
              className='default-field-Naam'
              InputLabelProps={{
                className: 'input-label',
                classes: {
                  shrink: 'input-label-shrink'
                }
              }}
            />
            </div>
            <div className="default-row">
              <TextField onKeyPress={handleKeyPress} 
                name="Adres"
                label={t('adres')}
                InputProps={{
                  readOnly: isReadonly
                }}

                value={vkiData && vkiData.Pluimveehouder ? vkiData.Pluimveehouder.Adres : ''}
                onChange={handleInputChange}
                className='default-field-Adres'
                error={!vkiData?.Pluimveehouder || !vkiData?.Pluimveehouder.Adres}
                helperText={!vkiData?.Pluimveehouder || !vkiData?.Pluimveehouder.Adres || !('Adres' in vkiData.Pluimveehouder) ? t('verplichtVeld') : ''}   
                InputLabelProps={{
                  className: 'input-label',
                  classes: {
                    shrink: 'input-label-shrink'
                  }
                }}
              />
            </div>
            <div className="default-row">
              <TextField onKeyPress={handleKeyPress} 
                name="Woonplaats"
                label={t('woonplaats')}
                InputProps={{
                  readOnly: isReadonly
                }}
                value={vkiData && vkiData.Pluimveehouder ? vkiData.Pluimveehouder.Woonplaats : ''}
                error={!vkiData?.Pluimveehouder || !vkiData?.Pluimveehouder.Woonplaats}
                helperText={!vkiData?.Pluimveehouder || !vkiData?.Pluimveehouder.Woonplaats || !('Woonplaats' in vkiData.Pluimveehouder) ? t('verplichtVeld') : ''}   
                onChange={handleInputChange}
                className='default-field-Woonplaats'
                InputLabelProps={{
                  className: 'input-label',
                  classes: {
                    shrink: 'input-label-shrink'
                  }
                }}
              />
            </div>
            <div className="default-row">
              <TextField onKeyPress={handleKeyPress} 
                name="Kipnummer"
                label={t('kipnummer')}
                InputProps={{
                  readOnly: isReadonly
                }}
                value={vkiData && vkiData.Pluimveehouder ? vkiData.Pluimveehouder.Kipnummer : ''}
                onChange={handleInputChange}
                className='default-field-Kipnummer'
                InputLabelProps={{
                  className: 'input-label',
                  classes: {
                    shrink: 'input-label-shrink'
                  }
                }}
                error={!vkiData?.Pluimveehouder || !vkiData?.Pluimveehouder.Kipnummer}
                helperText={!vkiData?.Pluimveehouder || !vkiData?.Pluimveehouder.Kipnummer || !('Kipnummer' in vkiData.Pluimveehouder) ? t('verplichtVeld') : ''}
              />
            </div>
            <div className="default-row">
              <TextField onKeyPress={handleKeyPress} 
                name="Email"
                label={t('email')}
                InputProps={{
                  readOnly: isReadonly
                }}
                value={vkiData && vkiData.Pluimveehouder ? vkiData.Pluimveehouder.Email : ''}
                onChange={handleInputChange}
                className='default-field-Email'
                InputLabelProps={{
                  className: 'input-label',
                  classes: {
                    shrink: 'input-label-shrink'
                  }
                }}
                error={!vkiData?.Pluimveehouder || !vkiData?.Pluimveehouder.Email || !('Email' in vkiData.Pluimveehouder) || emailError !== ''}
                helperText={
                  !vkiData?.Pluimveehouder || !vkiData?.Pluimveehouder.Email || !('Email' in vkiData.Pluimveehouder)
                    ? t('verplichtVeld')
                    : emailError || ''
                }
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default PluimveehouderDataSection;
