import { useState } from "react";

function inputComponent({ defaultvalue, inputref, ...props }: any) {
  //const [value, setvalue] = useState(() => props.value || defaultvalue);

  const handlechange = (event: any) => {
    event.target.value = props.value;
    if (props.onchange) {
      props.onchange(event);
    }
  };

  // You can return any values or functions that you want to expose
  return {
        onChange: handlechange,
  };
}

export default inputComponent;