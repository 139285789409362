import React, { FC, useEffect, useState } from 'react';
import { Agri } from './AGRI';
import { Boer } from './Boer';
import { NVWA } from './NVWA';
//import { VKI } from '../VKI/VKI';
import { Box, LinearProgress } from '@mui/material';
import camelcaseKeys from 'camelcase-keys';
import { environment } from './common/env';
import { useTranslation } from 'react-i18next';
export const HomePage: FC<any> = (props:any) => {
	const [data, setData] = useState<string[]>([]);
	const { t } = useTranslation();
	useEffect(() => {
		const fetchData = async () => {
		  try {
			const response = await fetch(environment.backendBaseUrl + 'GetGroupClaim?userid=' + props.accountInfo.account.idToken.sub + '&email=' + props.accountInfo.account.idToken.emails[0]  + '&environment=' + environment.environment +  '&code=' + environment.REACT_APP_FUNCTION_KEY
			,      { headers: {Authorization: 'Bearer ' + props.accountInfo.jwtIdToken, Accept: 'application/json'}}); // Replace with your REST endpoint URL
			if (response.ok) {
			  const jsonData = await response.json();
			  
			  const camelCasedData = camelcaseKeys(jsonData, { deep: true, pascalCase: true });
			  setData(camelCasedData);
			} else {
			  // Handle error if needed
			  
			  console.error('Error:', response.status);
			}
		  } catch (error) {
			// Handle error if needed
			console.error('Error:', error);
		  }
		};
		fetchData();
	
	}, ["loading"]);
	if (data.length > 0)
	{
		console.log(props.accountInfo);
		const role:string = data[0];
		if (role == "Boer")
		{
			return <Boer {...props} accountInfo={props.accountInfo} />
		}
		else if (role === "NVWA" || role === "NVWA Lezen" )
		{
			return <NVWA {...props} accountInfo={props.accountInfo} role={role}/>
		}
		else if (role === "AGRI" || role === "AGRI Lezen" || role === "AGRI Keuren")
		{
			return <Agri {...props} accountInfo={props.accountInfo} role={role}/>
		}
		else if (role === "loading")
		{
			return <>
				<Box sx={{ width: '100%' }}>
				<LinearProgress />
				</Box>
			</>
		}
		else if (role === "Geen rechten")
		{
			return <>
			<div className='VKIOverzicht'>
				<h1>{t("registratieKop")}</h1>
				<p>{t("registratieMelding")}</p>
			</div>
			</>
		}
		else
		{
			return <>

			</>
		}
	}
	else
	{
		return <>

		</>
	}
}

