import dayjs from "dayjs";

export class DateUtil {
  static async FormatDateStringToDate(date: string | Date | undefined | null): Promise<Date | undefined> {
    if (date === undefined || date === null) return Promise.resolve(undefined);

    return Promise.resolve(new Date(date));
  }
  
  // added this function because overloading cannot be done in typescript because its javascript under the hood.
  static async FormatExpectedDateStringToDate(date: string | Date): Promise<Date> {
    return Promise.resolve(new Date(date));
  }

  static FormatDateToString(date: Date | undefined): string {
    if (date === undefined || date === null || !(date instanceof Date) || isNaN(date.getTime())  ) {
      return '';
    }
    //date.setHours(date.getHours() + 2);
    if (date.toLocaleDateString().indexOf('/') > -1)
    {

      
      var dates = date.toLocaleDateString().split('/')
      var month =  dates[1];
      if (month.length == 1)
      {
        month = "0" +  dates[1];
      }
      var day =  dates[2];
      if (day.length == 1)
      {
        day = "0" +  dates[2];
      }
      
      return day + "-" + month + "-" +dates[0]  ;
    }
    else
    {
      
      var dates = date.toLocaleDateString().split('-')
      var month =  dates[1];
      if (month.length == 1)
      {
        month = "0" +  dates[1];
      }
      var day =  dates[0];
      if (day.length == 1)
      {
        day = "0" +  dates[0];
      }
      
      return dates[2] + "-" + month + "-" + day;
    }
  }

  static ValidateDates(start: Date | undefined, end: Date | undefined) {
    if (start === undefined || end === undefined) {
      return ''; // Return if any date is undefined
    }

    if (start > end) {
      return 'End date cannot be before the start date';
    } else if (end < start) {
      return 'Start date cannot be after the end date';
    }

    return '';
  }

  static ValidateOpzetDatum(date: Date | undefined): boolean {
    const today = new Date();
    const futureDate = new Date();
    futureDate.setMonth(today.getMonth() + 1);

    if (date === undefined) return true;

    return date < futureDate;
  }

  static isValidDate = (dateString: string): boolean => {
    const date = new Date(dateString);
    return !isNaN(date.getTime());
  };


  // static FormatDigitalTime(input: string| undefined): string | null {
  //   if(input === undefined) return null;
  //     const numericInput = parseInt(input);
    
  //     if (isNaN(numericInput)) {
  //       return null; // Ongeldige invoer
  //     }
    
  //     if (input.length === 1) {
  //       return `0${numericInput}:00`;
  //     } else if (input.length === 2) {
  //       return `${numericInput}:00`;
  //     } else if (input.length === 3) {
  //       const hour = Math.floor(numericInput / 10);
  //       const minute = numericInput % 10;
  //       return `${hour}:0${minute}`;
  //     } else if (input.length === 4) {
  //       const hour = Math.floor(numericInput / 100);
  //       const minute = numericInput % 100;
  //       return `${hour}:${minute}`;
  //     }
    
  //     return null; // Ongeldige invoer
  // }

  static formatToDigitalClock = (input: string): string => {
    if (input.length > 5) {
      input = input.substring(0, 5);
    }

    if (input.length === 1) {
      return `0${input}:00`;
    } else if (input.length === 2) {
      return `${input}:00`;
    }

    if (input.length >= 2 && input.length <= 4) {
      const hours = input.substring(0, 2);
      const minutes = input.substring(2, 4);

      if (input.length === 2) {
        return `${hours}`;
      } else {
        return `${hours}:${minutes}`;
      }
    }

    return input;
  };

  // format a HH:mm or YYYY-MM-DDTHH:mm string to a YYYY-MM-DDTHH:mm string
  static formatToDaysjs = (input: string, format: string): string => {
    return dayjs(input).format(format);
  };

}
