import { FormHelperText, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { getInitialProps, useTranslation } from 'react-i18next';
import { IFormValidationObject, IValidationObject, IVoerLeverancierDataSectionProps } from '../../VKI/Interfaces/IForm';
import { IVKI, IVoer } from '../../VKI/Interfaces/IVKI';
import { IVKIFormItem } from '../../VKI/Interfaces/VKIForm';
import CustomDatePicker from '../CustomDatePicker';
import { Valideren } from '../common/Validatie';

const VoerLeverancierDataSection: React.FC<IVoerLeverancierDataSectionProps> = ({ vkiData, accountInfo, onChange, isReadonly, groupClaim, coccidiostaticumOptions, validateObject, setValidationObject, soortVoerOptions }) => {
  const { t } = useTranslation();
  const [BeginDatumErrorObject, setBeginDatumErrorObject] = useState<IValidationObject[]>([
    {
      index: 0,
      errorMessage: '',
      isError: false
    },
    {
      index: 1,
      errorMessage: '',
      isError: false
    },
    {
      index: 2,
      errorMessage: '',
      isError: false
    },
    {
      index: 3,
      errorMessage: '',
      isError: false
    },
    {
      index: 4,
      errorMessage: '',
      isError: false
    }
  ]);
  const [EindDatumErrorObject, setEindDatumErrorObject] = useState<IValidationObject[]>([
    {
      index: 0,
      errorMessage: '',
      isError: false
    },
    {
      index: 1,
      errorMessage: '',
      isError: false
    },
    {
      index: 2,
      errorMessage: '',
      isError: false
    },
    {
      index: 3,
      errorMessage: '',
      isError: false
    },
    {
      index: 4,
      errorMessage: '',
      isError: false
    }
  ]);

  // toepasbaarheids filter..
  const [SoortVoerToepasbaarheidsFilter, SetSoortVoerToepasbaarheidsFilter] = useState<IVKIFormItem[]>([]);
  const [SoortVoerToepasbaarheidsFilter1, SetSoortVoerToepasbaarheidsFilter1] = useState<IVKIFormItem[]>([]);
  const [SoortVoerToepasbaarheidsFilter2, SetSoortVoerToepasbaarheidsFilter2] = useState<IVKIFormItem[]>([]);
  const [SoortVoerToepasbaarheidsFilter3, SetSoortVoerToepasbaarheidsFilter3] = useState<IVKIFormItem[]>([]);
  const [SoortVoerToepasbaarheidsFilter4, SetSoortVoerToepasbaarheidsFilter4] = useState<IVKIFormItem[]>([]);


  const [firstRun, setFirstRun] = useState<boolean>(true);

  const intialProps = getInitialProps();

  useEffect(() => {
    if(vkiData)isVoerLeverancierValid(vkiData);
    if(coccidiostaticumOptions && coccidiostaticumOptions !== undefined && coccidiostaticumOptions !== null && coccidiostaticumOptions.length > 0) {
      // eerste load moet ook gefilterd worden.
      SetSoortVoerToepasbaarheidsFilter(coccidiostaticumOptions);
      SetSoortVoerToepasbaarheidsFilter1(coccidiostaticumOptions);
      SetSoortVoerToepasbaarheidsFilter2(coccidiostaticumOptions);
      SetSoortVoerToepasbaarheidsFilter3(coccidiostaticumOptions);
      SetSoortVoerToepasbaarheidsFilter4(coccidiostaticumOptions);
    }

    if(firstRun && vkiData !== undefined)
    {
      ValidateDates(vkiData, 0);
      setFirstRun(false);
    }
    
  }, [vkiData]);

  const handleDateChange = (date: Date | null, name:string, index: number) => {
    onChange((prevVkiData: IVKI) => {
      const updatedVkiData: IVKI = { ...prevVkiData };
      const updatedVoer = [...prevVkiData.Voer];

      if(date === null || date === undefined) {
        updatedVoer[index] = { ...updatedVoer[index], [name]: undefined };
      } else { 
        updatedVoer[index] = { ...updatedVoer[index], [name]: new Date(date) };
      }

      if (updatedVoer.length >= index+2)
      {
        updatedVoer[index+1].Begindatum = updatedVoer[index].Einddatum
      }

      updatedVkiData.Voer = updatedVoer;

      ValidateDates(updatedVkiData, index);
  
      isVoerLeverancierValid(updatedVkiData);
  
      return {...updatedVkiData};
    });
  };

  const handleSelectChanges = (event: SelectChangeEvent<string>, index: number) => {
    const { value, name } = event.target;

    const newVoerLeverancier: IVoer = {
      SoortVoer: '',
      Coccidiostaticum: '',
      WettelijkeWachttermijn: 0,
      Begindatum: undefined,
      Einddatum: undefined,
      SoortVoerExterneId: '',
      CoccidiostaticumExterneId: ''
    };

    onChange((prevVkiData: IVKI) => {
      // Step 1: Create a copy of prevVkiData
      let updatedVKI: IVKI = { ...prevVkiData };
    
      // Step 2: Modify updatedVoerLeverancier
      let updatedVoerLeverancier: IVoer[] = prevVkiData.Voer ? [...prevVkiData.Voer] : [];
      if (index > 0)
      {
        newVoerLeverancier.Begindatum = updatedVoerLeverancier[index-1].Einddatum
      }
      if (updatedVoerLeverancier.length === 0) updatedVoerLeverancier.push(newVoerLeverancier);
    
      if (index >= 0 && index < updatedVoerLeverancier.length) {
        updatedVoerLeverancier[index] = {
          ...updatedVoerLeverancier[index],
          [name]: value,
        };
      }
    
      if (name === 'SoortVoer') {
        var gekozenSoortvoer = soortVoerOptions?.find((option) => option.ExterneId === value);
        updatedVoerLeverancier[index] = {
          ...updatedVoerLeverancier[index],
          SoortVoerExterneId: value !== 'empty' ? value : "",
          SoortVoer: value !== 'empty' ? gekozenSoortvoer?.Titel : "",
        };

        // set the cocci filter
        setFilterForCocci(value, index);

        // controleren if value is 4 dan moet je de laatste item van de array eraf halen. if not voeg een nieuwe voer leverancier object toe aan array.
        for (let i = 0; i < updatedVoerLeverancier.length; i++) {
          if (value === '4') {
            updatedVoerLeverancier.length = index + 1;
          } else if (value === 'empty') {
            updatedVoerLeverancier.length = index + 1;
            updatedVoerLeverancier[index] = {
              ...updatedVoerLeverancier[index],
              SoortVoer: undefined,
            };
          } else {
            if (index + 1 < updatedVoerLeverancier.length) break;
            updatedVoerLeverancier.push(newVoerLeverancier);
            break;
          }
        }
      }
    
      if (name === 'CoccidiostaticumExterneId') {
        let selectedCoccidiostaticum;
      
        if (coccidiostaticumOptions !== undefined) {
          selectedCoccidiostaticum = coccidiostaticumOptions.find((coccidiostaticumOption: IVKIFormItem) => coccidiostaticumOption.ExterneId === value && coccidiostaticumOption.Taal?.toLowerCase() === intialProps.initialLanguage.toLowerCase());
        }
      
        if (selectedCoccidiostaticum) { // Check if selectedCoccidiostaticum is truthy
          updatedVoerLeverancier[index] = {
            ...updatedVoerLeverancier[index],
            WettelijkeWachttermijn: selectedCoccidiostaticum.Wachttermijn as number,
            Coccidiostaticum: selectedCoccidiostaticum.Titel,
            CoccidiostaticumExterneId: selectedCoccidiostaticum.ExterneId,
          };
        } else {
          updatedVoerLeverancier[index] = {
            ...updatedVoerLeverancier[index],
            Coccidiostaticum: "",
            CoccidiostaticumExterneId: '',
          };
        }
      }


      updatedVKI.Voer = updatedVoerLeverancier;

      ValidateDates(updatedVKI, index);

      isVoerLeverancierValid(updatedVKI);
  
      return updatedVKI;
    });
  };

  const setFilterForCocci = (value: any, index: number): void => {
       // set filter 
       if(coccidiostaticumOptions !== undefined) {
        // de opties die een soortVoer heeft.
        var toepasbaarOp = soortVoerOptions?.filter((voerOption) => voerOption.ExterneId === value)[0]?.ToepasbaarOp? soortVoerOptions?.filter((voerOption) => voerOption.ExterneId === value)[0]?.ToepasbaarOp : undefined;
        if(toepasbaarOp !== undefined || toepasbaarOp !== null) {
          var options = coccidiostaticumOptions?.filter((coccidiostaticumOption: IVKIFormItem) => toepasbaarOp?.includes(coccidiostaticumOption.ExterneId)).length > 0 ? coccidiostaticumOptions?.filter((coccidiostaticumOption: IVKIFormItem) => toepasbaarOp?.includes(coccidiostaticumOption.ExterneId)): coccidiostaticumOptions  ;
          switch(index) {
            case 0: {
              SetSoortVoerToepasbaarheidsFilter((prevOptions) => {
                return options;
              });
              break;
            }
            case 1: {
              SetSoortVoerToepasbaarheidsFilter1((prevOptions) => {
                return options;
              });
              break;
            }
            case 2: {
              SetSoortVoerToepasbaarheidsFilter2((prevOptions) => {
                return options;
              });
              break;
            }
            case 3: {
              SetSoortVoerToepasbaarheidsFilter3((prevOptions) => {
                return options;
              });
              break;
            }
            case 4: {
              SetSoortVoerToepasbaarheidsFilter4((prevOptions) => {
                return options;
              });
              break;
            }
            default: { 
              break;
            }
          }
        }
      }
  }

  const ValidateDates = (Vki: IVKI, index: number) : void => {
    // validaties op geprioriseerde volgorde
    let isBeginDatumError= false;
    let isEindDatumError= false;

    if((Vki.Voer?.[index]?.Begindatum === undefined || Vki.Voer?.[index]?.Begindatum === null ) && !isBeginDatumError) {
      handleDateValidation(setBeginDatumErrorObject, t('verplichtVeld') as string, true, index);
      isBeginDatumError = true;
    } else {
      if(!isBeginDatumError) {
        handleDateValidation(setBeginDatumErrorObject, '', false, index);
        isBeginDatumError = false;  
      } 
    }
    
    if(((Vki.Voer?.[index]?.Einddatum === undefined || Vki.Voer?.[index]?.Einddatum === null) && Vki.Voer?.[index]?.SoortVoer?.indexOf("nd") == -1) && !isEindDatumError) {
      handleDateValidation(setEindDatumErrorObject, t('verplichtVeld') as string, true, index);
      isEindDatumError = true;

    } else {
      if(!isEindDatumError) {
        handleDateValidation(setEindDatumErrorObject, '', false, index);
        isEindDatumError = false;  
      }
    }

    const valideerBeginDatumVanInvullenString = Valideren.valideerVoerBeginDatumDatumVanInvullen(Vki, index + 1)
    if(valideerBeginDatumVanInvullenString !== '' && valideerBeginDatumVanInvullenString !== undefined  && valideerBeginDatumVanInvullenString !== null && !isBeginDatumError){
      handleDateValidation(setBeginDatumErrorObject, valideerBeginDatumVanInvullenString as string, true, index);
      isBeginDatumError = true;
    } else {
      if(!isBeginDatumError) {
        handleDateValidation(setBeginDatumErrorObject, '', false, index);
        isBeginDatumError = false;  
      }
    }

    const valideerBeginDatumVoorEindDatum = Valideren.valideerVoerBeginDatumVoorEindDatum(Vki, index + 1);
    const valideerEindDatumVoorBeginDatum = Valideren.valideerEindDatumNaBeginDatum(Vki, index + 1);
    if(valideerBeginDatumVoorEindDatum !== '' && valideerBeginDatumVoorEindDatum !== undefined  && valideerBeginDatumVoorEindDatum !== null && !isBeginDatumError || valideerEindDatumVoorBeginDatum !== '' && valideerEindDatumVoorBeginDatum !== undefined  && valideerEindDatumVoorBeginDatum !== null && !isEindDatumError){
      handleDateValidation(setBeginDatumErrorObject, valideerBeginDatumVoorEindDatum as string, true, index);
      handleDateValidation(setEindDatumErrorObject, valideerEindDatumVoorBeginDatum as string, true, index);
      isBeginDatumError = true;
      isEindDatumError = true;
    } else {
      if(!isEindDatumError) {
        handleDateValidation(setEindDatumErrorObject, '', false, index);
        isEindDatumError = false;  
      } 
      if(!isBeginDatumError) {
        handleDateValidation(setBeginDatumErrorObject, '', false, index);
        isBeginDatumError = false;  
      } 
    }

    const valideerEindDatumWettelijkWachttermijn = Valideren.valideerBeginEindDatumWettelijkwachttermijn(Vki, index + 1);
    if(valideerEindDatumWettelijkWachttermijn !== '' && valideerEindDatumWettelijkWachttermijn !== undefined  && valideerEindDatumWettelijkWachttermijn !== null && !isEindDatumError){
      handleDateValidation(setEindDatumErrorObject, valideerEindDatumWettelijkWachttermijn as string, true, index);
      isEindDatumError = true;
    } else {
      if(!isEindDatumError) {
        handleDateValidation(setEindDatumErrorObject, '', false, index);
        isEindDatumError = false;  
      } 
    }

    const valideerEindDatumLaatsteDatumLaden = Valideren.valideerVoerEinddatumLaaddatum(Vki, index + 1);
    if(valideerEindDatumLaatsteDatumLaden !== '' && valideerEindDatumLaatsteDatumLaden !== undefined  && valideerEindDatumLaatsteDatumLaden !== null && !isEindDatumError){
      handleDateValidation(setEindDatumErrorObject, valideerEindDatumLaatsteDatumLaden as string, true, index);
      isEindDatumError = true;
    } else {
      if(!isEindDatumError) {
        handleDateValidation(setEindDatumErrorObject, '', false, index);
        isEindDatumError = false;  
      } 
    }
  };

  const handleDateValidation = (setErrorObject: React.Dispatch<React.SetStateAction<IValidationObject[]>>, errorMessage: string, isError: boolean, index: number) => {
    setErrorObject((prevErrorObjectArray: IValidationObject[]) => {
      const updatedErrorObjectArray: IValidationObject[] = [...prevErrorObjectArray];
      updatedErrorObjectArray[index] = {
        ...updatedErrorObjectArray[index],
        isError: isError,
        errorMessage: errorMessage,
      };
      return updatedErrorObjectArray;
    });
  };
  
  const isVoerLeverancierValid = (data: IVKI): void => {
    let isValid: boolean = false;
    let Alteast1ValidItem: boolean= false;
    if (data && data.Voer && data.Voer.length > 0) {
      data.Voer.forEach((voer: IVoer, index: number) => {
        if (voer.SoortVoer && voer.SoortVoer.trim() !== '') {
          if (voer.Coccidiostaticum && voer.Coccidiostaticum.trim() !== '') {
            if (voer.Einddatum !== undefined) {
              const valideerVoerEindDatum: String = Valideren.valideerBeginEindDatumWettelijkwachttermijn(data, index + 1);
              const valideerVoerEinddatumLaaddatum: String = Valideren.valideerVoerEinddatumLaaddatum(data, index + 1);
              const valideerVoerEindDatumSlachtDatum: String = Valideren.valideerVoerEinddatumSlachtDatum(data, index + 1);
              if (valideerVoerEindDatum === '' && valideerVoerEinddatumLaaddatum === '' && valideerVoerEindDatumSlachtDatum === '') {
                isValid = true;
                Alteast1ValidItem = true;
                return; 
              } else {
                
              }
            }
          } else {
            isValid = false;
            return;
          }
        } else {
          isValid = false;
          if(index > 0 && Alteast1ValidItem === true) {
            if(voer.SoortVoer === '' || voer.SoortVoer === undefined) isValid = true;
          }
          return;
        }
      });
    }
  
    // update the validation object..
    setValidationObject((prevValidationObject: IFormValidationObject) => ({
      ...prevValidationObject,
      VoerleverancierDataSection: isValid,
    }));
  };
  
  const handleKeyPress = (event:any) => {
    if (event.key === 'Enter') {
      try
      {
        event.preventDefault(); // Prevent the default behavior of the Enter key
        // You can add any custom logic here if needed
        const form = event.target.form;
        const index = Array.from(form.elements).indexOf(event.target);
        form.elements[index + 1].focus();
      }
      catch(ex){}
    }
  };

  return (
    <div className="default-section-container">
    <h4>{t('VoerLeverancierCaps')}</h4>
      <div className="default-row-container-columns">
        
        {/*@@@@@@@@@@@@@@@@@@@@@@@@@@ SOORT VOER @@@@@@@@@@@@@@@@@@@@@@@@@@*/}

        <div className="default-row-content-columns-data">
          <div className="default-row-content-label">{t('soortvoer')}</div>
          <Select onKeyPress={handleKeyPress} 
            tabIndex={1001}
            inputProps={{ tabIndex: 1001}}
            name={`SoortVoer`}
            disabled={isReadonly}
            className="default-row-content-selectbox"
            value={vkiData && vkiData.Voer && vkiData.Voer[0] && vkiData.Voer[0].SoortVoer && vkiData.Voer[0].SoortVoerExterneId !== undefined ? vkiData.Voer[0].SoortVoerExterneId : ''}
            onChange={(event) => handleSelectChanges(event, 0)}
            size="small"
            style={{ border: (!vkiData || !vkiData.Voer || vkiData.Voer.length === 0 || !vkiData.Voer[0]?.SoortVoerExterneId || vkiData.Voer[0]?.SoortVoerExterneId === '' || vkiData.Voer[0]?.SoortVoerExterneId === undefined) ? '1px solid red' : '' }}
            >
            <MenuItem value={"empty"} style={{textIndent: '-9999px'}}>
              {"Empty"}
            </MenuItem>
            {soortVoerOptions?.map((soortVoer: IVKIFormItem, optionIndex: number) => (
                soortVoer.Taal?.toLowerCase() === intialProps.initialLanguage.toLowerCase() ? (
                  <MenuItem key={optionIndex} value={soortVoer.ExterneId}>
                    {soortVoer.Titel}
                  </MenuItem>
                ) : (
                  null
                )
              ))}
          </Select>
          {!vkiData || !vkiData.Voer || !vkiData.Voer[0] || vkiData.Voer.length === 0 || vkiData.Voer[0]?.SoortVoerExterneId === undefined || vkiData.Voer[0]?.SoortVoerExterneId === '' ? (<FormHelperText error>{t('verplichtVeld')}</FormHelperText>) : null}

          {vkiData && vkiData.Voer && vkiData.Voer[1] ? (
            <React.Fragment>
              <Select onKeyPress={handleKeyPress} 
              tabIndex={1010}
              inputProps={{ tabIndex: 1010}}
                name={`SoortVoer`}
                disabled={isReadonly}
                className="default-row-content-selectbox"
                value={vkiData.Voer[1].SoortVoerExterneId !== undefined ? vkiData.Voer[1].SoortVoerExterneId: ''}
                onChange={(event) => handleSelectChanges(event, 1)}
                size="small"
              >
                <MenuItem value={"empty"} style={{textIndent: '-9999px'}}>
                  {"Empty"}
                </MenuItem>
                {soortVoerOptions?.map((soortVoer: IVKIFormItem, optionIndex: number) => (
                  soortVoer.Taal?.toLowerCase() === intialProps.initialLanguage.toLowerCase() ? (
                    <MenuItem key={optionIndex} value={soortVoer.ExterneId}>
                      {soortVoer.Titel}
                    </MenuItem>
                  ) : (
                    null
                  )
                ))}
              </Select>

            </React.Fragment>
          ) : null}

          {vkiData && vkiData.Voer && vkiData.Voer[2] ? (
            <React.Fragment>
              <Select onKeyPress={handleKeyPress} 
              tabIndex={1020}
              inputProps={{ tabIndex: 1020}}
                name={`SoortVoer`}
                disabled={isReadonly}
                className="default-row-content-selectbox"
                value={vkiData.Voer[2].SoortVoerExterneId !== undefined ? vkiData.Voer[2].SoortVoerExterneId: ''}
                onChange={(event) => handleSelectChanges(event, 2)}
                size="small"
              >
                <MenuItem value={"empty"} style={{textIndent: '-9999px'}}>
                  {"Empty"}
                </MenuItem>
                {soortVoerOptions?.map((soortVoer: IVKIFormItem, optionIndex: number) => (
                  soortVoer.Taal?.toLowerCase() === intialProps.initialLanguage.toLowerCase() ? (
                    <MenuItem key={optionIndex} value={soortVoer.ExterneId}>
                      {soortVoer.Titel}
                    </MenuItem>
                  ) : (
                    null
                  )
                ))}
              </Select>

            </React.Fragment>
          ) : null}

          {vkiData && vkiData.Voer && vkiData.Voer[3] ? (
            <React.Fragment>
              <Select onKeyPress={handleKeyPress} 
              tabIndex={1030}
              inputProps={{ tabIndex: 1030}}
                name={`SoortVoer`}
                disabled={isReadonly}
                className="default-row-content-selectbox"
                value={vkiData.Voer[3].SoortVoerExterneId !== undefined ? vkiData.Voer[3].SoortVoerExterneId: ''}
                onChange={(event) => handleSelectChanges(event, 3)}
                size="small"
              >
                <MenuItem value={"empty"} style={{textIndent: '-9999px'}}>
                  {"Empty"}
                </MenuItem>
                {soortVoerOptions?.map((soortVoer: IVKIFormItem, optionIndex: number) => (
                  soortVoer.Taal?.toLowerCase() === intialProps.initialLanguage.toLowerCase() ? (
                    <MenuItem key={optionIndex} value={soortVoer.ExterneId}>
                      {soortVoer.Titel}
                    </MenuItem>
                  ) : (
                    null
                  )
                ))}
              </Select>

            </React.Fragment>
          ) : null}

          {vkiData && vkiData.Voer && vkiData.Voer[4] ? (
            <React.Fragment>
              <Select onKeyPress={handleKeyPress} 
                tabIndex={1040}
                inputProps={{ tabIndex: 1040}}
                name={`SoortVoer`}
                disabled={isReadonly}
                className="default-row-content-selectbox"
                value={vkiData.Voer[4].SoortVoerExterneId !== undefined ? vkiData.Voer[4].SoortVoerExterneId: ''}
                onChange={(event) => handleSelectChanges(event, 4)}
                size="small"
              >
                <MenuItem value={"empty"} style={{textIndent: '-9999px'}}>
                  {"Empty"}
                </MenuItem>
                {soortVoerOptions?.map((soortVoer: IVKIFormItem, optionIndex: number) => (
                  soortVoer.Taal?.toLowerCase() === intialProps.initialLanguage.toLowerCase() ? (
                    <MenuItem key={optionIndex} value={soortVoer.ExterneId}>
                      {soortVoer.Titel}
                    </MenuItem>
                  ) : (
                    null
                  )
                ))}
              </Select>

            </React.Fragment>
          ) : null}
        </div>

        {/*@@@@@@@@@@@@@@@@@@@@@@@@@@ Coccidiostaticum @@@@@@@@@@@@@@@@@@@@@@@@@@*/}

        <div className="default-row-content-columns-data">
          <div className="default-row-content-label">{t('Coccidiostaticum')}</div>
          <Select onKeyPress={handleKeyPress} 
            tabIndex={1002}
            inputProps={{ tabIndex: 1002}}
            name={`CoccidiostaticumExterneId`}
            disabled={isReadonly}
            value={vkiData && vkiData.Voer && vkiData.Voer[0] && vkiData.Voer[0].CoccidiostaticumExterneId && vkiData.Voer[0].CoccidiostaticumExterneId !== undefined ? vkiData.Voer[0].CoccidiostaticumExterneId : ''}
            className={vkiData && vkiData.Voer && vkiData?.Voer[0].SoortVoer !== undefined && vkiData?.Voer[0].SoortVoer !== '' && ((!vkiData?.Voer[0]?.Coccidiostaticum || vkiData?.Voer[0]?.Coccidiostaticum === '' || vkiData?.Voer[0]?.Coccidiostaticum === undefined)) ? 'coccidiostaticum-selectbox-error' : 'default-row-content-selectbox'}
            size="small"
            onChange={(event) => handleSelectChanges(event, 0)}
            style={{border:vkiData?.Voer && vkiData?.Voer.length !== 0 && vkiData?.Voer[0] && vkiData?.Voer[0].SoortVoer !== undefined  && vkiData?.Voer[0].SoortVoer !== null && vkiData?.Voer[0].SoortVoer !== '' && ((!vkiData?.Voer[0]?.Coccidiostaticum || vkiData?.Voer[0]?.Coccidiostaticum === '' || vkiData?.Voer[0]?.Coccidiostaticum === undefined)) ? '1px solid red' : ''}}
          >
            <MenuItem value={"emptyCoccidostaticum"} style={{textIndent: '-9999px'}}>
              {"Empty"}
            </MenuItem>
            {SoortVoerToepasbaarheidsFilter?.map((coccidiostaticum: IVKIFormItem, optionIndex: number) => (
                coccidiostaticum.Taal?.toLowerCase() === intialProps.initialLanguage.toLowerCase() ? (
                  <MenuItem key={optionIndex} value={coccidiostaticum.ExterneId}>
                    {coccidiostaticum.Titel}
                  </MenuItem>
                ) : (
                  null
                )
              ))}
          </Select>
          {vkiData?.Voer && vkiData?.Voer.length !== 0 && vkiData?.Voer[0] && vkiData?.Voer[0].SoortVoer !== undefined   && vkiData?.Voer[0].SoortVoer !== null && vkiData?.Voer[0].SoortVoer !== '' && ((!vkiData?.Voer[0]?.Coccidiostaticum || vkiData?.Voer[0]?.Coccidiostaticum === '' || vkiData?.Voer[0]?.Coccidiostaticum === undefined) ? (<FormHelperText className='default-row-content-selectbox-error-coccidiostaticum' error>{t('verplichtVeld')}</FormHelperText>) : null)}
          {vkiData?.Voer && vkiData?.Voer.length !== 0 && vkiData?.Voer[1] ? (
            <React.Fragment>
              <Select onKeyPress={handleKeyPress} 
                tabIndex={1012}
                inputProps={{ tabIndex: 1012}}
                name={`CoccidiostaticumExterneId`}
                disabled={isReadonly}
                value={vkiData && vkiData.Voer[1].CoccidiostaticumExterneId  && vkiData.Voer[1].CoccidiostaticumExterneId !== undefined ? vkiData.Voer[1].CoccidiostaticumExterneId : ''}
                className={vkiData?.Voer[1].SoortVoer !== undefined && vkiData?.Voer[1].SoortVoer !== '' && ((!vkiData?.Voer[1]?.Coccidiostaticum || vkiData?.Voer[1]?.Coccidiostaticum === '' || vkiData?.Voer[1]?.Coccidiostaticum === undefined)) ? 'coccidiostaticum-selectbox-error' : 'default-row-content-selectbox'}
                size="small"
                onChange={(event) => handleSelectChanges(event, 1)}
                style={{border:vkiData?.Voer[1].SoortVoer !== undefined && vkiData?.Voer[1].SoortVoer !== ''  && vkiData?.Voer[1].SoortVoer !== null  && vkiData?.Voer[1].SoortVoer !== null && ((!vkiData?.Voer[1]?.Coccidiostaticum || vkiData?.Voer[1]?.Coccidiostaticum === '' || vkiData?.Voer[1]?.Coccidiostaticum === undefined)) ? '1px solid red' : ''}}
                >
                  <MenuItem value={"emptyCoccidostaticum"} style={{textIndent: '-9999px'}}>
                  {"Empty"}
                </MenuItem>
                {SoortVoerToepasbaarheidsFilter1?.map((coccidiostaticum: IVKIFormItem, optionIndex: number) => (
                coccidiostaticum.Taal?.toLowerCase() === intialProps.initialLanguage.toLowerCase() ? (
                  <MenuItem key={optionIndex} value={coccidiostaticum.ExterneId}>
                    {coccidiostaticum.Titel}
                  </MenuItem>
                ) : (
                  null
                )
              ))}
              </Select>
              {vkiData?.Voer[1].SoortVoer !== undefined && vkiData?.Voer[1].SoortVoer !== '' && vkiData?.Voer[1].SoortVoer !== null && ((!vkiData?.Voer[1]?.Coccidiostaticum || vkiData?.Voer[1]?.Coccidiostaticum === '' || vkiData?.Voer[1]?.Coccidiostaticum === undefined) ? (<FormHelperText className='default-row-content-selectbox-error-coccidiostaticum' error>{t('verplichtVeld')}</FormHelperText>) : null)}
            </React.Fragment>
          ) : null}
          {vkiData?.Voer && vkiData?.Voer.length !== 0 && vkiData?.Voer[2] ? (
            <React.Fragment>
              <Select onKeyPress={handleKeyPress} 
                tabIndex={1022}
                inputProps={{ tabIndex: 1022}}
                name={`CoccidiostaticumExterneId`}
                disabled={isReadonly}
                value={vkiData && vkiData.Voer[2].CoccidiostaticumExterneId  && vkiData.Voer[2].CoccidiostaticumExterneId !== undefined ? vkiData.Voer[2].CoccidiostaticumExterneId : ''}
                className={vkiData?.Voer[2].SoortVoer !== undefined && vkiData?.Voer[2].SoortVoer !== '' && vkiData?.Voer[2].SoortVoer !== null && ((!vkiData?.Voer[2]?.Coccidiostaticum || vkiData?.Voer[2]?.Coccidiostaticum === '' || vkiData?.Voer[2]?.Coccidiostaticum === undefined)) ? 'coccidiostaticum-selectbox-error' : 'default-row-content-selectbox'}
                size="small"
                onChange={(event) => handleSelectChanges(event, 2)}
                style={{border:vkiData?.Voer[2].SoortVoer !== undefined && vkiData?.Voer[2].SoortVoer !== ''&& vkiData?.Voer[2].SoortVoer !== null && ((!vkiData?.Voer[2]?.Coccidiostaticum || vkiData?.Voer[2]?.Coccidiostaticum === '' || vkiData?.Voer[2]?.Coccidiostaticum === undefined)) ? '1px solid red' : ''}}
              >
                <MenuItem value={"emptyCoccidostaticum"} style={{textIndent: '-9999px'}}>
                  {"Empty"}
                </MenuItem>
                {SoortVoerToepasbaarheidsFilter2?.map((coccidiostaticum: IVKIFormItem, optionIndex: number) => (
                coccidiostaticum.Taal?.toLowerCase() === intialProps.initialLanguage.toLowerCase() ? (
                  <MenuItem key={optionIndex} value={coccidiostaticum.ExterneId}>
                    {coccidiostaticum.Titel}
                  </MenuItem>
                ) : (
                  null
                )
              ))}
              </Select>
              {vkiData?.Voer[2].SoortVoer !== undefined && vkiData?.Voer[2].SoortVoer !== ''  && vkiData?.Voer[2].SoortVoer !== null && ((!vkiData?.Voer[2]?.Coccidiostaticum || vkiData?.Voer[2]?.Coccidiostaticum === '' || vkiData?.Voer[2]?.Coccidiostaticum === undefined) ? (<FormHelperText className='default-row-content-selectbox-error-coccidiostaticum' error>{t('verplichtVeld')}</FormHelperText>) : null)}
            </React.Fragment>
          ) : null}
           {vkiData?.Voer && vkiData?.Voer.length !== 0 && vkiData?.Voer[3] ? (
            <React.Fragment>
              <Select onKeyPress={handleKeyPress} 
                tabIndex={1032}
                inputProps={{ tabIndex: 1032}}
                name={`CoccidiostaticumExterneId`}
                disabled={isReadonly}
                value={vkiData && vkiData.Voer[3].CoccidiostaticumExterneId  && vkiData.Voer[3].CoccidiostaticumExterneId !== undefined ? vkiData.Voer[3].CoccidiostaticumExterneId : ''}
                className={vkiData?.Voer[3].SoortVoer !== undefined && vkiData?.Voer[3].SoortVoer !== '' && vkiData?.Voer[3].SoortVoer !== null && ((!vkiData?.Voer[3]?.Coccidiostaticum || vkiData?.Voer[3]?.Coccidiostaticum === '' || vkiData?.Voer[3]?.Coccidiostaticum === undefined)) ? 'coccidiostaticum-selectbox-error' : 'default-row-content-selectbox'}
                size="small"
                onChange={(event) => handleSelectChanges(event, 3)}
                style={{border:vkiData?.Voer[3].SoortVoer !== undefined && vkiData?.Voer[3].SoortVoer !== '' && vkiData?.Voer[3].SoortVoer !== null && ((!vkiData?.Voer[3]?.Coccidiostaticum || vkiData?.Voer[3]?.Coccidiostaticum === '' || vkiData?.Voer[3]?.Coccidiostaticum === undefined)) ? '1px solid red' : ''}}
              >
                <MenuItem value={"emptyCoccidostaticum"} style={{textIndent: '-9999px'}}>
                  {"Empty"}
                </MenuItem>
                {SoortVoerToepasbaarheidsFilter3?.map((coccidiostaticum: IVKIFormItem, optionIndex: number) => (
                coccidiostaticum.Taal?.toLowerCase() === intialProps.initialLanguage.toLowerCase() ? (
                  <MenuItem key={optionIndex} value={coccidiostaticum.ExterneId}>
                    {coccidiostaticum.Titel}
                  </MenuItem>
                ) : (
                  null
                )
              ))}
              </Select>
              {vkiData?.Voer[3].SoortVoer !== undefined && vkiData?.Voer[3].SoortVoer !== ''&& vkiData?.Voer[3].SoortVoer !== null && ((!vkiData?.Voer[3]?.Coccidiostaticum || vkiData?.Voer[3]?.Coccidiostaticum === '' || vkiData?.Voer[3]?.Coccidiostaticum === undefined) ? (<FormHelperText className='default-row-content-selectbox-error-coccidiostaticum' error>{t('verplichtVeld')}</FormHelperText>) : null)}
            </React.Fragment>
          ) : null}
          {vkiData?.Voer && vkiData?.Voer.length !== 0 && vkiData?.Voer[4] ? (
            <React.Fragment>
              <Select onKeyPress={handleKeyPress} 
                tabIndex={1042}
                inputProps={{ tabIndex: 1042}}
                name={`CoccidiostaticumExterneId`}
                disabled={isReadonly}
                value={vkiData && vkiData.Voer[4].CoccidiostaticumExterneId  && vkiData.Voer[4].CoccidiostaticumExterneId !== undefined ? vkiData.Voer[4].CoccidiostaticumExterneId : ''}
                className={vkiData?.Voer[4].SoortVoer !== undefined && vkiData?.Voer[4].SoortVoer !== '' && ((!vkiData?.Voer[4]?.Coccidiostaticum || vkiData?.Voer[4]?.Coccidiostaticum === '' || vkiData?.Voer[4]?.Coccidiostaticum === undefined)) ? 'coccidiostaticum-selectbox-error' : 'default-row-content-selectbox'}
                size="small"
                onChange={(event) => handleSelectChanges(event, 4)}
                style={{border:vkiData?.Voer[4].SoortVoer !== undefined && vkiData?.Voer[4].SoortVoer !== '' && vkiData?.Voer[4].SoortVoer !== null && ((!vkiData?.Voer[4]?.Coccidiostaticum || vkiData?.Voer[4]?.Coccidiostaticum === '' || vkiData?.Voer[4]?.Coccidiostaticum === undefined)) ? '1px solid red' : ''}}
              >
                <MenuItem value={"emptyCoccidostaticum"} style={{textIndent: '-9999px'}}>
                  {"Empty"}
                </MenuItem>
                {SoortVoerToepasbaarheidsFilter4?.map((coccidiostaticum: IVKIFormItem, optionIndex: number) => (
                coccidiostaticum.Taal?.toLowerCase() === intialProps.initialLanguage.toLowerCase() ? (
                  <MenuItem key={optionIndex} value={coccidiostaticum.ExterneId}>
                    {coccidiostaticum.Titel}
                  </MenuItem>
                ) : (
                  null
                )
              ))}
              </Select>
              {vkiData?.Voer[4].SoortVoer !== undefined && vkiData?.Voer[4].SoortVoer !== ''&& vkiData?.Voer[4].SoortVoer !== null && ((!vkiData?.Voer[4]?.Coccidiostaticum || vkiData?.Voer[4]?.Coccidiostaticum === '' || vkiData?.Voer[4]?.Coccidiostaticum === undefined) ? (<FormHelperText className='default-row-content-selectbox-error-coccidiostaticum' error>{t('verplichtVeld')}</FormHelperText>) : null)}
            </React.Fragment>
          ) : null}
        </div>


        {/*@@@@@@@@@@@@@@@@@@@@@@@@@@ WETTELIJKE WACHTTIJD @@@@@@@@@@@@@@@@@@@@@@@@@@*/}

         <div className="default-row-content-columns-data">
            <div className="default-row-content-label">{t('Wettelijkewachttermijn')}</div>
            <Typography
              variant="body1"
              className="default-row-content-typography"
              component="div"
              style={{ fontWeight: 'bold' }}
            >
              {vkiData && vkiData.Voer && vkiData.Voer[0] && vkiData.Voer[0].WettelijkeWachttermijn ? vkiData.Voer[0].WettelijkeWachttermijn+ ' ' + t("Dagen") : '0 '+ t("Dagen") }
            </Typography>
            { vkiData && vkiData.Voer && vkiData.Voer[1] ? 
                 <Typography
                 variant="body1"
                 className="default-row-content-typography"
                 component="div"
                 style={{ fontWeight: 'bold' }}
               >
                 {vkiData && vkiData.Voer[1] && vkiData.Voer[1].WettelijkeWachttermijn ? vkiData.Voer[1].WettelijkeWachttermijn+ ' ' + t("Dagen") : '0 '+ t("Dagen")}
               </Typography>
            : null }
            { vkiData && vkiData.Voer && vkiData.Voer[2] ? 
                <Typography
                variant="body1"
                className="default-row-content-typography"
                component="div"
                style={{ fontWeight: 'bold' }}
              >
                {vkiData && vkiData.Voer[2] && vkiData.Voer[2].WettelijkeWachttermijn ? vkiData.Voer[2].WettelijkeWachttermijn+ ' ' + t("Dagen") : '0 '+ t("Dagen")}
              </Typography>
            : null }
            { vkiData && vkiData.Voer && vkiData.Voer[3] ? 
                 <Typography
                 variant="body1"
                 className="default-row-content-typography"
                 component="div"
                 style={{ fontWeight: 'bold' }}
               >
                 {vkiData && vkiData.Voer[3] && vkiData.Voer[3].WettelijkeWachttermijn ? vkiData.Voer[3].WettelijkeWachttermijn+ ' ' + t("Dagen") : '0 '+ t("Dagen")}
               </Typography>
            : null }
            { vkiData && vkiData.Voer && vkiData.Voer[4] ? 
                 <Typography
                 variant="body1"
                 className="default-row-content-typography"
                 component="div"
                 style={{ fontWeight: 'bold' }}
               >
                 {vkiData && vkiData.Voer[4] && vkiData.Voer[4].WettelijkeWachttermijn ? vkiData.Voer[4].WettelijkeWachttermijn+ ' ' + t("Dagen") : '0 '+ t("Dagen")}
               </Typography>
            : null }
        </div>

        {/*@@@@@@@@@@@@@@@@@@@@@@@@@@ COLUMN BeginDatum @@@@@@@@@@@@@@@@@@@@@@@@@@*/}

        <div className="default-row-content-columns-data">
            <div className="default-row-content-label">{t('begindatum')} {t('totEnMet')}</div>
            <CustomDatePicker readOnly={isReadonly} datePickerClassName='datepickerWidth270px' name="Begindatum" className="VoerDateFields" label={''} value={vkiData && vkiData.Voer &&  vkiData.Voer[0] && vkiData?.Voer[0].Begindatum ? vkiData.Voer[0].Begindatum: null} onChange={handleDateChange} size="small" isError={BeginDatumErrorObject[0].isError} errorText={BeginDatumErrorObject[0].errorMessage} hideBorders={true} index={0}/>
            {/* <TextField onKeyPress={handleKeyPress} 
                tabIndex={1003}
                inputProps={{ inputcomponent: inputComponent, tabIndex: 1003}}
              name={`Begindatum`}
              type="date"
              InputProps={{
                readOnly: isReadonly,
              }}
              value={vkiData && vkiData.Voer &&  vkiData.Voer[0] && vkiData?.Voer[0].Begindatum ?  DateUtil.FormatDateToString(vkiData.Voer[0].Begindatum) : undefined}
              className={BeginDatumErrorObject[0].isError ? "default-row-content-date-input-setError" : "default-row-content-date-input"}
              size="small"
              onChange={(event) => handleDateChange(event, 0)}
              error={BeginDatumErrorObject[0].isError}
              helperText={BeginDatumErrorObject[0].isError ?  BeginDatumErrorObject[0].errorMessage : ''}
            /> */}
            { vkiData && vkiData.Voer && vkiData.Voer[1] ? 
              //   <TextField onKeyPress={handleKeyPress} 
              //   tabIndex={1013}
              //   inputProps={{ inputcomponent: inputComponent, tabIndex: 1013}}
              //   name={`Begindatum`}
              //   type="date"
              //   InputProps={{
              //     readOnly: isReadonly,
              //   }}
              //   value={vkiData?.Voer[1] && vkiData.Voer[1] && vkiData?.Voer[1].Begindatum ?  DateUtil.FormatDateToString(vkiData.Voer[1].Begindatum) : undefined}
              //   className={BeginDatumErrorObject[1].isError ? "default-row-content-date-input-setError" : "default-row-content-date-input"}
              //   size="small"
              //   onChange={(event) => handleDateChange(event, 1)}
              //   error={BeginDatumErrorObject[1].isError}
              //   helperText={BeginDatumErrorObject[1].isError ?  BeginDatumErrorObject[1].errorMessage : ''}
              // />
              <CustomDatePicker readOnly={isReadonly}  datePickerClassName='datepickerWidth270px' name="Begindatum" className="VoerDateFields" label={''} value={vkiData && vkiData.Voer &&  vkiData.Voer[1] && vkiData?.Voer[1].Begindatum ? vkiData.Voer[1].Begindatum: null} onChange={handleDateChange} size="small" isError={BeginDatumErrorObject[1].isError} errorText={BeginDatumErrorObject[1].errorMessage} hideBorders={true} index={1}/>
            : null }
            { vkiData && vkiData.Voer && vkiData.Voer[2] ? 
              //   <TextField onKeyPress={handleKeyPress} 
              //   tabIndex={1023}
              //   inputProps={{inputcomponent: inputComponent, tabIndex: 1023}}
              //   name={`Begindatum`}
              //   type="date"
              //   InputProps={{
              //     readOnly: isReadonly,
              //   }}
              //   value={vkiData?.Voer[2] && vkiData.Voer[2] && vkiData?.Voer[2].Begindatum ?  DateUtil.FormatDateToString(vkiData.Voer[2].Begindatum) : undefined}
              //   className={BeginDatumErrorObject[2].isError ? "default-row-content-date-input-setError" : "default-row-content-date-input"}
              //   size="small"
              //   onChange={(event) => handleDateChange(event, 2)}
              //   error={BeginDatumErrorObject[2].isError}
              //   helperText={BeginDatumErrorObject[2].isError ?  BeginDatumErrorObject[2].errorMessage : ''}
              // />
              <CustomDatePicker readOnly={isReadonly}  datePickerClassName='datepickerWidth270px' name="Begindatum" className="VoerDateFields" label={''} value={vkiData && vkiData.Voer &&  vkiData.Voer[2] && vkiData?.Voer[2].Begindatum ? vkiData.Voer[2].Begindatum: null} onChange={handleDateChange} size="small" isError={BeginDatumErrorObject[2].isError} errorText={BeginDatumErrorObject[2].errorMessage} hideBorders={true} index={2}/>
            : null }
            { vkiData && vkiData.Voer && vkiData.Voer[3] ? 
            //   <TextField onKeyPress={handleKeyPress} 
            //   tabIndex={1033}
            //   inputProps={{inputcomponent: inputComponent, tabIndex: 1033}}
            //   name={`Begindatum`}
            //   type="date"
            //   InputProps={{
            //     readOnly: isReadonly,
            //   }}
            //   value={vkiData?.Voer[3] && vkiData.Voer[3] && vkiData?.Voer[3].Begindatum ?  DateUtil.FormatDateToString(vkiData.Voer[3].Begindatum) : undefined}
            //   className={BeginDatumErrorObject[3].isError ? "default-row-content-date-input-setError" : "default-row-content-date-input"}
            //   size="small"
            //   onChange={(event) => handleDateChange(event, 3)}
            //   error={BeginDatumErrorObject[3].isError}
            //   helperText={BeginDatumErrorObject[3].isError ?  BeginDatumErrorObject[3].errorMessage : ''}
            // />
            <CustomDatePicker readOnly={isReadonly}  datePickerClassName='datepickerWidth270px' name="Begindatum" className="VoerDateFields" label={''} value={vkiData && vkiData.Voer &&  vkiData.Voer[3] && vkiData?.Voer[3].Begindatum ? vkiData.Voer[3].Begindatum: null} onChange={handleDateChange} size="small" isError={BeginDatumErrorObject[3].isError} errorText={BeginDatumErrorObject[3].errorMessage} hideBorders={true} index={3}/>
            : null }
            { vkiData && vkiData.Voer && vkiData.Voer[4] ? 
                // <TextField onKeyPress={handleKeyPress} 
                //   tabIndex={1043}
                //   inputProps={{ inputcomponent: inputComponent,tabIndex: 1043}}
                //   name={`Begindatum`}
                //   type="date"
                //   InputProps={{
                //     readOnly: isReadonly,
                //   }}
                //   value={vkiData?.Voer[4] && vkiData.Voer[4] && vkiData?.Voer[4].Begindatum ?  DateUtil.FormatDateToString(vkiData.Voer[4].Begindatum) : undefined}
                //   className={BeginDatumErrorObject[4].isError ? "default-row-content-date-input-setError" : "default-row-content-date-input"}
                //   size="small"
                //   onChange={(event) => handleDateChange(event, 4)}
                //   error={BeginDatumErrorObject[4].isError}
                //   helperText={BeginDatumErrorObject[4].isError ?  BeginDatumErrorObject[4].errorMessage : ''}
                // />
                <CustomDatePicker readOnly={isReadonly}  datePickerClassName='datepickerWidth270px' name="Begindatum" className="VoerDateFields" label={''} value={vkiData && vkiData.Voer &&  vkiData.Voer[4] && vkiData?.Voer[4].Begindatum ? vkiData.Voer[4].Begindatum: null} onChange={handleDateChange} size="small" isError={BeginDatumErrorObject[4].isError} errorText={BeginDatumErrorObject[4].errorMessage} hideBorders={true} index={4}/>
            : null }
        </div>    

       {/*@@@@@@@@@@@@@@@@@@@@@@@@@@ COLUMN Einddatum @@@@@@@@@@@@@@@@@@@@@@@@@@*/}

        <div className="default-row-content-columns-data">
            <div className="default-row-content-label">{t('einddatum')}</div>
            <CustomDatePicker readOnly={isReadonly}  datePickerClassName='datepickerWidth270px' name="Einddatum" className="VoerDateFields" label={''} value={vkiData && vkiData.Voer && vkiData.Voer[0] && vkiData?.Voer[0].Einddatum ?  vkiData.Voer[0].Einddatum : null} onChange={handleDateChange} size="small" isError={EindDatumErrorObject[0].isError} errorText={EindDatumErrorObject[0].errorMessage} hideBorders={true} index={0}/>
            {/* 
            <TextField onKeyPress={handleKeyPress} 
                tabIndex={1004}
                inputProps={{inputcomponent: inputComponent, tabIndex: 1004}}
              name={`Einddatum`}
              type="date"
              InputProps={{
                readOnly: isReadonly,
              }}
              value={vkiData && vkiData.Voer && vkiData.Voer[0] && vkiData?.Voer[0].Einddatum ?  DateUtil.FormatDateToString(vkiData.Voer[0].Einddatum) : undefined}
              className={EindDatumErrorObject[0].isError ? "default-row-content-date-input-setError" : "default-row-content-date-input"}
              size="small"
              onChange={(event) => handleDateChange(event, 0)}
              error={EindDatumErrorObject[0].isError}
              helperText={EindDatumErrorObject[0].isError ?  EindDatumErrorObject[0].errorMessage : ''}
            /> */}
            { vkiData && vkiData.Voer && vkiData.Voer[1] ? 
              //   <TextField onKeyPress={handleKeyPress} 
              //   tabIndex={1014}
              //   inputProps={{inputcomponent: inputComponent, tabIndex: 1014}}
              //   name={`Einddatum`}
              //   type="date"
              //   InputProps={{
              //     readOnly: isReadonly,
              //   }}
              //   value={vkiData?.Voer[1] && vkiData.Voer[1] && vkiData?.Voer[1].Einddatum ?  DateUtil.FormatDateToString(vkiData.Voer[1].Einddatum) : undefined}
              //   className={EindDatumErrorObject[1].isError ? "default-row-content-date-input-setError" : "default-row-content-date-input"}
              //   size="small"
              //   onChange={(event) => handleDateChange(event, 1)}
              //   error={EindDatumErrorObject[1].isError}
              //   helperText={EindDatumErrorObject[1].isError ?  EindDatumErrorObject[1].errorMessage : ''}
              // />
                <CustomDatePicker readOnly={isReadonly}  datePickerClassName='datepickerWidth270px' name="Einddatum" className="VoerDateFields" label={''} value={vkiData && vkiData.Voer && vkiData.Voer[1] && vkiData?.Voer[1].Einddatum ?  vkiData.Voer[1].Einddatum : null} onChange={handleDateChange} size="small" isError={EindDatumErrorObject[1].isError} errorText={EindDatumErrorObject[1].errorMessage} hideBorders={true} index={1}/>
            : null }
            { vkiData && vkiData.Voer && vkiData.Voer[2] ? 
              //   <TextField onKeyPress={handleKeyPress} 
              //   tabIndex={1024}
              //   inputProps={{ inputcomponent: inputComponent,tabIndex: 1024}}
              //   name={`Einddatum`}
              //   type="date"
              //   InputProps={{
              //     readOnly: isReadonly,
              //   }}
              //   value={vkiData?.Voer[2] && vkiData.Voer[2] && vkiData?.Voer[2].Einddatum ?  DateUtil.FormatDateToString(vkiData.Voer[2].Einddatum) : undefined}
              //   className={EindDatumErrorObject[2].isError ? "default-row-content-date-input-setError" : "default-row-content-date-input"}
              //   size="small"
              //   onChange={(event) => handleDateChange(event, 2)}
              //   error={EindDatumErrorObject[2].isError}
              //   helperText={EindDatumErrorObject[2].isError ?  EindDatumErrorObject[2].errorMessage : ''}
              // />
              <CustomDatePicker readOnly={isReadonly}  datePickerClassName='datepickerWidth270px' name="Einddatum" className="VoerDateFields" label={''} value={vkiData && vkiData.Voer && vkiData.Voer[2] && vkiData?.Voer[2].Einddatum ?  vkiData.Voer[2].Einddatum : null} onChange={handleDateChange} size="small" isError={EindDatumErrorObject[2].isError} errorText={EindDatumErrorObject[2].errorMessage} hideBorders={true} index={2}/>
            : null }
            { vkiData && vkiData.Voer && vkiData.Voer[3] ? 
            //   <TextField onKeyPress={handleKeyPress} 
            //   tabIndex={1034}
            //   inputProps={{inputcomponent: inputComponent, tabIndex: 1034}}
            //   name={`Einddatum`}
            //   type="date"
            //   InputProps={{
            //     readOnly: isReadonly,
            //   }}
            //   value={vkiData?.Voer[3] && vkiData.Voer[3] && vkiData?.Voer[3].Einddatum ?  DateUtil.FormatDateToString(vkiData.Voer[3].Einddatum) : undefined}
            //   className={EindDatumErrorObject[3].isError ? "default-row-content-date-input-setError" : "default-row-content-date-input"}
            //   size="small"
            //   onChange={(event) => handleDateChange(event, 3)}
            //   error={EindDatumErrorObject[3].isError}
            //   helperText={EindDatumErrorObject[3].isError ?  EindDatumErrorObject[3].errorMessage : ''}
            // />
              <CustomDatePicker readOnly={isReadonly}  datePickerClassName='datepickerWidth270px' name="Einddatum" className="VoerDateFields" label={''} value={vkiData && vkiData.Voer && vkiData.Voer[3] && vkiData?.Voer[3].Einddatum ?  vkiData.Voer[3].Einddatum : null} onChange={handleDateChange} size="small" isError={EindDatumErrorObject[3].isError} errorText={EindDatumErrorObject[3].errorMessage} hideBorders={true} index={3}/>
            : null }
            { vkiData && vkiData.Voer && vkiData.Voer[4] ? 
              //   <TextField onKeyPress={handleKeyPress} 
              //   tabIndex={1044}
              //   inputProps={{ inputcomponent: inputComponent, tabIndex: 1044}}
              //     name={`Einddatum`}
              //     type="date"
              //     InputProps={{
              //       readOnly: isReadonly,
              //     }}
              //     value={vkiData?.Voer[4] && vkiData.Voer[4] && vkiData?.Voer[4].Einddatum ?  DateUtil.FormatDateToString(vkiData.Voer[4].Einddatum) : undefined}
              //     className={EindDatumErrorObject[4].isError ? "default-row-content-date-input-setError" : "default-row-content-date-input"}
              //     size="small"
              //     onChange={(event) => handleDateChange(event, 4)}
              //     error={EindDatumErrorObject[4].isError}
              // helperText={EindDatumErrorObject[4].isError ?  EindDatumErrorObject[4].errorMessage : ''}
              //   />
              <CustomDatePicker readOnly={isReadonly}  datePickerClassName='datepickerWidth270px' name="Einddatum" className="VoerDateFields" label={''} value={vkiData && vkiData.Voer && vkiData.Voer[4] && vkiData?.Voer[4].Einddatum ?  vkiData.Voer[4].Einddatum : null} onChange={handleDateChange} size="small" isError={EindDatumErrorObject[4].isError} errorText={EindDatumErrorObject[4].errorMessage} hideBorders={true} index={4}/>
            : null }
        </div>    
      </div>
    </div>
  );
};

export default VoerLeverancierDataSection;
