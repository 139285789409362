import MenuIcon from '@mui/icons-material/Menu';
import { Box, LinearProgress, Menu, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import camelcaseKeys from 'camelcase-keys';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from "react-router-dom";
import { IVKI } from '../VKI/Interfaces/IVKI';
import VKIService from './Services/VKIService';
import { environment } from './common/env';
import download from 'downloadjs';
import { DataGrid, GridCallbackDetails, GridCellParams, GridColDef, GridRowSelectionModel, GridToolbar, GridTreeNode } from '@mui/x-data-grid';
import { localizedTextsMap } from './common/LocalizedTextMap';
import { AppProps } from './App';
import { IAccountInfo } from 'react-aad-msal';
import VKI from './VKI';

export const Versiebeheer: React.FC<IAccountInfo> = (props:IAccountInfo) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
	const [data, setData] = useState<IVKI[] | null>(null);
  const [groupedData, setGroupedData] = useState<{ [status: string]: IVKI[] }>({});
  const [showAll, setShowAll] = useState<boolean>(false);
  const { id } = useParams();
  
  const fetchData = async (showGoed:boolean) => {

    const response = await fetch(environment.backendBaseUrl + 'VKIOverzicht?vkiid=' + id + '&type=versions&email=' + props.account.idToken.emails[0] + '&environment=' + environment.environment + '&code=' + environment.REACT_APP_FUNCTION_KEY, { headers: { Authorization: 'Bearer ' + props.jwtIdToken } });
    if (response.ok) {
      const jsonData = await response.json();
      const camelCasedData = camelcaseKeys(jsonData, { deep: true, pascalCase: true });
      setData(camelCasedData);


    } else {
      console.error('Error:', response.status);
    }
  };
  
  useEffect(() => {

  
    fetchData(showAll);
  }, []);

  const [searchTerm, setSearchTerm] = useState('');
  const [selectedRow, setSelectedRow] = useState<IVKI | null>(null);
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const filteredData = data?.filter(item =>
      item.Bedrijfslocatie?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item?.Pluimveehouder?.Naam?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item?.Slachthuis?.Naam?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item?.Slachthuis?.Plaats?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item?.Pluimveehouder?.Adres?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.Stalnummer?.toString().includes(searchTerm) ||
      item.Opzetdatum?.toString().includes(searchTerm) ||
      item.Levering?.toString().includes(searchTerm) ||
      item.GewijzigdDoor?.toString().includes(searchTerm) ||
      item.Gewijzigd?.toString().includes(searchTerm) ||
      item.Status.toString().includes(searchTerm)
  );
  

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorEl2, setAnchorEl2] = React.useState<null | HTMLElement>(null);
  const [anchorEl3, setAnchorEl3] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const open2 = Boolean(anchorEl2);
	const options:Intl.DateTimeFormatOptions = { day: '2-digit', month: '2-digit', year: 'numeric' };

const handleClick = (event: React.MouseEvent<HTMLButtonElement>, item: IVKI) => {
  setSelectedRow(item);
  setAnchorEl(event.currentTarget);
};
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick2 = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const handleClose3 = () => {
    setAnchorEl3(null);
  };

  const [sortConfig, setSortConfig] = useState<{ key: string; direction: 'asc' | 'desc' }>({
    key: 'Pluimveehouder.Naam',
    direction: 'asc',
  });

  const sortData = (key: string) => {
    let direction: 'asc' | 'desc' = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const sortedData = filteredData?.sort((a, b) => {
    const key: keyof IVKI = sortConfig.key as keyof IVKI;
    const aValue = a[key];
    const bValue = b[key];
  
    if (aValue && bValue) {
      if (aValue < bValue) {
        return sortConfig.direction === 'asc' ? -1 : 1;
      }
      if (aValue > bValue) {
        return sortConfig.direction === 'asc' ? 1 : -1;
      }
    }
  
    return 0;
  });

  const columns:GridColDef<IVKI>[] = [
    { field: 'Pluimveehouder.Naam', headerName: t('pluimveehouder')?.toString(), width: 200, editable: false, headerClassName: 'headerOverviewVKI',
      valueGetter: (params) => {
       return params.row.Pluimveehouder?.Naam;
      }
    },
    { field: 'Pluimveehouder.Adres', headerName: t('bedrijfslocatie')?.toString(), width: 200, editable: false, headerClassName: 'headerOverviewVKI',
      valueGetter: (params) => {
        return params.row.Pluimveehouder?.Adres;
      }
    },
    { field: 'Stalnummer', headerName: t('stalnummer')?.toString() , width: 150, headerClassName: 'headerOverviewVKI', editable: false }, 
    {
      field: 'Opzetdatum',
      headerClassName: 'headerOverviewVKI',
      headerName: t('opzetdatum')?.toString(),
      width: 150, editable: false,
      renderCell: (params: GridCellParams) => (
        params.value ? new Date(params.value as string).toLocaleDateString('nl-NL') : ''
      ),
    },
    {
      field: 'LaatsteSlachtDatum',
      headerClassName: 'headerOverviewVKI',
      headerName: t('Slachtdatum')?.toString(),
      width: 150, editable: false,
      renderCell: (params: GridCellParams<IVKI, any, any, GridTreeNode>) => (
        params.value ? new Date(params.value as string).toLocaleDateString('nl-NL') : ''
      ),
    },
    { field: 'Slachthuis.Naam', headerName: t('naamvanslachthuis')?.toString(), width: 200, editable: false, 
      headerClassName: 'headerOverviewVKI',
      valueGetter: (params) => {
        return params.row.Slachthuis?.Naam;
      }
    },
    { field: 'Leveringsnummer',headerClassName: 'headerOverviewVKI', headerName: t('levering')?.toString(), width: 120, editable: false },
    { field: 'GewijzigdDoor',headerClassName: 'headerOverviewVKI', headerName: "Gewijzigd door", width: 120, editable: false },
    {
      field: 'Gewijzigd',
      headerClassName: 'headerOverviewVKI',
      headerName: "Gewijzigd",
      width: 150, editable: false,
      renderCell: (params: GridCellParams<IVKI, any, any, GridTreeNode>) => (
        params.value ? new Date(params.value as string).toLocaleDateString('nl-NL') + " " + new Date(params.value as string).toLocaleTimeString('nl-NL') : ''
      ),
    },
    {
      field: 'actions',
      headerClassName: 'headerOverviewVKI',
      headerName: '...',
      width: 400,
      editable: false, 
      renderCell: (params: GridCellParams<IVKI, any, any, GridTreeNode>) => (
        <div>
          <Button className="vkibutton" hidden={params.row.IsVrijgegeven}  variant="contained" color="primary" 
            onClick={() => {
              const token = props.jwtIdToken;
              const downloadUrl = `${environment.backendBaseUrl}DownloadPDF?userid=${props.account.idToken.sub}&concept=true&id=${params.row.Id}&code=${environment.REACT_APP_FUNCTION_KEY}${'&environment=' + environment.environment}`;
  
              const options = {
                headers: {
                  Authorization: `Bearer ${token}`
                }
              };
  
              fetch(downloadUrl, options)
              .then( res => res.blob() )
              .then( blob => {
                let myDate:Date = new Date();
                try{
                  if (params.row.Opzetdatum)
                  {
                    myDate = new Date(params.row.Opzetdatum);
                  }
                }
                catch(error){}
                download(blob, params.row.Pluimveehouder?.Naam + "_" + params.row?.Stalnummer + "_" + myDate.getDate() +  myDate.getMonth() + myDate.getFullYear() + ".pdf");
                });
              }}
             >Concept</Button> &nbsp;
          <Button className="vkibuton" hidden={!params.row.IsVrijgegeven}  variant="contained" color="primary"  onClick={() => {
            const token = props.jwtIdToken;
            const downloadUrl = `${environment.backendBaseUrl}DownloadPDF?userid=${props.account.idToken.sub}&id=${params.row.Id}&code=${environment.REACT_APP_FUNCTION_KEY}${'&environment=' + environment.environment}`;

            const options = {
              headers: {
                Authorization: `Bearer ${token}`
              }
            };

            fetch(downloadUrl, options)
              .then( res => res.blob() )
              .then( blob => {
                let myDate:Date = new Date();
                try{
                  if (params.row.Opzetdatum)
                  {
                    myDate = new Date(params.row.Opzetdatum);
                  }
                }
                catch(error){}
                download(blob, params.row.Pluimveehouder.Naam + "_" + params.row.Stalnummer + "_" + myDate.getDate() +  myDate.getMonth() + myDate.getFullYear() + ".pdf");
              });
            }}
             >PDF</Button> &nbsp;
				<Button className="vkibuton" variant="contained" color="primary" 
        onClick={() => navigate("/VKI/" + params.row.Id)}>{params.row.Status != "Nieuw" &&  params.row.Status != "In bewerking" ? t('vkiSee') : t('edit')}</Button>&nbsp;&nbsp;
       <Button className="vkibuton" variant="contained" color="primary" onClick={() => VKIService.restoreVersion(params.row.Id, props.account.idToken.emails[0], props.account.idToken.sub, props.jwtIdToken).then(()=>{ window.location.reload(); })}>Herstellen</Button>
        </div>
      ),
    },
  ];

  
  return (
    <div className='VKIOverzicht'>
      <h1>Versiebeheer</h1>
      <TextField
        label="Zoeken"
        value={searchTerm}
        onChange={handleSearch}
        variant="outlined"
        style={{ marginBottom: '1rem' }}
      />
      <DataGrid
              getRowId={(row) => row.Id}
              rows={filteredData || []}
              columns={columns}
              unstable_ignoreValueFormatterDuringExport={true}
              pageSizeOptions={[{ value: 100, label: "100" }, { value: 25, label: "25" },  { value: 10, label: "10" }]}
              localeText={localizedTextsMap}
              componentsProps={{
                pagination: {
                  labelRowsPerPage: "Rijen per pagina"
                }
              }}
      />


      <Box hidden={data != null} sx={{ width: '100%' }}> <LinearProgress  /> </Box>

    </div>
  );
};

export default Versiebeheer;