import DeleteIcon from '@mui/icons-material/Delete';
import { Box, LinearProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import camelcaseKeys from 'camelcase-keys';
import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { IVKI } from '../VKI/Interfaces/IVKI';
import VKIService from './Services/VKIService';
import { environment } from './common/env';

export const NewVKI: React.FC<any> = (props:any) => {
  const navigate = useNavigate();
	const [data, setData] = useState<IVKI[]>([]);
  const fetchData = async () => {
    try {
      const response = await fetch(environment.backendBaseUrl + 'VKIOverzicht?type=newform' + '&environment=' + environment.environment + '&email=' + props.accountInfo.account.idToken.emails[0] + '&code=' + environment.REACT_APP_FUNCTION_KEY,{
        headers: {Authorization: 'Bearer ' + props.accountInfo.jwtIdToken}
      });
      if (response.ok) {
      const jsonData = await response.json();
      
      const camelCasedData = camelcaseKeys(jsonData, { deep: true, pascalCase: true });
      setData(camelCasedData);
      } else {
      // Handle error if needed
      
      console.error('Error:', response.status);
      }
    } catch (error) {
      // Handle error if needed
      console.error('Error:', error);
    }
  };
	useEffect(() => {  
	  fetchData();
	}, []);
	
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };
  const filteredData = data.filter(item =>
    item.Bedrijfslocatie?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
    item.Stalnummer?.toString()?.includes(searchTerm) ||
    item.Opzetdatum?.toString()?.includes(searchTerm) ||
    item.Levering?.toString()?.includes(searchTerm) ||
	item.Status?.toString()?.includes(searchTerm)
  );
	const options:Intl.DateTimeFormatOptions = { day: '2-digit', month: '2-digit', year: 'numeric' };
  return (
    <div className='VKIOverzicht'>
		<h1>Nieuw VKI</h1>
      <TextField
        label="Zoeken"
        value={searchTerm}
        onChange={handleSearch}
        variant="outlined"
        style={{ marginBottom: '1rem' }}
      />
      <Button variant="contained" color="primary" className='menuButton' onClick=
        {
          async () => 
          {
            await VKIService.newVki(props.accountInfo.account.idToken.emails[0], props.accountInfo.account.idToken.sub, props.accountInfo.jwtIdToken);
            window.location.reload();
            fetchData();
          }
        }>Nieuw formulier</Button>
        <Box hidden={data.length > 0} sx={{ width: '100%' }}> <LinearProgress  /> </Box>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell><strong>Bedrijfslocatie</strong></TableCell>
              <TableCell><strong>Stalnummer</strong></TableCell>
              <TableCell><strong>Opzetdatum</strong></TableCell>
              <TableCell><strong>Levering</strong></TableCell>
              <TableCell><strong>Status</strong></TableCell>
              <TableCell><strong>Account</strong></TableCell>
			  <TableCell><strong>..</strong></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          
		  {filteredData.map(item => (
              <TableRow key={item.Id}>
                <TableCell>{item.Bedrijfslocatie}</TableCell>
                <TableCell>{item.Stalnummer}</TableCell>
                <TableCell>{item.Opzetdatum != null ? new Date(item.Opzetdatum ).toLocaleDateString('nl-NL', options) : ""}</TableCell>
                <TableCell>{item.Leveringsnummer.toString()}</TableCell>
                <TableCell>{item.Status}</TableCell>
                <TableCell>{item.Account.join(", ")}</TableCell>
				<TableCell>
				<Button variant="contained" color="primary" onClick={() => navigate("/VKI/" + item.Id)}>Bewerken</Button>&nbsp;
        <Button variant="contained" color="primary"  startIcon={<DeleteIcon />} onClick=
        {
          async () => 
          {
            await VKIService.deleteVki(item.Id, props.accountInfo.account.idToken.emails[0], props.accountInfo.account.idToken.sub, props.accountInfo.jwtIdToken);
            window.location.reload();
            fetchData();
          }
        }>Verwijderen</Button>
        
        </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default NewVKI;