import {
  Box,
  Checkbox,
  FormControlLabel,
  LinearProgress,
  MenuItem,
  Select
} from '@mui/material';
import Button from '@mui/material/Button';
import camelcaseKeys from 'camelcase-keys';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { IVKI } from '../../VKI/Interfaces/IVKI';
import VKIService from '../Services/VKIService';
import { environment } from '../common/env';

export const Kopieerfunctie = (props: any) => {
  const [data, setData] = useState<IVKI[]>([]);
  const [selectedItem, setSelectedItem] = useState('');
  const [isBusy, setIsBusy] = useState(false);
  const { t } = useTranslation();
  const fetchData = async () => {

    try {
      const response = await fetch(
        environment.backendBaseUrl +
          'VKIOverzicht?type=kopieerfunctie' +
          '&environment=' + environment.environment + "&email="  + props.accountInfo.account.idTokenClaims.emails[0] + '&code=' + environment.REACT_APP_FUNCTION_KEY,
        {
          headers: { Authorization: 'Bearer ' + props.accountInfo.jwtIdToken },
        }
      );
      if (response.ok) {
        const jsonData = await response.json();

        const camelCasedData = camelcaseKeys(jsonData, { deep: true, pascalCase: true });
        setData(camelCasedData);
      } else {
        // Handle error if needed
      }
    } catch (error) {
      // Handle error if needed
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const options: Intl.DateTimeFormatOptions = { day: '2-digit', month: '2-digit', year: 'numeric' };
  const [voerChecked, setVoerChecked] = useState(true);
  const [vaccinsChecked, setVaccinsChecked] = useState(true);
  const [gezondheidChecked, setGezondheidChecked] = useState(true);
  const [ts, setTs] = useState(true);

  const handleVoerChange = (event:any) => {
    setVoerChecked(event.target.checked);
  };

  const handleVaccinsChange = (event:any) => {
    setVaccinsChecked(event.target.checked);
  };

  const handleGezondheidChange = (event:any) => {
    setGezondheidChecked(event.target.checked);
  };
  const handleTSdChange = (event:any) => {
    setTs(event.target.checked);
  };
  const navigate = useNavigate();
  if (props.rol !== "Boer")
  {
    return <></>;
  }
  return (

    <>
      <strong>{t('copyFunction')}</strong>&nbsp;<br/>
      <FormControlLabel
        control={<Checkbox checked={voerChecked} onChange={handleVoerChange} />}
        label={t('copyFunctionVoer')}
      />
      <FormControlLabel
        control={<Checkbox checked={vaccinsChecked} onChange={handleVaccinsChange} />}
        label={t('copyFunctionVaccins')}
      />
      <FormControlLabel
        control={<Checkbox checked={gezondheidChecked} onChange={handleGezondheidChange} />}
        label={t('copyFunctionGezondheid')}
      />
      <FormControlLabel
        control={<Checkbox checked={ts} onChange={handleTSdChange} />}
        label={t('TypeStrooisel')}
      />
      <Select

        value={selectedItem}
        autoWidth={true}
        onChange={(event) => setSelectedItem(event.target.value)}
        displayEmpty
        size="small"	
        style={{ marginBottom: '1rem' }}
      >
      <MenuItem value="" disabled>
        {t('selecteervki')}
      </MenuItem>
        {data != null && data.length > 0 ? (data.map((item) => (
          <MenuItem key={item.Id} value={item.Id} >
            {`${item.Opzetdatum != null ? new Date(item.Opzetdatum ).toLocaleDateString('nl-NL', options) : ""} - stal:${item.Stalnummer} - lev:${item.Leveringsnummer}`}
          </MenuItem>
        ))) : (<MenuItem disabled></MenuItem>)}
      </Select>&nbsp;
      
      <Button disabled={isBusy} variant="contained" color="primary" onClick={async () => {
        setIsBusy(true);
        await VKIService.kopieer(selectedItem, props.vki.Id, voerChecked, vaccinsChecked, gezondheidChecked, ts, props.accountInfo.jwtIdToken );
        window.location.reload(); //navigate("/VKI/" + window.location.href.split('/VKI/')[1]);
      }}>{t('kopieer')}</Button>
      <Box hidden={!isBusy} sx={{ width: '100%' }}> <LinearProgress  /> </Box>
      &nbsp;&nbsp;&nbsp;&nbsp;
      </>
  );
};

export default Kopieerfunctie;
