import { IVKI } from "../../VKI/Interfaces/IVKI";
import { IVKIFormItem } from "../../VKI/Interfaces/VKIForm";
import { environment } from "../common/env";

export default class VKIService {
  static async saveVki(vki: IVKI, bearer:string, userid: string): Promise<Response> {
    const response = await fetch(environment.backendBaseUrl + 'SaveVki' + '?environment=' + environment.environment+ '&userid=' + userid  + '&code=' + environment.REACT_APP_FUNCTION_KEY, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + bearer
      },
      
      body: JSON.stringify(vki),
    });

    return response;
  }
  static async getVki(id: string, bearer:string, userid:string): Promise<Response> {
    const response = await fetch(environment.backendBaseUrl + 'GetVKI?id=' + id + '&environment=' + environment.environment+ '&userid=' + userid + '&code=' + environment.REACT_APP_FUNCTION_KEY, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + bearer
      }
    });

    return response;
  }
  static async changeLanguage(taal: string, bearer:string, userid:string): Promise<Response> {
    const response = await fetch(environment.backendBaseUrl + 'ChangeLanguage?taal=' + taal + '&environment=' + environment.environment+ '&userid=' + userid + '&code=' + environment.REACT_APP_FUNCTION_KEY, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + bearer
      }
    });

    return response;
  }
  static async goedkeuren(vki:IVKI, userId: string,bearer:string): Promise<Response> {
    const response = await fetch(environment.backendBaseUrl + 'Goedkeuren' + '?environment=' + environment.environment  + '&userid=' + userId + '&id=' + vki.Id + '&code=' + environment.REACT_APP_FUNCTION_KEY, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + bearer
      },
      body: JSON.stringify(vki)
    });

    return response;
  }
  static async restoreVersion(id:string, email:string, userId: string,bearer:string): Promise<Response> {
    const response = await fetch(environment.backendBaseUrl + 'RestoreVersion' + '?environment=' + environment.environment  + '&userid=' + userId  + '&email=' + email + '&id=' + id + '&code=' + environment.REACT_APP_FUNCTION_KEY, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + bearer
      }
    });

    return response;
  }
  static async afkeuren(vki:IVKI, reden:string, userId: string, bearer:string): Promise<Response> {

    const response = await fetch(environment.backendBaseUrl + 'Goedkeuren' + '?environment=' + environment.environment  + '&userid=' + userId  + '&reden=' + reden + '&afkeuren=true&id=' + vki.Id + '&code=' + environment.REACT_APP_FUNCTION_KEY, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + bearer
      },
      body: JSON.stringify(vki)
    });

    return response;
  }
  static async newVki(email:string, userId: string,bearer:string): Promise<Response> {
    const response = await fetch(environment.backendBaseUrl + 'NieuwVKI' + '?environment=' + environment.environment  + '&userid=' + userId + '&email=' + email + '&code=' + environment.REACT_APP_FUNCTION_KEY, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + bearer
      }
    });

    return response;
  }

  static async deleteVki(id:string, email:string, userId: string,bearer:string): Promise<Response> {
    const response = await fetch(environment.backendBaseUrl + 'DeleteVKI' + '?environment=' + environment.environment  + '&userid=' + userId + '&id=' + id + '&email=' + email + '&code=' + environment.REACT_APP_FUNCTION_KEY, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + bearer
      }
    });

    return response;
  }
  static async kopieer(idFrom:string, idTo:string, voer:boolean, vaccin: boolean, gezondheid: boolean, ts: boolean,bearer:string): Promise<Response> {
    const response = await fetch(environment.backendBaseUrl + 'KopieerFunctie' + '?environment=' + environment.environment  + '&idFrom=' + idFrom + '&idTo=' + idTo + '&voer=' + voer+  '&ts=' + ts+ '&vaccin=' + vaccin+ '&gezondheid=' + gezondheid + '&code=' + environment.REACT_APP_FUNCTION_KEY, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + bearer
      }
    });

    return response;
  }

  static async kopieerExtern(vki:string, aantal:string, userId: string, bearer:string): Promise<Response> {
    const response = await fetch(environment.backendBaseUrl + 'KopieerExterneSlachthuis' +  '?environment=' + environment.environment  + '&aantal=' + aantal + '&userid=' + userId + '&vki=' + vki + '&code=' + environment.REACT_APP_FUNCTION_KEY, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + bearer
      }
    });

    return response;
  }
  static async deleteFormItem(list:string, item:IVKIFormItem, bearer:string): Promise<Response> {
    const response = await fetch(environment.backendBaseUrl + 'DeleteFormItem' +  '?environment=' + environment.environment  + '&list=' + list + '&code=' + environment.REACT_APP_FUNCTION_KEY, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + bearer
      },
      body: JSON.stringify(item)
    });
    return response;
  }
  static async syncVkiFormData( bearer:string): Promise<Response> {
    const response = await fetch(environment.backendBaseUrl + 'SyncVkiFormData' +  '?environment=' + environment.environment  + '&code=' + environment.REACT_APP_FUNCTION_KEY, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + bearer
      }
    });
    return response;
  }

  static async addFormItem(list:string, item:IVKIFormItem, bearer:string): Promise<Response> {
    const response = await fetch(environment.backendBaseUrl + 'AddFormItem' +  '?environment=' + environment.environment  + '&list=' + list + '&code=' + environment.REACT_APP_FUNCTION_KEY, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + bearer
      },
      body: JSON.stringify(item)
    });
    return response;
  }

  static async getGroupClaims(bearer:string, email:string): Promise<string[]> {
    const response = await fetch(environment.backendBaseUrl + 'GetGroupClaim' + '?environment=' + environment.environment  + '&email=' + email  +  '&code=' + environment.REACT_APP_FUNCTION_KEY, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + bearer
      }
    });

    return response.json();
  }

  static confirmExit(confirm:true):void {
    if (confirm) {
      window.addEventListener('beforeunload', (event) => {
        
          event.preventDefault();
          event.returnValue = 'You have unsaved changes. Are you sure you want to leave the page?';
        
      });
    }
    else
    {
      window.addEventListener('beforeunload', (event) => {
        event.returnValue = '';
      });
    }
  }
}
  