import MenuIcon from '@mui/icons-material/Menu';
import { Box, LinearProgress, Menu, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import camelcaseKeys from 'camelcase-keys';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import { IVKI } from '../VKI/Interfaces/IVKI';
import VKIService from './Services/VKIService';
import { environment } from './common/env';
import download from 'downloadjs';
import { DataGrid, GridCallbackDetails, GridCellParams, GridColDef, GridRowSelectionModel, GridToolbar, GridTreeNode } from '@mui/x-data-grid';
import { localizedTextsMap } from './common/LocalizedTextMap';

export const Agri: React.FC = (props:any) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
	const [data, setData] = useState<IVKI[] | null>(null);
  const [groupedData, setGroupedData] = useState<{ [status: string]: IVKI[] }>({});
  const [showAll, setShowAll] = useState<boolean>(false);

  const fetchData = async (showGoed:boolean) => {

    if (props.role == "AGRI Lezen")
    {
      showGoed = true;
    }
    else
    {
      showGoed = localStorage.getItem("pagina") === "goedgekeurd";
    }
    console.log(showGoed);
    const response = await fetch(environment.backendBaseUrl + 'VKIOverzicht?showall=' + showGoed + '&type=agri&email=' + props.accountInfo.account.idToken.emails[0] + '&environment=' + environment.environment + '&code=' + environment.REACT_APP_FUNCTION_KEY, { headers: { Authorization: 'Bearer ' + props.accountInfo.jwtIdToken } });
    if (response.ok) {
      const jsonData = await response.json();
      const camelCasedData = camelcaseKeys(jsonData, { deep: true, pascalCase: true });
      setData(camelCasedData);

      // Group data by AgriStatus
      const grouped = camelCasedData.reduce((result: { [status: string]: IVKI[] }, item: IVKI) => {
        const status = item.StatusAgri.toString();
        if (!result[status]) {
          result[status] = [];
        }
        result[status].push(item);
        return result;
      }, {});
      setGroupedData(grouped);
    } else {
      console.error('Error:', response.status);
    }
  };
  
  useEffect(() => {

  
    fetchData(showAll);
  }, []);
	async function GetVKIs()
  {
		try {
		  const response = await fetch(environment.backendBaseUrl + 'VKIOverzicht?showall=' + showAll + '&type=agri&email=' + props.accountInfo.account.idToken.emails[0] + '&environment=' + environment.environment + '&code=' + environment.REACT_APP_FUNCTION_KEY,
      { headers: {Authorization: 'Bearer ' + props.accountInfo.jwtIdToken}}); // Replace with your REST endpoint URL
		  if (response.ok) {
			const jsonData = await response.json();
			
			const camelCasedData = camelcaseKeys(jsonData, { deep: true, pascalCase: true });
			setData(camelCasedData);
		  } else {
			// Handle error if needed
			
			console.error('Error:', response.status);
		  }
		} catch (error) {
		  // Handle error if needed
		  console.error('Error:', error);
		}

  }
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedRow, setSelectedRow] = useState<IVKI | null>(null);
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const filteredData = data?.filter(item =>
      item.LaatsteSlachtDatum?.toString()?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
      item?.Pluimveehouder?.Naam?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
      item?.Slachthuis?.Naam?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
      item?.Slachthuis?.Plaats?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
      item?.Pluimveehouder?.Adres?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
      item?.Stalnummer?.toString()?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
      item?.Risico?.toString()?.replace("true", "ja")?.replace("false", "nee")?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
      item?.Opzetdatum?.toString()?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
      item?.Leveringsnummer?.toString()?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
      item?.StatusNvwa?.toString()?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
      item?.Vzl?.toString()?.replace("true", "ja")?.replace("false", "nee")?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
      item?.RedenAfkeuren?.toLowerCase()?.includes(searchTerm.toLowerCase())
  );
  

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorEl2, setAnchorEl2] = React.useState<null | HTMLElement>(null);
  const [anchorEl3, setAnchorEl3] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const open2 = Boolean(anchorEl2);
	const options:Intl.DateTimeFormatOptions = { day: '2-digit', month: '2-digit', year: 'numeric' };

const handleClick = (event: React.MouseEvent<HTMLButtonElement>, item: IVKI) => {
  setSelectedRow(item);
  setAnchorEl(event.currentTarget);
};
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick2 = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const handleClose3 = () => {
    setAnchorEl3(null);
  };

  const [sortConfig, setSortConfig] = useState<{ key: string; direction: 'asc' | 'desc' }>({
    key: 'Pluimveehouder.Naam',
    direction: 'asc',
  });

  const sortData = (key: string) => {
    let direction: 'asc' | 'desc' = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const sortedData = filteredData?.sort((a, b) => {
    const key: keyof IVKI = sortConfig.key as keyof IVKI;
    const aValue = a[key];
    const bValue = b[key];
  
    if (aValue && bValue) {
      if (aValue < bValue) {
        return sortConfig.direction === 'asc' ? -1 : 1;
      }
      if (aValue > bValue) {
        return sortConfig.direction === 'asc' ? 1 : -1;
      }
    }
  
    return 0;
  });

  const columns:GridColDef<IVKI>[] = [
    { field: 'Pluimveehouder.Naam', headerName: t('pluimveehouder')?.toString(), width: 300, editable: false, headerClassName: 'headerOverviewVKI',
      valueGetter: (params) => {
       return params.row.Pluimveehouder?.Naam;
      }
    },
    { field: 'Stalnummer', headerName: 'Stal' , width: 50, headerClassName: 'headerOverviewVKI', editable: false }, 
    {
      field: 'Opzetdatum',
      headerClassName: 'headerOverviewVKI',
      headerName: t('opzetdatum')?.toString(),
      width: 150, editable: false,
      renderCell: (params: GridCellParams) => (
        params.value ? new Date(params.value as string).toLocaleDateString('nl-NL') : ''
      ),
    },
    {
      field: 'LaatsteSlachtDatum',
      headerClassName: 'headerOverviewVKI',
      headerName: t('Slachtdatum')?.toString(),
      width: 150, editable: false,
      renderCell: (params: GridCellParams<IVKI, any, any, GridTreeNode>) => (
        params.value ? new Date(params.value as string).toLocaleDateString('nl-NL') : ''
      ),
    },
    { field: 'Risico', headerName: t('risico')?.toString(), width: 100, renderCell: (params: GridCellParams<IVKI, any, any, GridTreeNode>) => (params.value ? 'Ja' : 'Nee'), editable: false,headerClassName: 'headerOverviewVKI' },
    { field: 'Slachthuis.Naam', headerName: t('naamvanslachthuis')?.toString(), width: 150, editable: false, 
        headerClassName: 'headerOverviewVKI',
        valueGetter: (params) => {
          return params.row.Slachthuis?.Naam;
        }
      },
    { field: 'Slachthuis.Plaats', headerName: t('plaatsvanslachthuis')?.toString(), width: 150, editable: false, 
      headerClassName: 'headerOverviewVKI',
      valueGetter: (params) => {
        return params.row.Slachthuis?.Plaats;
      }
    },
    { field: 'Leveringsnummer',headerClassName: 'headerOverviewVKI', headerName: t('levering')?.toString(), width: 75, editable: false },
    { field: 'Kipconcept',headerClassName: 'headerOverviewVKI', headerName: "Kipconcept", width: 75, editable: false },
    { field: 'StatusNvwa',headerClassName: 'headerOverviewVKI', headerName: t('nvwastatus')?.toString(), width: 120, editable: false },
    { field: 'Vzl', headerClassName: 'headerOverviewVKI', headerName: t('vzl')?.toString(), width: 100, renderCell: (params: GridCellParams<IVKI, any, any, GridTreeNode>) => (params.value ? 'Ja' : 'Nee'), editable: false },
    { field: 'RedenAfkeuren', headerName:'Reden afkeuren', width: 275, editable: false, headerClassName: 'headerOverviewVKI',
      valueGetter: (params) => {
        return params.row.RedenAfkeuren;
      }
    },
    {
      field: 'actions',
      headerClassName: 'headerOverviewVKI',
      headerName: '...',
      width: 200,
      editable: false, 
      renderCell: (params: GridCellParams<IVKI, any, any, GridTreeNode>) => (
        <div>
          <Button
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            variant="contained"
            color="primary"
            onClick={(event) => handleClick(event, params.row)}
          >
            Acties
          </Button>
          <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open && selectedRow === params.row}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >

        <MenuItem onClick={() => {

      const token = props.accountInfo.jwtIdToken;
      const downloadUrl = `${environment.backendBaseUrl}DownloadPDF?userid=${props.accountInfo.account.idToken.sub}&id=${params.row.Id}&code=${environment.REACT_APP_FUNCTION_KEY}${'&environment=' + environment.environment}`;

      const options = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };

      fetch(downloadUrl, options)
        .then( res => res.blob() )
        .then( blob => {
          let myDate:Date = new Date();
          try{
            if (params.row.Opzetdatum)
            {
              myDate = new Date(params.row.Opzetdatum);
            }
          }
          catch(error){}
          download(blob, params.row.Pluimveehouder.Naam + "_" + params.row.Stalnummer + "_" + myDate.getDate() +  myDate.getMonth() + myDate.getFullYear() + ".pdf");
  });

        }}>PDF</MenuItem>
        <MenuItem onClick={() => { setAnchorEl(null); navigate("/VKI/" + params.row.Id) }}>{(params.row.StatusNvwa == "Goedgekeurd" ? "Bekijken" : "Bekijken")}</MenuItem>
        <MenuItem  onClick={async () => { 
          setAnchorEl(null); 
          if (params.row.StatusNvwa != 'Goedgekeurd'){
            params.row.Vzl = !params.row.Vzl;
            const updatedVki = { ...params.row };
            await VKIService.saveVki(params.row, props.accountInfo.jwtIdToken, props.accountInfo.account.idToken.sub);
            GetVKIs();
          }
        }} hidden={props.role == "AGRI Lezen"}>VZL</MenuItem>
        <MenuItem onClick={async () => { 
          if (params.row.StatusNvwa != 'Goedgekeurd'){
            setAnchorEl(null); 
            params.row.Risico = !params.row.Risico;
            const updatedVki = { ...params.row };
            await VKIService.saveVki(params.row, props.accountInfo.jwtIdToken, props.accountInfo.account.idToken.sub);
            GetVKIs();
          }
        }} hidden={props.role == "AGRI Lezen"}>Risico</MenuItem>
      </Menu>
        </div>
      ),
    },

  ];
  
  return (
    <div className='VKIOverzicht'>
      <TextField
        label="Zoeken"
        value={searchTerm}
        onChange={handleSearch}
        variant="outlined"
        style={{ marginBottom: '1rem' }}
      />
        <Button
        hidden={props.role == "AGRI Lezen" }
        id="basic-button"
        aria-controls={open2 ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open2 ? 'true' : undefined}
        variant="contained" color="primary"
        onClick={handleClick2}
        startIcon={<MenuIcon />}
        className='menuButton'
      >
        Menu
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl2}
        open={open2}
        onClose={handleClose2}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem hidden={props.role == "AGRI Lezen"} onClick={() => {setAnchorEl2(null);  setShowAll(false); localStorage.setItem("pagina", "nieuwvki");fetchData(false);}}>Nieuwe VKI's</MenuItem> 
        <MenuItem hidden={props.role == "AGRI Lezen"} onClick={() => {setAnchorEl2(null);  setShowAll(true); localStorage.setItem("pagina", "goedgekeurd");fetchData(true);}}>Goedgekeurde VKI's</MenuItem> 
        <MenuItem hidden={props.role == "AGRI Lezen"|| props.role == "AGRI Keuren"} onClick={() => { setAnchorEl2(null); navigate("/NewVKI"); }}>Nieuw formulier maken</MenuItem>
        <MenuItem hidden={props.role == "AGRI Lezen" || props.role == "AGRI Keuren"} onClick={() => { setAnchorEl2(null); navigate("/NVWAToevoegen") }}>NVWA gebruiker toevoegen</MenuItem>
        <MenuItem hidden={props.role == "AGRI Lezen" || props.role == "AGRI Keuren"} onClick={() => { setAnchorEl2(null); navigate("/WachtwoordReset") }}>Wachtwoord reset</MenuItem>
        <MenuItem hidden={props.role == "AGRI Lezen" || props.role == "AGRI Keuren"} onClick={() => { setAnchorEl2(null); navigate("/Archief") }}>Archief</MenuItem>
        <MenuItem hidden={props.role == "AGRI Lezen" || props.role == "AGRI Keuren"} onClick={() => { setAnchorEl2(null); navigate("/Beheer") }}>Beheer</MenuItem>
        <MenuItem hidden={props.role == "AGRI Lezen" || props.role == "AGRI Keuren"} onClick={() => { setAnchorEl2(null); navigate("/Instellingen") }}>VKI Instellingen</MenuItem>
      </Menu>
      <DataGrid
              getRowId={(row) => row.Id}
              rows={filteredData || []}
              columns={columns}
              unstable_ignoreValueFormatterDuringExport={true}
              rowHeight={30}
              pageSizeOptions={[{ value: 100, label: "100" }, { value: 25, label: "25" },  { value: 10, label: "10" }]}
              localeText={localizedTextsMap}
              componentsProps={{
                pagination: {
                  labelRowsPerPage: "Rijen per pagina"
                }
              }}
      />


      <Box hidden={data != null} sx={{ width: '100%' }}> <LinearProgress  /> </Box>

    </div>
  );
};

export default Agri;