import TextField from '@material-ui/core/TextField';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IFormSectionProps, IFormValidationObject } from '../../VKI/Interfaces/IForm';
import { IVKI } from '../../VKI/Interfaces/IVKI';
import { NumberUtils } from '../../utils/numbers';
import { CustomDatePicker } from '../CustomDatePicker';
import inputComponent from '../common/InputComponent';
import { Valideren } from '../common/Validatie';

const GeneralFormDataSection: React.FC<IFormSectionProps> = ({ vkiData, accountInfo, onChange, isReadonly, groupClaim, validateObject, setValidationObject}) => {
  const { t } = useTranslation();
  const [isOpzetDatumError, setIsOpzetDatumError] = useState<boolean>(false);
  const [isOpzetDatumErrorString, setIsOpzetDatumErrorString] = useState<string>('');
  const [initialLoad, setInitialLoad] = useState(true);


  // onload component make the nessesairy checks
  useEffect(() => {
      if (vkiData && vkiData.Mortaliteit && initialLoad) {
        const string = Valideren.valideerOpzetdatum(vkiData);
        if(string === '') {
          setIsOpzetDatumError(false);
          setIsOpzetDatumErrorString(string);
        } else {
          setIsOpzetDatumError(true);
          setIsOpzetDatumErrorString(string);
        }
      }

      // set the validation object basis van de valid checks
      
    setInitialLoad(false);
    isGeneralFormValid();
  }, [vkiData]);

  const isGeneralFormValid = (): void => {
    let isValid:boolean = true;
    if (vkiData) {
      const {
        DatumVanInvullen,
      } = vkiData;
  
        if(DatumVanInvullen === undefined) isValid = false;
        // if opzetdatum is filled in do this.. to check if the date is valid
        if(vkiData.Opzetdatum) Valideren.valideerOpzetdatum(vkiData) === '' ? isValid = isValid : isValid = false;
    }

    setValidationObject((prevValidationObject: IFormValidationObject) => ({
      ...prevValidationObject,
      GeneralFormDataSection: isValid,
    }));
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    onChange((prevVkiData: IVKI) => ({
      ...prevVkiData,
      [name]: value,
    }));
  };

  const handleDatePickerChange = (date: Date | null, name:string, index:number) => {
    console.log('date picker on change function', date, 'type of dit ding: ', typeof(date), new Date(date as Date));
  
    if (vkiData) {
      const valideerOpzetDatumstring = Valideren.valideerOpzetdatum(vkiData);
  
      if (valideerOpzetDatumstring === '') {
        setIsOpzetDatumError(false);
        setIsOpzetDatumErrorString(valideerOpzetDatumstring);
      } else {
        setIsOpzetDatumError(true);
        setIsOpzetDatumErrorString(valideerOpzetDatumstring);
      }
    }
  
    onChange((prevVkiData: IVKI) => {
      let updatedData: IVKI = { ...prevVkiData, [name]: undefined };
      if (date !== null) 
      {
       updatedData = { ...prevVkiData, [name]: new Date(date) };
      }

      isGeneralFormValid();

      return updatedData
    });
  
    isGeneralFormValid();
  };
  
  const handleNumberInputChange= (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    
    let formattedNumberValue: number | undefined;
    if(value !== '') {
      formattedNumberValue = NumberUtils.RemoveLeadingZero(Number(value));
    } else {
      formattedNumberValue = undefined;
    }

    onChange((prevVkiData: IVKI) => {
      const updatedData: IVKI = { ...prevVkiData, [name]: formattedNumberValue };

      return updatedData
    });
  }
  const handleKeyPress = (event:any) => {
    if (event.key === 'Enter') {
      try
      {
        event.preventDefault(); // Prevent the default behavior of the Enter key
        // You can add any custom logic here if needed
        const form = event.target.form;
        const index = Array.from(form.elements).indexOf(event.target);
        form.elements[index + 1].focus();
      }
      catch(ex){}
    }
  };

  return (
    <div className="form-header">
      <CustomDatePicker readOnly={true} name="DatumVanInvullen" className="form-header-field-DatumVanInvullen" label={t('datumVanInvullen')} value={vkiData?.DatumVanInvullen !== undefined ? vkiData?.DatumVanInvullen : null } onChange={handleDatePickerChange} isError={vkiData?.DatumVanInvullen === undefined || vkiData?.DatumVanInvullen === null} errorText={ t('verplichtVeld') as string} hideBorders={true} index={0} />
      {/* <TextField onKeyPress={handleKeyPress} 
          inputProps={{
            inputcomponent: inputComponent
          }}
        name="DatumVanInvullen"
        type="date"
        label={t('Invuldatum')}
        InputProps={{
          readOnly: true,
        }}
        value={vkiData && vkiData.DatumVanInvullen ? DateUtil.FormatDateToString(vkiData.DatumVanInvullen) : undefined}
        onChange={handleDateChange}
        className='form-header-field-DatumVanInvullen'
        error={!vkiData?.DatumVanInvullen}
        helperText={!vkiData?.DatumVanInvullen ? t('verplichtVeld') : ''}
        InputLabelProps={{
          className: 'input-label',
          classes: {
            shrink: 'input-label-shrink',
          },
        }}
      /> */}
      {/* Render the error message */}
      <TextField onKeyPress={handleKeyPress} 
          inputProps={{
            inputcomponent: inputComponent
          }}
        name="Volgnummer"
        placeholder="dd-mm-yyyy"
        label={t('volgnummer')}
        InputProps={{
          readOnly: isReadonly,
        }}
        value={vkiData ? vkiData.Volgnummer : ''}
        onChange={handleInputChange}
        className='form-header-field-Volgnummer'
        InputLabelProps={{
          className: 'input-label',
          classes: {
            shrink: 'input-label-shrink',
          },
        }}
      />
      <CustomDatePicker readOnly={isReadonly} name="Opzetdatum" className="form-header-field-Opzetdatum" label={t('opzetdatum')} value={vkiData?.Opzetdatum !== undefined ? vkiData?.Opzetdatum : null } onChange={handleDatePickerChange} isError={vkiData?.Opzetdatum === undefined || vkiData?.Opzetdatum === null || isOpzetDatumError} errorText={vkiData?.Opzetdatum === undefined || vkiData?.Opzetdatum === null ? t('verplichtveld') as string : isOpzetDatumError ? isOpzetDatumErrorString : ''} hideBorders={true} index={0} />
      {/* <TextField onKeyPress={handleKeyPress} 
          inputProps={{
            inputcomponent: inputComponent
          }}
        name="Opzetdatum"
        type="date"
        label={t('opzetdatum')}
        InputProps={{
          readOnly: isReadonly,
        }}
        value={vkiData && vkiData.Opzetdatum ? DateUtil.FormatDateToString(vkiData.Opzetdatum) : undefined}
        onChange={handleDateChange}
        className='form-header-field-Opzetdatum'
        InputLabelProps={{
          className: 'input-label',
          classes: {
            shrink: 'input-label-shrink',
          },
        }}
        error={isOpzetDatumError}
        helperText={isOpzetDatumError ?  isOpzetDatumErrorString : ''}
      /> */}
      <TextField onKeyPress={handleKeyPress} 
        name="Stalnummer"
        label={t('stalnummer')}
        InputProps={{
          readOnly: isReadonly,
        }}
        error={!vkiData?.Stalnummer}
        helperText={!vkiData?.Stalnummer ? t('verplichtVeld') : ''}
        value={vkiData && vkiData.Stalnummer !== undefined ? vkiData.Stalnummer?.toString() : ''}
        onChange={handleNumberInputChange}
        type='number'
        className='form-header-field-Stalnummer'
        InputLabelProps={{
              className: 'input-label', 
              classes: {
                shrink: 'input-label-shrink', 
              },
            }}
      />
      <TextField onKeyPress={handleKeyPress} 
        name="Bedrijfslocatie"
        label={t('bedrijfslocatie')}
        InputProps={{
          readOnly: isReadonly,
        }}
        error={!vkiData?.Bedrijfslocatie}
        helperText={!vkiData?.Bedrijfslocatie ? t('verplichtVeld') : ''}
        value={vkiData ? vkiData.Bedrijfslocatie : ''}
        onChange={handleInputChange}
        className='form-header-field-Bedrijfslocatie'
        InputLabelProps={{
              className: 'input-label', 
              classes: {
                shrink: 'input-label-shrink', 
              },
            }}
      />
    </div>
  );
};

export default GeneralFormDataSection;