import DeleteIcon from '@mui/icons-material/Delete';
import MenuIcon from '@mui/icons-material/Menu';
import { Box, FormControl, Grid, InputLabel, LinearProgress, Menu, MenuItem, Select, SelectChangeEvent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import camelcaseKeys from 'camelcase-keys';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { IVKIForm, IVKIFormItem, IVKIList } from '../VKI/Interfaces/VKIForm';
import VKIService from './Services/VKIService';
import { environment } from './common/env';
import { Visibility } from '@material-ui/icons';

export const Beheer: React.FC<any> = (props:any) => {
  const navigate = useNavigate();
	//const [data, setData] = useState<IVKIForm | undefined>(undefined);
  const [list, setList] = useState<IVKIList>();
  const [data, setData] = useState<IVKIForm>();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { id } = useParams();
  const [formData, setFormData] = useState<IVKIFormItem>({
    Titel: '',
    Volgorde: 0,
    Taal: '',
    Wachttermijn: 0,
    ToepasbaarOpZiekte: '',
    ExterneId: '',
    ToepasbaarOp: [],
    Email: '',
    Praktijk: '',
    Vestigingsplaats: ''
  });

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }));
  };

  const handleButtonClick = async () => {
    if (list?.Titel)
    {
      await VKIService.addFormItem(list.Titel, formData, props.accountInfo.jwtIdToken);
      await fetchData();
    }
    
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  
  const fetchData = async () => {
    try {
      const response = await fetch(environment.backendBaseUrl + 'VKIFormBeheer?type=beheer' + '&environment=' + environment.environment + '&email=' + props.accountInfo.account.idToken.emails[0]  + '&code=' + environment.REACT_APP_FUNCTION_KEY,{
        headers: {Authorization: 'Bearer ' + props.accountInfo.jwtIdToken}
      });
      if (response.ok) {
        const jsonData = await response.json();
        
        const camelCasedData = camelcaseKeys(jsonData, { deep: true, pascalCase: true });
        const data = camelCasedData as IVKIForm
        if (data)
        {
          
          if (!id)
          {
            var strooisellist:IVKIList = { Items : data?.Coccidiostaticum, Titel: "Strooisel"};
            setList(strooisellist);
          }
          else
          {
            var mylist:IVKIList = { Items : ((data as any)[id] as IVKIFormItem[]), Titel: id};
            setList(mylist);
            
          }
          setData(data);
        }
      } else {

      // Handle error if needed      
      console.error('Error:', response.status);
      }
    } catch (error) {
      // Handle error if needed
      console.error('Error:', error);
    }
  };
	useEffect(() => {  
	  fetchData();
	}, []);
	

  if (data && id && id != list?.Titel)
  {
    var mylist:IVKIList = { Items : ((data as any)[id] as IVKIFormItem[]), Titel: id};
    setList(mylist);
  }
  const [searchTerm, setSearchTerm] = useState('');
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };
  if (!list)
  {
    return(<Box sx={{ width: '100%' }}> <LinearProgress  /> </Box>);
  }
  const filteredData = list?.Items?.filter(item =>
    item.Titel?.toLowerCase()?.includes(searchTerm.toLowerCase())
  );
	const options:Intl.DateTimeFormatOptions = { day: '2-digit', month: '2-digit', year: 'numeric' };

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }));
  };

  return (
    <div className='VKIOverzicht'>
		<h1>Beheer</h1>


        <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained" color="primary"
        onClick={handleClick}
        startIcon={<MenuIcon />}
        className='menuButton'
      >
        Menu
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={() => { setAnchorEl(null); navigate("/Beheer/Coccidiostaticum") }}>Coccidiostaticum</MenuItem>
        <MenuItem onClick={() => { setAnchorEl(null); navigate("/Beheer/Geneesmiddel") }}>Geneesmiddel</MenuItem>
        <MenuItem onClick={() => { setAnchorEl(null); navigate("/Beheer/Medicijnen") }}>Medicijnen</MenuItem>
        <MenuItem onClick={() => { setAnchorEl(null); navigate("/Beheer/Methoden") }}>Methoden</MenuItem>
        <MenuItem onClick={() => { setAnchorEl(null); navigate("/Beheer/Ras") }}>Ras</MenuItem>
        <MenuItem onClick={() => { setAnchorEl(null); navigate("/Beheer/SalmonellaStatus") }}>SalmonellaStatus</MenuItem>
        <MenuItem onClick={() => { setAnchorEl(null); navigate("/Beheer/SoortVoer") }}>SoortVoer</MenuItem>
        <MenuItem onClick={() => { setAnchorEl(null); navigate("/Beheer/StrooiselKwaliteit") }}>StrooiselKwaliteit</MenuItem>
        <MenuItem onClick={() => { setAnchorEl(null); navigate("/Beheer/Vaccin") }}>Vaccin</MenuItem>
        <MenuItem onClick={() => { setAnchorEl(null); navigate("/Beheer/Ziekte") }}>Ziekte</MenuItem>
        <MenuItem onClick={() => { setAnchorEl(null); navigate("/Beheer/Ziektebeeld") }}>Ziektebeeld</MenuItem>
        <MenuItem onClick={() => { setAnchorEl(null); VKIService.syncVkiFormData(props.accountInfo.jwtIdToken).then(()=> navigate("/Beheer/Coccidiostaticum")) }}>Start PoultrySoft sync</MenuItem>
      </Menu>
      <div style={ {display: "none"} }>
      <Grid container spacing={1}>
      <Grid item xs={6} sm={3}>
        <TextField
          label="Titel"
          name="Titel"
          value={formData.Titel}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
        />
      </Grid>
      <Grid item xs={2} sm={1}>
        <TextField
          label="Volgorde"
          name="Volgorde"
          type="number"
          value={formData.Volgorde}
          onChange={handleInputChange}
          margin="normal"
        />
      </Grid>
      <Grid item xs={2} sm={1}>
        <FormControl fullWidth margin="normal">
          <InputLabel id="taal-label">Taal</InputLabel>
          <Select
            labelId="taal-label"
            id="taal-select"
            name="Taal"
            value={formData.Taal}
            onChange={handleSelectChange}
          >
            <MenuItem value="NL">NL</MenuItem>
            <MenuItem value="DE">DE</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={6} sm={3}>
        <TextField
          label="Wachttermijn"
          name="Wachttermijn"
          type="number"
          value={formData.Wachttermijn}
          onChange={handleInputChange}
        
          margin="normal"
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Toepasbaar op ziekte"
          name="ToepasbaarOpZiekte"
          value={formData.ToepasbaarOpZiekte}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
        />
      </Grid>
      <Grid item xs={12}>
        <Button variant="contained" color="primary" onClick={handleButtonClick}>
          Opslaan
        </Button>
      </Grid>
    </Grid>
    </div>
    <br/>
    <h1>{list?.Titel}</h1>
    <TextField
        label="Zoeken"
        value={searchTerm}
        onChange={handleSearch}
        variant="outlined"
        style={{ marginBottom: '1rem' }}
      />
        <Box hidden={list !== undefined} sx={{ width: '100%' }}> <LinearProgress  /> </Box>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell><strong>Titel</strong></TableCell>
              <TableCell><strong>Volgorde</strong></TableCell>
              <TableCell><strong>Taal</strong></TableCell>
              <TableCell><strong>Toepasbaarop</strong></TableCell>
              <TableCell><strong>Wachttermijn</strong></TableCell>
			  <TableCell><strong>..</strong></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          
		  {filteredData.map(item => (
              <TableRow key={item.Titel + ":" + item.Taal}>
                <TableCell>{item.Titel}</TableCell>
                <TableCell>{item.Volgorde.toString()}</TableCell>
                <TableCell>{item.Taal}</TableCell>
                <TableCell>{item.ToepasbaarOpZiekte}</TableCell>
                <TableCell>{item.Wachttermijn.toString()}</TableCell>
				<TableCell>
        <Button variant="contained" color="primary" hidden={true}  startIcon={<DeleteIcon />} onClick=
        {
          async () => 
          {
            await VKIService.deleteFormItem(list.Titel, item, props.accountInfo.jwtIdToken);
            window.location.reload();
            fetchData();
          }
        }>Verwijderen</Button>
        
        </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default Beheer;