import TextField from '@material-ui/core/TextField';
import { FormControl, FormHelperText, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { IAccountInfo } from 'react-aad-msal';
import { getInitialProps, useTranslation } from 'react-i18next';
import { IFormValidationObject, IGezondheidSectionProps } from '../../VKI/Interfaces/IForm';
import { IGezondheid, IVKI } from '../../VKI/Interfaces/IVKI';
import { IVKIFormItem } from '../../VKI/Interfaces/VKIForm';
import { NumberUtils } from '../../utils/numbers';
import CustomDatePicker from '../CustomDatePicker';
import { FileUpload } from '../common/FileUpload';
import { Valideren } from '../common/Validatie';
import { NumericFormatCustom } from '../common/CustomNumericFormat';

const GezondheidDataSection: React.FC<IGezondheidSectionProps> = ({ vkiData, vkiForm, accountInfo, onChange, isReadonly, groupClaim, ziekteBeeldOptions, geneesMiddelOptions, validateObject, setValidationObject }) => {
  const { t } = useTranslation();
  const intialProps = getInitialProps();
  const [IsEindDatumError, setIsEindDatumError] = useState<boolean>(false);
  const [IsEindDatumError1, setIsEindDatumError1] = useState<boolean>(false);
  const [IsEindDatumError2, setIsEindDatumError2] = useState<boolean>(false);
  const [IsEindDatumError3, setIsEindDatumError3] = useState<boolean>(false);
  const [IsEindDatumError4, setIsEindDatumError4] = useState<boolean>(false);
  const [IsEindDatumErrorString, setIsEindDatumErrorString] = useState<string>('');
  const [IsEindDatumErrorString1, setIsEindDatumErrorString1] = useState<string>('');
  const [IsEindDatumErrorString2, setIsEindDatumErrorString2] = useState<string>('');
  const [IsEindDatumErrorString3, setIsEindDatumErrorString3] = useState<string>('');
  const [IsEindDatumErrorString4, setIsEindDatumErrorString4] = useState<string>('');
  const [IsHoeveelheidError, setIsHoeveelheidError] = useState<boolean>(false);
  const [IsHoeveelheidError1, setIsHoeveelheidError1] = useState<boolean>(false);
  const [IsHoeveelheidError2, setIsHoeveelheidError2] = useState<boolean>(false);
  const [IsHoeveelheidError3, setIsHoeveelheidError3] = useState<boolean>(false);
  const [IsHoeveelheidError4, setIsHoeveelheidError4] = useState<boolean>(false);
  const [IsHoeveelheidErrorString, setIsHoeveelheidErrorString] = useState<string>('');
  const [IsHoeveelheidErrorString1, setIsHoeveelheidErrorString1] = useState<string>('');
  const [IsHoeveelheidErrorString2, setIsHoeveelheidErrorString2] = useState<string>('');
  const [IsHoeveelheidErrorString3, setIsHoeveelheidErrorString3] = useState<string>('');
  const [IsHoeveelheidErrorString4, setIsHoeveelheidErrorString4] = useState<string>('');

  const [GeneesMiddelenFilteredOptions, SetGeneesMiddelenFilteredOptions] = useState<IVKIFormItem[]>([]);
  const [GeneesMiddelenFilteredOptions1, SetGeneesMiddelenFilteredOptions1] = useState<IVKIFormItem[]>([]);
  const [GeneesMiddelenFilteredOptions2, SetGeneesMiddelenFilteredOptions2] = useState<IVKIFormItem[]>([]);
  const [GeneesMiddelenFilteredOptions3, SetGeneesMiddelenFilteredOptions3] = useState<IVKIFormItem[]>([]);
  const [GeneesMiddelenFilteredOptions4, SetGeneesMiddelenFilteredOptions4] = useState<IVKIFormItem[]>([]);

  console.log(vkiData?.Gezondheid);
  const [totaalgebruikt1, setTotaalgebruikt1] = useState(vkiData?.Gezondheid && vkiData?.Gezondheid.length > 0 && vkiData?.Gezondheid[0].TotaalGebruikteHoeveelheid && vkiData?.Gezondheid[0].TotaalGebruikteHoeveelheid?.toString() !== "0" ?  
    vkiData?.Gezondheid[0].TotaalGebruikteHoeveelheid?.toString()?.replace(".", "~")?.replace(",", ".")?.replace("~", ",") : "0");
  const [totaalgebruikt2, setTotaalgebruikt2] = useState(vkiData?.Gezondheid && vkiData?.Gezondheid.length > 1 && vkiData?.Gezondheid[1].TotaalGebruikteHoeveelheid && vkiData?.Gezondheid[1].TotaalGebruikteHoeveelheid?.toString() !== "0" ?  
  vkiData?.Gezondheid[1].TotaalGebruikteHoeveelheid?.toString()?.replace(".", "~")?.replace(",", ".")?.replace("~", ",") : "0");
  const [totaalgebruikt3, setTotaalgebruikt3] = useState(vkiData?.Gezondheid && vkiData?.Gezondheid.length > 2 && vkiData?.Gezondheid[2].TotaalGebruikteHoeveelheid && vkiData?.Gezondheid[2].TotaalGebruikteHoeveelheid?.toString() !== "0" ?  
  vkiData?.Gezondheid[2].TotaalGebruikteHoeveelheid?.toString()?.replace(".", "~")?.replace(",", ".")?.replace("~", ",") : "0");
  const [totaalgebruikt4, setTotaalgebruikt4] = useState(vkiData?.Gezondheid && vkiData?.Gezondheid.length > 3 && vkiData?.Gezondheid[3].TotaalGebruikteHoeveelheid && vkiData?.Gezondheid[3].TotaalGebruikteHoeveelheid?.toString() !== "0" ?  
  vkiData?.Gezondheid[3].TotaalGebruikteHoeveelheid?.toString()?.replace(".", "~")?.replace(",", ".")?.replace("~", ",") : "0");
  const [totaalgebruikt5, setTotaalgebruikt5] = useState(vkiData?.Gezondheid && vkiData?.Gezondheid.length > 4 && vkiData?.Gezondheid[3].TotaalGebruikteHoeveelheid && vkiData?.Gezondheid[4].TotaalGebruikteHoeveelheid?.toString() !== "0" ?  
  vkiData?.Gezondheid[4].TotaalGebruikteHoeveelheid?.toString()?.replace(".", "~")?.replace(",", ".")?.replace("~", ",") : "0");

  useEffect(() => {
    if (vkiData && vkiData.Gezondheid !== null) {
      if (!vkiData?.Gezondheid[0]) {
        vkiData.Gezondheid[0] = createNewGezondheidObject('Ziektebeeld', '');
      } else if (vkiData.Gezondheid.length > 0) {

        // uncomment om te testen, wettelijkwachttermijn
        // vkiData.Gezondheid[0].WettelijkeWachttermijn = 5;
        // vkiData.Gezondheid[0].Einddatum = new Date('1960-12-09');
        // vkiData.Gezondheid[0].Begindatum = new Date('1960-12-08');
        const lastGezondheidObject = vkiData.Gezondheid[vkiData.Gezondheid.length - 1];
        if (lastGezondheidObject.Ziektebeeld) {
          vkiData.Gezondheid.push(createNewGezondheidObject('Ziektebeeld', ''));
        }
      }

      // Set initial geneesmiddelen for first options.
      if(geneesMiddelOptions && geneesMiddelOptions !== undefined && geneesMiddelOptions !== null && geneesMiddelOptions.length > 0) {
        SetGeneesMiddelenFilteredOptions(geneesMiddelOptions);
        SetGeneesMiddelenFilteredOptions1(geneesMiddelOptions);
        SetGeneesMiddelenFilteredOptions2(geneesMiddelOptions);
        SetGeneesMiddelenFilteredOptions3(geneesMiddelOptions);
        SetGeneesMiddelenFilteredOptions4(geneesMiddelOptions);
      }

      isGezondheidValid(vkiData);
    }
  
  }, [vkiData]);
  
  const handleDateChange = (date: Date | null, name:string, index: number) => {
    onChange((prevVkiData: IVKI) => {
      const updatedVkiData: IVKI = { ...prevVkiData }; // Create a copy of the previous data
  
      const updatedGezondheid = [...prevVkiData.Gezondheid];
      updatedGezondheid[index] = { ...updatedGezondheid[index], [name]: date !== null ? new Date(date) : undefined };
  
      updatedVkiData.Gezondheid = updatedGezondheid;
  
      isGezondheidValid(updatedVkiData);
  
      return {...updatedVkiData};
    });
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
    const { name, value } = event.target;
      
      onChange((prevVkiData: IVKI) => {
        let newValue;
        if(value !== '') {
          let stringnumber:String = value?.replace(".", "~")?.replace(",", ".")?.replace("~", ",");
          console.log(stringnumber);
          var arr = stringnumber.split(',');
  
          if (!isNaN(stringnumber as unknown as number)){
            newValue = NumberUtils.RemoveLeadingZero(Number(stringnumber));
          }
        } else {
          newValue = undefined;
        }
        if (name === 'TotaalGebruikteHoeveelheid' && (index) == 0)
        {
          setTotaalgebruikt1(value);
        }
        if (name === 'TotaalGebruikteHoeveelheid' && (index) == 1)
        {
          setTotaalgebruikt2(value);
        }
        if (name === 'TotaalGebruikteHoeveelheid' && (index) == 2)
        {
          setTotaalgebruikt3(value);
        }
        if (name === 'TotaalGebruikteHoeveelheid' && (index) == 3)
        {
          setTotaalgebruikt4(value);
        }
        if (name === 'TotaalGebruikteHoeveelheid' && (index) == 4)
        {
          setTotaalgebruikt5(value);
        }
        // Create a deep copy of the prevVkiData object
        const updatedPrevVkiData: IVKI = {
          ...prevVkiData,
          Gezondheid: [...prevVkiData.Gezondheid],
        };
    
        // Update the specific Gezondheid item at the given index
        updatedPrevVkiData.Gezondheid[index] = {
          ...updatedPrevVkiData.Gezondheid[index],
          [name]: newValue,
        };
        
        isGezondheidValid(updatedPrevVkiData);
  
        return updatedPrevVkiData;
      });
    };

  const handleNumberInputChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
    const { name, value } = event.target;

    let formattedNumberValue: number | undefined;
    if(value !== '') {
      formattedNumberValue = NumberUtils.RemoveLeadingZero(Number(value));
    } else {
      formattedNumberValue = undefined;
    }

    onChange((prevVkiData: IVKI) => {
      // Create a deep copy of the prevVkiData object
      const updatedPrevVkiData: IVKI = {
        ...prevVkiData,
        Gezondheid: [...prevVkiData.Gezondheid],
      };
  
      // Update the specific Gezondheid item at the given index
      updatedPrevVkiData.Gezondheid[index] = {
        ...updatedPrevVkiData.Gezondheid[index],
        [name]: formattedNumberValue,
      };
      
      isGezondheidValid(updatedPrevVkiData);

      return updatedPrevVkiData;
    });
  };

  const handleFileUpload = (updatedVKI: IVKI) => {
    onChange((prevVkiData: IVKI) => ({
      ...prevVkiData,
      Bijlagen: updatedVKI.Bijlagen,
    }));
  };

  const createNewGezondheidObject = (name:any, value: any): IGezondheid => {
    const newGezondheidItem: IGezondheid = {
      Ziektebeeld: '',
      ZiektebeeldExterneId: '',
      RegnrGebruikteGeneesmiddel: '',
      RegnrGebruikteGeneesmiddelExterneId : '',
      WettelijkeWachttermijn: 0,
      Begindatum: undefined,
      Einddatum: undefined,
      TotaalGebruikteHoeveelheid: 0,
      Maat: '',
      MaatExterneId: ''
    };

    return newGezondheidItem;
  };
  
  const handleSelectChanges = (event: SelectChangeEvent<string>, index: number) => {
    const { value, name } = event.target;
   
    onChange((prevVkiData: IVKI) => {
      const updatedGezondheid = [...prevVkiData.Gezondheid || []];
      if (!prevVkiData.Gezondheid) {
        updatedGezondheid[index] = createNewGezondheidObject(name, value);
      } else {
        updatedGezondheid[index] = { ...updatedGezondheid[index], [name]: value };
      }
      if (index >= 0 && index < updatedGezondheid.length) {
        updatedGezondheid[index] = {
          ...updatedGezondheid[index],
          [name]: value,
        };
      }

      if(name === 'Ziektebeeld'){
        const selectedItemData = ziekteBeeldOptions?.find((ziektebeeldOption) => ziektebeeldOption.ExterneId === value && ziektebeeldOption.Taal?.toLowerCase() === intialProps.initialLanguage.toLowerCase());

        if (selectedItemData !== null && selectedItemData !== undefined) {
          updatedGezondheid[index] = {
            ...updatedGezondheid[index],
            ZiektebeeldExterneId: selectedItemData.ExterneId,
            Ziektebeeld: selectedItemData.Titel,
          };
        }
        if(geneesMiddelOptions) {
          var options = geneesMiddelOptions?.filter((geneesMiddelOption: IVKIFormItem) => geneesMiddelOption.ToepasbaarOpZiekte?.includes(value)).length > 0 ? geneesMiddelOptions?.filter((geneesMiddelOption: IVKIFormItem) => geneesMiddelOption.ToepasbaarOpZiekte?.includes(value)) : geneesMiddelOptions;  
          switch(index) {
            case 0: {
              SetGeneesMiddelenFilteredOptions(options);
              break;
            }
            case 1: {
              SetGeneesMiddelenFilteredOptions1(options);
              break;
            }
            case 2: {
              SetGeneesMiddelenFilteredOptions2(options);
              break;
            }
            case 3: {
              SetGeneesMiddelenFilteredOptions3(options);
              break;
            }
            case 4: {
              SetGeneesMiddelenFilteredOptions4(options);
              break;
            }
            default: { 
              break;
            }
          } 
        }
      }

      if(value === 'emptyZiektebeeld') {
        updatedGezondheid.length = index + 1;
        setEindDatumErrorMessage('', index);
        setHoeveelheidError('', index);
        updatedGezondheid[index] = {
          ...updatedGezondheid[index],
          Ziektebeeld: '',
          ZiektebeeldExterneId: '',
          RegnrGebruikteGeneesmiddel: '',
          RegnrGebruikteGeneesmiddelExterneId : '',
          WettelijkeWachttermijn: 0,
          Begindatum: undefined,
          Einddatum: undefined,
          TotaalGebruikteHoeveelheid: 0,
          Maat: '',
          MaatExterneId: ''
        };
      }
  
      if (index < 4 && index === updatedGezondheid.length - 1 && name === 'Ziektebeeld' && updatedGezondheid.length < 5) {
          if(value !== 'emptyZiektebeeld') updatedGezondheid.push(createNewGezondheidObject(name, undefined));
      }
     
      if (name === 'RegnrGebruikteGeneesmiddel') {
        const selectedItemData = geneesMiddelOptions?.find((geneesMiddelOption) => geneesMiddelOption.ExterneId === value && geneesMiddelOption.Taal?.toLowerCase() === intialProps.initialLanguage.toLowerCase());
        if (selectedItemData !== null && selectedItemData !== undefined) {
          updatedGezondheid[index] = {
            ...updatedGezondheid[index],
            WettelijkeWachttermijn:  value !== 'empty' ? selectedItemData.Wachttermijn.valueOf() : 0,
            RegnrGebruikteGeneesmiddelExterneId: value !== 'empty' ? selectedItemData?.ExterneId : "",
            RegnrGebruikteGeneesmiddel: value !== 'empty' ? selectedItemData?.Titel : "",
          };
        }

        if(value === 'empty') {
          updatedGezondheid[index] = {
            ...updatedGezondheid[index],
            WettelijkeWachttermijn: 0,
            RegnrGebruikteGeneesmiddelExterneId: "",
            RegnrGebruikteGeneesmiddel: "",
          }
        }
      }

      prevVkiData.Gezondheid = updatedGezondheid.map((gezondheidItem: IGezondheid) => {
        if (!gezondheidItem) {
          createNewGezondheidObject(name, value !== 'empty' ? value : undefined);
        }
        return gezondheidItem;
      });

      isGezondheidValid(prevVkiData);

      return prevVkiData;
    });
  };

  const isGezondheidValid = (data: IVKI): void => {
    let isValid: boolean = false;
  
    if (data && data.Gezondheid && data.Gezondheid.length > 0) {
      isValid = true;
  
      data.Gezondheid.forEach((gezondheid: IGezondheid, index: number) => {
        if (gezondheid.Ziektebeeld || (gezondheid.Ziektebeeld && gezondheid.Ziektebeeld.trim() !== '')) {
         
          if (!gezondheid.Begindatum || !gezondheid.Einddatum || gezondheid.TotaalGebruikteHoeveelheid === undefined || gezondheid.TotaalGebruikteHoeveelheid === 0) isValid = false;
          
          let valideerHoeveelheid = Valideren.valideerGezondheidHoeveelheid(data, index + 1, true);
          if(index !== 0 && gezondheid.Ziektebeeld.trim() !== '') {
            setHoeveelheidError(valideerHoeveelheid.toString(), index);
          }
          if (valideerHoeveelheid !== '') {
            isValid = false;
          }

          let gezondheidEinddatum = Valideren.valideerGezondheidEindDatum(data, index + 1, true);
          setEindDatumErrorMessage(gezondheidEinddatum.toString(), index);
          if (gezondheidEinddatum !== '') {
            isValid = false;
          }

          if(gezondheid.Einddatum && gezondheid.Einddatum !== undefined && gezondheid.WettelijkeWachttermijn && gezondheid.WettelijkeWachttermijn > 0 && gezondheidEinddatum === '') {
            const eidDatumWettelijkWachttermijnString = Valideren.valideerGezondheidEindDatumWettelijkWachttermijn(data, index + 1, true);
            setEindDatumErrorMessage(eidDatumWettelijkWachttermijnString.toString(), index);
            if (eidDatumWettelijkWachttermijnString !== '') {
              isValid = false;
            }
          }

          
        }
      });
  
      // Update the validation object
      setValidationObject((prevValidationObject: IFormValidationObject) => ({
        ...prevValidationObject,
        GezondheidDataSection: isValid,
      }));
    }
  };
  
  const setEindDatumErrorMessage = (validationString: string, index: number): void => {
    switch (index) {
      case 0:
        if(validationString !== '') {
          setIsEindDatumError(true);
          setIsEindDatumErrorString(validationString.toString());
        } else {
          setIsEindDatumError(false);
          setIsEindDatumErrorString('');
        }
        break;
      case 1:
        if(validationString !== '') {
          setIsEindDatumError1(true);
          setIsEindDatumErrorString1(validationString.toString());
        } else {
          setIsEindDatumError1(false);
          setIsEindDatumErrorString1('');
        }
        break;
      case 2:
        if(validationString !== '') {
          setIsEindDatumError2(true);
          setIsEindDatumErrorString2(validationString.toString());
        } else {
          setIsEindDatumError2(false);
          setIsEindDatumErrorString2('');
        }
        break;
      case 3:
        if(validationString !== '') {
          setIsEindDatumError3(true);
          setIsEindDatumErrorString3(validationString.toString());
        } else {
          setIsEindDatumError3(false);
          setIsEindDatumErrorString3('');
        }
        break;
      case 4:
        if(validationString !== '') {
          setIsEindDatumError4(true);
          setIsEindDatumErrorString4(validationString.toString());
        } else {
          setIsEindDatumError4(false);
          setIsEindDatumErrorString4('');
        }
        break;
      default:
        break;
    }
  };

  const setHoeveelheidError = (validationString: string, index: number, ): void => {

    switch (index) {
      case 0:
        if(validationString !== '') {
          setIsHoeveelheidError(true);
          setIsHoeveelheidErrorString(validationString.toString());
        } else {
          setIsHoeveelheidError(false);
          setIsHoeveelheidErrorString('');
        }
        break;
      case 1:
        if(validationString !== '') {
          setIsHoeveelheidError1(true);
          setIsHoeveelheidErrorString1(validationString.toString());
        } else {
          setIsHoeveelheidError1(false);
          setIsHoeveelheidErrorString1('');
        }
        break;
      case 2:
        if(validationString !== '') {
          setIsHoeveelheidError2(true);
          setIsHoeveelheidErrorString2(validationString.toString());
        } else {
          setIsHoeveelheidError2(false);
          setIsHoeveelheidErrorString2('');
        }
        break;
      case 3:
        if(validationString !== '') {
          setIsHoeveelheidError3(true);
          setIsHoeveelheidErrorString3(validationString.toString());
        } else {
          setIsHoeveelheidError3(false);
          setIsHoeveelheidErrorString3('');
        }
        break;
      case 4:
        if(validationString !== '') {
          setIsHoeveelheidError4(true);
          setIsHoeveelheidErrorString4(validationString.toString());
        } else {
          setIsHoeveelheidError4(false);
          setIsHoeveelheidErrorString4('');
        }
        break;
      default:
        break;
    }
  };
  const handleKeyPress = (event:any) => {
    if (event.key === 'Enter') {
      try
      {
        event.preventDefault(); // Prevent the default behavior of the Enter key
        // You can add any custom logic here if needed
        const form = event.target.form;
        const index = Array.from(form.elements).indexOf(event.target);
        form.elements[index + 1].focus();
      }
      catch(ex){}
    }
  };


  return (
    <>
      <div className="default-section-container">
      <h4>{t('gezondheidCaps')}</h4>
        <div className="default-row-container-columns" >
          
          {/*@@@@@@@@@@@@@@@@@@@@@@@@@@ COLUMN ZIEKTEBEELD @@@@@@@@@@@@@@@@@@@@@@@@@@*/}

          <div className="default-row-content-columns-data">
            <div className="default-row-content-label">{t('Ziektebeeld')}</div>
            
            
            <Select onKeyPress={handleKeyPress} 
              tabIndex={2001}
              inputProps={{ tabIndex: 2001}}
                name={`Ziektebeeld`}
                disabled={isReadonly}
                value={ vkiData && vkiData.Gezondheid && vkiData.Gezondheid[0] && vkiData.Gezondheid[0].Ziektebeeld ? vkiData.Gezondheid[0].ZiektebeeldExterneId : vkiData?.ZiektebeeldNa?.ExterneId}
                onChange={(event) => handleSelectChanges(event, 0)}
                className="default-row-content-selectbox"
                size="small"
                style={{ border: (!vkiData || !vkiData.Gezondheid || vkiData.Gezondheid.length === 0 || !vkiData.Gezondheid[0]?.Ziektebeeld || vkiData.Gezondheid[0]?.Ziektebeeld === '' || vkiData.Gezondheid[0]?.Ziektebeeld === undefined) ? '' : '' }}
                MenuProps={{
                  anchorEl: document.getElementById('mui-component-select-Ziektebeeld'),
                  transformOrigin: {
                    vertical: 'bottom',
                    horizontal: 'center',
                  },
                }}
              >
                <MenuItem style={{textIndent: '-9999px'}} value="emptyZiektebeeld">
                  {"Empty"}
                </MenuItem>
                {ziekteBeeldOptions?.map((ziekte: IVKIFormItem, index: number) => (
                  ziekte.Taal?.toLowerCase() === intialProps.initialLanguage.toLowerCase() ? (
                    <MenuItem key={index} value={ziekte.ExterneId}>{ziekte.Titel}</MenuItem>
                  ) : (
                    null
                  )
                ))}
              </Select>
              {(!vkiData || !vkiData.Gezondheid || vkiData.Gezondheid.length === 0 || !vkiData.Gezondheid[0]?.Ziektebeeld || vkiData.Gezondheid[0]?.Ziektebeeld === '' || vkiData.Gezondheid[0]?.Ziektebeeld === undefined) ? (<></>) : null}
              {  
                vkiData && vkiData.Gezondheid && vkiData.Gezondheid[1] ? 
                  <Select onKeyPress={handleKeyPress} 
                    tabIndex={2011}
                    inputProps={{ tabIndex: 2011}}
                    name={`Ziektebeeld`}
                    disabled={isReadonly}
                    value={vkiData.Gezondheid[1].Ziektebeeld ? vkiData.Gezondheid[1].ZiektebeeldExterneId : ''}
                    onChange={(event) => handleSelectChanges(event, 1)}
                    className="default-row-content-selectbox"
                    size="small"
                  >
                    <MenuItem style={{textIndent: '-9999px'}} value="emptyZiektebeeld">
                      {"Empty"}
                    </MenuItem>
                    {ziekteBeeldOptions?.map((ziekte: IVKIFormItem, index: number) => (
                      ziekte.Taal?.toLowerCase() === intialProps.initialLanguage.toLowerCase() ? (
                        <MenuItem key={index} value={ziekte.ExterneId}>{ziekte.Titel}</MenuItem>
                      ) : (
                        null
                      )
                    ))}
                  </Select>
              : null }
              {  vkiData && vkiData.Gezondheid && vkiData.Gezondheid[2] ? 
                    <Select onKeyPress={handleKeyPress} 
                    tabIndex={2021}
                    inputProps={{ tabIndex: 2021}}
                    name={`Ziektebeeld`}
                    disabled={isReadonly}
                    value={vkiData.Gezondheid[2].Ziektebeeld ? vkiData.Gezondheid[2].ZiektebeeldExterneId : ''}
                    onChange={(event) => handleSelectChanges(event, 2)}
                    className="default-row-content-selectbox"
                    size="small"
                  >
                    <MenuItem style={{textIndent: '-9999px'}} value="emptyZiektebeeld">
                      {"Empty"}
                    </MenuItem>
                    {ziekteBeeldOptions?.map((ziekte: IVKIFormItem, index: number) => (
                      ziekte.Taal?.toLowerCase() === intialProps.initialLanguage.toLowerCase() ? (
                        <MenuItem key={index} value={ziekte.ExterneId}>{ziekte.Titel}</MenuItem>
                      ) : (
                        null
                      )
                    ))}
                  </Select>
              : null }
              {  vkiData && vkiData.Gezondheid && vkiData.Gezondheid[3] ? 
                    <Select onKeyPress={handleKeyPress} 
                    tabIndex={2031}
                    inputProps={{ tabIndex: 2031}}
                    name={`Ziektebeeld`}
                    disabled={isReadonly}
                    value={vkiData.Gezondheid[3].Ziektebeeld ? vkiData.Gezondheid[3].ZiektebeeldExterneId : ''}
                    onChange={(event) => handleSelectChanges(event, 3)}
                    className="default-row-content-selectbox"
                    size="small"
                  >
                    <MenuItem style={{textIndent: '-9999px'}} value="emptyZiektebeeld">
                      {"Empty"}
                    </MenuItem>
                    {ziekteBeeldOptions?.map((ziekte: IVKIFormItem, index: number) => (
                      ziekte.Taal?.toLowerCase() === intialProps.initialLanguage.toLowerCase() ? (
                        <MenuItem key={index} value={ziekte.ExterneId}>{ziekte.Titel}</MenuItem>
                      ) : (
                        null
                      )
                    ))}
                  </Select>
              : null }
              {  vkiData && vkiData.Gezondheid && vkiData.Gezondheid[4] ? 
                    <Select onKeyPress={handleKeyPress} 
                    tabIndex={2041}
                    inputProps={{ tabIndex: 2041}}
                    name={`Ziektebeeld`}
                    disabled={isReadonly}
                    value={vkiData.Gezondheid[4].Ziektebeeld ? vkiData.Gezondheid[4].ZiektebeeldExterneId : ''}
                    onChange={(event) => handleSelectChanges(event, 4)}
                    className="default-row-content-selectbox"
                    size="small"
                  >
                    <MenuItem style={{textIndent: '-9999px'}} value="emptyZiektebeeld">
                      {"Empty"}
                    </MenuItem>
                    {ziekteBeeldOptions?.map((ziekte: IVKIFormItem, index: number) => (
                      ziekte.Taal?.toLowerCase() === intialProps.initialLanguage.toLowerCase() ? (
                        <MenuItem key={index} value={ziekte.ExterneId}>{ziekte.Titel}</MenuItem>
                      ) : (
                        null
                      )
                    ))}
                  </Select>
              : null }
          </div>
          
          {/*@@@@@@@@@@@@@@@@@@@@@@@@@@ COLUMN GENEESMIDDEL @@@@@@@@@@@@@@@@@@@@@@@@@@*/}

          <div className="default-row-content-columns-data">
            <div className="default-row-content-label">{t('geneesmiddel')}</div>
            <FormControl>
                <Select onKeyPress={handleKeyPress} 
                    tabIndex={2002}
                    inputProps={{ tabIndex: 2002}}
                  name={`RegnrGebruikteGeneesmiddel`}
                  disabled={isReadonly}
                  value={vkiData && vkiData.Gezondheid && vkiData.Gezondheid[0] && vkiData.Gezondheid[0].RegnrGebruikteGeneesmiddel ? vkiData.Gezondheid[0].RegnrGebruikteGeneesmiddelExterneId : vkiData?.GeneesmiddelNa?.ExterneId}
                  onChange={(event) => handleSelectChanges(event, 0)}
                  className={(vkiData && vkiData.Gezondheid && vkiData.Gezondheid[0] && vkiData.Gezondheid[0].Ziektebeeld && (vkiData.Gezondheid[0].RegnrGebruikteGeneesmiddel === undefined || vkiData.Gezondheid[0].RegnrGebruikteGeneesmiddel === null || vkiData.Gezondheid[0].RegnrGebruikteGeneesmiddel === '')) ? "gezondheidGebruiktGeneesmiddelErrorSelectbox": "default-row-content-selectbox"}
                  size="small"
                  style={{ border: (vkiData && vkiData.Gezondheid && vkiData.Gezondheid[0] && vkiData.Gezondheid[0].Ziektebeeld && (vkiData.Gezondheid[0].RegnrGebruikteGeneesmiddel === undefined || vkiData.Gezondheid[0].RegnrGebruikteGeneesmiddel === null || vkiData.Gezondheid[0].RegnrGebruikteGeneesmiddel === '')) ? '1px solid red' : '' }}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left"
                    },
                    transformOrigin: {
                      vertical: "bottom",
                      horizontal: "left"
                    },
                  }}
                >
                <MenuItem value={"empty"} style={{textIndent: '-9999px'}}>
                  {"Empty"}
                </MenuItem>
                  {GeneesMiddelenFilteredOptions?.map((geneesMiddel: IVKIFormItem, index: number) => (
                      geneesMiddel.Taal?.toLowerCase() === intialProps.initialLanguage.toLowerCase() ? (
                        <MenuItem key={index} value={geneesMiddel.ExterneId}>{geneesMiddel.Titel}</MenuItem>
                      ) : (
                        null
                      )
                    ))}
                </Select>
                {vkiData && vkiData.Gezondheid && vkiData.Gezondheid[0] && vkiData.Gezondheid[0].Ziektebeeld && (vkiData.Gezondheid[0].RegnrGebruikteGeneesmiddel === undefined || vkiData.Gezondheid[0].RegnrGebruikteGeneesmiddel === null || vkiData.Gezondheid[0].RegnrGebruikteGeneesmiddel === '')? (<FormHelperText className='gezondheidGebruiktGeneesmiddelError' error>{t('verplichtVeld')}</FormHelperText>) : null}

            </FormControl>


                {  vkiData && vkiData.Gezondheid && vkiData.Gezondheid[1] ? 
                 <FormControl>
                  <Select onKeyPress={handleKeyPress} 
                    tabIndex={2012}
                    inputProps={{ tabIndex: 2012}}
                    name={`RegnrGebruikteGeneesmiddel`}
                    disabled={isReadonly}
                    value={ vkiData && vkiData.Gezondheid[1] && vkiData.Gezondheid[1].RegnrGebruikteGeneesmiddel ? vkiData.Gezondheid[1].RegnrGebruikteGeneesmiddelExterneId : ''}
                    onChange={(event) => handleSelectChanges(event, 1)}
                    className={(vkiData && vkiData.Gezondheid && vkiData.Gezondheid[1] && vkiData.Gezondheid[1].Ziektebeeld && (vkiData.Gezondheid[1].RegnrGebruikteGeneesmiddel === undefined || vkiData.Gezondheid[1].RegnrGebruikteGeneesmiddel === null || vkiData.Gezondheid[1].RegnrGebruikteGeneesmiddel === '')) ? "gezondheidGebruiktGeneesmiddelErrorSelectbox": "default-row-content-selectbox"}
                    size="small"
                    style={{ border: (vkiData && vkiData.Gezondheid && vkiData.Gezondheid[1] && vkiData.Gezondheid[1].Ziektebeeld && (vkiData.Gezondheid[1].RegnrGebruikteGeneesmiddel === undefined || vkiData.Gezondheid[1].RegnrGebruikteGeneesmiddel === null || vkiData.Gezondheid[1].RegnrGebruikteGeneesmiddel === '')) ? '1px solid red' : '' }}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                      },
                      transformOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                      },
                    }}
                  >
                  <MenuItem value={"empty"} style={{textIndent: '-9999px'}}>
                    {"Empty"}
                  </MenuItem>
                  {GeneesMiddelenFilteredOptions1?.map((geneesMiddel: IVKIFormItem, index: number) => (
                      geneesMiddel.Taal?.toLowerCase() === intialProps.initialLanguage.toLowerCase() ? (
                        <MenuItem key={index} value={geneesMiddel.ExterneId}>{geneesMiddel.Titel}</MenuItem>
                      ) : (
                        null
                      )
                    ))}
                  </Select>
                  {vkiData && vkiData.Gezondheid && vkiData.Gezondheid[1] && vkiData.Gezondheid[1].Ziektebeeld && (vkiData.Gezondheid[1].RegnrGebruikteGeneesmiddel === undefined || vkiData.Gezondheid[1].RegnrGebruikteGeneesmiddel === null || vkiData.Gezondheid[1].RegnrGebruikteGeneesmiddel === '')? (<FormHelperText className='gezondheidGebruiktGeneesmiddelError' error>{t('verplichtVeld')}</FormHelperText>) : null}
                </FormControl>
              : null }
              { vkiData && vkiData.Gezondheid && vkiData.Gezondheid[2] ? 
                <FormControl>
                  <Select onKeyPress={handleKeyPress} 
                    tabIndex={2022}
                    inputProps={{ tabIndex: 2022}}
                    name={`RegnrGebruikteGeneesmiddel`}
                    disabled={isReadonly}
                    value={ vkiData && vkiData.Gezondheid[2] && vkiData.Gezondheid[2].RegnrGebruikteGeneesmiddel ? vkiData.Gezondheid[2].RegnrGebruikteGeneesmiddelExterneId : ''}
                    onChange={(event) => handleSelectChanges(event, 2)}
                    className={(vkiData && vkiData.Gezondheid && vkiData.Gezondheid[2] && vkiData.Gezondheid[2].Ziektebeeld && (vkiData.Gezondheid[2].RegnrGebruikteGeneesmiddel === undefined || vkiData.Gezondheid[2].RegnrGebruikteGeneesmiddel === null || vkiData.Gezondheid[2].RegnrGebruikteGeneesmiddel === '')) ? "gezondheidGebruiktGeneesmiddelErrorSelectbox": "default-row-content-selectbox"}
                    size="small"
                    style={{ border: (vkiData && vkiData.Gezondheid && vkiData.Gezondheid[2] && vkiData.Gezondheid[2].Ziektebeeld && (vkiData.Gezondheid[2].RegnrGebruikteGeneesmiddel === undefined || vkiData.Gezondheid[2].RegnrGebruikteGeneesmiddel === null || vkiData.Gezondheid[2].RegnrGebruikteGeneesmiddel === '')) ? '1px solid red' : '' }}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                      },
                      transformOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                      },
                    }}
                  >
                   {GeneesMiddelenFilteredOptions2?.map((geneesMiddel: IVKIFormItem, index: number) => (
                      geneesMiddel.Taal?.toLowerCase() === intialProps.initialLanguage.toLowerCase() ? (
                        <MenuItem key={index} value={geneesMiddel.ExterneId}>{geneesMiddel.Titel}</MenuItem>
                      ) : (
                        null
                      )
                    ))}
                </Select>
                {vkiData && vkiData.Gezondheid && vkiData.Gezondheid[2] && vkiData.Gezondheid[2].Ziektebeeld && (vkiData.Gezondheid[2].RegnrGebruikteGeneesmiddel === undefined || vkiData.Gezondheid[2].RegnrGebruikteGeneesmiddel === null || vkiData.Gezondheid[2].RegnrGebruikteGeneesmiddel === '')? (<FormHelperText className='gezondheidGebruiktGeneesmiddelError' error>{t('verplichtVeld')}</FormHelperText>) : null}
              </FormControl>
              : null }
              { vkiData && vkiData.Gezondheid &&  vkiData.Gezondheid[3] ? 
                <FormControl>
                  <Select onKeyPress={handleKeyPress} 
                    tabIndex={2032}
                    inputProps={{ tabIndex: 2032}}
                    name={`RegnrGebruikteGeneesmiddel`}
                    disabled={isReadonly}
                    value={ vkiData && vkiData.Gezondheid[3] && vkiData.Gezondheid[3].RegnrGebruikteGeneesmiddel ? vkiData.Gezondheid[3].RegnrGebruikteGeneesmiddelExterneId : ''}
                    onChange={(event) => handleSelectChanges(event, 3)}
                    className={(vkiData && vkiData.Gezondheid && vkiData.Gezondheid[3] && vkiData.Gezondheid[3].Ziektebeeld && (vkiData.Gezondheid[3].RegnrGebruikteGeneesmiddel === undefined || vkiData.Gezondheid[3].RegnrGebruikteGeneesmiddel === null || vkiData.Gezondheid[3].RegnrGebruikteGeneesmiddel === '')) ? "gezondheidGebruiktGeneesmiddelErrorSelectbox": "default-row-content-selectbox"}
                    size="small"
                    style={{ border: (vkiData && vkiData.Gezondheid && vkiData.Gezondheid[3] && vkiData.Gezondheid[3].Ziektebeeld && (vkiData.Gezondheid[3].RegnrGebruikteGeneesmiddel === undefined || vkiData.Gezondheid[3].RegnrGebruikteGeneesmiddel === null || vkiData.Gezondheid[3].RegnrGebruikteGeneesmiddel === '')) ? '1px solid red' : '' }}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                      },
                      transformOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                      },
                    }}
                  > 
                  <MenuItem value={"empty"} style={{textIndent: '-9999px'}}>
                    {"Empty"}
                  </MenuItem>
                    {GeneesMiddelenFilteredOptions3?.map((geneesMiddel: IVKIFormItem, index: number) => (
                      geneesMiddel.Taal?.toLowerCase() === intialProps.initialLanguage.toLowerCase() ? (
                        <MenuItem key={index} value={geneesMiddel.ExterneId}>{geneesMiddel.Titel}</MenuItem>
                      ) : (
                        null
                      )
                    ))}
                  </Select>
                  {vkiData && vkiData.Gezondheid && vkiData.Gezondheid[3] && vkiData.Gezondheid[3].Ziektebeeld && (vkiData.Gezondheid[3].RegnrGebruikteGeneesmiddel === undefined || vkiData.Gezondheid[3].RegnrGebruikteGeneesmiddel === null || vkiData.Gezondheid[3].RegnrGebruikteGeneesmiddel === '')? (<FormHelperText className='gezondheidGebruiktGeneesmiddelError' error>{t('verplichtVeld')}</FormHelperText>) : null}
                </FormControl>
              : null }
              { vkiData && vkiData.Gezondheid &&  vkiData.Gezondheid[4] ? 
                <FormControl>
                  <Select onKeyPress={handleKeyPress} 
                    tabIndex={2042}
                    inputProps={{ tabIndex: 2042}}
                    name={`RegnrGebruikteGeneesmiddel`}
                    disabled={isReadonly}
                    value={ vkiData && vkiData.Gezondheid[4] && vkiData.Gezondheid[4].RegnrGebruikteGeneesmiddel ? vkiData.Gezondheid[4].RegnrGebruikteGeneesmiddelExterneId : ''}
                    onChange={(event) => handleSelectChanges(event, 4)}
                    className={(vkiData && vkiData.Gezondheid && vkiData.Gezondheid[4] && vkiData.Gezondheid[4].Ziektebeeld && (vkiData.Gezondheid[4].RegnrGebruikteGeneesmiddel === undefined || vkiData.Gezondheid[4].RegnrGebruikteGeneesmiddel === null || vkiData.Gezondheid[4].RegnrGebruikteGeneesmiddel === '')) ? "gezondheidGebruiktGeneesmiddelErrorSelectbox": "default-row-content-selectbox"}
                    size="small"
                    style={{ border: (vkiData && vkiData.Gezondheid && vkiData.Gezondheid[4] && vkiData.Gezondheid[4].Ziektebeeld && (vkiData.Gezondheid[4].RegnrGebruikteGeneesmiddel === undefined || vkiData.Gezondheid[4].RegnrGebruikteGeneesmiddel === null || vkiData.Gezondheid[4].RegnrGebruikteGeneesmiddel === '')) ? '1px solid red' : '' }}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                      },
                      transformOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                      },
                    }}
                  >
                  <MenuItem value={"empty"} style={{textIndent: '-9999px'}}>
                    {"Empty"}
                  </MenuItem>
                   {GeneesMiddelenFilteredOptions4?.map((geneesMiddel: IVKIFormItem, index: number) => (
                      geneesMiddel.Taal?.toLowerCase() === intialProps.initialLanguage.toLowerCase() ? (
                        <MenuItem key={index} value={geneesMiddel.ExterneId}>{geneesMiddel.Titel}</MenuItem>
                      ) : (
                        null
                      )
                    ))}
                  </Select>
                  {vkiData && vkiData.Gezondheid && vkiData.Gezondheid[4] && vkiData.Gezondheid[4].Ziektebeeld && (vkiData.Gezondheid[4].RegnrGebruikteGeneesmiddel === undefined || vkiData.Gezondheid[4].RegnrGebruikteGeneesmiddel === null || vkiData.Gezondheid[4].RegnrGebruikteGeneesmiddel === '')? (<FormHelperText className='gezondheidGebruiktGeneesmiddelError' error>{t('verplichtVeld')}</FormHelperText>) : null}
                </FormControl>
              : null }
          </div>

          {/*@@@@@@@@@@@@@@@@@@@@@@@@@@ COLUMN WETTELIJK WACHTTERMIJN @@@@@@@@@@@@@@@@@@@@@@@@@@*/}

          <div className="default-row-content-columns-data">
              <div className="default-row-content-label">{t('Wettelijkewachttermijn')}</div>
            
              <Typography
                variant="body1"
                className="default-row-content-typography"
                component="div"
              >
                { vkiData && vkiData.Gezondheid && vkiData.Gezondheid[0] && vkiData.Gezondheid[0].WettelijkeWachttermijn ? vkiData.Gezondheid[0].WettelijkeWachttermijn + ' ' + t("Dagen") : '0 '+ t("Dagen")}
              </Typography>
              {  vkiData && vkiData.Gezondheid && vkiData.Gezondheid[1] ? 
                  <Typography
                  variant="body1"
                  className="default-row-content-typography"
                  component="div"
                >
                  { vkiData && vkiData.Gezondheid[1] && vkiData.Gezondheid[1].WettelijkeWachttermijn ? vkiData.Gezondheid[1].WettelijkeWachttermijn + ' ' + t("Dagen") : '0 '+ t("Dagen") }
                </Typography>
              : null }
              {  vkiData && vkiData.Gezondheid && vkiData.Gezondheid[2] ? 
                  <Typography
                  variant="body1"
                  className="default-row-content-typography"
                  component="div"
                >
                  { vkiData && vkiData.Gezondheid[2] && vkiData.Gezondheid[2].WettelijkeWachttermijn ? vkiData.Gezondheid[2].WettelijkeWachttermijn + ' ' + t("Dagen") : '0 '+ t("Dagen")}
                </Typography>
              : null }
              {  vkiData && vkiData.Gezondheid &&vkiData.Gezondheid[3] ? 
                  <Typography
                  variant="body1"
                  className="default-row-content-typography"
                  component="div"
                >
                  { vkiData && vkiData.Gezondheid[3] && vkiData.Gezondheid[3].WettelijkeWachttermijn ? vkiData.Gezondheid[3].WettelijkeWachttermijn + ' ' + t("Dagen") : '0 '+ t("Dagen")}
                </Typography>
              : null }
              {  vkiData && vkiData.Gezondheid && vkiData.Gezondheid[4] ? 
                  <Typography
                  variant="body1"
                  className="default-row-content-typography"
                  component="div"
                >
                  { vkiData && vkiData.Gezondheid[4] && vkiData.Gezondheid[4].WettelijkeWachttermijn ? vkiData.Gezondheid[4].WettelijkeWachttermijn + ' ' + t("Dagen") : '0 '+ t("Dagen")}
                </Typography>
              : null }
          </div>

        {/*@@@@@@@@@@@@@@@@@@@@@@@@@@ COLUMN BeginDatum @@@@@@@@@@@@@@@@@@@@@@@@@@*/}

          <div className="default-row-content-columns-data">
              <div className="default-row-content-label">{t('begindatum')} {t('totEnMet')}</div>

              {/* /> */}
                <CustomDatePicker datePickerClassName='datepickerWidth140px' readOnly={isReadonly} name="Begindatum" className="gezondheidDateFields" label={''} value={vkiData && vkiData.Gezondheid && vkiData.Gezondheid[0] && vkiData.Gezondheid[0].Begindatum ? vkiData.Gezondheid[0].Begindatum : null} onChange={handleDateChange} size="small" 
                  isError={vkiData &&
                    vkiData.Gezondheid &&
                    vkiData.Gezondheid[0] &&
                    vkiData.Gezondheid[0].Ziektebeeld &&
                    vkiData?.Gezondheid[0].ZiektebeeldExterneId !== vkiData?.ZiektebeeldNa?.ExterneId &&
                    (vkiData.Gezondheid[0].Begindatum === undefined ||
                      vkiData.Gezondheid[0].Begindatum === null)
                      ? true
                      : vkiData?.Gezondheid[0].Begindatum !== undefined && vkiData?.Gezondheid[0].Einddatum !== undefined && vkiData?.Gezondheid[0].Begindatum > vkiData?.Gezondheid[0].Einddatum ? true : false} 
                  errorText={ vkiData &&
                    vkiData.Gezondheid &&
                    vkiData.Gezondheid[0] &&
                    vkiData.Gezondheid[0].Ziektebeeld &&
                    vkiData?.Gezondheid[0].ZiektebeeldExterneId !== vkiData?.ZiektebeeldNa?.ExterneId &&
                    (vkiData.Gezondheid[0].Begindatum === undefined ||
                      vkiData.Gezondheid[0].Begindatum === null)
                      ? t('verplichtVeld') as string 
                      : vkiData?.Gezondheid[0].Begindatum !== undefined && vkiData?.Gezondheid[0].Einddatum !== undefined && vkiData?.Gezondheid[0].Begindatum > vkiData?.Gezondheid[0].Einddatum ? t('valideerGezondheidBeginDatumZonderRegelNmrVerk') as string : ''}
                  hideBorders={true} index={0}
                />

               {vkiData && vkiData.Gezondheid && vkiData.Gezondheid[1] ? (

                <CustomDatePicker datePickerClassName='datepickerWidth140px' readOnly={isReadonly} name="Begindatum" className="gezondheidDateFields" label={''} value={vkiData && vkiData.Gezondheid && vkiData.Gezondheid[1] && vkiData.Gezondheid[1].Begindatum ? vkiData.Gezondheid[1].Begindatum : null} onChange={handleDateChange} size="small" 
                  isError={vkiData?.Gezondheid[1].Ziektebeeld &&
                      vkiData?.Gezondheid[1].ZiektebeeldExterneId !== vkiData?.ZiektebeeldNa?.ExterneId &&
                      (vkiData.Gezondheid[1].Begindatum === undefined ||
                        vkiData.Gezondheid[1].Begindatum === null)
                        ? true
                        : vkiData?.Gezondheid[1].Begindatum !== undefined && vkiData?.Gezondheid[1].Einddatum !== undefined && vkiData?.Gezondheid[1].Begindatum > vkiData?.Gezondheid[1].Einddatum ? true : false}
                  errorText={ 
                        vkiData?.Gezondheid[1].Ziektebeeld &&
                        vkiData?.Gezondheid[1].ZiektebeeldExterneId !== vkiData?.ZiektebeeldNa?.ExterneId &&
                        (vkiData.Gezondheid[1].Begindatum === undefined ||
                          vkiData.Gezondheid[1].Begindatum === null)
                          ? t('verplichtVeld') as string
                          : vkiData?.Gezondheid[1].Begindatum !== undefined && vkiData?.Gezondheid[1].Einddatum !== undefined && vkiData?.Gezondheid[1].Begindatum > vkiData?.Gezondheid[1].Einddatum ? t('valideerGezondheidBeginDatumZonderRegelNmrVerk')?.replace("{regel}", "1") as string : ''
                      }
                  hideBorders={true} index={1}
                />
              ) : null}
               {vkiData && vkiData.Gezondheid && vkiData.Gezondheid[2] ? (

                <CustomDatePicker datePickerClassName='datepickerWidth140px' readOnly={isReadonly} name="Begindatum" className="gezondheidDateFields" label={''} value={vkiData && vkiData.Gezondheid && vkiData.Gezondheid[2] && vkiData.Gezondheid[2].Begindatum ? vkiData.Gezondheid[2].Begindatum : null} onChange={handleDateChange} size="small" 
                isError={vkiData?.Gezondheid[2].Ziektebeeld &&
                    vkiData?.Gezondheid[2].ZiektebeeldExterneId !== vkiData?.ZiektebeeldNa?.ExterneId &&
                    (vkiData.Gezondheid[2].Begindatum === undefined ||
                      vkiData.Gezondheid[2].Begindatum === null)
                      ? true
                      : vkiData?.Gezondheid[2].Begindatum !== undefined && vkiData?.Gezondheid[2].Einddatum !== undefined && vkiData?.Gezondheid[2].Begindatum > vkiData?.Gezondheid[2].Einddatum ? true : false}
                errorText={ 
                      vkiData?.Gezondheid[2].Ziektebeeld &&
                      vkiData?.Gezondheid[2].ZiektebeeldExterneId !==vkiData?.ZiektebeeldNa?.ExterneId&&
                      (vkiData.Gezondheid[2].Begindatum === undefined ||
                        vkiData.Gezondheid[2].Begindatum === null)
                        ? t('verplichtVeld') as string
                        : vkiData?.Gezondheid[2].Begindatum !== undefined && vkiData?.Gezondheid[2].Einddatum !== undefined && vkiData?.Gezondheid[2].Begindatum > vkiData?.Gezondheid[2].Einddatum ? t('valideerGezondheidBeginDatumZonderRegelNmrVerk')?.replace("{regel}", "2") as string : ''
                    }
                hideBorders={true} index={2}
              />
            ) : null}
              {vkiData && vkiData.Gezondheid && vkiData.Gezondheid[3] ? (

              <CustomDatePicker datePickerClassName='datepickerWidth140px' readOnly={isReadonly} name="Begindatum" className="gezondheidDateFields" label={''} value={vkiData && vkiData.Gezondheid && vkiData.Gezondheid[3] && vkiData.Gezondheid[3].Begindatum ? vkiData.Gezondheid[3].Begindatum : null} onChange={handleDateChange} size="small" 
                isError={vkiData?.Gezondheid[3].Ziektebeeld &&
                    vkiData?.Gezondheid[3].ZiektebeeldExterneId !== vkiData?.ZiektebeeldNa?.ExterneId &&
                    (vkiData.Gezondheid[3].Begindatum === undefined ||
                      vkiData.Gezondheid[3].Begindatum === null)
                      ? true
                      : vkiData?.Gezondheid[3].Begindatum !== undefined && vkiData?.Gezondheid[3].Einddatum !== undefined && vkiData?.Gezondheid[3].Begindatum > vkiData?.Gezondheid[3].Einddatum ? true : false}
                errorText={ 
                      vkiData?.Gezondheid[3].Ziektebeeld &&
                      vkiData?.Gezondheid[3].ZiektebeeldExterneId !==  vkiData?.ZiektebeeldNa?.ExterneId &&
                      (vkiData.Gezondheid[3].Begindatum === undefined ||
                        vkiData.Gezondheid[3].Begindatum === null)
                        ? t('verplichtVeld') as string
                        : vkiData?.Gezondheid[3].Begindatum !== undefined && vkiData?.Gezondheid[3].Einddatum !== undefined && vkiData?.Gezondheid[3].Begindatum > vkiData?.Gezondheid[3].Einddatum ? t('valideerGezondheidBeginDatumZonderRegelNmrVerk')?.replace("{regel}", "3") as string : ''
                    }
                hideBorders={true} index={3}
              />
            ) : null}
               {vkiData && vkiData.Gezondheid && vkiData.Gezondheid[4] ? (

              <CustomDatePicker datePickerClassName='datepickerWidth140px' readOnly={isReadonly} name="Begindatum" className="gezondheidDateFields" label={''} value={vkiData && vkiData.Gezondheid && vkiData.Gezondheid[4] && vkiData.Gezondheid[4].Begindatum ? vkiData.Gezondheid[4].Begindatum : null} onChange={handleDateChange} size="small" 
                isError={vkiData?.Gezondheid[4].Ziektebeeld &&
                    vkiData?.Gezondheid[4].ZiektebeeldExterneId !== vkiData?.ZiektebeeldNa?.ExterneId &&
                    (vkiData.Gezondheid[4].Begindatum === undefined ||
                      vkiData.Gezondheid[4].Begindatum === null)
                      ? true
                      : vkiData?.Gezondheid[4].Begindatum !== undefined && vkiData?.Gezondheid[4].Einddatum !== undefined && vkiData?.Gezondheid[4].Begindatum > vkiData?.Gezondheid[4].Einddatum ? true : false}
                errorText={ 
                      vkiData?.Gezondheid[4].Ziektebeeld &&
                      vkiData?.Gezondheid[4].ZiektebeeldExterneId !== vkiData?.ZiektebeeldNa?.ExterneId &&
                      (vkiData.Gezondheid[4].Begindatum === undefined ||
                        vkiData.Gezondheid[4].Begindatum === null)
                        ? t('verplichtVeld') as string
                        : vkiData?.Gezondheid[4].Begindatum !== undefined && vkiData?.Gezondheid[4].Einddatum !== undefined && vkiData?.Gezondheid[4].Begindatum > vkiData?.Gezondheid[4].Einddatum ? t('valideerGezondheidBeginDatumZonderRegelNmrVerk')?.replace("{regel}", "4") as string : ''
                    }
                hideBorders={true} index={4}
              />
            ) : null}
          </div>    

        {/*@@@@@@@@@@@@@@@@@@@@@@@@@@ COLUMN Einddatum @@@@@@@@@@@@@@@@@@@@@@@@@@*/}

          <div className="default-row-content-columns-data">
              <div className="default-row-content-label">{t('einddatum')}</div>

               <CustomDatePicker datePickerClassName='datepickerWidth140px' readOnly={isReadonly} name="Einddatum" className="gezondheidDateFields" label={''} value={vkiData && vkiData.Gezondheid && vkiData.Gezondheid[0] && vkiData?.Gezondheid[0].Einddatum ? vkiData.Gezondheid[0].Einddatum : null} onChange={handleDateChange} size="small" 
                isError={vkiData && vkiData.Gezondheid && vkiData.Gezondheid[0] && vkiData.Gezondheid[0].Ziektebeeld && vkiData?.Gezondheid[0].ZiektebeeldExterneId !== vkiData?.ZiektebeeldNa?.ExterneId&& (vkiData.Gezondheid[0].Einddatum === undefined || vkiData.Gezondheid[0].Einddatum === null) || IsEindDatumError}
                errorText={vkiData && vkiData.Gezondheid && vkiData.Gezondheid[0] && vkiData.Gezondheid[0].Ziektebeeld && vkiData?.Gezondheid[0].ZiektebeeldExterneId !== vkiData?.ZiektebeeldNa?.ExterneId &&(vkiData.Gezondheid[0].Einddatum === undefined || vkiData.Gezondheid[0].Einddatum === null) ? t('verplichtVeld') as string : IsEindDatumErrorString}
                hideBorders={true} index={0}
              />
              {  vkiData && vkiData.Gezondheid && vkiData.Gezondheid[1] ? 

                <CustomDatePicker datePickerClassName='datepickerWidth140px' readOnly={isReadonly} name="Einddatum" className="gezondheidDateFields" label={''} value={vkiData && vkiData.Gezondheid && vkiData.Gezondheid[1] && vkiData?.Gezondheid[1].Einddatum ? vkiData.Gezondheid[1].Einddatum : null} onChange={handleDateChange} size="small" 
                isError={vkiData && vkiData.Gezondheid && vkiData.Gezondheid[1] && vkiData.Gezondheid[1].Ziektebeeld && vkiData?.Gezondheid[1].ZiektebeeldExterneId !== vkiData?.ZiektebeeldNa?.ExterneId&&  (vkiData.Gezondheid[1].Einddatum === undefined || vkiData.Gezondheid[1].Einddatum === null) || IsEindDatumError1}
                errorText={vkiData && vkiData.Gezondheid && vkiData.Gezondheid[1] && vkiData.Gezondheid[1].Ziektebeeld && vkiData?.Gezondheid[1].ZiektebeeldExterneId !==vkiData?.ZiektebeeldNa?.ExterneId && (vkiData.Gezondheid[1].Einddatum === undefined || vkiData.Gezondheid[1].Einddatum === null) ? t('verplichtVeld') as string : IsEindDatumErrorString1}
                  hideBorders={true} index={1}
                />
              : null }
              {  vkiData && vkiData.Gezondheid && vkiData.Gezondheid[2] ? 
                //   <TextField onKeyPress={handleKeyPress} 
                //   tabIndex={2024}
                //   inputProps={{ inputcomponent: inputComponent, tabIndex: 2024}}
                //   name={`Einddatum`}
                //   type="date"
                //   InputProps={{
                //     readOnly: isReadonly,
                //   }}
                //   value={vkiData?.Gezondheid[2] && vkiData.Gezondheid[2] && vkiData?.Gezondheid[2].Einddatum ? DateUtil.FormatDateToString(vkiData.Gezondheid[2].Einddatum) : undefined}
                //   className="default-row-content-date-input"
                //   size="small"
                //   onChange={(event) => handleDateChange(event, 2)} 
                //   error={IsEindDatumError2}
                //   helperText={IsEindDatumError2 ?  IsEindDatumErrorString2 : ''}  
                //   FormHelperTextProps={{
                //     className: 'custom-error-message',
                //   }}
                // />
                <CustomDatePicker datePickerClassName='datepickerWidth140px' readOnly={isReadonly} name="Einddatum" className="gezondheidDateFields" label={''} value={vkiData && vkiData.Gezondheid && vkiData.Gezondheid[2] && vkiData?.Gezondheid[2].Einddatum ? vkiData.Gezondheid[2].Einddatum : null} onChange={handleDateChange} size="small" 
                isError={vkiData && vkiData.Gezondheid && vkiData.Gezondheid[2] && vkiData.Gezondheid[2].Ziektebeeld && vkiData?.Gezondheid[2].ZiektebeeldExterneId !== vkiData?.ZiektebeeldNa?.ExterneId &&  (vkiData.Gezondheid[2].Einddatum === undefined || vkiData.Gezondheid[2].Einddatum === null) || IsEindDatumError2}
                errorText={vkiData && vkiData.Gezondheid && vkiData.Gezondheid[2] && vkiData.Gezondheid[2].Ziektebeeld && vkiData?.Gezondheid[2].ZiektebeeldExterneId !== vkiData?.ZiektebeeldNa?.ExterneId &&  (vkiData.Gezondheid[2].Einddatum === undefined || vkiData.Gezondheid[2].Einddatum === null) ? t('verplichtVeld') as string : IsEindDatumErrorString2}
                  hideBorders={true} index={2}
                />
              : null }
              {  vkiData && vkiData.Gezondheid && vkiData.Gezondheid[3] ? 

              <CustomDatePicker datePickerClassName='datepickerWidth140px' readOnly={isReadonly} name="Einddatum" className="gezondheidDateFields" label={''} value={vkiData && vkiData.Gezondheid && vkiData.Gezondheid[3] && vkiData?.Gezondheid[3].Einddatum ? vkiData.Gezondheid[3].Einddatum : null} onChange={handleDateChange} size="small" 
              isError={vkiData && vkiData.Gezondheid && vkiData.Gezondheid[3] && vkiData.Gezondheid[3].Ziektebeeld && vkiData?.Gezondheid[3].ZiektebeeldExterneId !== vkiData?.ZiektebeeldNa?.ExterneId&& (vkiData.Gezondheid[3].Einddatum === undefined || vkiData.Gezondheid[3].Einddatum === null) || IsEindDatumError}
              errorText={vkiData && vkiData.Gezondheid && vkiData.Gezondheid[3] && vkiData.Gezondheid[3].Ziektebeeld && vkiData?.Gezondheid[3].ZiektebeeldExterneId !== vkiData?.ZiektebeeldNa?.ExterneId &&  (vkiData.Gezondheid[3].Einddatum === undefined || vkiData.Gezondheid[3].Einddatum === null) ? t('verplichtVeld') as string : IsEindDatumErrorString3}
              hideBorders={true} index={3}
              />
              : null }
              {  vkiData && vkiData.Gezondheid && vkiData.Gezondheid[4] ? 

                  <CustomDatePicker datePickerClassName='datepickerWidth140px' readOnly={isReadonly} name="Einddatum" className="gezondheidDateFields" label={''} value={vkiData && vkiData.Gezondheid && vkiData.Gezondheid[4] && vkiData?.Gezondheid[4].Einddatum ? vkiData.Gezondheid[4].Einddatum : null} onChange={handleDateChange} size="small" 
                  isError={vkiData && vkiData.Gezondheid && vkiData.Gezondheid[4] && vkiData.Gezondheid[4].Ziektebeeld && vkiData?.Gezondheid[4].ZiektebeeldExterneId !== vkiData?.ZiektebeeldNa?.ExterneId && (vkiData.Gezondheid[4].Einddatum === undefined || vkiData.Gezondheid[4].Einddatum === null) || IsEindDatumError4}
                  errorText={vkiData && vkiData.Gezondheid && vkiData.Gezondheid[4] && vkiData.Gezondheid[4].Ziektebeeld && vkiData?.Gezondheid[4].ZiektebeeldExterneId !== vkiData?.ZiektebeeldNa?.ExterneId&&  (vkiData.Gezondheid[4].Einddatum === undefined || vkiData.Gezondheid[4].Einddatum === null) ? t('verplichtVeld') as string : IsEindDatumErrorString4}
                    hideBorders={true} index={4}
                  />
              : null }
          </div>    

        {/*@@@@@@@@@@@@@@@@@@@@@@@@@@ COLUMN TOTAALGEBRUIKTEHOEVEELHEID @@@@@@@@@@@@@@@@@@@@@@@@@@*/}

          <div className="default-row-content-columns-data">
              <div className="default-row-content-label">{t('totaalgebruiktehoeveelheid')}</div>
              <TextField onKeyPress={handleKeyPress} 
                  tabIndex={2005}
                  inputProps={{ tabIndex: 2005, inputComponent: NumericFormatCustom as any}}
                name={`TotaalGebruikteHoeveelheid`}
                
                InputProps={{
                  readOnly: isReadonly,
                }}
                value={totaalgebruikt1}
                className="default-row-content-number-input"
                size="small"
                onChange={(event) => handleInputChange(event, 0)}

                error={isNaN(totaalgebruikt1?.replace(".", "~")?.replace(",", ".")?.replace("~", ",") as unknown as number) ||  totaalgebruikt1 === ""}
                helperText={isNaN(totaalgebruikt1?.replace(".", "~")?.replace(",", ".")?.replace("~", ",") as unknown as number) ? t('correctnumeriek') : totaalgebruikt1 === "" ? t('verplichtVeld') :  undefined}
                
                FormHelperTextProps={{
                    className: 'custom-error-message',
                  }}
              />
              {  vkiData && vkiData.Gezondheid && vkiData.Gezondheid[1] ? 
                <TextField onKeyPress={handleKeyPress} 
                  tabIndex={2015}
                  inputProps={{ tabIndex: 2015}}
                  name={`TotaalGebruikteHoeveelheid`}
                  type="text"
                  InputProps={{
                    readOnly: isReadonly,
                  }}
                  
                  className="default-row-content-number-input"
                  size="small"
                  onChange={(event) => handleInputChange(event, 1)}
                  value={totaalgebruikt2}
                  error={(isNaN(totaalgebruikt2?.replace(".", "~")?.replace(",", ".")?.replace("~", ",") as unknown as number) ||  totaalgebruikt2 === "")}
                  helperText={isNaN(totaalgebruikt2?.replace(".", "~")?.replace(",", ".")?.replace("~", ",") as unknown as number) ? t('correctnumeriek') : totaalgebruikt2 === "" ? t('verplichtVeld') :  undefined}
                
                  FormHelperTextProps={{
                    className: 'custom-error-message',
                  }}
                />
              : null }
              {  vkiData && vkiData.Gezondheid && vkiData.Gezondheid[2] ? 
                <TextField onKeyPress={handleKeyPress} 
                  tabIndex={2025}
                  inputProps={{ tabIndex: 2025}}
                  name={`TotaalGebruikteHoeveelheid`}
                  type="text"
                  InputProps={{
                    readOnly: isReadonly,
                  }}
                  className="default-row-content-number-input"
                  size="small"
                  onChange={(event) => handleInputChange(event, 2)}
                  value={totaalgebruikt3}
                  error={isNaN(totaalgebruikt3?.replace(".", "~")?.replace(",", ".")?.replace("~", ",") as unknown as number) ||  totaalgebruikt3 === ""}
                  helperText={isNaN(totaalgebruikt3?.replace(".", "~")?.replace(",", ".")?.replace("~", ",") as unknown as number) ? t('correctnumeriek') : totaalgebruikt3 === "" ? t('verplichtVeld') :  undefined}
                  FormHelperTextProps={{
                    className: 'custom-error-message',
                  }}
                />
              : null }
              {  vkiData && vkiData.Gezondheid && vkiData.Gezondheid[3] ? 
                <TextField onKeyPress={handleKeyPress} 
                  tabIndex={2035}
                  inputProps={{ tabIndex: 2035}}
                  name={`TotaalGebruikteHoeveelheid`}
                  type="text"
                  InputProps={{
                    readOnly: isReadonly,
                  }}
                  className="default-row-content-number-input"
                  size="small"
                  onChange={(event) => handleInputChange(event, 3)}
                  value={totaalgebruikt4}
                  error={isNaN(totaalgebruikt4?.replace(".", "~")?.replace(",", ".")?.replace("~", ",") as unknown as number) ||  totaalgebruikt4 === ""}
                  helperText={isNaN(totaalgebruikt4?.replace(".", "~")?.replace(",", ".")?.replace("~", ",") as unknown as number) ? t('correctnumeriek') : totaalgebruikt4 === "" ? t('verplichtVeld') :  undefined}
                  FormHelperTextProps={{
                    className: 'custom-error-message',
                  }}
                  />
              : null }
              {  vkiData && vkiData.Gezondheid && vkiData.Gezondheid[4] ? 
                  <TextField onKeyPress={handleKeyPress} 
                    tabIndex={2045}
                    inputProps={{ tabIndex: 2045}}
                    name={`TotaalGebruikteHoeveelheid`}
                    type="text"
                    InputProps={{
                      readOnly: isReadonly,
                    }}
                    className="default-row-content-number-input"
                    size="small"
                    onChange={(event) => handleInputChange(event, 4)}
                    value={totaalgebruikt5}
                    error={isNaN(totaalgebruikt5?.replace(".", "~")?.replace(",", ".")?.replace("~", ",") as unknown as number) ||  totaalgebruikt5 === ""}
                    helperText={isNaN(totaalgebruikt5?.replace(".", "~")?.replace(",", ".")?.replace("~", ",") as unknown as number) ? t('correctnumeriek') : totaalgebruikt5 === "" ? t('verplichtVeld') :  undefined}

                  FormHelperTextProps={{
                    className: 'custom-error-message',
                  }}
                  />
              : null }
          </div>    
          
        {/*@@@@@@@@@@@@@@@@@@@@@@@@@@ COLUMN MAAT @@@@@@@@@@@@@@@@@@@@@@@@@@*/}

          <div className="default-row-content-columns-data">
            <div className="default-row-content-label" style={{ color: 'transparent' }}> &nbsp </div>
              <Select onKeyPress={handleKeyPress} 
                tabIndex={2006}
                inputProps={{ tabIndex: 2006}}
                name={`Maat`}
                disabled={isReadonly}
                value={ vkiData && vkiData.Gezondheid && vkiData.Gezondheid[0] && vkiData.Gezondheid[0].Maat ? vkiData.Gezondheid[0].Maat : 'kg'}
                onChange={(event) => handleSelectChanges(event, 0)}
                className="default-row-content-selectbox"
                size="small"
              >
                <MenuItem value={'kg'}>kg</MenuItem>
                <MenuItem value={'liter'}>Liter</MenuItem>
              </Select>
              {  vkiData && vkiData.Gezondheid && vkiData.Gezondheid[1] ? 
                <Select onKeyPress={handleKeyPress} 
                  tabIndex={2016}
                  inputProps={{ tabIndex: 2016}}
                  name={`Maat`}
                  disabled={isReadonly}
                  value={ vkiData && vkiData.Gezondheid[1] && vkiData.Gezondheid[1].Maat ? vkiData.Gezondheid[1].Maat : 'kg'}
                  onChange={(event) => handleSelectChanges(event, 1)}
                  className="default-row-content-selectbox"
                  size="small"
                >
                  <MenuItem value={'kg'}>kg</MenuItem>
                  <MenuItem value={'liter'}>Liter</MenuItem>
                </Select>
              : null }
              {  vkiData && vkiData.Gezondheid && vkiData.Gezondheid[2] ? 
                <Select onKeyPress={handleKeyPress} 
                  tabIndex={2026}
                  inputProps={{ tabIndex: 2026}}
                  name={`Maat`}
                  disabled={isReadonly}
                  value={ vkiData && vkiData.Gezondheid[2] && vkiData.Gezondheid[2].Maat ? vkiData.Gezondheid[2].Maat : 'kg'}
                  onChange={(event) => handleSelectChanges(event, 2)}
                  className="default-row-content-selectbox"
                  size="small"
                >
                  <MenuItem value={'kg'}>kg</MenuItem>
                  <MenuItem value={'liter'}>Liter</MenuItem>
                </Select>
              : null }
              {  vkiData && vkiData.Gezondheid && vkiData.Gezondheid[3] ? 
                <Select onKeyPress={handleKeyPress} 
                  tabIndex={2036}
                  inputProps={{ tabIndex: 2036}}
                  name={`Maat`}
                  disabled={isReadonly}
                  value={ vkiData && vkiData.Gezondheid[3] && vkiData.Gezondheid[3].Maat ? vkiData.Gezondheid[3].Maat : 'kg'}
                  onChange={(event) => handleSelectChanges(event, 3)}
                  className="default-row-content-selectbox"
                  size="small"
                >
                  <MenuItem value={'kg'}>kg</MenuItem>
                  <MenuItem value={'liter'}>Liter</MenuItem>
                </Select>
              : null }
              {  vkiData && vkiData.Gezondheid && vkiData.Gezondheid[4] ? 
                  <Select onKeyPress={handleKeyPress} 
                    tabIndex={2046}
                    inputProps={{ tabIndex: 2046}}
                    name={`Maat`}
                    disabled={isReadonly}
                    value={ vkiData && vkiData.Gezondheid[4] && vkiData.Gezondheid[4].Maat ? vkiData.Gezondheid[4].Maat : 'kg'}
                    onChange={(event) => handleSelectChanges(event, 4)}
                    className="default-row-content-selectbox"
                    size="small"
                  >
                    <MenuItem value={'kg'}>kg</MenuItem>
                    <MenuItem value={'liter'}>Liter</MenuItem>
                  </Select>
              : null }
          </div>    
          
        </div>
        
        {/*@@@@@@@@@@@@@@@@@@@@@@@@@@ FileUpload @@@@@@@@@@@@@@@@@@@@@@@@@@*/}
        { accountInfo && vkiData && groupClaim &&
          <FileUpload accountInfo={accountInfo as IAccountInfo} vki={vkiData as IVKI} rol={groupClaim as string} type="Gezondheidsverklaring" onFileUpload={handleFileUpload} readonly={isReadonly}/>
        }
      </div>
    </>
  );
};

export default GezondheidDataSection;