import { Box, LinearProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import camelcaseKeys from 'camelcase-keys';
import download from 'downloadjs';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import { IVKI } from '../VKI/Interfaces/IVKI';
import { environment } from './common/env';
import { LogoutFunction } from '../authProviders/authProvider';
import { HeaderProps } from './common/Header';


export const LogoutVKI: React.FC<HeaderProps> = ({ accountInfo, logout }) => {
  const { t } = useTranslation();
  logout();
  return (
    <div className='VKIOverzicht'>
      <p>{t('logout')}</p>
    </div>
  );
};

export default LogoutVKI;