import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import './App.scss';
import App from './Components/App';
import Authenticating from './Components/auth/Authenticating';
import Unauthenticated from './Components/auth/Unauthenticated';
import WithLogin from './Components/auth/WithLogin';
import { MuiThemeProvider, ThemeProvider, createTheme } from '@material-ui/core';

const AppWithLogin = (): JSX.Element => WithLogin(App, Authenticating, Unauthenticated);
const theme = createTheme({
	props: {
	  // Name of the component
	  MuiTextField : {
		size : "small"
	  }
	},
	palette: {

	  }
  });

ReactDOM.render(

	<React.StrictMode>
		<MuiThemeProvider theme={theme}>
			<AppWithLogin />
		</MuiThemeProvider>
	</React.StrictMode>
	,
	document.getElementById('root')
);
