export class NumberUtils {
  static RemoveLeadingZero(num: number): number | undefined {

    const numStr = num.toString();
  
    if (numStr.length === 0) {
      return undefined;
    }
  
    if (numStr.charAt(0) === '0') {
      if (numStr.length > 1) {
        return Number(numStr.slice(1));
      } else {
        return 0;
      }
    }
  
    if (numStr.charAt(0) === '-') {
      return 0;
    }
  
    return Number(num);
  }
  
}
