import TextField from '@material-ui/core/TextField';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { IAccountInfo } from 'react-aad-msal';
import { useTranslation } from 'react-i18next';
import { IFormSectionProps, IFormValidationObject } from '../../VKI/Interfaces/IForm';
import { IMortaliteit, IVKI } from '../../VKI/Interfaces/IVKI';
import { NumberUtils } from '../../utils/numbers';
import { FileUpload } from '../common/FileUpload';

const MortaliteitDataSection: React.FC<IFormSectionProps> = ({ vkiData, accountInfo, onChange, isReadonly, groupClaim, validateObject, setValidationObject }) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(true);
  const [initialLoad, setInitialLoad] = useState(true);
  const [aantalOpgezet, setAantalOpgezet] = useState(vkiData?.Mortaliteit?.AantalOpgezetteDieren?.toString() == "0" ?
    '' : vkiData?.Mortaliteit?.AantalOpgezetteDieren?.toString());
  const [uitvalEersteWeek, setUitvalEersteWeek] = useState(vkiData?.Mortaliteit?.UitvalEersteWeek?.toString() == "0" ?
  '' : vkiData?.Mortaliteit?.UitvalEersteWeek?.toString());
  const [gecumuleerdeDagelijkseMortaliteit, setGecumuleerdeDagelijkseMortaliteit] = useState(vkiData?.Mortaliteit?.GecumuleerdeDagelijkseMortaliteit?.toString() == "0" ?
  '' : vkiData?.Mortaliteit?.GecumuleerdeDagelijkseMortaliteit?.toString().replace(".", "~").replace(",", ".").replace("~", ","));
  const [totaleDagelijkseMortaliteit, seTotaleDagelijkseMortaliteit] = useState(vkiData?.Mortaliteit?.TotaleDagelijkseMortaliteit?.toString() == "0" ?
  '' : vkiData?.Mortaliteit?.TotaleDagelijkseMortaliteit?.toString().replace(".", "~").replace(",", ".").replace("~", ","));
  const [uitvalTotaal, seTotalUitvalTotaal] = useState(vkiData?.Mortaliteit?.UitvalTotaal?.toString() == "0" ?
  '' : vkiData?.Mortaliteit?.UitvalTotaal?.toString().replace(".", "~").replace(",", ".").replace("~", ","));
  
  useEffect(() => {
    const checkMissingInputs = () => {
      if (vkiData && vkiData.Mortaliteit && initialLoad) {
        const { AantalOpgezetteDieren, UitvalEersteWeek, GecumuleerdeDagelijkseMortaliteit, TotaleDagelijkseMortaliteit } = vkiData.Mortaliteit;
        let isDataMissing = false;


        if (!AantalOpgezetteDieren)  isDataMissing = true;
        if (!UitvalEersteWeek)  isDataMissing = true;
        if (!GecumuleerdeDagelijkseMortaliteit)  isDataMissing = true;
        if (!TotaleDagelijkseMortaliteit)  isDataMissing = true;

        if(isDataMissing === false) {
          //setExpanded(isDataMissing);
        }
      }
      // check data when its avaialble
      if(vkiData !== undefined ) isMortaliteitValid(vkiData);
    };

    checkMissingInputs();
    setInitialLoad(false);
  }, [vkiData]);
   
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    
    onChange((prevVkiData: IVKI) => {
      const updatedData: IVKI = { ...prevVkiData };
  
      let newValue;
      if(value !== '') {
        let stringnumber:String = value.replace(".", "~").replace(",", ".").replace("~", ",");
        console.log(stringnumber);
        var arr = stringnumber.split(',');

        if (!isNaN(stringnumber as unknown as number)){
          newValue = NumberUtils.RemoveLeadingZero(Number(stringnumber));
        }
      } else {
        newValue = undefined;
      }
      if (name === 'AantalOpgezetteDieren')
      {
        setAantalOpgezet(value);
      }
      if (name === 'UitvalEersteWeek')
      {
        setUitvalEersteWeek(value);
      }
      if (name === 'UitvalTotaal')
      {
        seTotalUitvalTotaal(value);
      }
      if (name === 'GecumuleerdeDagelijkseMortaliteit')
      {
        setGecumuleerdeDagelijkseMortaliteit(value);
      }
      if (name === 'TotaleDagelijkseMortaliteit')
      {
        seTotaleDagelijkseMortaliteit(value);
      }
      updatedData.Mortaliteit = {
        ...prevVkiData.Mortaliteit,
        [name as keyof IMortaliteit]: newValue,
      }
  
      isMortaliteitValid(updatedData);
  
      return updatedData;
    });
  };

  const handleFileUpload = (updatedVKI: IVKI) => {
    onChange((prevVkiData: IVKI) => ({
      ...prevVkiData,
      Bijlagen: updatedVKI.Bijlagen,
    }));
  };

  const isMortaliteitValid = (data: IVKI): void => {
    let isValid:boolean = true;
    if(data)  {
      if(data.Mortaliteit) {
        const { AantalOpgezetteDieren, UitvalEersteWeek, GecumuleerdeDagelijkseMortaliteit, TotaleDagelijkseMortaliteit } = data.Mortaliteit;
        if (!AantalOpgezetteDieren)  isValid = false;
        if (!UitvalEersteWeek)  isValid = false;
        if (!GecumuleerdeDagelijkseMortaliteit)  isValid = false;
        if (!TotaleDagelijkseMortaliteit)  isValid = false;
      }
    }

    // update the object..
    setValidationObject((prevValidationObject: IFormValidationObject) => ({
      ...prevValidationObject,
      MortaliteitDataSection: isValid,
    }));
  };

  const handleKeyPress = (event:any) => {
    if (event.key === 'Enter') {
      try
      {
        event.preventDefault(); // Prevent the default behavior of the Enter key
        // You can add any custom logic here if needed
        const form = event.target.form;
        const index = Array.from(form.elements).indexOf(event.target);
        form.elements[index + 1].focus();
      }
      catch(ex){}
    }
  };


  return (
    <div className="default-section-container" >
      <h4>{t('MortaliteitCaps')}</h4>
      <div className="default-row">
        <TextField onKeyPress={handleKeyPress} 
          name="AantalOpgezetteDieren"
          label={t('aantalopgezette')}
          InputProps={{
            readOnly: isReadonly,
          }}
          value={aantalOpgezet}
          onChange={handleInputChange}
          type='text'
          className='default-row-AantalOpgezetteDieren'
          InputLabelProps={{
              className: 'input-label', 
              classes: {
                shrink: 'input-label-shrink', 
              },
            }}
            error={isNaN(aantalOpgezet?.replace(".", "~").replace(",", "a").replace("~", "a") as unknown as number) ||  aantalOpgezet === ""}
            helperText={isNaN(aantalOpgezet?.replace(".", "a").replace(",", "a").replace("~", "a") as unknown as number) ? t('correctnumeriekGeheel') : aantalOpgezet === "" ? t('verplichtVeld') :  undefined}
        />
      </div>
      <div className="default-row">
        <TextField onKeyPress={handleKeyPress} 
          name="UitvalEersteWeek"
          label={t('uitvalEersteWeek')}
          InputProps={{
            readOnly: isReadonly,
          }}
          value={uitvalEersteWeek}
          onChange={handleInputChange}
          type='text'
          className='default-row-UitvalEersteWeek'
          InputLabelProps={{
              className: 'input-label', 
              classes: {
                shrink: 'input-label-shrink', 
              },
            }}
            error={isNaN(uitvalEersteWeek?.replace(".", "~").replace(",", "q").replace("~", "q") as unknown as number) ||  uitvalEersteWeek === ""}
            helperText={isNaN(uitvalEersteWeek?.replace(".", "~").replace(",", "q").replace("~", "q") as unknown as number) ? t('correctnumeriekGeheel') : uitvalEersteWeek === "" ? t('verplichtVeld') :  undefined}
        />
      </div>
      <div className="default-row">
        <TextField onKeyPress={handleKeyPress} 
          name="UitvalTotaal"
          label={t('uitvalTotaal')}
          InputProps={{
            readOnly: isReadonly,
          }}
          value={uitvalTotaal}
          onChange={handleInputChange}
          type='text'
          className='default-row-UitvalEersteWeek'
          InputLabelProps={{
              className: 'input-label', 
              classes: {
                shrink: 'input-label-shrink', 
              },
            }}
            error={isNaN(uitvalTotaal?.replace(".", "~").replace(",", "q").replace("~", "q") as unknown as number) ||  uitvalTotaal === ""}
            helperText={isNaN(uitvalTotaal?.replace(".", "~").replace(",", "q").replace("~", "q") as unknown as number) ? t('correctnumeriekGeheel') : uitvalTotaal === "" ? t('verplichtVeld') :  undefined}
        />
      </div>
      <div className="default-row">
        <TextField onKeyPress={handleKeyPress} 
          name="GecumuleerdeDagelijkseMortaliteit"
          label={t('gecumuleerdedagelijksemortaliteit')}
          InputProps={{
            readOnly: isReadonly,
          }}
          value={gecumuleerdeDagelijkseMortaliteit}
          onChange={handleInputChange}
          type='text'
          className='default-row-GecumuleerdeDagelijkseMortaliteit'
          InputLabelProps={{
              className: 'input-label', 
              classes: {
                shrink: 'input-label-shrink', 
              },
            }}
            error={isNaN(gecumuleerdeDagelijkseMortaliteit?.replace(".", "~").replace(",", ".").replace("~", ",") as unknown as number) ||  gecumuleerdeDagelijkseMortaliteit === ""}
            helperText={isNaN(gecumuleerdeDagelijkseMortaliteit?.replace(".", "~").replace(",", ".").replace("~", ",") as unknown as number) ? t('correctnumeriek') : gecumuleerdeDagelijkseMortaliteit === "" ? t('verplichtVeld') :  undefined}
        />
      </div>
      <div className="default-row">
        <TextField onKeyPress={handleKeyPress} 
          name="TotaleDagelijkseMortaliteit"
          label={t('totaalDagelijkseMoraliteit')}
          InputProps={{
            readOnly: isReadonly,
          }}
          value={totaleDagelijkseMortaliteit}
          onChange={handleInputChange}
          type='text'
          className='default-row-TotaleDagelijkseMortaliteit'
          InputLabelProps={{
              className: 'input-label', 
              classes: {
                shrink: 'input-label-shrink', 
              },
            }}
            error={isNaN(totaleDagelijkseMortaliteit?.replace(".", "~").replace(",", ".").replace("~", ",") as unknown as number) ||  totaleDagelijkseMortaliteit === ""}
            helperText={isNaN(totaleDagelijkseMortaliteit?.replace(".", "~").replace(",", ".").replace("~", ",") as unknown as number) ? t('correctnumeriek') : totaleDagelijkseMortaliteit === "" ? t('verplichtVeld') :  undefined}
        />
      </div>
      <div className="default-row">
        <a href="https://www.rvo.nl/onderwerpen/dieren-houden-verkopen-verzorgen/vleeskuikens-afvoeren" target="_blank"> { t('berekeningSite') } </a>
      </div>
      <div className="default-row">
      { accountInfo && vkiData && groupClaim &&
        <FileUpload accountInfo={accountInfo as IAccountInfo} vki={vkiData as IVKI} type='Dierenartsverklaring' rol={groupClaim as string} onFileUpload={handleFileUpload} readonly={isReadonly}/>}
      </div>
    </div>
  );
};

export default MortaliteitDataSection;
