export const localizedTextsMap = {
    // Root
    noRowsLabel: 'Geen rijen',
    noResultsOverlayLabel: 'Geen resultaten gevonden.',
    errorOverlayDefaultLabel: 'Er is een fout opgetreden.',
  
    // Density selector toolbar button text
    toolbarDensity: 'Dichtheid',
    toolbarDensityLabel: 'Dichtheid',
    toolbarDensityCompact: 'Compact',
    toolbarDensityStandard: 'Standaard',
    toolbarDensityComfortable: 'Comfortabel',
  
    // Columns selector toolbar button text
    toolbarColumns: 'Kolommen',
    toolbarColumnsLabel: 'Selecteer kolommen',
  
    // Filters toolbar button text
    toolbarFilters: 'Filters',
    toolbarFiltersLabel: 'Toon filters',
    toolbarFiltersTooltipHide: 'Verberg filters',
    toolbarFiltersTooltipShow: 'Toon filters',
    toolbarFiltersTooltipActive: (count: number) =>
      count !== 1 ? `${count} actieve filters` : `${count} actief filter`,
  
    // Quick filter toolbar field
    toolbarQuickFilterPlaceholder: 'Zoeken...',
    toolbarQuickFilterLabel: 'Zoeken',
    toolbarQuickFilterDeleteIconLabel: 'Wissen',
  
    // Export selector toolbar button text
    toolbarExport: 'Exporteren',
    toolbarExportLabel: 'Exporteren',
    toolbarExportCSV: 'Download als CSV',
    toolbarExportPrint: 'Afdrukken',
    toolbarExportExcel: 'Download als Excel',
  
    // Columns panel text
    columnsPanelTextFieldLabel: 'Zoek kolom',
    columnsPanelTextFieldPlaceholder: 'Kolomtitel',
    columnsPanelDragIconLabel: 'Kolom herschikken',
    columnsPanelShowAllButton: 'Toon alles',
    columnsPanelHideAllButton: 'Verberg alles',
  
    // Filter panel text
    filterPanelAddFilter: 'Filter toevoegen',
    filterPanelDeleteIconLabel: 'Verwijderen',
    filterPanelLinkOperator: 'Logische operator',
    filterPanelOperators: 'Operator', // TODO v6: hernoem naar filterPanelOperator
    filterPanelOperatorAnd: 'En',
    filterPanelOperatorOr: 'Of',
    filterPanelColumns: 'Kolommen',
    filterPanelInputLabel: 'Waarde',
    filterPanelInputPlaceholder: 'Filterwaarde',
  
    // Filter operators text
    filterOperatorContains: 'bevat',
    filterOperatorEquals: 'is gelijk aan',
    filterOperatorStartsWith: 'begint met',
    filterOperatorEndsWith: 'eindigt met',
    filterOperatorIs: 'is',
    filterOperatorNot: 'is niet',
    filterOperatorAfter: 'is na',
    filterOperatorOnOrAfter: 'is op of na',
    filterOperatorBefore: 'is voor',
    filterOperatorOnOrBefore: 'is op of voor',
    filterOperatorIsEmpty: 'is leeg',
    filterOperatorIsNotEmpty: 'is niet leeg',
    filterOperatorIsAnyOf: 'is een van',
  
    // Filter values text
    filterValueAny: 'elk',
    filterValueTrue: 'waar',
    filterValueFalse: 'onwaar',
  
    // Column menu text
    columnMenuLabel: 'Menu',
    columnMenuShowColumns: 'Toon kolommen',
    columnMenuFilter: 'Filter',
    columnMenuHideColumn: 'Verberg',
    columnMenuUnsort: 'Niet sorteren',
    columnMenuSortAsc: 'Sorteer oplopend',
    columnMenuSortDesc: 'Sorteer aflopend',
  
    // Column header text
    columnHeaderFiltersTooltipActive: (count: number) =>
      count !== 1 ? `${count} actieve filters` : `${count} actief filter`,
    columnHeaderFiltersLabel: 'Toon filters',
    columnHeaderSortIconLabel: 'Sorteer',
  
    // Rows selected footer text
    footerRowSelected: (count: number) =>
      count !== 1
        ? `${count.toLocaleString()} geselecteerde rijen`
        : `${count.toLocaleString()} geselecteerde rij`,
  
    // Total row amount footer text
    footerTotalRows: 'Totaal aantal rijen:',
  
    // Total visible row amount footer text
    footerTotalVisibleRows: (visibleCount: { toLocaleString: () => any; }, totalCount: { toLocaleString: () => any; }) =>
      `${visibleCount.toLocaleString()} van ${totalCount.toLocaleString()}`,
  
    // Checkbox selection text
    checkboxSelectionHeaderName: 'Selectie met vinkjes',
    checkboxSelectionSelectAllRows: 'Selecteer alle rijen',
    checkboxSelectionUnselectAllRows: 'Deselecteer alle rijen',
    checkboxSelectionSelectRow: 'Selecteer rij',
    checkboxSelectionUnselectRow: 'Deselecteer rij',
  
    // Boolean cell text
    booleanCellTrueLabel: 'ja',
    booleanCellFalseLabel: 'nee',
  
    // Actions cell more text
    actionsCellMore: 'meer',
  
    // Column pinning text
    pinToLeft: 'Vastzetten aan de linkerkant',
    pinToRight: 'Vastzetten aan de rechterkant',
    unpin: 'Losmaken',

    // Tree Data
    treeDataGroupingHeaderName: 'Groeperen',
    treeDataExpand: 'Toon kinderen',
    treeDataCollapse: 'Verberg kinderen',
    
    // Grouping columns
    groupingColumnHeaderName: 'Groeperen',
    groupColumn: (name: any) => `Groeperen op ${name}`,
    unGroupColumn: (name: any) => `Stoppen met: any groeperen: any op: any $: any{name}`,
    
    // Master/detail
    detailPanelToggle: 'Schakel detailpaneel',
    expandDetailPanel: 'Uitvouwen',
    collapseDetailPanel: 'Inklappen',
    
    // Gebruikte vertaalde toetsen voor kerncomponenten
    MuiTablePagination: {},
    
    // Tekst voor het herordenen van rijen
    rowReorderingHeaderName: 'Rijen herordenen',
    
    // Aggregatie
    aggregationMenuItemHeader: 'Aggregatie',
    aggregationFunctionLabelSum: 'som',
    aggregationFunctionLabelAvg: 'gemiddelde',
    aggregationFunctionLabelMin: 'minimum',
    aggregationFunctionLabelMax: 'maximum',
    aggregationFunctionLabelSize: 'grootte',
    };