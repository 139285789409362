import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';
import { IVKIBijlage, VKIBijlagePreview } from '../../VKI/Interfaces/IVKI';
import { environment } from './env';
import { t } from 'i18next';
import { IAccountInfo } from 'react-aad-msal';

type PreviewDialogProps = {
  previews: VKIBijlagePreview[];
  bijlage: IVKIBijlage | null;
  accountInfo: IAccountInfo;
  currentPage: number;
  onClose: () => void;
  onPreviousPage: () => void;
  onNextPage: () => void;
};

export const PreviewDialog: FC<PreviewDialogProps> = (props: PreviewDialogProps) => {
  const { previews, bijlage, currentPage, onClose, onPreviousPage, onNextPage } = props;
  const [base64Image, setBase64Image] = useState<string>('');

  const handleDownload = (bijlage: IVKIBijlage) => {

    let anchor = document.createElement("a");
    document.body.appendChild(anchor);
    let file = environment.backendBaseUrl + bijlage.Locatie + '&code=' + environment.REACT_APP_FUNCTION_KEY;
    
    let headers = new Headers();
    headers.append('Authorization', 'Bearer ' +  props.accountInfo.jwtIdToken);
    
    fetch(file, { headers })
        .then(response => response.blob())
        .then(blobby => {
            let objectUrl = window.URL.createObjectURL(blobby);
    
            anchor.href = objectUrl;
            anchor.download =bijlage.Naam;
            anchor.click();
    
            window.URL.revokeObjectURL(objectUrl);
        });
  };

  useEffect(() => {
    console.log("Previews:", previews);
    // Rest of the code...
  }, [previews, currentPage]);

  useEffect(() => {
    const fetchBase64Image = async () => {
      if (bijlage) {
        try {
          console.log(bijlage.Previews[currentPage-1].Locatie);
          const response = await fetch(environment.backendBaseUrl + bijlage.Previews[currentPage-1].Locatie + "&base64"  +  '&code=' + environment.REACT_APP_FUNCTION_KEY,
          {
            headers: {Authorization: 'Bearer ' + props.accountInfo.jwtIdToken}
          });
          const blob = await response.json();
          setBase64Image(blob);
        } catch (error) {
          console.error('Error fetching image:', error);
        }
      }
    };

    fetchBase64Image();
  }, [bijlage, currentPage]);

  return (
    <Dialog className="previews-dialog" open={true} onClose={onClose}>
      <DialogTitle>{bijlage?.Naam}</DialogTitle>
      <DialogContent>
        {/* Display the current page's previews */}
        {previews
          .filter((preview) => preview.Volgorde === (currentPage -1))
          .map((preview) => (
            base64Image !== '' && <div key={preview.Volgorde + "_" + preview.Naam}>
              <img
                src={"data:image/jpeg;base64, " + base64Image} // Use the base64Image state as the source
                alt={preview.Naam}
                className="preview-image"
              />
            </div>
          ))}
      </DialogContent>
      <DialogActions>
        {/* Display the page number and navigation buttons */}
        <Button onClick={onPreviousPage} disabled={currentPage === 1}>
          {t('vorige')}
        </Button>
        <span>{t('pagina')} {currentPage}</span>
        <Button onClick={onNextPage} disabled={currentPage === previews.length}>
          {t('volgende')}
        </Button>
        <Button onClick={onClose} color="secondary">
          {t('sluiten')}
        </Button>
        <Button onClick={() => { if (bijlage) handleDownload(bijlage);}} color="secondary">
          {t('download')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};