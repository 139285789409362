import MenuIcon from '@mui/icons-material/Menu';
import { Box, LinearProgress, Menu, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import camelcaseKeys from 'camelcase-keys';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/nl';
import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { IVKI } from '../VKI/Interfaces/IVKI';
import { environment } from './common/env';

export const Archief: React.FC<any> = (props:any) => {
  const navigate = useNavigate();
	const [data, setData] = useState<IVKI[]>([]);
  const [authorized, setAutorized] = React.useState<boolean>(false);
  const currentDate = new Date(); // Current date
  const oneYearAgo = new Date();
  oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);

  const [fromDate, setFromDate] = React.useState<Dayjs | null>(dayjs(oneYearAgo));
  const [toDate, setToDate] = React.useState<Dayjs | null>(dayjs(currentDate));
  const [orderBy, setOrderBy] = useState<string>('');
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  
	useEffect(() => {
	  const fetchData = async () => {
      if (fromDate != null && toDate != null)
        GetVKIs(fromDate.toISOString(), toDate.toISOString());
	  };
  
	  fetchData();
	}, []);
	async function GetVKIs(dateFrom:string, dateTo:string)
  {
		try {
      
      const responseClaim = await fetch(environment.backendBaseUrl + 'GetGroupClaim?userid=' + props.accountInfo.account.idToken.sub + '&environment=' + environment.environment + '&code=' + environment.REACT_APP_FUNCTION_KEY
      ,
      { headers: {Authorization: 'Bearer ' + props.accountInfo.jwtIdToken}}); // Replace with your REST endpoint URL
      if (responseClaim.ok) {
          const jsonDataClaim = await responseClaim.json();
          
        const claimData = camelcaseKeys(jsonDataClaim, { deep: true, pascalCase: true });
        if (claimData.indexOf("AGRI") > -1)
        {
            setAutorized(true);
        }
        const response = await fetch(environment.backendBaseUrl + 'VKIOverzicht?type=archief&email=' + props.accountInfo.account.idToken.emails[0] +'&environment=' + environment.environment + '&dateFrom=' + dateFrom + '&dateTo=' + dateTo + '&code=' + environment.REACT_APP_FUNCTION_KEY,
        { headers: {Authorization: 'Bearer ' + props.accountInfo.jwtIdToken}}); // Replace with your REST endpoint URL
        if (response.ok) {
        const jsonData = await response.json();
        
        const camelCasedData = camelcaseKeys(jsonData, { deep: true, pascalCase: true });
        setData(camelCasedData);
        } else {
        // Handle error if needed
        
        console.error('Error:', response.status);
        }
      }
		} catch (error) {
		  // Handle error if needed
		  console.error('Error:', error);
		}

  }
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const filteredData = data?.filter(item =>
    item.Bedrijfslocatie?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    item.Stalnummer?.toString()?.includes(searchTerm) ||
    item.Account?.toString()?.includes(searchTerm) ||
    item.Pluimveehouder?.Naam?.toString()?.includes(searchTerm) ||
    item.Slachthuis?.Naam?.toString()?.includes(searchTerm) ||
    item.Levering?.toString()?.includes(searchTerm) ||
    item.Status?.toString()?.includes(searchTerm) ||
    item.Opzetdatum?.toString()?.includes(searchTerm) ||
    item.LaatsteSlachtDatum?.toString()?.includes(searchTerm) ||
    item.Slachthuis?.Plaats?.toString()?.includes(searchTerm) ||
    item.Levering?.toString()?.includes(searchTerm) ||
	item.Status?.toString().includes(searchTerm)
  );

  const sortedData = React.useMemo(() => {
    const sortedArray = [...filteredData];
    if (orderBy !== '') {
      sortedArray.sort((a:any | IVKI, b:any | IVKI) => {
        const valueA = a[orderBy];
        const valueB = b[orderBy];

        if (typeof valueA === 'string' && typeof valueB === 'string') {
          return order === 'asc' ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
        }

        return order === 'asc' ? (valueA - valueB) : (valueB - valueA);
      });
    }
    return sortedArray;
  }, [filteredData, orderBy, order]);

  dayjs.locale('nl'); // Stel de gewenste lokale taal in

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorEl2, setAnchorEl2] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const open2 = Boolean(anchorEl2);
	const options:Intl.DateTimeFormatOptions = { day: '2-digit', month: '2-digit', year: 'numeric' };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);

  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick2 = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };
  const handleDateFromChange = (date: Dayjs | null) => {
    if (date != null)
    {
      setFromDate(date);
      if (fromDate != null && toDate != null)
      {
        GetVKIs(fromDate.toISOString(), toDate.toISOString());
      }
    }
  };
  const handleDateToChange = (date: Dayjs | null) => {
    if (date != null)
      setToDate(date);
      if (fromDate != null && toDate != null)
      {
        GetVKIs(fromDate.toISOString(), toDate.toISOString());
      }
  };
  if (!authorized)
  {
    return(<Box sx={{ width: '100%' }}> <LinearProgress  /> </Box>);
  }

  const sortableFields = [
    { field: 'Account', key: 'Account' },
    { field: 'Naam van pluimveehouder', key: 'Pluimveehouder.Naam' },
    { field: 'Bedrijfslocatie', key: 'Pluimveehouder.Adres' },
    { field: 'Stalnummer', key: 'Stalnummer' },
    { field: 'Opzetdatum', key: 'Opzetdatum' },
    { field: 'Laatsteslachtdatum', key: 'LaatsteSlachtDatum' },
    { field: 'Risico', key: 'Risico' },
    { field: 'Naam van slachthuis', key: 'Slachthuis.Naam' },
    { field: 'Plaats van slachthuis', key: 'Slachthuis.Plaats' },
    { field: 'Levering', key: 'Leveringsnummer' },
    { field: 'status', key: 'Nvwa' },
    { field: 'Vzl', key: 'Vzl' },
    { field: 'Boer Ingediend Op', key: 'BoerIngediendOp' },
    { field: 'Agri Goedgekeurd Op', key: 'AgriGoedgekeurdOp' },
    { field: 'Agri Goedgekeurd Door', key: 'AgriGoedgekeurdDoor' },
    { field: 'NVWA Goedgekeurd Op', key: 'NVWAGoedgekeurdOp' },
    { field: 'NVWA Goedgekeurd Door', key: 'NVWAGoedgekeurdDoor' },
    { field: 'NVWA Afgekeurd Op', key: 'NVWAAfgekeurdOp' },
    { field: 'NVWA Afgekeurd Door', key: 'NVWAAfgekeurdDoor' }
  ];
  



  const handleSort = (field: string) => {
    if (orderBy === field) {
      setOrder(order === 'asc' ? 'desc' : 'asc');
    } else {
      setOrderBy(field);
      setOrder('asc');
    }
  };

  const createSortHandler = (field: string) => {
    return () => {
      handleSort(field);
    };
  };
  return (
    <div className='VKIOverzicht'>
		<h1>Archief</h1>
      
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker label="van" format='D-M-YYYY' value={fromDate} onChange={handleDateFromChange} />&nbsp;
      <DatePicker label="tot" format='D-M-YYYY' value={toDate}   onChange={handleDateToChange}  />
      </LocalizationProvider>&nbsp;
      <TextField
        label="Zoeken"
        value={searchTerm}
        onChange={handleSearch}
        variant="outlined"
        style={{ marginBottom: '1rem' }}
      />
        <Button
        id="basic-button"
        aria-controls={open2 ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open2 ? 'true' : undefined}
        variant="contained" color="primary"
        onClick={handleClick2}
        startIcon={<MenuIcon />}
        className='menuButton'
      >
        Menu
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl2}
        open={open2}

        onClose={handleClose2}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >

        <MenuItem onClick={() => { setAnchorEl2(null); navigate("/NewVKI") }}>Nieuw formulier maken</MenuItem>
      </Menu>
      <Box hidden={data.length > 0} sx={{ width: '100%' }}> <LinearProgress  /> </Box>
      <TableContainer className="vkiTable">
        <Table>
          <TableHead>
            <TableRow className='headerOverviewVKI' >
              {sortableFields.map((item) => (
                <TableCell className='tableHeader' key={item.field}>
                  <TableSortLabel
                    active={orderBy === item.key}
                    direction={orderBy === item.key ? order : 'asc'}
                    onClick={createSortHandler(item.key)}
                  >
                    {item.field}
                  </TableSortLabel>
                </TableCell>
              ))}
              <TableCell  className='tableHeader'><strong>..</strong></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {sortedData.map((item) => (
              <TableRow key={item.Id}>
                <TableCell>{item.Account}</TableCell>
                <TableCell>{item?.Pluimveehouder?.Naam}</TableCell>
                <TableCell>{item?.Pluimveehouder?.Adres}</TableCell>
                <TableCell>{item.Stalnummer}</TableCell>
                <TableCell>{item.Opzetdatum != null ? new Date(item.Opzetdatum ).toLocaleDateString('nl-NL', options) : ""}</TableCell>
                <TableCell>{item.LaatsteSlachtDatum != null ? new Date(item.LaatsteSlachtDatum ).toLocaleDateString('nl-NL', options) : ""}</TableCell>
                <TableCell>{item.Risico ? "Ja" : "Nee"}</TableCell>
                <TableCell>{item?.Slachthuis?.Naam}</TableCell>
                <TableCell>{item?.Slachthuis?.Plaats}</TableCell>
                <TableCell>{item.Leveringsnummer.toString()}</TableCell>
                <TableCell>{item.Status}</TableCell>
                <TableCell>{item.Vzl ? "Ja" : "Nee"}</TableCell>
                <TableCell>{item.BoerIngediendOp != null ? new Date(item.BoerIngediendOp ).toLocaleDateString('nl-NL', options) : ""}</TableCell>
                <TableCell>{item.AgriGoedgekeurdOp != null ? new Date(item.AgriGoedgekeurdOp ).toLocaleDateString('nl-NL', options) : ""}</TableCell>
                <TableCell>{item.AgriGoedgekeurdDoor}</TableCell>
                <TableCell>{item.NvwaGoedgekeurdOp != null ? new Date(item.NvwaGoedgekeurdOp ).toLocaleDateString('nl-NL', options) : ""}</TableCell>
                <TableCell>{item.NvwaGoedgekeurdDoor}</TableCell>
                <TableCell>{item.NvwaAfgekeurdOp != null ? new Date(item.NvwaAfgekeurdOp ).toLocaleDateString('nl-NL', options) : ""}</TableCell>
                <TableCell>{item.NvwaAfgekeurdDoor}</TableCell>
				<TableCell> &nbsp;
        <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained" color="primary"
        onClick={handleClick}
      >
        Acties
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={() => { setAnchorEl(null); navigate("/VKI/" + item.Id); }}>Bekijken</MenuItem>
      </Menu>
        </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default Archief;